import { GRID_CHECKBOX_SELECTION_COL_DEF } from '@mui/x-data-grid-pro';
import { produce } from 'immer';
import { ENTITIES, LANG_CONFIG, STREAM_TYPES } from '../../consts';
import { AUTH_ACTION_TYPES as actionTypesAuth } from '../auth/actions.js';
import { MODEL_ACTION_TYPES as actionTypesModel } from '../model/actions.js';
import { UI_ACTION_TYPES as actionTypes } from '../ui/actions.js';

const filterModelDefault = {
	items: [],
	logicOperator: 'and',
};

const initialState = {
	allowReviewForExamId: null,
	currentLangCode: LANG_CONFIG[0].code,
	historyListTab: [ENTITIES.DEFINITIONS],
	historySectionTab: [ENTITIES.STATS],
	examPreview: {
		videoSeek: 0,
		videoState: 'pause',
		isPip: false,
		typeFilter: STREAM_TYPES.WEBCAM,
		playbackRate: 1,
	},
	pageTitle: 'Proctoring Admin',
	xgridConfig: {
		[ENTITIES.DASHBOARD_PREVIEW]: {
			filterModel: filterModelDefault,
			sortModel: [{ field: 'endsAt', sort: 'desc' }],
			columnsOrder: [],
			invisibleComulmns: [],
			pinnedColumns: { left: [], right: [] },
		},
		[ENTITIES.NOTES]: {
			filterModel: filterModelDefault,
			sortModel: [{ field: 'createdAt', sort: 'desc' }],
			columnsOrder: [],
			invisibleComulmns: [],
			pinnedColumns: { left: [], right: [] },
		},
		[ENTITIES.DEFINITIONS]: {
			filterModel: filterModelDefault,
			sortModel: [{ field: 'endsAt', sort: 'desc' }],
			columnsOrder: [],
			invisibleComulmns: [],
			pinnedColumns: { left: ['detail', GRID_CHECKBOX_SELECTION_COL_DEF.field], right: [] },
		},
		[ENTITIES.DEFINITIONS_LIST]: {
			filterModel: filterModelDefault,
			sortModel: [{ field: 'startsAt', sort: 'desc' }],
			columnsOrder: [],
			invisibleComulmns: [],
			pinnedColumns: { left: ['detail'], right: [] },
		},
		[ENTITIES.EXAMS]: {
			filterModel: filterModelDefault,
			sortModel: [{ field: 'endedAt', sort: 'desc' }],
			columnsOrder: [],
			invisibleComulmns: [],
			pinnedColumns: { left: ['detail', GRID_CHECKBOX_SELECTION_COL_DEF.field, 'firstName', 'lastName'], right: [] },
		},
		[ENTITIES.EXAMS_FILTER]: {
			filterModel: filterModelDefault,
			sortModel: [{ field: 'endedAt', sort: 'desc' }],
			columnsOrder: [],
			invisibleComulmns: [],
			pinnedColumns: { left: ['detail'], right: [] },
		},
		[ENTITIES.POSTPONED_EXAMS]: {
			filterModel: filterModelDefault,
			sortModel: [{ field: 'assignedTo', sort: 'desc' }],
			columnsOrder: [],
			invisibleComulmns: [],
			pinnedColumns: { left: ['detail'], right: [] },
		},
		[ENTITIES.REVIEWED_EXAMS]: {
			filterModel: filterModelDefault,
			sortModel: [{ field: 'endsAt', sort: 'desc' }],
			columnsOrder: [],
			invisibleComulmns: [],
			pinnedColumns: { left: [], right: [] },
		},
		[ENTITIES.USERS]: {
			filterModel: filterModelDefault,
			sortModel: [{ field: 'email', sort: 'asc' }],
			columnsOrder: [],
			invisibleComulmns: [],
			pinnedColumns: { left: [], right: [] },
		},
		[ENTITIES.SCALING_HISTORY]: {
			filterModel: filterModelDefault,
			sortModel: [{ field: 'createdAt', sort: 'desc' }],
			columnsOrder: [],
			invisibleComulmns: [],
			pinnedColumns: { left: [], right: [] },
		},
		[ENTITIES.STATS]: {
			filterModel: filterModelDefault,
			sortModel: [{ field: 'startsAt', sort: 'desc' }],
			columnsOrder: [],
			invisibleComulmns: [],
			pinnedColumns: { left: [], right: [] },
		},
		[ENTITIES.EXAMS_PREVIOUS]: {
			filterModel: filterModelDefault,
			sortModel: [{ field: 'startsAt', sort: 'desc' }],
			columnsOrder: [],
			invisibleComulmns: [],
			pinnedColumns: { left: [], right: [] },
		},
	},
};

const model = produce((state, action) => {
	const { payload, type } = action;
	switch (type) {
		case actionTypes.SET_EXAMS_LIST_CONFIG:
			state.examsListConfig = payload.config;
			break;
		case actionTypes.SET_CURRENT_LIST_TAB:
			state.historyListTab = [payload.tabId, ...state.historyListTab];
			break;
		case actionTypes.SET_CURRENT_SECTION_TAB:
			state.historySectionTab = [payload.tabId, ...state.historySectionTab];
			break;
		case actionTypes.SET_CURRENT_LANG_CODE:
			state.currentLangCode = payload.langCode;
			break;
		case actionTypes.SET_XGRID_CONFIG:
			state.xgridConfig[payload.configEntity] = payload.config;
			break;
		case actionTypes.SET_VIDEO_SEEK:
			state.examPreview.videoSeek = payload.seek;
			break;
		case actionTypes.SET_VIDEO_STATE:
			state.examPreview.videoState = payload.state;
			break;
		case actionTypes.SET_VIDEO_PLAYBACK_RATE:
			state.examPreview.playbackRate = payload.playbackRate;
			break;
		case actionTypes.SET_IS_PIP:
			state.examPreview.isPip = payload.isPip;
			break;
		case actionTypes.SET_TYPE_FILTER:
			state.examPreview.typeFilter = payload.typeFilter;
			break;
		case actionTypes.SET_PAGE_TITLE:
			state.pageTitle = payload.pageTitle + ' | Proctoring Admin';
			break;
		case actionTypesAuth.LOGOUT_USER:
			return initialState;
		case actionTypesModel.FETCH_VIOLATIONS:
			state.allowReviewForExamId = payload.examId;
			break;
		default:
			return state;
	}
}, initialState);

export default model;
