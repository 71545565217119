import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import messages from '../../intl/messages';
import { activateUser, deactivateUser, deleteUser } from '../../store/auth/actions';
import { hideModal } from '../../store/modal/actions';
import SaveCloseModalTemplate from './SaveCloseModalTemplate';

const ActiveDeactiveRemoveUserModal = ({ activate, deactivate, user }) => {
	const intl = useIntl();
	const dispatch = useDispatch();

	const text = (
		<FormattedMessage {...messages[activate ? 'activateUser' : deactivate ? 'deactivateUser' : 'removeUser']} />
	);
	const question = (
		<FormattedMessage
			{...messages[activate ? 'sureToActivateUser' : deactivate ? 'sureToDeactivateUser' : 'sureToRemoveUser']}
		/>
	);

	const handleOnSave = () => {
		dispatch(
			activate ? activateUser(user.id, intl) : deactivate ? deactivateUser(user.id, intl) : deleteUser(user.id, intl)
		);
		dispatch(hideModal());
	};

	return (
		<SaveCloseModalTemplate title={text} saveBtnTitle={text} onSave={handleOnSave}>
			{question} <strong>{user.email}</strong> ?
		</SaveCloseModalTemplate>
	);
};

export default ActiveDeactiveRemoveUserModal;
