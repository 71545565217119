import { FormControl, MenuItem, Select } from '@mui/material';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from 'tss-react/mui';
import { LANG_CONFIG } from '../../consts';
import { setCurrentLangCode } from '../../store/ui/actions';
import { getCurrentLangCode } from '../../store/ui/selectors';

const useStyles = makeStyles()((theme) => ({
	root: {
		display: 'flex',
		'& .langSelector': {
			verticalAlign: 'middle',
			'& .MuiInputBase-input': {
				padding: '10px 35px 10px 10px',
				backgroundColor: 'white',
			},
		},
	},
}));

const LangSelector = () => {
	const { classes } = useStyles();
	const dispatch = useDispatch();
	const langCode = useSelector(getCurrentLangCode);

	return (
		<div className={classes.root}>
			<FormControl variant="outlined" className="langSelector">
				<Select value={langCode} onChange={(e) => dispatch(setCurrentLangCode(e.target.value))}>
					{LANG_CONFIG.map((lang) => (
						<MenuItem key={`lang-code-${lang.code}`} className={lang.code === langCode && 'hidden'} value={lang.code}>
							{lang.code}
						</MenuItem>
					))}
				</Select>
			</FormControl>
		</div>
	);
};

export default LangSelector;
