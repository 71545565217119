import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from 'tss-react/mui';
import { fetchScalingCluster, fetchScalingHistory } from '../../store/model/actions';
import { getScalingCluster, getScalingHistory } from '../../store/model/selectors';
import useInterval from '../../utils/useInterval';
import ScalingAutoscaler from './ScalingAutoscaler';
import ScalingCluster from './ScalingCluster';
import ScalingExam from './ScalingExam';
import ScalingHistory from './ScalingHistory';
import ScalingPostprocessing from './ScalingPostprocessing';

const useStyles = makeStyles()((theme) => ({
	bounding: {
		padding: '24px',
	},
	'main-heading': {
		margin: 0,
		fontWeight: 200,
		color: theme.palette.primary.dark,
		fontSize: '1.3rem',
		textAlign: 'center',
	},
}));

const Scaling = () => {
	const dispatch = useDispatch();
	const { classes } = useStyles();

	const history = useSelector(getScalingHistory);
	const aksCluster = useSelector(getScalingCluster);
	const [isClusterRunning, setIsClusterRunning] = useState(false);

	useEffect(() => {
		dispatch(fetchScalingCluster());
	}, [dispatch]);

	useEffect(() => {
		// Re-fetch history everytime aks cluster has caught up
		if (!isClusterRunning) setTimeout(() => dispatch(fetchScalingHistory()), 1000);
	}, [dispatch, isClusterRunning]);

	useEffect(() => {
		setIsClusterRunning(aksCluster?.powerState === 'Running' && aksCluster?.provisioningState === 'Succeeded');
	}, [aksCluster]);

	useInterval(
		() => {
			dispatch(fetchScalingCluster());
		},
		10000,
		true
	);

	return (
		<div className={classes.bounding}>
			<ScalingCluster aksCluster={aksCluster} isClusterRunning={isClusterRunning} />
			<br />
			<ScalingAutoscaler />
			{isClusterRunning ? (
				<div>
					<br />
					<ScalingExam />
					<br />
					<ScalingPostprocessing />
				</div>
			) : (
				<span></span>
			)}
			<br />
			<ScalingHistory history={history} />
		</div>
	);
};

export default Scaling;
