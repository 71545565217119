import { Button, FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { AUTHORIZED_ACTIONS, FILTER_VIOLATIONS } from '../../consts';
import messages from '../../intl/messages';
import { getUserRole, getUsers } from '../../store/auth/selectors';
import { showAddViolationModal } from '../../store/modal/actions';
import { getCurrentExam } from '../../store/model/selectors';
import { getTypeFilter } from '../../store/ui/selectors';
import checkUserAuthorization from '../../utils/userRights';

const RightBoxControls = ({ isAssignedToMe, isLoading, setUserFilter, userFilter }) => {
	const dispatch = useDispatch();

	const userRole = useSelector(getUserRole);
	const canReviewAsCommissioner = checkUserAuthorization(userRole, AUTHORIZED_ACTIONS.REVIEW_AS_COMMISSIONER);
	const canReviewAsReviewer = checkUserAuthorization(userRole, AUTHORIZED_ACTIONS.REVIEW_AS_REVIEWER);

	const users = useSelector(getUsers);
	const exam = useSelector(getCurrentExam);
	const typeFilter = useSelector(getTypeFilter);

	const reviewedByReviewer = users?.[exam?.reviewerReviewedBy]?.email ?? null;
	const reviewedByCommissioner = users?.[exam?.commissionerReviewedBy]?.email ?? null;

	return (
		<>
			<FormControl variant="outlined">
				<InputLabel id="user-filter-label">
					<FormattedMessage {...messages.filterBy} />
				</InputLabel>
				<Select
					variant="outlined"
					id="user-filter-select"
					labelId="user-filter-label"
					value={userFilter}
					onChange={(e) => setUserFilter(e.target.value)}
					label="Filter by"
					fullWidth
					className="userFilterSelect"
				>
					<MenuItem value={FILTER_VIOLATIONS.ALL}>
						<FormattedMessage {...messages.all} />
					</MenuItem>
					<MenuItem value={FILTER_VIOLATIONS.GROUPS}>
						<FormattedMessage {...messages.groups} />
					</MenuItem>
					<MenuItem value={FILTER_VIOLATIONS.SUSP}>
						<FormattedMessage {...messages.onlySuspicious} />
					</MenuItem>
					<MenuItem value={FILTER_VIOLATIONS.NO_REVIEW}>
						<FormattedMessage {...messages.onlyUnreviewed} />
					</MenuItem>
				</Select>
			</FormControl>
			{canReviewAsCommissioner && reviewedByReviewer && (
				<span style={{ margin: '0 10px', lineHeight: '45px' }}>
					<FormattedMessage {...messages.reviewer} />: {reviewedByReviewer}
				</span>
			)}
			{canReviewAsCommissioner && reviewedByCommissioner && (
				<span style={{ margin: '0 10px', lineHeight: '45px' }}>
					<FormattedMessage {...messages.commissioner} />: {reviewedByCommissioner}
				</span>
			)}

			{!isLoading && (canReviewAsReviewer || (canReviewAsCommissioner && isAssignedToMe)) && (
				<Button
					className="floatRight"
					variant="contained"
					color="primary"
					onClick={() => dispatch(showAddViolationModal(typeFilter))}
				>
					<FormattedMessage {...messages.addViolation} />
				</Button>
			)}
		</>
	);
};

RightBoxControls.propTypes = {
	isAssignedToMe: PropTypes.bool,
	isLoading: PropTypes.bool,
	setUserFilter: PropTypes.func,
	userFilter: PropTypes.string,
};

export default RightBoxControls;
