const formatDate = (date) => {
	if (!date || date === '-' || date === '') return '-';

	return new Intl.DateTimeFormat('cs-CZ', {
		year: 'numeric',
		month: 'numeric',
		day: 'numeric',
		hour: 'numeric',
		minute: 'numeric',
		second: 'numeric',
		hour12: false,
	}).format(new Date(date));
};

export default formatDate;

export const msToTime = (s, wholeFormat) => {
	const ms = s % 1000;
	s = (s - ms) / 1000;
	let secs = s % 60;
	s = (s - secs) / 60;
	let mins = s % 60;
	let hrs = (s - mins) / 60;
	mins = mins < 10 ? '0' + mins : mins;
	hrs = hrs < 10 ? '0' + hrs : hrs;
	secs = secs < 10 ? '0' + secs : secs;

	return wholeFormat ? hrs + ':' + mins + ':' + secs : hrs === '00' ? mins + ':' + secs : hrs + ':' + mins + ':' + secs;
};

export const toDate = (date) => {
	const _date = new Date(date);
	if (isNaN(date)) return date.substr(0, 10);
	else return _date.toISOString().substr(0, 10);
};

export const toDateCzLocalized = (date) => {
	if (!date || date === '-' || date === '') return '-';

	return new Intl.DateTimeFormat('cs-CZ', {
		year: 'numeric',
		month: 'numeric',
		day: 'numeric',
		hour12: false,
	}).format(new Date(date));
};

export const toTimestamp = (date) => {
	const _date = new Date(date);
	return _date.getTime();
};
