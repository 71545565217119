import { Dialog } from '@mui/material';
import { isEmpty } from 'ramda';
import React, { useEffect, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { makeStyles } from 'tss-react/mui';
import messages from '../intl/messages';
import { getFaceUrl, getIdUrl } from '../store/model/selectors';

const useStyles = makeStyles()((theme) => ({
	root: {
		width: '100%',
		height: '471px',
		'& > div': {
			width: '100%',
			display: 'flex',
			alignItems: 'stretch',
			justifyContent: 'center',
			padding: '20px 0',
			gap: '2%',
		},
		'& > div > *': {
			flex: 1,
			flexBasis: 0,
			border: '1px solid black',
			boxSizing: 'border-box',
			display: 'inline-flex',
			alignItems: 'center',
			justifyContent: 'center',
			maxWidth: '49%',
			minHeight: '280px',
		},
		'& div img': {
			height: 'auto',
			border: 'none',
		},
	},
}));

const NoPhoto = ({ id }) => (
	<div className="noPhoto">
		<FormattedMessage {...messages[id ? 'idPhotoNotAvailable' : 'facePhotoNotAvailable']} />
	</div>
);

const Identification = () => {
	const { classes } = useStyles();
	const idUrl = useSelector(getIdUrl);
	const faceUrl = useSelector(getFaceUrl);
	const [image, setImage] = useState(null);
	const idRef = useRef();
	const faceRef = useRef();
	const zoomRef = useRef();

	// Prevent context menu on mouse right click
	useEffect(() => {
		if (idRef.current) idRef.current.addEventListener('contextmenu', (e) => e.preventDefault());
		if (faceRef.current) faceRef.current.addEventListener('contextmenu', (e) => e.preventDefault());
		if (image) {
			setTimeout(() => {
				zoomRef?.current?.addEventListener('contextmenu', (e) => e.preventDefault());
			}, 1);
		}
	}, [idRef, faceRef, image]);

	return (
		<div className={`${classes.root}`}>
			<div>
				{image && (
					<Dialog maxWidth={'xl'} open={true} onClick={() => setImage(null)}>
						<img draggable="false" ref={zoomRef} src={image} alt="" style={{ cursor: 'zoom-out' }} />
					</Dialog>
				)}
				{!isEmpty(idUrl) ? (
					<img
						draggable="false"
						ref={idRef}
						alt="Id"
						src={idUrl}
						onClick={() => setImage(idUrl)}
						style={{ cursor: 'zoom-in' }}
					/>
				) : (
					<NoPhoto id />
				)}
				{!isEmpty(faceUrl) ? (
					<img
						draggable="false"
						ref={faceRef}
						alt="Face"
						src={faceUrl}
						onClick={() => setImage(faceUrl)}
						style={{ cursor: 'zoom-in' }}
					/>
				) : (
					<NoPhoto face />
				)}
			</div>
		</div>
	);
};

export default Identification;
