import Alert from '@mui/material/Alert';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { ENTITIES, EVALUATION_GROUPS, STREAM_TYPES } from '../../consts';
import messages from '../../intl/messages';
import { getInstructionsByType } from '../../store/model/selectors';
import { getTypeFilter } from '../../store/ui/selectors';

const Instructions = () => {
	const typeFilter = useSelector(getTypeFilter);
	const instructions = useSelector(getInstructionsByType);

	const type =
		typeFilter === STREAM_TYPES.IMAGE
			? EVALUATION_GROUPS.IDENTIFICATION
			: typeFilter === ENTITIES.VIDEO_ROOM
			? EVALUATION_GROUPS.ROOM
			: typeFilter;

	return instructions?.[type] ? (
		<Alert severity="warning">
			<strong>
				<FormattedMessage {...messages.instructions} />{' '}
			</strong>
			<span dangerouslySetInnerHTML={{ __html: instructions[type].text }} />
		</Alert>
	) : null;
};

export default Instructions;
