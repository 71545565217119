import { mergeDeepRight, omit } from 'ramda';
import { toTimestamp } from '../utils/formatDate';
import trimErrMsg from '../utils/trimErrMsg';
import { AUTH_ACTION_TYPES } from './auth/actions';
import { MODEL_ACTION_TYPES } from './model/actions';
import { NOTIF_ACTION_TYPES } from './notif/actions';

export const catchFail = ({ callback, dispatch, e, type }) => {
	if (!e) return;

	if (e === 'REPEAT_ORIGINAL_CALL') {
		dispatch(callback());
		return;
	}

	if (
		type === MODEL_ACTION_TYPES.FETCH_WEBCAM_URL ||
		type === MODEL_ACTION_TYPES.FETCH_SCREEN_URL ||
		type === MODEL_ACTION_TYPES.FETCH_ROOM_VIDEO_URL ||
		type === MODEL_ACTION_TYPES.FETCH_ID_URL ||
		type === MODEL_ACTION_TYPES.FETCH_FACE_URL ||
		type === AUTH_ACTION_TYPES.CHANGE_PASSWORD ||
		type === AUTH_ACTION_TYPES.GET_USER_BY_TOKEN
	) {
		return;
	}

	if (e?.response?.status !== 401) {
		dispatch({
			type: NOTIF_ACTION_TYPES.ERROR,
			payload: { type, message: e?.response?.data?.error ?? trimErrMsg(e) },
		});
	}
};

/**
 * state.exams should be replaced if exams for a different definition are fetched
 * if exams are refetched though, merge the 2 lists
 * some properties that are fetched individually would otherwise get lost (e.g. webcamUrl, screenUrl, ...)
 */
export const mergeWithExistingExams = (existingExams, newExams) => {
	for (const [id, exam] of Object.entries(newExams)) {
		if (existingExams[id]) {
			newExams[id] = mergeDeepRight(existingExams[id], exam);
		}
	}
	return newExams;
};

export const prepareDefinitionForServer = (definition) => ({
	...omit(['runningExamsCount', 'finishedExamsCount'], definition),
	endsAt: toTimestamp(definition.endsAt),
	startsAt: toTimestamp(definition.startsAt),
	// Convert potentially null value
	isDefault: !!definition.isDefault,
	participantsCount: definition.isOnboarding ? 1 : Number(definition.participantsCount),
});
