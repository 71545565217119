import { MODAL_TYPES } from '../../consts';
import { createActionsMap, makeActionCreator } from '../../utils/redux';

export const ID = 'modal';

export const actionTypes = createActionsMap(ID, ['SHOW_MODAL', 'HIDE_MODAL']);

const showModal = makeActionCreator(actionTypes.SHOW_MODAL, 'modalType', 'modalProps');
export const hideModal = makeActionCreator(actionTypes.HIDE_MODAL);

export const showProcessModal = (process) => showModal(MODAL_TYPES.PROCESS, { process });
export const showAddViolationModal = () => showModal(MODAL_TYPES.ADD_VIOLATION);
export const showConfirmReviewModal = (exit) => showModal(MODAL_TYPES.CONFIRM_REVIEW, { exit });
export const showConfirmModal = ({ handler, title, text, button }) =>
	showModal(MODAL_TYPES.CONFIRM, { handler, title, text, button });
export const showEditNoteModal = ({ noteId }) => showModal(MODAL_TYPES.EDIT_NOTE, { noteId });
export const showEditTextModal = (payload) => showModal(MODAL_TYPES.EDIT_TEXT, payload);
export const showRemoveDefinitionModal = (definitionId) => showModal(MODAL_TYPES.REMOVE_DEFINITION, { definitionId });
export const showFinishDefinitionExamsModal = (definitionId) =>
	showModal(MODAL_TYPES.FINISH_DEFINITION_EXAMS, { definitionId });
export const showEditUserModal = ({ userId }) => showModal(MODAL_TYPES.EDIT_USER, { userId });
export const showForgotPasswordModal = ({ email }) => showModal(MODAL_TYPES.FORGOT_PASSWORD, { email });
export const showActiveDeactiveRemoveUserModal = ({ activate, deactivate, user }) =>
	showModal(MODAL_TYPES.ACTIVATE_DEACTIVATE_REMOVE_USER, { activate, deactivate, user });
export const showAssignReviewModal = (examIds, callback) =>
	showModal(MODAL_TYPES.ASSIGN_REVIEWS, { examIds, callback });
export const showRemoveExamsModal = (examIds, clearSelectionModel) =>
	showModal(MODAL_TYPES.REMOVE_EXAMS, { examIds, clearSelectionModel });
