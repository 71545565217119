import { useSnackbar } from 'notistack';
import { isEmpty, omit } from 'ramda';
import { useDispatch, useSelector } from 'react-redux';
import { NOTIF_ACTION_TYPES } from '../store/notif/actions';
import { getNotifErrors, getNotifSuccess } from '../store/notif/selectors';

const Notifications = () => {
	const { closeSnackbar, enqueueSnackbar } = useSnackbar();
	const errorMsgs = useSelector(getNotifErrors);
	const successMsgs = useSelector(getNotifSuccess);
	const dispatch = useDispatch();

	if (isEmpty(errorMsgs) && isEmpty(successMsgs)) closeSnackbar();
	else {
		for (const msgType of Object.values(omit([NOTIF_ACTION_TYPES.CLEAN_STATE], NOTIF_ACTION_TYPES))) {
			for (const msg of Object.values(msgType === NOTIF_ACTION_TYPES.ERROR ? errorMsgs : successMsgs)) {
				enqueueSnackbar(msg, {
					variant: msgType.toLowerCase(),
					onClose: () => dispatch({ type: NOTIF_ACTION_TYPES.CLEAN_STATE }),
				});
			}
		}
	}

	return null;
};

export default Notifications;
