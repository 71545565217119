import { TextField } from '@mui/material';
import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import messages from '../../intl/messages';
import { forgetPassword } from '../../store/auth/actions';
import { hideModal } from '../../store/modal/actions';
import SaveCloseModalTemplate from './SaveCloseModalTemplate';

const ForgotPasswordModal = ({ email: emailProp }) => {
	const intl = useIntl();
	const dispatch = useDispatch();
	const [email, setEmail] = useState(emailProp);

	const handleSendEmail = async () => {
		if (!email) {
			return;
		}

		dispatch(forgetPassword({ email, intl }));
		dispatch(hideModal());
	};

	return (
		<SaveCloseModalTemplate
			title={<FormattedMessage {...messages.forgotPassword} />}
			saveBtnTitle={<FormattedMessage {...messages.send} />}
			onSave={handleSendEmail}
		>
			<TextField
				onChange={(e) => setEmail(e.target.value)}
				fullWidth
				label={<FormattedMessage {...messages.email} />}
				value={email}
				variant="outlined"
			/>
		</SaveCloseModalTemplate>
	);
};

export default ForgotPasswordModal;
