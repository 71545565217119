import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTimePicker as DateAndTimePickerMui } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';
import { makeStyles } from 'tss-react/mui';
import 'dayjs/locale/cs';

const useStyles = makeStyles()((theme) => ({
	container: {
		display: 'inline-flex',
		'& .MuiFormControl-root': {
			width: '100%',
		},
	},
	textField: {
		marginLeft: theme.spacing(1),
		marginRight: theme.spacing(1),
		width: '100%',
	},
}));

const DateAndTimePicker = ({ classname, defaultValue, label, onChange }) => {
	const { classes } = useStyles();
	const displayDateOnly = defaultValue.length === 10;

	return (
		<form className={`${classes.container} ${classname}`} noValidate>
			<LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="cs">
				<DateAndTimePickerMui
					label={label}
					defaultValue={dayjs(defaultValue)}
					type={displayDateOnly ? 'date' : 'datetime-local'}
					onChange={onChange}
				/>
			</LocalizationProvider>
		</form>
	);
};

export default DateAndTimePicker;
