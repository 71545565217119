import { Paper } from '@mui/material';
import React from 'react';
import { useIntl } from 'react-intl';
import { ENTITIES, YES_NO_VALUE_OPTIONS } from '../../consts';
import messages from '../../intl/messages';
import formatDate from '../../utils/formatDate';
import { numericOperators, selectOperators } from '../../utils/prepareXGridData';
import DataGrid from '../DataGrid';
import { useStyles } from '../Exams/ExamsList';

const ScalingHistory = ({ history: rows }) => {
	const { classes } = useStyles();
	const intl = useIntl();

	const columns = [
		{
			field: 'id',
			headerName: intl.formatMessage(messages.id),
			flex: 1,
			type: 'number',
			headerAlign: 'right',
			align: 'right',
			filterOperators: numericOperators(intl),
		},
		{
			field: 'numberOfUsers',
			headerName: intl.formatMessage(messages.users),
			flex: 1,
			type: 'number',
			headerAlign: 'right',
			align: 'right',
			filterOperators: numericOperators(intl),
		},
		{
			field: 'createdAt',
			headerName: intl.formatMessage(messages.startsAt),
			flex: 2,
			valueFormatter: ({ value }) => formatDate(value).slice(0, -3),
			type: 'dateTime',
			valueGetter: ({ value }) => new Date(value),
		},
		{
			field: 'isManualScaling',
			headerName: intl.formatMessage(messages.isManualScaling),
			flex: 2,
			type: 'singleSelect',
			filterOperators: selectOperators(YES_NO_VALUE_OPTIONS, intl, false),
		},
		{
			field: 'wasScaledByLoad',
			headerName: intl.formatMessage(messages.wasScaledByLoad),
			flex: 2,
			type: 'singleSelect',
			filterOperators: selectOperators(YES_NO_VALUE_OPTIONS, intl, false),
		},
	];

	const dataGridConfig = { columns, gridId: ENTITIES.SCALING_HISTORY, rows };

	return (
		<Paper className={classes.bounding} style={{ height: '345px' }}>
			<h2>{intl.formatMessage(messages.scalingHistory)}</h2>
			<div style={{ height: '345px' }}>
				<DataGrid {...dataGridConfig} />
			</div>
		</Paper>
	);
};

export default ScalingHistory;
