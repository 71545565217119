import { fromPairs } from 'ramda';

/**
 * Creates object with names as keys and value as name + given prefix.
 *
 * @param {String} prefix Literal prepended the value divided by /.
 * @param {Array} names Array of action names used for value generation e.g. ['SAVE_DATA', 'LOAD_ENTITIES'].
 */
export const createActionsMap = (prefix, names) => fromPairs(names.map((n) => [n, `${prefix}/${n}`]));

/**
 * Makes action creator.
 *
 * @param {string} type - Action type e.g. ASYNC_ACTION_STATES.SUCCESS.
 * @param  {...string} argNames - Action arguments for payload props.
 *
 * @example
 * const addAction = makeActionCreator('ADD', 'id', 'value')
 * addAction(1, 'Some value'); // => { type: 'ADD', payload: { id: 1, value: 'Some value'}}
 */
export const makeActionCreator =
	(type, ...argNames) =>
	(...args) => {
		const action = { type, payload: {} };
		argNames.forEach((arg, index) => {
			action.payload[arg] = args[index];
		});
		return action;
	};
