import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import messages from '../../intl/messages';
import { hideModal } from '../../store/modal/actions';
import { finishDefinitionExams } from '../../store/model/actions';
import SaveCloseModalTemplate from './SaveCloseModalTemplate';

const FinishDefinitionExamsModal = ({ definitionId }) => {
	const dispatch = useDispatch();
	const intl = useIntl();

	return (
		<SaveCloseModalTemplate
			title={<FormattedMessage {...messages.finishExams} />}
			saveBtnTitle={<FormattedMessage {...messages.finishExams} />}
			onSave={() => {
				dispatch(finishDefinitionExams(definitionId, intl));
				dispatch(hideModal());
			}}
		>
			<FormattedMessage {...messages.sureToFinishDefinitionExams} />
		</SaveCloseModalTemplate>
	);
};

export default FinishDefinitionExamsModal;
