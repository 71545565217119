import { ThumbDown } from '@mui/icons-material';
import { Badge, Button, Tooltip } from '@mui/material';
import { flatten } from 'ramda';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from 'tss-react/mui';
import { ENTITIES, REVIEW, STREAM_TYPES } from '../../consts';
import messages from '../../intl/messages';
import { getScreenUrl, getViolations, getViolationsRoomScan, getWebcamUrl } from '../../store/model/selectors';
import { setTypeFilter } from '../../store/ui/actions';
import { getTypeFilter } from '../../store/ui/selectors';
import { filterByType } from '../ViolationsList';

const useStyles = makeStyles()((theme) => ({
	root: {
		'& .MuiAlert-root': {
			marginBottom: 15,
			padding: '5px 15px',
		},
		'& .active': {
			backgroundColor: theme.palette.primary.dark,
			color: 'white',
		},
		'& .MuiBadge-root': {
			'& svg': {
				width: '0.75em',
				height: '0.75em',
			},
		},
	},
}));

const determineThumbColor = (violations) => {
	for (const violation of violations) {
		if (violation[REVIEW.TYPES.COMMISSIONER] === 'NOK') return 'error';
	}

	return 'warning';
};

const LeftBoxControls = () => {
	const { classes } = useStyles();
	const typeFilter = useSelector(getTypeFilter);
	const screenUrl = useSelector(getScreenUrl);
	const webcamUrl = useSelector(getWebcamUrl);
	const violations = useSelector(getViolations);
	const violationsRoomScan = useSelector(getViolationsRoomScan); // returns all violations but timestamps are counted in favor to room scan
	const dispatch = useDispatch();

	const violationsByType = {
		[STREAM_TYPES.IMAGE]: flatten(Object.values(filterByType(violations, STREAM_TYPES.IMAGE))),
		[ENTITIES.VIDEO_ROOM]: flatten(Object.values(filterByType(violationsRoomScan, ENTITIES.VIDEO_ROOM))),
		[STREAM_TYPES.SCREEN]: flatten(Object.values(filterByType(violations, STREAM_TYPES.SCREEN))),
		[STREAM_TYPES.WEBCAM]: flatten(Object.values(filterByType(violations, STREAM_TYPES.WEBCAM))),
	};

	const violationCounts = {
		[STREAM_TYPES.IMAGE]: violationsByType[STREAM_TYPES.IMAGE].length / 2,
		[ENTITIES.VIDEO_ROOM]: violationsByType[ENTITIES.VIDEO_ROOM].length / 2,
		[STREAM_TYPES.SCREEN]: violationsByType[STREAM_TYPES.SCREEN].length / 2,
		[STREAM_TYPES.WEBCAM]: violationsByType[STREAM_TYPES.WEBCAM].length / 2,
	};

	const showViolationThumbs = {
		[STREAM_TYPES.IMAGE]: violationsByType[STREAM_TYPES.IMAGE].some(
			(violation) => violation[REVIEW.TYPES.REVIEWER] === 'NOK' || violation[REVIEW.TYPES.COMMISSIONER] === 'NOK'
		),
		[ENTITIES.VIDEO_ROOM]: violationsByType[ENTITIES.VIDEO_ROOM].some(
			(violation) => violation[REVIEW.TYPES.REVIEWER] === 'NOK' || violation[REVIEW.TYPES.COMMISSIONER] === 'NOK'
		),
		[STREAM_TYPES.SCREEN]: violationsByType[STREAM_TYPES.SCREEN].some(
			(violation) => violation[REVIEW.TYPES.REVIEWER] === 'NOK' || violation[REVIEW.TYPES.COMMISSIONER] === 'NOK'
		),
		[STREAM_TYPES.WEBCAM]: violationsByType[STREAM_TYPES.WEBCAM].some(
			(violation) => violation[REVIEW.TYPES.REVIEWER] === 'NOK' || violation[REVIEW.TYPES.COMMISSIONER] === 'NOK'
		),
	};

	return (
		<div className={classes.root}>
			<Tooltip title={<FormattedMessage {...messages.identification} />}>
				<span>
					<Badge
						overlap="circular"
						badgeContent={
							showViolationThumbs[STREAM_TYPES.IMAGE] ? (
								<ThumbDown color={determineThumbColor(violationsByType[STREAM_TYPES.IMAGE])} />
							) : null
						}
					>
						<Button
							variant="contained"
							color="primary"
							className={typeFilter === STREAM_TYPES.IMAGE && 'active'}
							onClick={() => dispatch(setTypeFilter(STREAM_TYPES.IMAGE))}
						>
							<FormattedMessage {...messages.identification} /> ({violationCounts[STREAM_TYPES.IMAGE]})
						</Button>
					</Badge>
				</span>
			</Tooltip>

			<Tooltip title={<FormattedMessage {...messages.room} />}>
				<span>
					<Badge
						overlap="circular"
						badgeContent={
							showViolationThumbs[ENTITIES.VIDEO_ROOM] ? (
								<ThumbDown color={determineThumbColor(violationsByType[ENTITIES.VIDEO_ROOM])} />
							) : null
						}
					>
						<Button
							variant="contained"
							color="primary"
							className={typeFilter === ENTITIES.VIDEO_ROOM && 'active'}
							onClick={() => dispatch(setTypeFilter(ENTITIES.VIDEO_ROOM))}
						>
							<FormattedMessage {...messages.room} /> ({violationCounts[ENTITIES.VIDEO_ROOM]})
						</Button>
					</Badge>
				</span>
			</Tooltip>

			<Tooltip
				title={screenUrl ? <FormattedMessage {...messages.screen} /> : <FormattedMessage {...messages.assetMissing} />}
			>
				<span>
					<Badge
						overlap="circular"
						badgeContent={
							showViolationThumbs[STREAM_TYPES.SCREEN] ? (
								<ThumbDown color={determineThumbColor(violationsByType[STREAM_TYPES.SCREEN])} />
							) : null
						}
					>
						<Button
							variant="contained"
							color="primary"
							className={typeFilter === STREAM_TYPES.SCREEN && 'active'}
							onClick={() => dispatch(setTypeFilter(STREAM_TYPES.SCREEN))}
						>
							<FormattedMessage {...messages.screen} /> ({violationCounts[STREAM_TYPES.SCREEN]})
						</Button>
					</Badge>
				</span>
			</Tooltip>

			<Tooltip
				title={webcamUrl ? <FormattedMessage {...messages.camera} /> : <FormattedMessage {...messages.assetMissing} />}
			>
				<span>
					<Badge
						overlap="circular"
						badgeContent={
							showViolationThumbs[STREAM_TYPES.WEBCAM] ? (
								<ThumbDown color={determineThumbColor(violationsByType[STREAM_TYPES.WEBCAM])} />
							) : null
						}
					>
						<Button
							variant="contained"
							color="primary"
							className={typeFilter === STREAM_TYPES.WEBCAM && 'active'}
							onClick={() => dispatch(setTypeFilter(STREAM_TYPES.WEBCAM))}
						>
							<FormattedMessage {...messages.camera} /> ({violationCounts[STREAM_TYPES.WEBCAM]})
						</Button>
					</Badge>
				</span>
			</Tooltip>
		</div>
	);
};

export default LeftBoxControls;
