import { Save, Add, ExpandMore, ChevronRight } from '@mui/icons-material';
import { Grid, Paper, TextField } from '@mui/material';
import { yellow } from '@mui/material/colors';
import { difference, isEmpty, update } from 'ramda';
import React, { useEffect, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from 'tss-react/mui';
import { CHECKPOINTS_TYPE, CHECKPOINT_GROUP } from '../../consts';
import messages from '../../intl/messages';
import { showProcessModal } from '../../store/modal/actions';
import { editCheckpoints, fetchCheckpoints, setProcessWasEdited } from '../../store/model/actions';
import { getConfigDefinitionId, getCheckpoints, getProcessWasEdited } from '../../store/model/selectors';
import { getCurrentLangCode } from '../../store/ui/selectors';
import Switch from '../Switch';
import UniButtton from '../UniButtton';

const useStyles = makeStyles()((theme) => ({
	bounding: {
		padding: '0.2rem 2rem 2rem 2rem',
		fontSize: '0.9rem',
		'&.draft': {
			background: yellow['100'],
		},
		'& h2': {
			display: 'inline-block',
		},
		'& .buttonsContainer': { float: 'right', marginTop: '7px', display: 'flex' },
		'& span.saveIcon': { margin: '19px' },
		'& .header': {
			fontWeight: 'bold',
			fontSize: '0.8rem',
			textAlign: 'center',
			padding: '10px 0',
		},
		'& .clickable': {
			cursor: 'pointer',
		},
		'& .row': {
			textAlign: 'center',
			'& .alignCenter': {
				textAlign: 'center',
				'& input': {
					textAlign: 'center',
				},
			},
			'& .checkpointTypeColumn': {
				position: 'relative',
			},
		},
		'& .toggle': {
			cursor: 'pointer',
		},
	},
}));

// Sort table by tab and evaluationGroup, alphabetically descending
const comparator = (a, b) => {
	if (
		a.checkpointGroup === CHECKPOINT_GROUP.PROCESS_ANALYSIS &&
		b.checkpointGroup !== CHECKPOINT_GROUP.PROCESS_ANALYSIS
	)
		return -1;
	else if (
		b.checkpointGroup === CHECKPOINT_GROUP.PROCESS_ANALYSIS &&
		a.checkpointGroup !== CHECKPOINT_GROUP.PROCESS_ANALYSIS
	)
		return 1;

	if (a.tab < b.tab) return -1;
	else if (a.tab === b.tab) {
		if (a.evaluationGroup < b.evaluationGroup) return -1;
		else return 1;
	} else return 1;
};

let nthProcessType = 0;

const CheckpointRow = ({
	checkpointGroup,
	checkpointType,
	confidenceThreshold,
	confidenceThresholdLow,
	displayed,
	enabled,
	evaluationGroup,
	frameOccurence,
	id,
	name,
	streamType,
	violationMessage,
	tab,
	index,
	state,
	setState,
	checkpoint,
}) => {
	const dispatch = useDispatch();

	return (
		<Grid
			container
			spacing={2}
			key={id}
			className={`row ${checkpointGroup === CHECKPOINT_GROUP.PROCESS_ANALYSIS ? 'clickable' : ''}`}
			direction="row"
			justifyContent="center"
			alignItems="center"
			// This requires click stop handlers in input fields
			onClick={
				checkpointGroup === CHECKPOINT_GROUP.PROCESS_ANALYSIS ? () => dispatch(showProcessModal(checkpoint)) : null
			}
		>
			<Grid item xs={1} className="checkpointTypeColumn">
				{checkpointType}
			</Grid>
			<Grid item xs={1} className="checkpointTypeColumn">
				{tab}
			</Grid>
			<Grid item xs={1}>
				{streamType}
			</Grid>
			<Grid item xs={1}>
				{checkpointGroup}
			</Grid>
			<Grid item xs={1}>
				{evaluationGroup}
			</Grid>
			<Grid item xs={1}>
				<div>{name}</div>
			</Grid>
			<Grid item xs={2}>
				<Grid container spacing={2} direction="row" justifyContent="center" alignItems="center">
					<Grid item xs={4}>
						<TextField
							value={confidenceThreshold}
							onChange={(e) =>
								setState(update(index, { ...state[index], confidenceThreshold: Number(e.target.value) }, state))
							}
							onClick={(e) => e.stopPropagation()}
							variant="outlined"
							size="small"
							className="alignCenter"
						/>
					</Grid>
					<Grid item xs={4}>
						<TextField
							value={confidenceThresholdLow}
							onChange={(e) =>
								setState(update(index, { ...state[index], confidenceThresholdLow: Number(e.target.value) }, state))
							}
							onClick={(e) => e.stopPropagation()}
							variant="outlined"
							size="small"
							className="alignCenter"
						/>
					</Grid>
					<Grid item xs={4}>
						<TextField
							value={frameOccurence}
							onChange={(e) =>
								setState(update(index, { ...state[index], frameOccurence: Number(e.target.value) }, state))
							}
							onClick={(e) => e.stopPropagation()}
							variant="outlined"
							size="small"
							className="alignCenter"
						/>
					</Grid>
				</Grid>
			</Grid>
			<Grid item xs={2}>
				<TextField
					value={violationMessage}
					onChange={(e) => setState(update(index, { ...state[index], violationMessage: e.target.value }, state))}
					onClick={(e) => e.stopPropagation()}
					variant="outlined"
					size="small"
					fullWidth
				/>
			</Grid>
			{/* Switches don't have a click handler... */}
			<Grid item xs={1} onClick={(e) => e.stopPropagation()}>
				<Switch
					checked={displayed}
					handleChange={(e) => setState(update(index, { ...state[index], displayed: !displayed }, state))}
					labelPlacement="top"
				/>
			</Grid>
			<Grid item xs={1} onClick={(e) => e.stopPropagation()}>
				<Switch
					checked={enabled}
					handleChange={(e) => setState(update(index, { ...state[index], enabled: !enabled }, state))}
					labelPlacement="top"
				/>
			</Grid>
		</Grid>
	);
};

const isAllOn = (checkpoints, prop) => {
	for (const checkpoint of checkpoints) {
		if (
			!checkpoint[prop] &&
			checkpoint.checkpointType === CHECKPOINTS_TYPE.PROCESS &&
			checkpoint.checkpointGroup === CHECKPOINT_GROUP.PROCESS_ANALYSIS
		)
			return false;
	}
	return true;
};

const setAllTo = (checkpoints, prop, value) =>
	checkpoints.map((checkpoint) =>
		checkpoint.checkpointType === CHECKPOINTS_TYPE.PROCESS &&
		checkpoint.checkpointGroup === CHECKPOINT_GROUP.PROCESS_ANALYSIS
			? { ...checkpoint, [prop]: value }
			: checkpoint
	);

const Checkpoints = () => {
	const { classes } = useStyles();
	const definitionId = useSelector(getConfigDefinitionId);
	const checkpoints = useSelector(getCheckpoints(definitionId));
	const [state, setState] = useState(checkpoints.sort(comparator));
	const [isProcessTypeVisible, setIsProcessTypeVisible] = useState(false);
	const [isSaving, setIsSaving] = useState(false);
	const dispatch = useDispatch();
	const [isDraft, setIsDraft] = useState(false);
	const currentLangCode = useSelector(getCurrentLangCode);
	const processWasEdited = useSelector(getProcessWasEdited);
	const areAllProcessTypesDisplayed = useMemo(() => isAllOn(state, 'displayed'), [state]);
	const areAllProcessTypesEnabled = useMemo(() => isAllOn(state, 'enabled'), [state]);

	const displayedAll = state.every((checkpoint) => checkpoint.displayed);
	const enabledAll = state.every((checkpoint) => checkpoint.enabled);

	useEffect(() => {
		(async () => {
			await dispatch(fetchCheckpoints(definitionId));
			setState([]);
		})();
	}, [currentLangCode, definitionId, dispatch]);

	useEffect(() => {
		if (!isEmpty(checkpoints)) {
			if (isEmpty(state)) setState(checkpoints);

			// equals() does not seem to work in this case
			if (difference(checkpoints, state).length === 0) {
				window.onbeforeunload = () => {};
				setIsDraft(false);
				// In case new checkpoints (processes) were created/deleted
				// A special flag has to be raised when a process is edited using the dialog
			} else if (state.length !== checkpoints.length || processWasEdited) {
				setState(checkpoints);
				dispatch(setProcessWasEdited(false));
			} else if (!isDraft && !isEmpty(state)) {
				window.onbeforeunload = () => false;
				setIsDraft(true);
			}
		}
	}, [checkpoints, dispatch, isDraft, processWasEdited, state]);

	const handleSave = async () => {
		setIsSaving(true);
		await dispatch(editCheckpoints(state, definitionId));
		setIsSaving(false);
		window.onbeforeunload = () => {};
	};

	return (
		<Paper elevation={3} className={`${classes.bounding} ${isDraft && 'draft'}`}>
			<h2>
				<FormattedMessage {...messages.checkpoints} />
			</h2>
			<div className="buttonsContainer">
				{isSaving ? (
					<span className="saveIcon">
						<FormattedMessage {...messages.uploading} /> ...
					</span>
				) : (
					<UniButtton
						tooltip={<FormattedMessage {...messages[isDraft ? 'saveAllCheckpoints' : 'noChanges']} />}
						color="primary"
						onClick={handleSave}
						icon={<Save fontSize="large" />}
						disabled={!isDraft}
					/>
				)}
			</div>
			<Grid container spacing={2} className="header" direction="row" justifyContent="center" alignItems="center">
				<Grid item xs={1}>
					<FormattedMessage {...messages.type} />
				</Grid>
				<Grid item xs={1}>
					<FormattedMessage {...messages.panel} />
				</Grid>
				<Grid item xs={1}>
					<FormattedMessage {...messages.streamType} />
				</Grid>
				<Grid item xs={1}>
					<FormattedMessage {...messages.checkpointGroup} />
				</Grid>
				<Grid item xs={1}>
					<FormattedMessage {...messages.evaluationGroup} />
				</Grid>
				<Grid item xs={1}>
					<FormattedMessage {...messages.name} />
				</Grid>
				<Grid item xs={2}>
					<Grid container spacing={2} direction="row" justifyContent="center" alignItems="center">
						<Grid item xs={4}>
							<FormattedMessage {...messages.confidanceTreshold} />
						</Grid>
						<Grid item xs={4}>
							<FormattedMessage {...messages.confidanceTresholdLow} />
						</Grid>
						<Grid item xs={4}>
							<FormattedMessage {...messages.frameOccurance} />
						</Grid>
					</Grid>
				</Grid>
				<Grid item xs={2}>
					<FormattedMessage {...messages.violationMessage} />
				</Grid>
				<Grid item xs={1}>
					<FormattedMessage {...messages.display} />
				</Grid>
				<Grid item xs={1}>
					<FormattedMessage {...messages.enabled} />
				</Grid>
			</Grid>
			<Grid container spacing={2}>
				<Grid item xs={10}></Grid>
				{/* Switches don't have a click handler... */}
				<Grid item xs={1} onClick={(e) => e.stopPropagation()}>
					<Switch
						checked={displayedAll}
						handleChange={(e) => setState(state.map((checkpoint) => ({ ...checkpoint, displayed: !displayedAll })))}
						labelPlacement="top"
					/>
				</Grid>
				<Grid item xs={1} onClick={(e) => e.stopPropagation()}>
					<Switch
						checked={enabledAll}
						handleChange={(e) => setState(state.map((checkpoint) => ({ ...checkpoint, enabled: !enabledAll })))}
						labelPlacement="top"
					/>
				</Grid>
			</Grid>

			{state.map((checkpoint, index) => {
				const rowProps = { ...checkpoint, index, state, setState, checkpoint };
				const isProcessType =
					checkpoint.checkpointType === CHECKPOINTS_TYPE.PROCESS &&
					checkpoint.checkpointGroup === CHECKPOINT_GROUP.PROCESS_ANALYSIS;
				const isVisible = !isProcessType || isProcessTypeVisible;
				nthProcessType = isProcessType ? nthProcessType + 1 : 0;

				return nthProcessType === 1 ? (
					<>
						<Grid
							container
							spacing={2}
							onClick={() => setIsProcessTypeVisible(!isProcessTypeVisible)}
							alignItems="center"
							className="toggle"
						>
							<Grid item alignItems="center" xs={10}>
								<div style={{ display: 'flex', alignItems: 'center' }}>
									{isProcessTypeVisible ? <ChevronRight /> : <ExpandMore />}
									<FormattedMessage {...messages.processes} />
									<UniButtton
										tooltip={<FormattedMessage {...messages.addProcess} />}
										color="primary"
										onClick={() => dispatch(showProcessModal())}
										icon={<Add fontSize="large" />}
										disabled={isSaving}
									/>
								</div>
							</Grid>
							<Grid item alignItems="center" xs={1} onClick={(e) => e.stopPropagation()}>
								<Switch
									checked={areAllProcessTypesDisplayed}
									handleChange={() => {
										setIsProcessTypeVisible(true);
										setState(setAllTo(state, 'displayed', !areAllProcessTypesDisplayed));
									}}
									labelPlacement="top"
								/>
							</Grid>
							<Grid item alignItems="center" xs={1} onClick={(e) => e.stopPropagation()}>
								<Switch
									checked={areAllProcessTypesEnabled}
									handleChange={() => {
										setIsProcessTypeVisible(true);
										setState(setAllTo(state, 'enabled', !areAllProcessTypesEnabled));
									}}
									labelPlacement="top"
								/>
							</Grid>
						</Grid>
						{isVisible && <CheckpointRow {...rowProps} />}
					</>
				) : isVisible ? (
					<CheckpointRow {...rowProps} />
				) : null;
			})}
		</Paper>
	);
};

export default Checkpoints;
