import { Refresh } from '@mui/icons-material';
import { Paper } from '@mui/material';
import { useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { ENTITIES, YES_NO_VALUE_OPTIONS } from '../consts';
import messages from '../intl/messages';
import { fetchDefinitions, fetchStats } from '../store/model/actions';
import { getDefinitions, getStats, getTermsForExamFilter } from '../store/model/selectors';
import formatDate from '../utils/formatDate';
import { numericOperators, selectOperators, stringOperatorsExtended } from '../utils/prepareXGridData';
import DataGrid from './DataGrid';
import { useStyles } from './Exams/ExamsList';
import UniButtton from './UniButtton';

const Stats = () => {
	const intl = useIntl();
	const { classes } = useStyles();
	const dispatch = useDispatch();
	const stats = useSelector(getStats());
	const terms = useSelector(getTermsForExamFilter());
	const definitions = useSelector(getDefinitions());

	const columns = [
		{
			field: 'name',
			headerName: intl.formatMessage(messages.definition),
			width: 220,
			type: 'string',
			filterOperators: stringOperatorsExtended(intl),
		},
		{
			field: 'term',
			headerName: intl.formatMessage(messages.term),
			width: 180,
			type: 'singleSelect',
			filterOperators: selectOperators(terms, intl),
		},
		{
			field: 'startsAt',
			headerName: intl.formatMessage(messages.startsAt),
			width: 180,
			valueFormatter: ({ value }) => formatDate(value).slice(0, -3),
			valueGetter: ({ value }) => new Date(value),
			type: 'dateTime',
		},
		{
			field: 'endsAt',
			headerName: intl.formatMessage(messages.endsAt),
			width: 180,
			valueFormatter: ({ value }) => formatDate(value).slice(0, -3),
			valueGetter: ({ value }) => new Date(value),
			type: 'dateTime',
		},
		{
			field: 'isOnboarding',
			headerName: intl.formatMessage(messages.isOnboarding),
			width: 100,
			type: 'singleSelect',
			filterOperators: selectOperators(YES_NO_VALUE_OPTIONS, intl, false),
		},
		{
			field: 'priority',
			headerName: intl.formatMessage(messages.priority),
			width: 120,
			type: 'number',
			align: 'right',
			headerAlign: 'right',
			filterOperators: numericOperators(intl),
		},
		{
			field: 'total',
			headerName: intl.formatMessage(messages.total),
			width: 120,
			type: 'number',
			align: 'right',
			headerAlign: 'right',
			filterOperators: numericOperators(intl),
		},
		{
			field: 'finished',
			headerName: intl.formatMessage(messages.finished),
			width: 150,
			type: 'number',
			align: 'right',
			headerAlign: 'right',
			filterOperators: numericOperators(intl),
		},
		{
			field: 'processed',
			headerName: intl.formatMessage(messages.processed),
			width: 150,
			type: 'number',
			align: 'right',
			headerAlign: 'right',
			filterOperators: numericOperators(intl),
		},
		{
			field: 'reviewedByReviewer',
			headerName: intl.formatMessage(messages.reviewerReviewedBy),
			width: 180,
			type: 'number',
			align: 'right',
			headerAlign: 'right',
			filterOperators: numericOperators(intl),
		},
		{
			field: 'reviewedByCommissioner',
			headerName: intl.formatMessage(messages.commissionerReviewedBy),
			width: 180,
			type: 'number',
			align: 'right',
			headerAlign: 'right',
			filterOperators: numericOperators(intl),
		},
		{
			field: 'reviewed',
			headerName: intl.formatMessage(messages.reviewed),
			width: 160,
			type: 'number',
			align: 'right',
			headerAlign: 'right',
			filterOperators: numericOperators(intl),
		},
	];

	const dataGridConfig = {
		columns: columns,
		gridId: ENTITIES.STATS,
		rows: stats.map((stat) => ({
			...stat,
			name: definitions[stat.definitionId]?.name ?? '',
			term: definitions[stat.definitionId]?.term ?? '',
		})),
		getRowId: (row) => row.definitionId,
	};

	useEffect(() => {
		dispatch(fetchStats());
		dispatch(fetchDefinitions());
	}, [dispatch]);

	return (
		<Paper className={classes.bounding} style={{ margin: '1.5rem', height: 'calc(100vh - 100px)' }}>
			<h2>
				<FormattedMessage {...messages.statistics} />
				<UniButtton
					onClick={() => dispatch(fetchStats())}
					icon={<Refresh size="small" />}
					tooltip={<FormattedMessage {...messages.refreshExams} />}
				/>
			</h2>
			<div style={{ height: 'calc(100vh - 155px)' }}>
				<DataGrid {...dataGridConfig} />
			</div>
		</Paper>
	);
};

export default Stats;
