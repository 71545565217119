import React from 'react';

export const intlHtml = {
	br: () => <br />,
	h1: (m) => <h1>{m}</h1>,
	h3: (m) => <h3>{m}</h3>,
	/**
	 * a - Hyperlink
	 *
	 * @example:
	 * <a>
	 * 		"text":"GOTO_WAIT",
	 * 		"href":"https://docs.partner.borndigital.ai/yaml_main.html#goto-wait",
	 * 		"target":"blank"
	 * </a>
	 */
	a: (m) => {
		const link = JSON.parse('{' + m + '}');
		return (
			<a target={link?.target ?? 'blank'} href={link.href}>
				{link.text}
			</a>
		);
	},
	p: (m) => <p>{m}</p>,
	strong: (m) => <strong>{m}</strong>,
};
