import { Save } from '@mui/icons-material';
import { Grid, Paper, TextField } from '@mui/material';
import { yellow } from '@mui/material/colors';
import { equals, isEmpty, merge, update } from 'ramda';
import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from 'tss-react/mui';
import { postFile } from '../../api';
import messages from '../../intl/messages';
import { showEditTextModal } from '../../store/modal/actions';
import { editInstructions } from '../../store/model/actions';
import { getInstructions } from '../../store/model/selectors';
import UniButtton from '../UniButtton';

const useStyles = makeStyles()((theme) => ({
	bounding: {
		padding: '0.2rem 2rem 2rem 2rem',
		fontSize: '0.9rem',
		'& .saveIcon': { float: 'right', marginTop: '7px' },
		'& .row': {
			backgroundColor: 'white',
		},
		'&.draft': {
			background: yellow['100'],
		},
		'& h2': {
			display: 'inline-block',
		},
		'& .header': {
			fontWeight: 'bold',
			fontSize: '0.8rem',
			textAlign: 'center',
			padding: '10px 0',
		},
	},
}));

const Instructions = () => {
	const { classes } = useStyles();
	const instructions = useSelector(getInstructions);
	const [state, setState] = useState(instructions);
	const dispatch = useDispatch();
	const [isDraft, setIsDraft] = useState(false);
	const [isSaving, setIsSaving] = useState(false);

	// ux - saving draft
	useEffect(() => {
		if (!isEmpty(instructions)) {
			if (isEmpty(state)) setState(instructions);

			if (equals(instructions, state)) {
				window.onbeforeunload = () => {};
				setIsDraft(false);
			} else if (!isDraft) {
				window.onbeforeunload = () => false;
				setIsDraft(true);
			}
		}
	}, [instructions, isDraft, state]);

	const handleSave = async () => {
		setIsSaving(true);
		await dispatch(editInstructions(state));
		setIsSaving(false);
		window.onbeforeunload = () => {};
	};

	return (
		<Paper elevation={3} className={`${classes.bounding} ${isDraft && 'draft'}`}>
			<h2>
				<FormattedMessage {...messages.instructions} />
			</h2>
			{isSaving ? (
				<span className="saveIcon">
					<FormattedMessage {...messages.uploading} /> ...
				</span>
			) : (
				<UniButtton
					tooltip={<FormattedMessage {...messages[isDraft ? 'saveInstructions' : 'noChanges']} />}
					color="primary"
					onClick={handleSave}
					icon={<Save fontSize="large" />}
					className="saveIcon"
					disabled={!isDraft}
				/>
			)}
			<Grid container spacing={2} className="row" direction="row" alignItems="flex-end">
				{!isEmpty(state) &&
					instructions.map((instruction, i) => (
						<Grid item xs={6}>
							<TextField
								fullWidth
								value={state[i].text}
								label={instruction.type}
								variant="outlined"
								onClick={() =>
									dispatch(
										showEditTextModal({
											title: `Instructions text (${instruction.type})`,
											text: state[i].text,
											handleTextChange: ({ text, textType }) =>
												setState(update(i, merge(state[i], { [textType]: text }), state)),
											textType: 'text',
											uploadFile: (file) => postFile(instruction.id, file),
										})
									)
								}
							/>
						</Grid>
					))}
			</Grid>
		</Paper>
	);
};

export default Instructions;
