import { produce } from 'immer';
import { AUTH_ACTION_TYPES as authActionTypes } from '../auth/actions.js';
import { NOTIF_ACTION_TYPES as actionTypes } from './actions.js';

const initialState = {
	errors: {},
	success: {},
};

const notif = produce((state, action) => {
	const { type } = action;
	const { type: payloadType, message } = action?.payload ?? {};

	switch (type) {
		case actionTypes.ERROR:
			state.errors = { ...state.errors, [payloadType]: message };
			break;
		case actionTypes.SUCCESS:
			state.success = { ...state.success, [payloadType]: message };
			break;
		case actionTypes.CLEAN_STATE:
			return initialState;
		case authActionTypes.LOGIN_USER:
			const _state = { ...state };
			Reflect.deleteProperty(_state.errors, action.type);
			return _state;
		default:
			return state;
	}
}, initialState);

export default notif;
