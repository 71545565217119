import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Breadcrumbs as BreadcrumbsMui, Typography } from '@mui/material';
import { isEmpty } from 'ramda';
import { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';
import { AUTHORIZED_ACTIONS, ROUTES, USER_RIGHTS } from '../consts';
import messages from '../intl/messages';
import { getUserRole } from '../store/auth/selectors';
import { getCurrentDefinition, getCurrentExam, getSelectedDefinitions } from '../store/model/selectors';
import { setPageTitle } from '../store/ui/actions';
import InfoTooltip from './InfoTooltip';
import UniButtton from './UniButtton';

const useStyles = makeStyles()((theme) => ({
	container: {
		display: 'inline-flex',
		alignItems: 'center',
		marginBottom: '10px',
	},
	arrows: {
		display: 'inline-block',
		margin: '0 !important',
	},
	bounding: {
		display: 'inline-block',
		'&.disabled': {
			'& .MuiBreadcrumbs-li': { background: 'white' },
		},
		'& .MuiBreadcrumbs-li': {
			background: theme.palette.primary.main,
			color: 'white',
			padding: '2px 10px',
			borderRadius: '20px',
			maxHeight: '20px',
			'&:last-child': {
				background: theme.palette.primary.dark,
			},
			'& .MuiButtonBase-root': {
				margin: 0,
				color: 'white',
				padding: 0,
				'&.Mui-disabled': {
					color: 'black',
				},
				'& p': {
					fontSize: '1rem',
					textTransform: 'initial',
					lineHeight: 'inherit',
					top: '-4px',
					position: 'relative',
				},
			},
		},
	},
}));

const Breadcrumbs = ({ disableControls }) => {
	const { classes } = useStyles();
	const definition = useSelector(getCurrentDefinition);
	const selectedDefinitions = useSelector(getSelectedDefinitions);
	const exam = useSelector(getCurrentExam);
	const userRole = useSelector(getUserRole());
	const disabled = !USER_RIGHTS[userRole].includes(AUTHORIZED_ACTIONS.USE_BREADCRUMBS);
	const navigate = useNavigate();
	const { pathname, definitionId, examId: examIdUrl } = useParams();
	const examId = examIdUrl || exam?.id || '';
	const location = useLocation();
	const dispatch = useDispatch();

	// Set page title for exam preview and exam list
	useEffect(() => {
		if (pathname !== ROUTES.EXAMS_PREVIEW_COMMISSIONER.PATH && pathname !== ROUTES.EXAMS_PREVIEW_REVIEWER.PATH) {
			if (exam?.firstName && exam?.lastName && examIdUrl)
				dispatch(setPageTitle(`${exam?.firstName} ${exam?.lastName} - ${definition?.name}`));
			else if (definition?.name && definitionId) dispatch(setPageTitle(definition.name));
		}
	}, [definition?.name, definitionId, dispatch, exam, examIdUrl, pathname]);

	const handleNavigateForward = () =>
		navigate(
			definitionId && !examIdUrl && exam.id
				? `${ROUTES.EXAMS.PATH}/${definitionId}/${exam.id}`
				: definition.id && !definitionId && `${ROUTES.EXAMS.PATH}/${definition.id}`
		);

	return (
		<div className={classes.container}>
			{!disableControls && (
				<UniButtton
					icon={<ArrowBackIosIcon size="small" />}
					className={classes.arrows}
					tooltip={<FormattedMessage {...messages.previous} />}
					onClick={() => navigate(-1)}
				/>
			)}
			<BreadcrumbsMui className={!disabled ? `${classes.bounding}` : `${classes.bounding} disabled`}>
				<InfoTooltip
					onClick={() => navigate(ROUTES.EXAMS.PATH)}
					disabled={disabled}
					title="breadcrumbDefinitions"
					placement="bottom"
				>
					<Typography>
						<FormattedMessage {...messages.definitions} />
					</Typography>
				</InfoTooltip>

				{(definitionId || disableControls) && (
					<InfoTooltip
						onClick={() => navigate(`${ROUTES.EXAMS.PATH}/${definitionId || definition.id}`)}
						disabled={disabled}
						title="breadcrumbDefinition"
						values={{ id: definitionId }}
						placement="bottom"
					>
						<Typography>
							{!isEmpty(selectedDefinitions) ? selectedDefinitions.map((d) => d.name).join(', ') : definition?.name}
						</Typography>
					</InfoTooltip>
				)}
				{(examIdUrl ||
					location.pathname === ROUTES.EXAMS_PREVIEW_REVIEWER.PATH ||
					location.pathname === ROUTES.EXAMS_PREVIEW_COMMISSIONER.PATH) && (
					<InfoTooltip
						disabled={disabled}
						title="breadcrumbExam"
						values={{
							customerId: exam?.customerId,
							examId: examId,
							externalId: exam?.externalId,
							firstName: exam?.firstName,
							lastName: exam?.lastName,
						}}
						placement="bottom"
					>
						<Typography>{exam?.firstName ? `${exam?.firstName} ${exam?.lastName}` : examId}</Typography>
					</InfoTooltip>
				)}
			</BreadcrumbsMui>
			{!disableControls && (
				<UniButtton
					icon={<ArrowForwardIosIcon size="small" />}
					className={classes.arrows}
					tooltip={<FormattedMessage {...messages.next} />}
					onClick={handleNavigateForward}
				/>
			)}
		</div>
	);
};

export default Breadcrumbs;
