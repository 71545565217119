import {
	Button,
	MenuItem,
	TextField,
	Select,
	Grid,
	FormControl,
	InputLabel,
	Checkbox,
	FormControlLabel,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { USER } from '../consts';
import messages from '../intl/messages';
import { signupUser } from '../store/auth/actions';
import { isPhoneNumberInvalid } from '../utils/validation';

const Signup = () => {
	const dispatch = useDispatch();
	const intl = useIntl();
	const userRoles = Object.values(USER.ROLES);

	const [name, setName] = useState('');
	const [surname, setSurname] = useState('');
	const [email, setEmail] = useState('');
	const [role, setRole] = useState(userRoles[0]);

	const [shouldSendEmail, setShouldSendEmail] = useState(true);
	const [has2fa, setHas2fa] = useState(true);
	const [phoneNumber, setPhoneNumber] = useState('');
	const [maxPostponedExams, setMaxPostponedExams] = useState('');

	const [phoneNumberHelperText, setPhoneNumberHelperText] = useState(null);
	const [emailHelperText, setEmailHelperText] = useState(null);
	const [nameHelperText, setNameHelperText] = useState(null);
	const [surnameHelperText, setSurnameHelperText] = useState(null);

	useEffect(() => {
		if (!has2fa) {
			setPhoneNumber('');
			setPhoneNumberHelperText(null);
		}
	}, [has2fa]);

	useEffect(() => {
		if (phoneNumber) setPhoneNumberHelperText(null);
	}, [phoneNumber]);

	useEffect(() => {
		if (email) setEmailHelperText(null);
		if (name) setNameHelperText(null);
		if (surname) setSurnameHelperText(null);
	}, [email, name, surname]);

	const handleSignup = async () => {
		if (!name) setNameHelperText(<FormattedMessage {...messages.required} />);

		if (!surname) setSurnameHelperText(<FormattedMessage {...messages.required} />);

		if (!email) setEmailHelperText(<FormattedMessage {...messages.required} />);

		if (has2fa) {
			const phoneNumberError = isPhoneNumberInvalid(phoneNumber);
			setPhoneNumberHelperText(phoneNumberError);
			if (phoneNumberError) return;
		}

		if (!name || !surname || !email) return;

		// BE does not accept empty string
		await dispatch(
			signupUser({
				email,
				has2fa,
				intl,
				maxPostponedExams,
				name,
				phoneNumber: phoneNumber || undefined,
				role,
				shouldSendEmail,
				surname,
			})
		);

		setName('');
		setSurname('');
		setEmail('');
		setRole(userRoles[0]);
		setShouldSendEmail(true);
		setHas2fa(true);
		setPhoneNumber('');
	};

	return (
		<Grid container spacing={2}>
			<Grid item xs={12}>
				<TextField
					variant="outlined"
					fullWidth
					label={<FormattedMessage {...messages.firstName} />}
					value={name}
					error={!!nameHelperText}
					helperText={nameHelperText}
					onChange={(e) => setName(e.target.value)}
					id="name-sign-up"
				/>
			</Grid>
			<Grid item xs={12}>
				<TextField
					variant="outlined"
					fullWidth
					label={<FormattedMessage {...messages.lastName} />}
					value={surname}
					error={!!surnameHelperText}
					helperText={surnameHelperText}
					onChange={(e) => setSurname(e.target.value)}
					id="surname-sign-up"
				/>
			</Grid>
			<Grid item xs={12}>
				<TextField
					variant="outlined"
					fullWidth
					label={<FormattedMessage {...messages.email} />}
					type="email"
					value={email}
					error={!!emailHelperText}
					helperText={emailHelperText}
					onChange={(e) => setEmail(e.target.value)}
					id="email-sign-up"
				/>
			</Grid>
			<Grid item xs={12}>
				<FormControl variant="outlined" fullWidth>
					<InputLabel>
						<FormattedMessage {...messages.userRole} />
					</InputLabel>
					<Select
						label={<FormattedMessage {...messages.userRole} />}
						variant="outlined"
						value={role}
						onChange={(e) => setRole(e.target.value)}
					>
						{userRoles.map((role) => (
							<MenuItem key={`signup-roles-${role}`} value={role}>
								{role}
							</MenuItem>
						))}
					</Select>
				</FormControl>
			</Grid>
			<Grid item xs={6}>
				<FormControlLabel
					control={<Checkbox checked={shouldSendEmail} onChange={() => setShouldSendEmail(!shouldSendEmail)} />}
					label={<FormattedMessage {...messages.shouldSendEmail} />}
				/>
			</Grid>
			<Grid item xs={6}>
				<FormControlLabel
					control={<Checkbox checked={has2fa} onChange={() => setHas2fa(!has2fa)} />}
					label={<FormattedMessage {...messages.has2fa} />}
				/>
			</Grid>
			{has2fa && (
				<Grid item xs={12}>
					<TextField
						fullWidth
						variant="outlined"
						label={<FormattedMessage {...messages.phoneNumber} />}
						type="phoneNumber"
						value={phoneNumber}
						error={!!phoneNumberHelperText}
						helperText={phoneNumberHelperText}
						onChange={(e) => setPhoneNumber(e.target.value)}
					/>
				</Grid>
			)}
			<Grid item xs={12}>
				<TextField
					fullWidth
					value={maxPostponedExams || ' '}
					onChange={(e) => setMaxPostponedExams(Number(e.target.value) < 1 ? '' : e.target.value)}
					label={<FormattedMessage {...messages.deferredLimit} />}
					variant="outlined"
					type="number"
				/>
			</Grid>
			<Grid item xs={12}>
				<Button variant="contained" color="primary" onClick={handleSignup}>
					<FormattedMessage {...messages.createNewUser} />
				</Button>
			</Grid>
		</Grid>
	);
};

export default Signup;
