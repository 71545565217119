import { Close, Monitor, CameraIndoor, PhotoCameraFront } from '@mui/icons-material';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { Rnd } from 'react-rnd';
import { makeStyles } from 'tss-react/mui';
import { ENTITIES, STREAM_TYPES } from '../consts';
import { messages } from '../intl';
import { setIsPip } from '../store/ui/actions';
import { getTypeFilter } from '../store/ui/selectors';
import useInterval from '../utils/useInterval';
import VideoBox from './ExamPreview/VideoBox';
import { syncVideos } from './ExamPreview/VideoControlBar';

const useStyles = makeStyles()((theme) => ({
	bounding: {
		zIndex: 1000000,
		position: 'absolute',
		bottom: '470px',
		right: '470px',
		'& .react-draggable': { overflow: 'hidden', boxShadow: '0 0 20px #666' },
		'& .dot': { top: '23px' },
		'& .resize': { left: '9px', position: 'absolute', pointerEvents: 'none', zIndex: 120, top: ' 8px' },
		'& .source': {
			position: 'absolute',
			top: '8px',
			zIndex: 10,
			display: 'flex',
			gap: '10px',
			left: '12px',
			color: 'white',
			'& div': { cursor: 'pointer' },
			'& svg': { color: 'white' },
		},
		'& .title': {
			color: 'white',
			position: 'absolute',
			top: 0,
			background: theme.palette.primary.main,
			width: 'calc(100% - 20px)',
			left: '0',
			padding: '8px 10px 5px 10px',
			display: 'flex',
			zIndex: 1,
			pointerEvents: 'none',
			justifyContent: 'center',
		},
		'& .close': {
			position: 'absolute',
			top: '8px',
			zIndex: 10,
			display: 'flex',
			cursor: 'pointer',
			gap: '10px',
			right: '12px',
			'& svg': { color: 'white' },
		},
		'& #videoContainer': {
			width: '100% !important',
			display: 'flex',
			alignContent: 'center',
			flexWrap: 'wrap',
			justifyContent: 'center',
			flexDirection: 'column',
			height: '100%',
			background: 'black',
			'& video': { width: '100% !important', height: 'auto !important', marginTop: '-79px' },
		},
		'& .control-bar': {
			position: 'fixed',
			bottom: 0,
			left: 0,
			background: 'white',
			padding: '10px',
		},
		'& .MuiPopover-root': {
			position: 'absolute !important',
			'& .MuiPaper-root': {
				position: 'absolute !important',
				maxHeight: '230px !important',
				top: '-130px !important',
				left: '0 !important',
			},
		},
	},
}));

const Pip = () => {
	const { classes } = useStyles();
	const intl = useIntl();
	const typeFilter = useSelector(getTypeFilter);
	const [pipTypeFilter, setPipTypeFilter] = useState(typeFilter);
	const dispatch = useDispatch();

	// Handle current time difference
	useInterval(() => {
		const htmlVideos = document.querySelectorAll('video.examVideo');
		const v1 = htmlVideos?.[0]?.currentTime ?? 0;
		const v2 = htmlVideos?.[1]?.currentTime ?? 0;

		if (v1.toFixed(2) !== v2.toFixed(2)) syncVideos('currentTime', v1);
	}, 2000);

	return (
		<div className={classes.bounding}>
			<Rnd default={{ x: 0, y: 0, width: 460, height: 460 }} minWidth={460} minHeight={460}>
				<div className="source">
					<div title={intl.formatMessage(messages.room)} onClick={() => setPipTypeFilter(ENTITIES.VIDEO_ROOM)}>
						<CameraIndoor fontSize="10" />
					</div>
					<div title={intl.formatMessage(messages.screen)} onClick={() => setPipTypeFilter(STREAM_TYPES.SCREEN)}>
						<Monitor fontSize="10" />
					</div>
					<div title={intl.formatMessage(messages.camera)} onClick={() => setPipTypeFilter(STREAM_TYPES.WEBCAM)}>
						<PhotoCameraFront fontSize="10" />
					</div>
				</div>
				<div className="title">{intl.formatMessage(messages[pipTypeFilter])}</div>
				<div className="close" title={intl.formatMessage(messages.close)} onClick={() => dispatch(setIsPip(false))}>
					<Close fontSize="10" />
				</div>
				<VideoBox pipTypeFilter={pipTypeFilter} />
			</Rnd>
		</div>
	);
};

export default Pip;
