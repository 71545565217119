import { curry } from 'ramda';

const ID = 'ui';

export const getAllowReviewForExamId = curry((state) => state[ID]['allowReviewForExamId']);
export const getCurrentListTab = curry((state) => state[ID]['historyListTab'][0]);
export const getCurrentLangCode = curry((state) => state[ID]['currentLangCode']);
export const getXgridConfig = curry((state) => state[ID]['xgridConfig']);
export const getVideoSeek = curry((state) => state[ID]['examPreview']['videoSeek']);
export const getVideoState = curry((state) => state[ID]['examPreview']['videoState']);
export const getPageTitle = curry((state) => state[ID]['pageTitle']);
export const getIsPip = curry((state) => state[ID]['examPreview']['isPip']);
export const getTypeFilter = curry((state) => state[ID]['examPreview']['typeFilter']);
export const getVideoPlaybackRate = curry((state) => state[ID]['examPreview']['playbackRate']);
