import { Button, Tooltip } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { makeStyles } from 'tss-react/mui';
import messages from '../intl/messages';
import { intlHtml } from '../utils/intlHtml';

const useStyles = makeStyles()((theme) => ({
	wrap: {
		listStyleType: 'none',
	},
	popper: {
		'& div': {
			background: theme.palette.primary.dark,
			padding: '10px 20px',
			lineHeight: '1.2rem',
			fontSize: '0.9rem',
			'& h3': {
				padding: 0,
				margin: '10px 0',
			},
			'& a': {
				background: theme.palette.success.light,
				padding: '0 5px',
				borderRadius: '3px',
				color: 'white',
				textDecoration: 'none',
			},
			'& p': {
				whiteSpace: 'inherit',
				padding: '5px 0',
				margin: '0',
			},
		},
	},
	button: {
		padding: 4,
	},
}));

const InfoTooltip = (props) => {
	const { title, placement, values, ...rest } = props;
	const { classes } = useStyles();

	return (
		<div className={classes.wrap}>
			<Tooltip
				classes={{
					popper: classes.popper,
				}}
				arrow
				interactive
				placement={placement || 'top'}
				title={<FormattedMessage {...messages[title]} values={{ ...intlHtml, ...values }} />}
			>
				<span>
					<Button aria-label={title} className={classes.button} {...rest}>
						{props.children}
					</Button>
				</span>
			</Tooltip>
		</div>
	);
};

InfoTooltip.propTypes = {
	placement: PropTypes.string,
	title: PropTypes.string,
};

export default InfoTooltip;
