import {
	activateUser as activateUserApi,
	deactivateUser as deactivateUserApi,
	deleteUser as deleteUserApi,
	forgetPasswordRequest,
	loginRequest,
	getSignedUp,
	getUsers,
	renewPasswordRequest,
	getUserByTokenRequest,
	changePasswordRequest,
	login2FARequest,
	editUserRequest,
} from '../../api';
import { LS_USER } from '../../consts';
import { messages } from '../../intl';
import arrToObjIdAsKey from '../../utils/arrToObjIdAsKey';
import keyMirror from '../../utils/keyMirror';
import { NOTIF_ACTION_TYPES } from '../notif/actions';
import { catchFail } from '../utils';

export const AUTH_ACTION_TYPES = keyMirror({
	ACTIVATE_USER: null,
	CHANGE_PASSWORD: null,
	DEACTIVATE_USER: null,
	DELETE_USER: null,
	EDIT_USER: null,
	FETCH_USERS: null,
	FORGET_PASSWORD: null,
	GET_USER_BY_TOKEN: null,
	LOGIN_USER: null,
	LOGIN_USER_2FA: null,
	LOGOUT_USER: null,
	SIGNUP_USER: null,
});

export const loginUser =
	({ email, password }) =>
	async (dispatch) => {
		const type = AUTH_ACTION_TYPES.LOGIN_USER;

		try {
			const user = await loginRequest({ email, password });

			if (!user.has2fa) {
				localStorage.setItem(LS_USER, JSON.stringify(user));
				localStorage.setItem('lastLogin', +new Date());
			}

			dispatch({ type, payload: user });

			return user;
		} catch (e) {
			catchFail({ callback: () => loginUser({ email, password }), dispatch, e, type });
		}
	};

export const loginUser2FA =
	({ userId, code, tokenId, callback }) =>
	async (dispatch) => {
		const type = AUTH_ACTION_TYPES.LOGIN_USER_2FA;

		try {
			const user = await login2FARequest({ userId, code, tokenId });

			localStorage.setItem(LS_USER, JSON.stringify(user));
			localStorage.setItem('lastLogin', +new Date());

			callback();
			dispatch({ type, payload: user });

			return user;
		} catch (e) {
			catchFail({ callback: () => loginUser2FA({ userId, code, tokenId, callback }), dispatch, e, type });
		}
	};

export const signupUser = (props) => async (dispatch) => {
	const type = AUTH_ACTION_TYPES.SIGNUP_USER;

	try {
		const user = await getSignedUp(props);

		dispatch({ type, payload: user });

		dispatch({
			type: NOTIF_ACTION_TYPES.SUCCESS,
			payload: { type, message: props.intl.formatMessage(messages.notifOperationSuccess) },
		});

		return user;
	} catch (e) {
		catchFail({
			callback: () => signupUser(props),
			dispatch,
			e,
			type,
		});
	}
};

export const getUserByToken =
	({ token }) =>
	async (dispatch) => {
		const type = AUTH_ACTION_TYPES.GET_USER_BY_TOKEN;

		try {
			const user = await getUserByTokenRequest({ token });

			dispatch({ type, payload: user });

			return user;
		} catch (e) {
			catchFail({ callback: () => getUserByToken({ token }), dispatch, e, type });
		}
	};

export const changePassword =
	({ password, confirmPassword, userId, goToResetToken }) =>
	async (dispatch) => {
		const type = AUTH_ACTION_TYPES.CHANGE_PASSWORD;

		try {
			await changePasswordRequest({ password, confirmPassword, userId });

			return true;
		} catch (e) {
			catchFail({
				callback: () => changePasswordRequest({ password, confirmPassword, userId, goToResetToken }),
				type,
				dispatch,
				e,
			});
			goToResetToken();
		}
	};
export const forgetPassword =
	({ email, intl }) =>
	async (dispatch) => {
		try {
			const res = await forgetPasswordRequest({ email });

			dispatch({
				type: NOTIF_ACTION_TYPES.SUCCESS,
				payload: { type: NOTIF_ACTION_TYPES.SUCCESS, message: intl.formatMessage(messages.notifOperationSuccess) },
			});

			return res;
		} catch (e) {
			catchFail({ callback: () => forgetPassword({ email, intl }), dispatch, e });
		}
	};

export const logoutUser = () => (dispatch) => {
	localStorage.removeItem(LS_USER);

	dispatch({
		type: AUTH_ACTION_TYPES.LOGOUT_USER,
	});
};

export const deleteUser = (id, intl) => async (dispatch) => {
	const type = AUTH_ACTION_TYPES.DELETE_USER;

	try {
		await deleteUserApi(id);

		dispatch({
			type: NOTIF_ACTION_TYPES.SUCCESS,
			payload: { type, message: intl.formatMessage(messages.notifOperationSuccess) },
		});

		dispatch({ type, payload: { id } });

		return id;
	} catch (e) {
		catchFail({ callback: () => deleteUser(id, intl), dispatch, e, type });
	}
};

export const activateUser = (id, intl) => async (dispatch) => {
	const type = AUTH_ACTION_TYPES.ACTIVATE_USER;

	try {
		await activateUserApi(id);

		dispatch({
			type: NOTIF_ACTION_TYPES.SUCCESS,
			payload: { type, message: intl.formatMessage(messages.notifOperationSuccess) },
		});

		dispatch({ type, payload: { id } });

		return id;
	} catch (e) {
		catchFail({ callback: () => activateUser(id, intl), dispatch, e, type });
	}
};

export const deactivateUser = (id, intl) => async (dispatch) => {
	const type = AUTH_ACTION_TYPES.DEACTIVATE_USER;

	try {
		await deactivateUserApi(id);

		dispatch({
			type: NOTIF_ACTION_TYPES.SUCCESS,
			payload: { type, message: intl.formatMessage(messages.notifOperationSuccess) },
		});

		dispatch({ type, payload: { id } });

		return id;
	} catch (e) {
		catchFail({ callback: () => deactivateUser(id, intl), dispatch, e, type });
	}
};

export const fetchUsers = () => async (dispatch) => {
	const type = AUTH_ACTION_TYPES.FETCH_USERS;

	try {
		const users = arrToObjIdAsKey(await getUsers());

		dispatch({ type, payload: users });
	} catch (e) {
		catchFail({ callback: () => fetchUsers(), dispatch, e, type });
	}
};

export const renewPassword = (userId, intl) => async (dispatch) => {
	try {
		await renewPasswordRequest(userId);

		dispatch({
			type: NOTIF_ACTION_TYPES.SUCCESS,
			payload: { type: NOTIF_ACTION_TYPES.SUCCESS, message: intl.formatMessage(messages.notifOperationSuccess) },
		});
	} catch (e) {
		catchFail({ callback: () => renewPassword(userId, intl), dispatch, e });
	}
};

export const editUser =
	({ user }) =>
	async (dispatch) => {
		const actionType = AUTH_ACTION_TYPES.EDIT_USER;

		const { id: userId, name, surname, skill, role, has2fa, phoneNumber, maxPostponedExams } = user;

		try {
			await editUserRequest({ name, surname, role, userId, skill, phoneNumber, has2fa, maxPostponedExams });

			dispatch(fetchUsers());
		} catch (e) {
			catchFail({ callback: () => editUser({ user }), dispatch, e, type: actionType });
		}
	};
