import { TextField } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from 'tss-react/mui';
import messages from '../../intl/messages';
import { getUsers } from '../../store/auth/selectors';
import { hideModal } from '../../store/modal/actions';
import sortArrByObjProp from '../../utils/sortArrByObjProp';
import SaveCloseModalTemplate from './SaveCloseModalTemplate';

const useStyles = makeStyles()((theme) => ({
	bounding: {},
}));

const AssignReviewModal = ({ examIds, callback }) => {
	const { classes } = useStyles();
	const dispatch = useDispatch();
	const users = useSelector(getUsers);

	const [user, setUser] = useState(null);
	const [userText, setUserText] = useState('');

	return (
		<SaveCloseModalTemplate
			title={<FormattedMessage {...messages.assignReviews} />}
			saveBtnTitle={<FormattedMessage {...messages.assignReviews} />}
			isSaveBtnDisabled={!user}
			onSave={() => {
				callback(examIds, user.id);
				dispatch(hideModal());
			}}
		>
			<div className={classes.bounding}>
				<Autocomplete
					options={sortArrByObjProp('email', Object.values(users))}
					freeSolo
					fullWidth
					blurOnSelect
					getOptionLabel={({ email }) => email}
					value={user?.id}
					inputValue={userText}
					onInputChange={(e, v) => setUserText(v)}
					renderInput={(params) => (
						<TextField {...params} label={<FormattedMessage {...messages.selectUser} />} fullWidth variant="outlined" />
					)}
					onChange={(e, v) => setUser(v)}
				/>
			</div>
		</SaveCloseModalTemplate>
	);
};

export default AssignReviewModal;
