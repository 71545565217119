import Alert from '@mui/material/Alert';
import { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from 'tss-react/mui';
import { AUTHORIZED_ACTIONS, USER_RIGHTS } from '../../consts';
import messages from '../../intl/messages';
import { getUserRole } from '../../store/auth/selectors';
import { fetchDashboardExams, fetchDefinitions } from '../../store/model/actions';
import { getInstructionsByType } from '../../store/model/selectors';
import checkUserAuthorization from '../../utils/userRights';
import ExamFilter from './ExamFilter';
import PostponedExams from './PostponedExams';
import ReviewedExams from './ReviewedExams';

const useStyles = makeStyles()((theme) => ({
	bounding: {
		padding: '1.5rem',
		'& .MuiAlert-root': {
			marginBottom: '20px',
		},
	},
}));

const Dashboard = () => {
	const { classes } = useStyles();
	const dispatch = useDispatch();
	const userRole = useSelector(getUserRole());
	const instructions = useSelector(getInstructionsByType);

	const canReviewExams = checkUserAuthorization(userRole, [
		AUTHORIZED_ACTIONS.REVIEW_AS_REVIEWER,
		AUTHORIZED_ACTIONS.REVIEW_AS_COMMISSIONER,
	]);

	useEffect(() => {
		dispatch(fetchDefinitions());
	}, [dispatch]);

	useEffect(() => {
		if (canReviewExams) dispatch(fetchDashboardExams());
	}, [canReviewExams, dispatch]);

	return (
		<div className={classes.bounding}>
			{instructions?.[userRole]?.text && (
				<Alert severity="warning">
					<strong>
						<FormattedMessage {...messages.instructions} />{' '}
					</strong>
					<span dangerouslySetInnerHTML={{ __html: instructions[userRole].text }} />
				</Alert>
			)}
			{canReviewExams && (
				<>
					<PostponedExams />
					<br />
					<ReviewedExams />
				</>
			)}
			{USER_RIGHTS[userRole].includes(AUTHORIZED_ACTIONS.VIEW_EXAM_FILTER) && (
				<>
					<br /> <ExamFilter />
				</>
			)}
		</div>
	);
};

export default Dashboard;
