import React from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { modals } from '../../consts';
import { getModalProps, getModalType } from '../../store/modal/reducers';

const ModalRoot = () => {
	const modalType = useSelector(getModalType);
	const modalProps = useSelector(getModalProps, shallowEqual);
	const Modal = modals[modalType] || null;

	return Modal ? <Modal {...modalProps} /> : null;
};

export default ModalRoot;
