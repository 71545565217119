import { curry, path } from 'ramda';
const ID = 'auth';

export const getUser = path([ID, 'user']);
export const getIsUserLoggedIn = path([ID, 'isUserLoggedIn']);
export const getIsUserPasswordCorrect = path([ID, 'isUserPasswordCorrect']);
export const getUserEmail = path([ID, 'user', 'email']);
export const getUserId = path([ID, 'user', 'id']);
export const getUserRole = path([ID, 'user', 'role']);
export const getUsers = path([ID, 'users']);
export const getEmails = (state) => Object.values(getUsers(state)).map((user) => user.email);
export const getUserById = curry((userId, state) => {
	const users = getUsers(state);
	return users[userId];
});
