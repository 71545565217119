import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Switch from '@mui/material/Switch';
import React from 'react';
import { makeStyles, withStyles } from 'tss-react/mui';

const IOSSwitch = withStyles(({ classes, ...props }) => {
	return (
		<Switch
			focusVisibleClassName={classes.focusVisible}
			disableRipple
			classes={{
				root: classes.root,
				switchBase: classes.switchBase,
				thumb: classes.thumb,
				track: classes.track,
				checked: classes.checked,
			}}
			{...props}
		/>
	);
}, (theme, _params, classes) => ({
	root: {
		width: 52,
		height: 30,
		padding: 2,
		margin: theme.spacing(1),
	},
	switchBase: {
		padding: 3,
		[`&.${classes.checked}`]: {
			transform: 'translateX(16px)',
			color: theme.palette.common.white + ' !important',
			[`& + .${classes.track}`]: {
				backgroundColor: theme.palette.primary.main,
				opacity: 1,
				border: 'none',
			},
		},
		'&.Mui-disabled': {
			[`& + .${classes.track}`]: {
				backgroundColor: theme.palette.grey[400] + ' !important',
				opacity: 1,
			},
		},
		[`&.${classes.focusVisible} .${classes.thumb}`]: {
			color: theme.palette.primary.light,
			border: '1px solid #fff',
		},
		[`& + .${classes.track}`]: {
			borderRadius: 26 / 2,
			border: `1px solid ${theme.palette.grey[400]}`,
			backgroundColor: '#9f9f9f',
			opacity: '1 !important',
			transition: theme.transitions.create(['background-color', 'border']),
		},
	},
	thumb: {
		width: 24,
		height: 24,
	},
	track: {},
	checked: {},
	focusVisible: {},
}));

const useStyles = makeStyles()((theme) => ({
	bounding: {
		'& .MuiTypography-root': {
			margin: '0',
			whiteSpace: 'nowrap',
			color: theme.palette.grey[600],
			fontSize: '0.75rem',
		},
	},
}));

const SwitchCustomised = ({ checked, disabled, handleChange, label, labelPlacement }) => {
	const { classes } = useStyles();

	return (
		<FormGroup className={classes.bounding}>
			<FormControlLabel
				control={<IOSSwitch checked={checked} onChange={handleChange} disabled={disabled} name="checkedB" />}
				label={label}
				labelPlacement={labelPlacement || 'top'}
			/>
		</FormGroup>
	);
};

export default SwitchCustomised;
