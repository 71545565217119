import { Save } from '@mui/icons-material';
import { Grid, Paper } from '@mui/material';
import { yellow } from '@mui/material/colors';
import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from 'tss-react/mui';
import messages from '../../intl/messages';
import { disableAutoscaler, enableAutoscaler, fetchAutoscalerStatus } from '../../store/model/actions';
import { getScalingAutoscalerStatus } from '../../store/model/selectors';
import Switch from '../Switch';
import UniButtton from '../UniButtton';

const useStyles = makeStyles()((theme) => ({
	bounding: {
		padding: '0.7rem 2rem 2rem 2rem',
		fontSize: '0.9rem',
		'& .headingContainer': {
			display: 'flex',
			alignItems: 'flex-start',
		},
		'& h2': {
			display: 'inline-block',
			fontSize: '1.2rem',
		},
		'& .saveIcon': { float: 'right', marginTop: '7px' },
		'&.draft': {
			background: yellow['100'],
		},
		'& .switchGrid': {
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'flex-start',
			marginLeft: '12px',
			width: '100%',
			'& .switchLabel': {
				minWidth: '60%',
			},
		},
	},
}));

const ScalingAutoscaler = () => {
	const dispatch = useDispatch();
	const isAutoscalerEnabled = useSelector(getScalingAutoscalerStatus);
	const [stateIsAutoscalerEnabled, setStateIsAutoscalerEnabled] = useState(isAutoscalerEnabled);
	const [wasSwitchChanged, setWasSwitchChanged] = useState(false);
	const [isDraft, setIsDraft] = useState(false);
	const [isSaving, setIsSaving] = useState(false);
	const { classes } = useStyles();

	useEffect(() => {
		dispatch(fetchAutoscalerStatus());
	}, [dispatch]);

	useEffect(() => {
		setStateIsAutoscalerEnabled(isAutoscalerEnabled);
	}, [isAutoscalerEnabled]);

	useEffect(() => {
		if (wasSwitchChanged && isAutoscalerEnabled !== stateIsAutoscalerEnabled) {
			window.onbeforeunload = () => false;
			setIsDraft(true);
		} else if (isDraft) {
			window.onbeforeunload = () => {};
			setIsDraft(false);
		}
	}, [isDraft, isAutoscalerEnabled, stateIsAutoscalerEnabled, wasSwitchChanged]);

	const handleSave = async () => {
		setIsSaving(true);
		if (stateIsAutoscalerEnabled) {
			await dispatch(enableAutoscaler());
		} else {
			await dispatch(disableAutoscaler());
		}
		setIsSaving(false);
		setIsDraft(false);
		setWasSwitchChanged(false);
		window.onbeforeunload = () => {};
	};

	return (
		<Paper elevation={3} className={`${classes.bounding} ${isDraft && 'draft'}`}>
			<Grid container spacing={0} direction="row" justifyContent="space-evenly" alignItems="flex-start">
				<Grid item xs={3} className="headingContainer">
					<h2>
						<FormattedMessage {...messages.autoscaler} />
					</h2>
				</Grid>
				<Grid item xs={8} />
				<Grid item xs={1}>
					{isSaving ? (
						<span className="saveIcon">
							<FormattedMessage {...messages.uploading} /> ...
						</span>
					) : (
						<UniButtton
							tooltip={<FormattedMessage {...messages[isDraft ? 'saveInstructions' : 'noChanges']} />}
							color="primary"
							onClick={handleSave}
							icon={<Save fontSize="large" />}
							className="saveIcon"
							disabled={!isDraft}
						/>
					)}
				</Grid>
			</Grid>
			<Grid container spacing={0} direction="row" justifyContent="space-between" alignItems="flex-start">
				<Grid item xs={2} className="switchGrid">
					<div className="switchLabel">
						<FormattedMessage {...messages.switchAutoscaler} />
					</div>
					<Switch
						checked={stateIsAutoscalerEnabled}
						handleChange={() => {
							setStateIsAutoscalerEnabled(!stateIsAutoscalerEnabled);
							setWasSwitchChanged(true);
						}}
					/>
				</Grid>
			</Grid>
		</Paper>
	);
};

export default ScalingAutoscaler;
