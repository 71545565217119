import { Checkbox, FormControl, FormControlLabel, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import { isEmpty, mergeAll } from 'ramda';
import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from 'tss-react/mui';
import { AUTHORIZED_ACTIONS, REVIEW } from '../../consts';
import messages from '../../intl/messages';
import { getUser } from '../../store/auth/selectors';
import { hideModal } from '../../store/modal/actions';
import { fetchEvaluationDetails, sendReview } from '../../store/model/actions';
import { getCurrentExam, getEvaluationDetails, getViolations } from '../../store/model/selectors';
import { msToTime } from '../../utils/formatDate';
import checkUserAuthorization from '../../utils/userRights';
import SaveCloseModalTemplate from './SaveCloseModalTemplate';

const useStyles = makeStyles()((theme) => ({
	selectTitle: {
		width: '406px',
	},
}));

const preventDuplicity = (violations) => {
	const violationsByName = {};

	for (const violation of violations) {
		violationsByName[violation.reviewerNote || violation.comment || 'null'] = violation;
	}

	return Object.values(violationsByName);
};

const ConfirmReviewModal = ({ exit }) => {
	const { classes } = useStyles();
	const dispatch = useDispatch();
	const exam = useSelector(getCurrentExam);
	const user = useSelector(getUser);
	const evaluationDetails = useSelector(getEvaluationDetails);
	const canReviewAsCommissioner = checkUserAuthorization(user.role, AUTHORIZED_ACTIONS.REVIEW_AS_COMMISSIONER);
	const allViolations = Object.values(mergeAll(Object.values(useSelector(getViolations))));
	const confirmedViolations = allViolations.filter(
		(violation) => violation[canReviewAsCommissioner ? REVIEW.TYPES.COMMISSIONER : REVIEW.TYPES.REVIEWER] === 'NOK'
	);
	const review = isEmpty(confirmedViolations) ? 'PASS' : canReviewAsCommissioner ? 'FAIL' : 'SUSPICIOUS';

	const isReviewPass = review === 'PASS';
	const [isViolationIntentional, setIsViolationIntentional] = useState(exam.isViolationIntentional || false);
	const [finalViolation, setFinalViolation] = useState(exam.finalViolationMessage || null);
	const [violationNote, setViolationNote] = useState(exam.violationNote || '');
	const [evaluationId, setEvaluationId] = useState(null);
	const [evaluationDetail, setEvaluationDetail] = useState(exam.evaluationDetail || null);
	const undoneViolations = [];

	useEffect(() => {
		if (isEmpty(evaluationDetails)) {
			dispatch(fetchEvaluationDetails());
		}
	}, [dispatch, evaluationDetails]);

	// if editing already reviewed, set evaluationId so we can have evaluation detail select filled
	useEffect(() => {
		if (exam.evaluationDetail && !evaluationId) {
			for (const { comment, evaluationId, reviewerNote } of preventDuplicity(confirmedViolations)) {
				if (reviewerNote === exam.finalViolationMessage || comment === exam.finalViolationMessage) {
					setEvaluationId(evaluationId);
				}
			}
		}
	}, [confirmedViolations, evaluationId, exam.evaluationDetail, exam.finalViolationMessage]);

	const handleAddReview = async () => {
		await dispatch(
			sendReview({
				evaluationDetail: evaluationDetail || '-',
				examId: exam.id,
				exit,
				finalViolationMessage: finalViolation || '-',
				isViolationIntentional,
				review,
				reviewedBy: user.id,
				userId: user.id,
				violationNote,
			})
		);

		dispatch(hideModal());
	};

	// check all violations for review
	for (const violation of allViolations) {
		if (
			(!violation.reviewerReview && !violation.commissionerReview) ||
			(violation.reviewerReview === 'NOK' && !violation.commissionerReview && canReviewAsCommissioner)
		) {
			undoneViolations.push(violation);
		}
	}

	const isReviewCompleted = isEmpty(undoneViolations);

	return (
		<SaveCloseModalTemplate
			title={<FormattedMessage {...messages.confirmReview} />}
			saveBtnTitle={<FormattedMessage {...messages.confirmReview} />}
			onSave={handleAddReview}
			isSaveBtnVisible={isReviewCompleted}
			isSaveBtnDisabled={canReviewAsCommissioner && !evaluationDetail && !isReviewPass}
		>
			{isReviewCompleted ? (
				canReviewAsCommissioner && !isReviewPass ? (
					<>
						<FormControl className={classes.selectTitle}>
							<InputLabel id="select-violation">
								<FormattedMessage {...messages.violationTitle} />
							</InputLabel>
							<Select
								labelId="select-violation"
								label={<FormattedMessage {...messages.violationTitle} />}
								value={finalViolation}
								variant="outlined"
								onChange={(e, child) => {
									setEvaluationId(child.props.evaluationId);
									setFinalViolation(e.target.value);
								}}
							>
								{preventDuplicity(confirmedViolations).map((violation, i) => (
									<MenuItem
										key={violation.id}
										value={violation.reviewerNote || violation.comment || 'null'}
										evaluationId={violation.evaluationId}
									>
										{violation.reviewerNote || violation.comment || 'null'}
									</MenuItem>
								))}
							</Select>
						</FormControl>
						<br />
						<br />
						<FormControl className={classes.selectTitle}>
							<InputLabel id="select-evaluation-detail">
								<FormattedMessage {...messages.evaluationDetail} />
							</InputLabel>
							<Select
								labelId="select-evaluation-detail"
								label={<FormattedMessage {...messages.evaluationDetail} />}
								value={evaluationDetail}
								variant="outlined"
								onChange={(e) => setEvaluationDetail(e.target.value)}
							>
								{evaluationDetails
									.filter(({ evaluationId: _evaluationId }) => evaluationId === _evaluationId)
									.map((evaluationDetail) => (
										<MenuItem key={evaluationDetail.id} value={evaluationDetail.text}>
											{evaluationDetail.text}
										</MenuItem>
									))}
							</Select>
						</FormControl>
						<br />
						<br />
						<TextField
							value={violationNote}
							label={<FormattedMessage {...messages.violationNote} />}
							onChange={(e) => setViolationNote(e.target.value)}
							variant="outlined"
							fullWidth
						/>
						<br />
						<br />
						<FormControl>
							<FormControlLabel
								control={
									<Checkbox
										checked={isViolationIntentional}
										onChange={() => setIsViolationIntentional(!isViolationIntentional)}
										color="primary"
									/>
								}
								label={<FormattedMessage {...messages.intentionalViolation} />}
							/>
						</FormControl>
					</>
				) : (
					<FormattedMessage {...messages.confirmReviewDone} />
				)
			) : (
				<>
					<FormattedMessage {...messages.confirmReviewUndone} />
					<br />
					<br />
					{undoneViolations.map((violation) => (
						<div key={violation.id}>
							<FormattedMessage {...messages[violation.tab]} /> - {msToTime(violation.createdAt)} - {violation.comment}
						</div>
					))}
				</>
			)}
		</SaveCloseModalTemplate>
	);
};

export default ConfirmReviewModal;
