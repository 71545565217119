import { Save } from '@mui/icons-material';
import { Grid, Paper, TextField } from '@mui/material';
import { yellow } from '@mui/material/colors';
import { equals } from 'ramda';
import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from 'tss-react/mui';
import { SCALING_POSTPROCESSING_DEPLOYMENTS } from '../../consts';
import messages from '../../intl/messages';
import {
	editScalingPostprocessing,
	fetchScalingDeployments,
	fetchScalingJobsCount,
	fetchRunningJobsCount,
} from '../../store/model/actions';
import { getScalingDeployments, getScalingJobsCount, getRunningJobsCount } from '../../store/model/selectors';
import useInterval from '../../utils/useInterval';
import UniButtton from '../UniButtton';

const useStyles = makeStyles()((theme) => ({
	bounding: {
		padding: '0.7rem 2rem 2rem 2rem',
		fontSize: '0.9rem',
		'& .saveIcon': { float: 'right', marginTop: '7px' },
		'& .row': {
			backgroundColor: 'white',
		},
		'&.draft': {
			background: yellow['100'],
		},
		'& h2': {
			display: 'inline-block',
			fontSize: '1.2rem',
			marginBottom: '2rem',
		},
		'& h4': {
			margin: '5px',
			marginLeft: 0,
		},
		'& .ml10': { marginLeft: '10px' },
		'& .mb40': { marginBottom: '40px' },
		'& .runningJobs': {
			fontWeight: 'bold',
			fontSize: '1rem',
			margin: '30px 0 0 12px',
		},
	},
}));

const ScalingPostprocessing = () => {
	const { classes } = useStyles();
	const scalingDeployments = useSelector(getScalingDeployments).filter((deployment) =>
		SCALING_POSTPROCESSING_DEPLOYMENTS.includes(deployment.name)
	);
	const scalingJobsCount = useSelector(getScalingJobsCount);
	const runningJobsCount = useSelector(getRunningJobsCount);
	const [stateJobsCount, setStateJobsCount] = useState(scalingJobsCount);
	const dispatch = useDispatch();
	const [isDraft, setIsDraft] = useState(false);
	const [isSaving, setIsSaving] = useState(false);

	useEffect(() => {
		dispatch(fetchScalingJobsCount());
		dispatch(fetchScalingDeployments());
		dispatch(fetchRunningJobsCount());
	}, [dispatch]);

	useEffect(() => {
		setStateJobsCount(scalingJobsCount);
	}, [scalingJobsCount]);

	// saving a draft UX
	useEffect(() => {
		if (equals(scalingJobsCount, stateJobsCount)) {
			window.onbeforeunload = () => {};
			setIsDraft(false);
		} else if (!isDraft) {
			window.onbeforeunload = () => false;
			setIsDraft(true);
		}
	}, [isDraft, stateJobsCount, scalingJobsCount]);

	const handleSave = async () => {
		setIsSaving(true);
		await dispatch(editScalingPostprocessing(stateJobsCount));
		setIsSaving(false);
		setIsDraft(false);
		await dispatch(fetchScalingJobsCount());
		window.onbeforeunload = () => {};
	};

	// continuous data fetching
	useInterval(
		() => {
			dispatch(fetchScalingDeployments());
		},
		20000,
		true
	);

	useInterval(
		() => {
			dispatch(fetchRunningJobsCount());
		},
		5000,
		true
	);

	return (
		<Paper elevation={3} className={`${classes.bounding} ${isDraft && 'draft'}`}>
			<h2>
				<FormattedMessage {...messages.scalingPostprocessing} />
			</h2>
			{isSaving ? (
				<span className="saveIcon">
					<FormattedMessage {...messages.uploading} /> ...
				</span>
			) : (
				<UniButtton
					tooltip={<FormattedMessage {...messages[isDraft ? 'saveInstructions' : 'noChanges']} />}
					color="primary"
					onClick={handleSave}
					icon={<Save fontSize="large" />}
					className="saveIcon"
					disabled={!isDraft}
				/>
			)}
			<Grid
				container
				spacing={0}
				direction="row"
				justifyContent="space-evenly"
				alignItems="flex-start"
				className="mb40"
			>
				<Grid item xs={2}>
					<Grid item xs={12}>
						<TextField
							fullWidth
							value={stateJobsCount}
							label={<FormattedMessage {...messages.parallelJobsCount} />}
							variant="outlined"
							onChange={(e) => setStateJobsCount(isNaN(e.target.value) ? stateJobsCount : Number(e.target.value))}
						/>
					</Grid>
					<Grid item xs={12} className="runningJobs">
						<div>
							<FormattedMessage {...messages.runningJobs} />
							<span className="ml10">{runningJobsCount}</span>
						</div>
					</Grid>
				</Grid>
				<Grid item xs={1} />
				<Grid item xs={3}>
					<h4>
						<FormattedMessage {...messages.application} />
					</h4>
					{scalingDeployments.map((deployment, i) => (
						<div key={'deploymentName' + i}>{deployment.name}</div>
					))}
				</Grid>
				<Grid item xs={3} className="tac">
					<h4>
						<FormattedMessage {...messages.required} />
					</h4>
					{scalingDeployments.map((deployment, i) => (
						<div key={'replicas' + i}>{`${deployment.replicas || 0}x`}</div>
					))}
				</Grid>
				<Grid item xs={3} className="tac">
					<h4>
						<FormattedMessage {...messages.currentStatus} />
					</h4>
					{scalingDeployments.map((deployment, i) => (
						<div key={'availableReplicas' + i}>{`${deployment.availableReplicas || 0}x`}</div>
					))}
				</Grid>
			</Grid>
		</Paper>
	);
};

export default ScalingPostprocessing;
