import { useEffect, useRef } from 'react';

const useInterval = (callback, delay, doIt = true) => {
	const savedCallback = useRef();

	useEffect(() => {
		savedCallback.current = callback;
	}, [callback]);

	useEffect(() => {
		const tick = () => {
			if (doIt) {
				savedCallback.current();
			}
		};

		if (delay !== null) {
			const id = setInterval(tick, delay);
			return () => clearInterval(id);
		}
	}, [delay, doIt]);
};

export default useInterval;
