import { Delete, Edit, ImportExport } from '@mui/icons-material';
import { Button, FormControl, Grid, InputLabel, MenuItem, Paper, Select, TextField } from '@mui/material';
import PropTypes from 'prop-types';
import { invertObj, isEmpty, reverse } from 'ramda';
import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from 'tss-react/mui';
import { NOTE_TYPES } from '../../consts';
import messages from '../../intl/messages';
import { getUserId, getUsers } from '../../store/auth/selectors';
import { hideModal, showConfirmModal, showEditNoteModal } from '../../store/modal/actions';
import { deleteNote, fetchNotes, sendNote } from '../../store/model/actions';
import { getNotesByCreatedAt } from '../../store/model/selectors';
import formatDate from '../../utils/formatDate';
import UniButtton from '../UniButtton';

const useStyles = makeStyles()((theme) => ({
	bounding: {
		padding: '20px',
		'& .notes': { maxHeight: '200px', overflowY: 'scroll', fontSize: '0.9rem' },
		'& .MuiFormControl-root': { width: '100%', marginBottom: '20px' },

		'& .editButton, .deleteButton': {
			display: 'inline',
			'& .MuiButtonBase-root': {
				margin: 0,
			},
		},
	},
}));

const Notes = ({ examId }) => {
	const { classes } = useStyles();
	const dispatch = useDispatch();
	const notes = useSelector(getNotesByCreatedAt);
	const userId = useSelector(getUserId);
	const users = useSelector(getUsers);
	const [text, setText] = useState('');
	const [type, setType] = useState(NOTE_TYPES.VIOLATION);
	const [isReversed, setIsReversed] = useState(true);
	const isNotes = !isEmpty(notes);
	const isAllowedToSendNote = !isEmpty(text) && text !== '\n';

	useEffect(() => {
		dispatch(fetchNotes(examId));
	}, [examId, dispatch]);

	const handleSendNote = () => {
		setText('');

		if (!isAllowedToSendNote) return;

		dispatch(sendNote({ examId, userId, type, text }));
	};

	const handleEditNote = (noteId) => {
		dispatch(showEditNoteModal({ noteId }));
	};

	const handleDeleteNote = (noteId) => {
		dispatch(
			showConfirmModal({
				handler: () => {
					dispatch(deleteNote({ noteId }));
					dispatch(hideModal());
				},
				text: <FormattedMessage {...messages.deleteNotePrompt} />,
				title: <FormattedMessage {...messages.deleteNote} />,
				button: <FormattedMessage {...messages.deleteNote} />,
			})
		);
	};

	return (
		<Paper elevation={3} className={classes.bounding}>
			<Grid container spacing={2} direction="row" alignItems="flex-start">
				{isNotes && (
					<Grid item xs={7}>
						<div className="notes">
							{(isReversed ? reverse(notes) : notes).map(({ createdAt, id, text, userId: noteUserId, type }) => (
								<p key={id}>
									<strong>
										{formatDate(createdAt)} | {users?.[noteUserId]?.email} (
										{<FormattedMessage {...messages['noteType' + invertObj(NOTE_TYPES)[type]]} />})
									</strong>
									:
									{userId === noteUserId && (
										<>
											<UniButtton
												tooltip={<FormattedMessage {...messages.editNote} />}
												color="black"
												onClick={() => handleEditNote(id)}
												icon={<Edit fontSize="small" />}
												className="editButton"
												size="small"
												type="icon"
											/>
											<UniButtton
												tooltip={<FormattedMessage {...messages.deleteNote} />}
												color="black"
												onClick={() => handleDeleteNote(id)}
												icon={<Delete fontSize="small" />}
												className="deleteButton"
												size="small"
												type="icon"
											/>
										</>
									)}
									<br />
									{text}
								</p>
							))}
						</div>
					</Grid>
				)}
				{isNotes && (
					<Grid item xs={1}>
						<UniButtton
							icon={<ImportExport size="small" />}
							className={classes.arrows}
							tooltip={<FormattedMessage {...messages[isReversed ? 'columnMenuSortAsc' : 'columnMenuSortDesc']} />}
							onClick={() => setIsReversed(!isReversed)}
						/>
					</Grid>
				)}
				<Grid item xs={isNotes ? 4 : 12}>
					<TextField
						onChange={(e) => setText(e.target.value)}
						onKeyDown={(e) => (e.key === 'Enter' ? handleSendNote() : null)}
						fullWidth
						label={<FormattedMessage {...messages.writeYourNote} />}
						multiline
						rows={isNotes ? 2 : 1}
						value={text}
						variant="outlined"
						direction="row"
					/>
					<br />
					<FormControl variant="outlined">
						<InputLabel id="note-type">
							<FormattedMessage {...messages.type} />
						</InputLabel>
						<Select
							labelId="note-type"
							value={type}
							onChange={(e) => setType(e.target.value)}
							label={<FormattedMessage {...messages.type} />}
							fullWidth
						>
							{Object.entries(NOTE_TYPES).map(([name, type]) => (
								<MenuItem key={'note-type-' + type} value={type}>
									<FormattedMessage {...messages['noteType' + name]} />
								</MenuItem>
							))}
						</Select>
					</FormControl>

					<Button variant="contained" color="primary" disabled={!isAllowedToSendNote} onClick={handleSendNote}>
						<FormattedMessage {...messages.addNote} />
					</Button>
				</Grid>
			</Grid>
		</Paper>
	);
};

Notes.propTypes = {
	examId: PropTypes.string,
};

export default Notes;
