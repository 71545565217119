import { FormattedMessage } from 'react-intl';
import messages from '../intl/messages';

export const isPhoneNumberInvalid = (number) => {
	if (!number) {
		return <FormattedMessage {...messages.required} />;
	}

	if (!number.match(/\+[0-9]{12}/g) || number.length > 13)
		return <FormattedMessage {...messages.wrongPhoneNumberFormat} />;

	return null;
};
