import { FileOpen } from '@mui/icons-material';
import QueuePlayNextIcon from '@mui/icons-material/QueuePlayNext';
import { Paper } from '@mui/material';
import { useGridApiRef, gridExpandedSortedRowEntriesSelector } from '@mui/x-data-grid-pro';
import { isEmpty } from 'ramda';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { AUTHORIZED_ACTIONS, ENTITIES, ROUTES, USER_RIGHTS, YES_NO_VALUE_OPTIONS } from '../../consts';
import messages from '../../intl/messages';
import { getUserRole } from '../../store/auth/selectors';
import {
	fetchCheckpoints,
	fetchDefinitions,
	setDefinitionId,
	updateSelectedDefinitionIds,
} from '../../store/model/actions';
import { getDefinitionId, getTermsForExamFilter, getUnarchivedDefinitionsList } from '../../store/model/selectors';
import { setXGridConfig } from '../../store/ui/actions';
import { getXgridConfig } from '../../store/ui/selectors';
import debouce from '../../utils/debounce';
import formatDate from '../../utils/formatDate';
import { numericOperators, selectOperators, stringOperatorsExtended } from '../../utils/prepareXGridData';
import redirectInsteadMenu from '../../utils/redirectInsteadMenu';
import Breadcrumbs from '../Breadcrumbs';
import DataGrid from '../DataGrid';
import UniButtton from '../UniButtton';
import { useStyles } from './ExamsList';

const ExamDefinitions = () => {
	const intl = useIntl();
	const { classes } = useStyles();
	const dispatch = useDispatch();
	const gridRef = useGridApiRef();
	const definitionId = useSelector(getDefinitionId);
	const definitions = useSelector(getUnarchivedDefinitionsList);
	const xgridConfig = useSelector(getXgridConfig());
	const config = xgridConfig[ENTITIES.DEFINITIONS];
	const terms = useSelector(getTermsForExamFilter());
	const [definitionsFiltered, setDefinitionsFiltered] = useState([]);
	const [rowSelectionModel, setSelectionModel] = useState([]);
	const userRole = useSelector(getUserRole());
	const navigate = useNavigate();

	useEffect(() => {
		dispatch(fetchDefinitions());
	}, [dispatch]);

	const handleGoToExams = async (id, definitionsFiltered = []) => {
		if (definitionsFiltered.length > 0) {
			// happens when we display exams from multiple definitions
			dispatch(updateSelectedDefinitionIds(definitionsFiltered));

			for (const definition of definitionsFiltered) {
				await dispatch(fetchCheckpoints(definition));
			}
		} else dispatch(updateSelectedDefinitionIds([]));

		if (id !== definitionId) await dispatch(setDefinitionId(id));

		navigate(`${ROUTES.EXAMS.PATH}/${id}`);
	};

	const columns = [
		{
			field: 'detail',
			headerName: '',
			width: 55,
			resizable: false,
			filterable: false,
			sortable: false,
			pinnable: false,
			disableExport: true,
			renderCell: (params) => (
				<div
					onContextMenu={(e) => redirectInsteadMenu(e, `${ROUTES.EXAMS.PATH}/${params.row.id}`)}
					onClick={() => handleGoToExams(params.row.id, [])}
					style={{ margin: 'auto', display: 'flex' }}
				>
					<FileOpen />
				</div>
			),
		},
		{
			field: 'id',
			headerName: intl.formatMessage(messages.definitionId),
			width: 320,
			cellClassName: 'cursorAuto',
			type: 'string',
			filterOperators: stringOperatorsExtended(intl),
		},
		{
			field: 'name',
			headerName: intl.formatMessage(messages.definition),
			width: 220,
			type: 'string',
			filterOperators: stringOperatorsExtended(intl),
		},
		{
			field: 'term',
			headerName: intl.formatMessage(messages.term),
			width: 180,
			type: 'singleSelect',
			filterOperators: selectOperators(terms, intl),
		},
		{
			field: 'participantsCount',
			headerName: intl.formatMessage(messages.expectedExams),
			align: 'right',
			type: 'number',
			headerAlign: 'right',
			width: 150,
			filterOperators: numericOperators(intl),
		},
		{
			field: 'runningExamsCount',
			headerName: intl.formatMessage(messages.running),
			width: 150,
			align: 'right',
			type: 'number',
			headerAlign: 'right',
			filterOperators: numericOperators(intl),
		},
		{
			field: 'finishedExamsCount',
			headerName: intl.formatMessage(messages.finished),
			width: 150,
			align: 'right',
			type: 'number',
			headerAlign: 'right',
			filterOperators: numericOperators(intl),
		},
		{
			field: 'isOnboarding',
			headerName: intl.formatMessage(messages.isOnboarding),
			width: 100,
			type: 'singleSelect',
			filterOperators: selectOperators(YES_NO_VALUE_OPTIONS, intl, false),
		},
		{
			field: 'priority',
			headerName: intl.formatMessage(messages.priority),
			width: 180,
			align: 'right',
			type: 'number',
			headerAlign: 'right',
			filterOperators: numericOperators(intl),
		},
		{
			field: 'startsAt',
			headerName: intl.formatMessage(messages.startsAt),
			width: 180,
			renderCell: (params) => <div>{formatDate(params.row.startsAt).slice(0, -3)}</div>,
			type: 'dateTime',
			valueGetter: (params) => new Date(params.row.startsAt),
		},
		{
			field: 'endsAt',
			headerName: intl.formatMessage(messages.endsAt),
			width: 180,
			renderCell: (params) => <div>{formatDate(params.row.endsAt).slice(0, -3)}</div>,
			type: 'dateTime',
			valueGetter: (params) => new Date(params.row.endsAt),
		},
	];

	const canSelectMultipleRows = useMemo(() => {
		return USER_RIGHTS[userRole].includes(AUTHORIZED_ACTIONS.SELECT_MULTIPLE_DEFINITIONS);
	}, [userRole]);

	const dataGridConfig = {
		checkboxSelection: canSelectMultipleRows,
		disableRowSelectionOnClick: true,
		gridId: ENTITIES.DEFINITIONS,
		rows: definitions,
		// remember filtering
		onFilterModelChange: (e, { api }) => {
			let visibleRows = [];
			const rows = gridExpandedSortedRowEntriesSelector(gridRef);

			// weird workaround - on prod build e.visibleRows is a Map, on localhost it's an array
			if (rows instanceof Map) {
				for (const [, value] of rows) {
					visibleRows.push(value);
				}
			} else {
				visibleRows = rows;
			}
			const visibleRowsIds = visibleRows.map((row) => row.id);

			if (visibleRowsIds.length < 6) setDefinitionsFiltered(visibleRowsIds);
			else setDefinitionsFiltered([]);

			// Sysadmin can select definitions by filtering them
			// if (canSelectMultipleRows) {
			// 	// if there is a filter, select all visible rows
			// 	if (!isEmpty(e.items.filter((filterItem) => Boolean(filterItem?.value))))
			// 		gridRef.current.setRowSelectionModel(visibleRows.map((row) => row.id));
			// 	// deselect all visible rows
			// 	else gridRef.current.setRowSelectionModel([]);
			// }

			// store filter model config
			dispatch(setXGridConfig({ ...config, filterModel: e }, ENTITIES.DEFINITIONS));
		},
		onRowSelectionModelChange: (e) => {
			setDefinitionsFiltered(e);
			setSelectionModel(e);
		},
		rowSelectionModel,
		columns,
		apiRef: gridRef,
	};

	// Save scroll coordinates
	const saveScrollCoordinates = useCallback(
		() => localStorage.setItem('definitionsScrollCoordinates', JSON.stringify(gridRef.current.getScrollPosition())),
		[gridRef]
	);

	// Apply last scroll coordinates
	useEffect(() => {
		const initScrollPos = localStorage.getItem('definitionsScrollCoordinates');

		if (gridRef?.current?.scroll && initScrollPos)
			setTimeout(() => gridRef.current?.scroll(JSON.parse(initScrollPos)), 1);
	}, [gridRef]);

	// Listen to scroll
	useEffect(() => {
		setTimeout(() => {
			document.querySelector('.MuiDataGrid-virtualScroller')?.addEventListener('scroll', () => {
				debouce(saveScrollCoordinates);
			});
		}, 1);
	}, [saveScrollCoordinates]);

	return (
		<div className={classes.bounding}>
			<Breadcrumbs />
			{!isEmpty(definitionsFiltered) && (
				<UniButtton
					className="listControlsExams"
					onClick={() => handleGoToExams(definitionsFiltered[0], definitionsFiltered)}
					icon={<QueuePlayNextIcon size="small" />}
					tooltip={<FormattedMessage {...messages.proceedToExams} />}
				/>
			)}
			<br />
			<Paper elevation={3} style={{ padding: '7px' }}>
				<div style={{ height: 'calc(100vh - 150px)' }}>
					<DataGrid {...dataGridConfig} />
				</div>
			</Paper>
		</div>
	);
};

export default ExamDefinitions;
