import { TextField } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { STREAM_TYPES } from '../../consts';
import messages from '../../intl/messages';
import { getCurrentExam } from '../../store/model/selectors';
import { getTypeFilter } from '../../store/ui/selectors';
import { toDateCzLocalized } from '../../utils/formatDate';
import Identification from '../Identification';
import VideoBox from './VideoBox';

const LeftBoxContent = () => {
	const typeFilter = useSelector(getTypeFilter);
	const exam = useSelector(getCurrentExam);

	return (
		<div className="controls">
			<TextField
				value={exam?.firstName ?? '-'}
				disabled
				label={<FormattedMessage {...messages.firstName} />}
				size="small"
				variant="filled"
			/>
			<TextField
				value={exam?.lastName ?? '-'}
				disabled
				label={<FormattedMessage {...messages.lastName} />}
				size="small"
				variant="filled"
			/>
			<TextField
				value={exam?.dateOfBirth ? toDateCzLocalized(exam.dateOfBirth) : '-'}
				disabled
				label={<FormattedMessage {...messages.birthDate} />}
				size="small"
				variant="filled"
			/>
			{typeFilter === STREAM_TYPES.IMAGE ? <Identification /> : <VideoBox />}
		</div>
	);
};

export default LeftBoxContent;
