import Stats from '././components/Stats';
import ChangePassword from './components/ChangePassword';
import Dashboard from './components/Dashboard/Dashboard';
import ExamPreview from './components/ExamPreview/ExamPreview';
import ExamDefinitions from './components/Exams/ExamDefinitions';
import Exams from './components/Exams/Exams';
import ExamsList from './components/Exams/ExamsList';
import Login from './components/Login';
import ActiveDeactiveRemoveUserModal from './components/Modals/ActiveDeactiveRemoveUserModal';
import AddViolationModal from './components/Modals/AddViolationModal';
import AssignReviewModal from './components/Modals/AssignReviewModal';
import ConfirmModal from './components/Modals/ConfirmModal';
import ConfirmReviewModal from './components/Modals/ConfirmReviewModal';
import EditNoteModal from './components/Modals/EditNoteModal';
import EditTextModal from './components/Modals/EditTextModal';
import EditUserModal from './components/Modals/EditUserModal';
import FinishDefinitionExamsModal from './components/Modals/FinishDefinitionExamsModal';
import ForgotPasswordModal from './components/Modals/ForgotPasswordModal';
import ProcessModal from './components/Modals/ProcessModal';
import RemoveDefinitionModal from './components/Modals/RemoveDefinitionModal';
import RemoveExamsModal from './components/Modals/RemoveExamsModal';
import Notes from './components/Notes';
import ResetChangePasswordToken from './components/ResetChangePasswordToken';
import Scaling from './components/Scaling/Scaling';
import Sections from './components/Sections';
import Settings from './components/Settings/Settings';
import Users from './components/Users';
import keyMirror from './utils/keyMirror';

export const MODAL_TYPES = keyMirror({
	ACTIVATE_DEACTIVATE_REMOVE_USER: null,
	ADD_VIOLATION: null,
	ASSIGN_REVIEWS: null,
	CONFIRM: null,
	CONFIRM_REVIEW: null,
	EDIT_NOTE: null,
	EDIT_TEXT: null,
	EDIT_USER: null,
	FINISH_DEFINITION_EXAMS: null,
	FORGOT_PASSWORD: null,
	PROCESS: null,
	REMOVE_DEFINITION: null,
	REMOVE_EXAMS: null,
});

export const modals = {
	[MODAL_TYPES.ACTIVATE_DEACTIVATE_REMOVE_USER]: ActiveDeactiveRemoveUserModal,
	[MODAL_TYPES.ADD_VIOLATION]: AddViolationModal,
	[MODAL_TYPES.ASSIGN_REVIEWS]: AssignReviewModal,
	[MODAL_TYPES.CONFIRM]: ConfirmModal,
	[MODAL_TYPES.CONFIRM_REVIEW]: ConfirmReviewModal,
	[MODAL_TYPES.EDIT_NOTE]: EditNoteModal,
	[MODAL_TYPES.EDIT_TEXT]: EditTextModal,
	[MODAL_TYPES.EDIT_USER]: EditUserModal,
	[MODAL_TYPES.FINISH_DEFINITION_EXAMS]: FinishDefinitionExamsModal,
	[MODAL_TYPES.FORGOT_PASSWORD]: ForgotPasswordModal,
	[MODAL_TYPES.PROCESS]: ProcessModal,
	[MODAL_TYPES.REMOVE_DEFINITION]: RemoveDefinitionModal,
	[MODAL_TYPES.REMOVE_EXAMS]: RemoveExamsModal,
};

const API_BASE_URL = 'https://proctoring-backend.sciolink.cz/api/';

export const ENTITIES = {
	ACTIVATE_USER: 'users/activate',
	ASSIGN_REVIEWS: 'reviews/assign',
	CHECKLISTS: 'checklists',
	CHECKPOINTS: 'checkpoints',
	DASHBOARD: 'dashboard',
	DASHBOARD_PREVIEW: 'dashboard_preview',
	DEACTIVATE_USER: 'users/deactivate',
	DEFINITIONS: 'exam_definitions',
	DEFINITIONS_LIST: 'definitions',
	DEFINITION_SETTINGS: 'settings',
	EVALUATIONS: 'evaluations',
	EVALUATION_DETAILS: 'evaluation_details',
	EXAM: 'exam',
	EXAMS: 'exams',
	EXAMS_EXTERNAL: 'exams/external',
	EXAMS_FILTER: 'exams/filter',
	EXAMS_PREVIOUS: 'exams/previous',
	EXAM_PREVIEW_ORIGIN: 'exam_preview_origin',
	EXAMS_FOR_REVIEW_COUNT: 'reviews/count/reviewer_queue',
	EXAMS_FOR_COMMISSIONER_REVIEW_COUNT: 'reviews/count/commissioner_queue',
	FACE_URL: 'image/face',
	FINISH_DEFINITION: 'exams/finish/definition',
	FINISH_EXAM: 'exams/finish',
	ID_URL: 'image/id',
	INSTRUCTIONS: 'instructions',
	LISTS: 'lists',
	LOGIN: 'auth/login',
	NOTES: 'notes',
	PASSWORD: 'auth/password',
	POSTPONED_EXAMS: 'postponed_exams',
	POSTPONE_REVIEW: 'reviews/postpone',
	PROCESS: 'checkpoints/process',
	QUEUE: 'queue',
	REFRESH_TOKEN: 'auth/token',
	RESET_ASSIGNED_REVIEWS: 'reviews/reset_assigned',
	RESET_POSTPONED_REVIEWS: 'reviews/reset_postponed',
	REVIEWED_EXAMS: 'reviewed_exams',
	REVIEWS: 'reviews',
	REVIEWS_COMMISIONER: 'reviews/commissioner',
	REVIEW_SETTINGS: 'review_settings',
	RUNNING_EXAMS: 'scaling/running/exams',
	RUNNING_JOBS: 'scaling/running/jobs',
	SCALING: 'scaling',
	SCALING_CLUSTER: 'scaling/cluster',
	SCALING_CLUSTER_START: 'scaling/cluster/start',
	SCALING_CLUSTER_STOP: 'scaling/cluster/stop',
	SCALING_HISTORY: 'scaling/history',
	SCALING_DB: 'scaling/db',
	SCALING_DEPLOYMENTS: 'scaling/deployments',
	SCALING_JOBS_COUNT: 'scaling/jobs',
	SCALING_NODES: 'scaling/nodepools',
	SCALING_SCALE: 'scaling/scale',
	SCALING_SCALE_POSTPROCESSING: 'scaling/scale/postprocessing',
	SCALING_STATUS: 'scaling/status',
	SCALING_SWITCH: 'scaling/switch',
	SCALING_AUTOSCALER_STATUS: 'scaling/autoscaler/status',
	SCALING_USERS_COUNT: 'scaling/users',
	START_AUTOSCALER: 'scaling/autoscaler/start',
	STOP_AUTOSCALER: 'scaling/autoscaler/stop',
	SCREEN: 'screen',
	SIGNUP: 'auth/signup',
	STATS: 'statistics',
	SETTINGS: 'statistics',
	UNASSIGN_REVIEW: 'reviews/unassign',
	UPLOAD: 'upload',
	USERS: 'users',
	USER_BY_TOKEN: 'auth/user',
	VIDEO_ROOM: 'video/room',
	VIOLATIONS: 'violations',
	VIOLATION_REVIEW: 'REVIEW',
	VIOLATION_REVIEWS: 'REVIEWS',
	WEBCAM: 'webcam',
	PROCESS_EXAM: 'exams/process',
	DATAGRID: 'datagrid',
};

export const AUTHORIZED_ACTIONS = {
	REVIEW_AS_REVIEWER: 'reviewAsReviewer',
	REVIEW_AS_COMMISSIONER: 'reviewAsCommissioner',
	ASSIGN_REVIEW: 'assignReview',
	SELF_ASSIGN_REVIEW: 'selfAssignReview',
	STOP_RUNNING_EXAM: 'stopRunningExam',
	USE_BREADCRUMBS: 'viewBreadcrumbs',
	VIEW_REVIEW_THUMBS: 'viewReviewThumbs',
	VIEW_PREVIOUS_EXAMS: 'viewPreviousExams',
	SELECT_MULTIPLE_DEFINITIONS: 'selectMultipleDefinitions',
	SELECT_MULTIPLE_EXAMS: 'selectMultipleExams',
	REMOVE_EXAMS: 'removeExams',
	RESET_REVIEWS: 'resetReviews',
	SET_INSTRUCTIONS: 'setInstructions',
	VIEW_EXAM_FILTER: 'viewExamFilter',
	COMMISSIONER_QUEUE: 'commissionerQueue',
	REVIEWER_QUEUE: 'reviewerQueue',
	FINISH_EXAM: 'finishExam',
};

export const EXAM = {
	RUNNING: 'RUNNING',
	FINISHED: 'FINISHED',
};

// TODO: function that adds API_BASE_URL would be better
export const API_URL = {
	CREATE_2FA_TOKEN: API_BASE_URL + ENTITIES.LOGIN + '/2fa/create',
	FORGET_PASSWORD: API_BASE_URL + ENTITIES.PASSWORD + '/forgot',
	RENEW_PASSWORD: API_BASE_URL + ENTITIES.PASSWORD + '/renew',
	VERIFY_2FA_TOKEN: API_BASE_URL + ENTITIES.LOGIN + '/2fa/verify',
	[ENTITIES.ACTIVATE_USER]: API_BASE_URL + ENTITIES.ACTIVATE_USER,
	[ENTITIES.ASSIGN_REVIEWS]: API_BASE_URL + ENTITIES.ASSIGN_REVIEWS,
	[ENTITIES.CHECKLISTS]: (id) => API_BASE_URL + ENTITIES.CHECKLISTS + '/' + id,
	[ENTITIES.CHECKPOINTS]: (id) => API_BASE_URL + ENTITIES.CHECKPOINTS + '/' + id,
	[ENTITIES.DASHBOARD]: API_BASE_URL + ENTITIES.DASHBOARD,
	[ENTITIES.DEACTIVATE_USER]: API_BASE_URL + ENTITIES.DEACTIVATE_USER,
	[ENTITIES.DEFINITIONS]: API_BASE_URL + ENTITIES.DEFINITIONS,
	[ENTITIES.EVALUATIONS]: API_BASE_URL + ENTITIES.EVALUATIONS,
	[ENTITIES.EVALUATION_DETAILS]: API_BASE_URL + ENTITIES.EVALUATION_DETAILS,
	[ENTITIES.EXAMS]: (id) => API_BASE_URL + ENTITIES.EXAMS + '/' + id,
	[ENTITIES.EXAMS_FOR_REVIEW_COUNT]: (id) => API_BASE_URL + ENTITIES.EXAMS_FOR_REVIEW_COUNT + '/' + id,
	[ENTITIES.EXAMS_FOR_COMMISSIONER_REVIEW_COUNT]: (id) =>
		API_BASE_URL + ENTITIES.EXAMS_FOR_COMMISSIONER_REVIEW_COUNT + '/' + id,
	[ENTITIES.EXAMS_EXTERNAL]: (id) => API_BASE_URL + ENTITIES.EXAMS_EXTERNAL + '/' + id,
	[ENTITIES.EXAMS_FILTER]: API_BASE_URL + ENTITIES.EXAMS_FILTER,
	[ENTITIES.EXAMS_PREVIOUS]: API_BASE_URL + ENTITIES.EXAMS_PREVIOUS,
	[ENTITIES.FACE_URL]: (id) => API_BASE_URL + ENTITIES.FACE_URL + '/' + id,
	[ENTITIES.FINISH_DEFINITION]: API_BASE_URL + ENTITIES.FINISH_DEFINITION,
	[ENTITIES.FINISH_EXAM]: (id) => API_BASE_URL + ENTITIES.FINISH_EXAM + '/' + id,
	[ENTITIES.ID_URL]: (id) => API_BASE_URL + ENTITIES.ID_URL + '/' + id,
	[ENTITIES.INSTRUCTIONS]: API_BASE_URL + ENTITIES.INSTRUCTIONS,
	[ENTITIES.LOGIN]: API_BASE_URL + ENTITIES.LOGIN,
	[ENTITIES.NOTES]: API_BASE_URL + ENTITIES.NOTES,
	[ENTITIES.PASSWORD]: API_BASE_URL + ENTITIES.PASSWORD,
	[ENTITIES.POSTPONE_REVIEW]: API_BASE_URL + ENTITIES.POSTPONE_REVIEW,
	[ENTITIES.PROCESS]: (id) => API_BASE_URL + ENTITIES.PROCESS + '/' + id,
	[ENTITIES.REFRESH_TOKEN]: API_BASE_URL + ENTITIES.REFRESH_TOKEN,
	[ENTITIES.RESET_ASSIGNED_REVIEWS]: API_BASE_URL + ENTITIES.RESET_ASSIGNED_REVIEWS,
	[ENTITIES.RESET_POSTPONED_REVIEWS]: API_BASE_URL + ENTITIES.RESET_POSTPONED_REVIEWS,
	[ENTITIES.REVIEWS]: API_BASE_URL + ENTITIES.REVIEWS,
	[ENTITIES.RUNNING_EXAMS]: API_BASE_URL + ENTITIES.RUNNING_EXAMS,
	[ENTITIES.RUNNING_JOBS]: API_BASE_URL + ENTITIES.RUNNING_JOBS,
	[ENTITIES.SCALING_CLUSTER]: API_BASE_URL + ENTITIES.SCALING_CLUSTER,
	[ENTITIES.SCALING_HISTORY]: API_BASE_URL + ENTITIES.SCALING_HISTORY,
	[ENTITIES.SCALING_CLUSTER_START]: API_BASE_URL + ENTITIES.SCALING_CLUSTER_START,
	[ENTITIES.SCALING_CLUSTER_STOP]: API_BASE_URL + ENTITIES.SCALING_CLUSTER_STOP,
	[ENTITIES.SCALING_DB]: API_BASE_URL + ENTITIES.SCALING_DB,
	[ENTITIES.SCALING_DEPLOYMENTS]: API_BASE_URL + ENTITIES.SCALING_DEPLOYMENTS,
	[ENTITIES.SCALING_JOBS_COUNT]: API_BASE_URL + ENTITIES.SCALING_JOBS_COUNT,
	[ENTITIES.SCALING_NODES]: API_BASE_URL + ENTITIES.SCALING_NODES,
	[ENTITIES.SCALING_SCALE]: API_BASE_URL + ENTITIES.SCALING_SCALE,
	[ENTITIES.SCALING_SCALE_POSTPROCESSING]: API_BASE_URL + ENTITIES.SCALING_SCALE_POSTPROCESSING,
	[ENTITIES.SCALING_STATUS]: API_BASE_URL + ENTITIES.SCALING_STATUS,
	[ENTITIES.SCALING_SWITCH]: API_BASE_URL + ENTITIES.SCALING_SWITCH,
	[ENTITIES.SCALING_AUTOSCALER_STATUS]: API_BASE_URL + ENTITIES.SCALING_AUTOSCALER_STATUS,
	[ENTITIES.SCALING_USERS_COUNT]: API_BASE_URL + ENTITIES.SCALING_USERS_COUNT,
	[ENTITIES.START_AUTOSCALER]: API_BASE_URL + ENTITIES.START_AUTOSCALER,
	[ENTITIES.STOP_AUTOSCALER]: API_BASE_URL + ENTITIES.STOP_AUTOSCALER,
	[ENTITIES.SCREEN]: (id) => API_BASE_URL + 'video/' + ENTITIES.SCREEN + '/' + id,
	[ENTITIES.SIGNUP]: API_BASE_URL + ENTITIES.SIGNUP,
	[ENTITIES.STATS]: API_BASE_URL + ENTITIES.STATS,
	[ENTITIES.UNASSIGN_REVIEW]: API_BASE_URL + ENTITIES.UNASSIGN_REVIEW,
	[ENTITIES.UPLOAD]: API_BASE_URL + ENTITIES.UPLOAD,
	[ENTITIES.USERS]: API_BASE_URL + ENTITIES.USERS,
	[ENTITIES.USER_BY_TOKEN]: API_BASE_URL + ENTITIES.USER_BY_TOKEN,
	[ENTITIES.VIDEO_ROOM]: (id) => API_BASE_URL + ENTITIES.VIDEO_ROOM + '/' + id,
	[ENTITIES.VIOLATIONS]: API_BASE_URL + ENTITIES.VIOLATIONS,
	[ENTITIES.VIOLATION_REVIEWS]: (userId) => API_BASE_URL + ENTITIES.VIOLATIONS + '/' + userId,
	[ENTITIES.VIOLATION_REVIEW]: (userId, violationId) =>
		API_BASE_URL + ENTITIES.VIOLATIONS + '/' + userId + '/' + violationId,
	[ENTITIES.WEBCAM]: (id) => API_BASE_URL + 'video/' + ENTITIES.WEBCAM + '/' + id,
	[ENTITIES.PROCESS_EXAM]: API_BASE_URL + ENTITIES.PROCESS_EXAM,
	[ENTITIES.DATAGRID]: API_BASE_URL + ENTITIES.DATAGRID,
};

export const LS_USER = '__user__';

export const USER = {
	ROLES: {
		ADMIN: 'admin',
		COMMISSIONER: 'commissioner',
		REVIEWER: 'reviewer',
		SYSADMIN: 'sysadmin',
		SUPERADMIN: 'superadmin',
	},
};

export const USER_ROLES = Object.values(USER.ROLES);

export const REVIEW = {
	TYPES: {
		REVIEWER: 'reviewerReview',
		COMMISSIONER: 'commissionerReview',
	},
};

export const STREAM_TYPES = {
	DISPLAYS: 'displays',
	IMAGE: 'image',
	PROCESS: 'process',
	SCREEN: 'screen',
	SCREEN_CHUNKS: 'screen_chunks',
	SOUND: 'sound',
	WEBCAM: 'webcam',
	WEBCAM_CHUNKS: 'webcam_chunks',
	HEADPOSE: 'headpose',
};

export const CHECKPOINTS_TYPE = {
	PROCESS: 'process',
};

export const FILTER_VIOLATIONS = keyMirror({
	ALL: null,
	GROUPS: null,
	SUSP: null,
	NO_REVIEW: null,
});

export const CHECKPOINT_GROUP = {
	FORBIDDEN_OBJECTS: 'forbidden_objects',
	NUMBER_DISPLAYS: 'number_of_display',
	PROCESS_ANALYSIS: 'process_analysis',
	ROOM_OBJECTS: 'room_objects',
	SOUND_ANALYSIS: 'sound_analysis',
	VIDEO_QUALITY: 'video_quality',
};

export const EVENTS = {
	STREAM_STARTED_AT: 'streamStartedAt',
};

export const NOTE_TYPES = {
	VIOLATION: 'reviewer',
	NOTE: 'commissioner',
	FEEDBACK: 'positive_feedback',
	NEGATIVE_FEEDBACK: 'negative_feedback',
	BOARD: 'appeals_board',
};

export const EVALUATION_GROUPS = {
	IDENTIFICATION: 'identification',
	ROOM: 'room',
	SCREEN: 'screen',
	CAMERA: 'camera',
};

export const USER_SKILLS = Object.values(EVALUATION_GROUPS);

export const videoSeekFallback = 2; // seconds

export const ROUTES = {
	CHANGE_PASSWORD: { PUBLIC: true, PATH: '/change-password', ELEMENT: <ChangePassword /> },
	DASHBOARD: { PATH: '/dashboard', ELEMENT: <Sections child={<Dashboard />} />, PAGE_TITLE: 'dashboard' },
	EXAMS: {
		PATH: '/exams',
		ELEMENT: <Sections child={<Exams child={<ExamDefinitions />} />} />,
		PAGE_TITLE: 'definitions',
	},
	EXAMS_LIST: { PATH: '/exams/:definitionId', ELEMENT: <Sections child={<Exams child={<ExamsList />} />} /> },
	EXAMS_PREVIEW: {
		PATH: '/exams/:definitionId/:examId',
		ELEMENT: <Sections child={<Exams child={<ExamPreview />} />} />,
	},
	EXAMS_PREVIEW_COMMISSIONER: {
		PATH: '/commissioner-queue',
		ELEMENT: <Sections child={<ExamPreview isCommissionerQueue />} />,
		PAGE_TITLE: 'commissionerQueue',
	},
	EXAMS_PREVIEW_REVIEWER: {
		PATH: '/reviewer-queue',
		ELEMENT: <Sections child={<ExamPreview isReviewerQueue />} />,
		PAGE_TITLE: 'reviewerQueue',
	},
	LOGIN: { PUBLIC: true, PATH: '/login', ELEMENT: <Login />, PAGE_TITLE: 'login' },
	NOTES: { PATH: '/notes', ELEMENT: <Sections child={<Notes />} />, PAGE_TITLE: 'notes' },
	RESET_PASSWORD: { PUBLIC: true, PATH: '/reset-change-password-token', ELEMENT: <ResetChangePasswordToken /> },
	SCALING: { PATH: '/scaling', ELEMENT: <Sections child={<Scaling />} />, PAGE_TITLE: 'scaling' },
	SETTINGS: { PATH: '/settings', ELEMENT: <Sections child={<Settings />} />, PAGE_TITLE: 'settings' },
	SETTINGS_DEFINITION: { PATH: '/settings/:settingsDefinitionId', ELEMENT: <Sections child={<Settings />} /> },
	STAS: { PATH: '/stats', ELEMENT: <Sections child={<Stats />} />, PAGE_TITLE: 'statistics' },
	USERS: { PATH: '/users', ELEMENT: <Sections child={<Users />} />, PAGE_TITLE: 'users' },
};

// Visibility config
export const TAB_VISIBILITY_RIGHTS = {
	[USER.ROLES.REVIEWER]: [ROUTES.DASHBOARD.PATH, ROUTES.STAS.PATH, ROUTES.EXAMS_PREVIEW_REVIEWER.PATH],
	[USER.ROLES.COMMISSIONER]: [
		ROUTES.DASHBOARD.PATH,
		ROUTES.STAS.PATH,
		ROUTES.EXAMS.PATH,
		ROUTES.EXAMS_PREVIEW.PATH,
		ROUTES.EXAMS_PREVIEW_REVIEWER.PATH,
		ROUTES.EXAMS_PREVIEW_COMMISSIONER.PATH,
	],
	[USER.ROLES.ADMIN]: [ROUTES.STAS.PATH, ROUTES.EXAMS.PATH, ROUTES.SETTINGS.PATH],
	[USER.ROLES.SYSADMIN]: [
		ROUTES.DASHBOARD.PATH,
		ROUTES.STAS.PATH,
		ROUTES.NOTES.PATH,
		ROUTES.EXAMS.PATH,
		ROUTES.USERS.PATH,
		ROUTES.SETTINGS.PATH,
		ROUTES.EXAMS_PREVIEW.PATH,
		ROUTES.EXAMS_PREVIEW_REVIEWER.PATH,
		ROUTES.EXAMS_PREVIEW_COMMISSIONER.PATH,
	],
	[USER.ROLES.SUPERADMIN]: [
		ROUTES.DASHBOARD.PATH,
		ROUTES.STAS.PATH,
		ROUTES.NOTES.PATH,
		ROUTES.EXAMS.PATH,
		ROUTES.USERS.PATH,
		ROUTES.SETTINGS.PATH,
		ROUTES.SCALING.PATH,
		ROUTES.EXAMS_PREVIEW.PATH,
		ROUTES.EXAMS_PREVIEW_REVIEWER.PATH,
		ROUTES.EXAMS_PREVIEW_COMMISSIONER.PATH,
	],
};

// Action rights (by default, every userRole can do/click on any feature unless the action is specified here, then only those with that action can do so)
export const USER_RIGHTS = {
	[USER.ROLES.REVIEWER]: [AUTHORIZED_ACTIONS.REVIEW_AS_REVIEWER, AUTHORIZED_ACTIONS.REVIEWER_QUEUE],
	[USER.ROLES.COMMISSIONER]: [
		AUTHORIZED_ACTIONS.REVIEW_AS_COMMISSIONER,
		AUTHORIZED_ACTIONS.COMMISSIONER_QUEUE,
		AUTHORIZED_ACTIONS.SELECT_MULTIPLE_DEFINITIONS,
		AUTHORIZED_ACTIONS.SELECT_MULTIPLE_EXAMS,
		AUTHORIZED_ACTIONS.USE_BREADCRUMBS,
		AUTHORIZED_ACTIONS.VIEW_PREVIOUS_EXAMS,
		AUTHORIZED_ACTIONS.VIEW_REVIEW_THUMBS,
		AUTHORIZED_ACTIONS.VIEW_EXAM_FILTER,
		AUTHORIZED_ACTIONS.SELF_ASSIGN_REVIEW,
	],
	[USER.ROLES.ADMIN]: [],
	[USER.ROLES.SYSADMIN]: [
		AUTHORIZED_ACTIONS.SELF_ASSIGN_REVIEW,
		AUTHORIZED_ACTIONS.REVIEW_AS_COMMISSIONER,
		AUTHORIZED_ACTIONS.COMMISSIONER_QUEUE,
		AUTHORIZED_ACTIONS.REVIEWER_QUEUE,
		AUTHORIZED_ACTIONS.SELECT_MULTIPLE_DEFINITIONS,
		AUTHORIZED_ACTIONS.SELECT_MULTIPLE_EXAMS,
		AUTHORIZED_ACTIONS.USE_BREADCRUMBS,
		AUTHORIZED_ACTIONS.VIEW_PREVIOUS_EXAMS,
		AUTHORIZED_ACTIONS.VIEW_REVIEW_THUMBS,
		AUTHORIZED_ACTIONS.RESET_REVIEWS,
		AUTHORIZED_ACTIONS.VIEW_EXAM_FILTER,
		AUTHORIZED_ACTIONS.ASSIGN_REVIEW,
	],
	[USER.ROLES.SUPERADMIN]: [
		AUTHORIZED_ACTIONS.SELF_ASSIGN_REVIEW,
		AUTHORIZED_ACTIONS.REVIEW_AS_COMMISSIONER,
		AUTHORIZED_ACTIONS.COMMISSIONER_QUEUE,
		AUTHORIZED_ACTIONS.REVIEWER_QUEUE,
		AUTHORIZED_ACTIONS.SELECT_MULTIPLE_DEFINITIONS,
		AUTHORIZED_ACTIONS.SELECT_MULTIPLE_EXAMS,
		AUTHORIZED_ACTIONS.USE_BREADCRUMBS,
		AUTHORIZED_ACTIONS.VIEW_PREVIOUS_EXAMS,
		AUTHORIZED_ACTIONS.VIEW_REVIEW_THUMBS,
		AUTHORIZED_ACTIONS.RESET_REVIEWS,
		AUTHORIZED_ACTIONS.REMOVE_EXAMS,
		AUTHORIZED_ACTIONS.VIEW_EXAM_FILTER,
		AUTHORIZED_ACTIONS.FINISH_EXAM,
		AUTHORIZED_ACTIONS.ASSIGN_REVIEW,
	],
};

export const LANG_CONFIG = [
	{ code: 'cz', img: '' },
	{ code: 'sk', img: '' },
	{ code: 'en', img: '' },
	{ code: 'ua', img: '' },
];

export const EDITOR_DEFAULT = {
	API_KEY: 'd6djood1er54dyga1y3a5nou8lchrkub15be6hcvbscjvwi4',
	INIT: {
		entity_encoding: 'raw',
		height: 500,
		width: 1200,
		menubar: false,
		language: 'cs',
		plugins: [
			'advlist autolink lists link image charmap print preview anchor',
			'searchreplace visualblocks code fullscreen',
			'insertdatetime media table paste code help wordcount',
		],
		toolbar:
			'undo redo | formatselect | ' +
			'bold italic backcolor | alignleft aligncenter ' +
			'alignright alignjustify | bullist numlist outdent indent | ' +
			'removeformat | help',
		content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14',
	},
};

export const JOB_STATUS = keyMirror({ QUEUED: null, RUNNING: null, COMPLETED: null, FAILED: null });

export const COMMISSIONER_REVIEW = ['PASS', 'FAIL', '-'];

export const REVIEWER_REVIEW = ['PASS', 'SUSPICIOUS', '-'];

export const YES_NO_VALUE_OPTIONS = ['Ano', 'Ne'];

export const CHECK = {
	EXAM: 'during_exam',
};

export const TABS = { CAMERA: 'camera', IDENTIFICATION: 'identification', ROOM: 'room', SCREEN: 'screen' };

export const SCALING_EXAM_DEPLOYMENTS = ['app', 'proctoring-ai', 'proctoring-screen', 'proctoring-headpose'];
export const SCALING_POSTPROCESSING_DEPLOYMENTS = ['proctoring-sound', 'worker'];
export const SCALING_EXAM_NODEPOOLS = ['backendpool', 'gpunp', 'screenpool', 'headposepool'];

export const externalMonitoringUrl = (externalId) =>
	window.location.host.includes('dev')
		? `https://admin-sciolink-dev.sciodev.cz/Monitoring/TestSetRedirect?ExamId=${externalId}`
		: `https://admin.sciolink.cz/Monitoring/TestSetRedirect?ExamId=${externalId}`;
