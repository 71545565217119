import { Button, TextField, Paper, Grid } from '@mui/material';
import { toLower } from 'ramda';
import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';
import { ROUTES } from '../consts';
import messages from '../intl/messages';
import { changePassword, getUserByToken } from '../store/auth/actions';
import { getIsUserLoggedIn, getUser } from '../store/auth/selectors';
import { setPageTitle } from '../store/ui/actions';

const useStyles = makeStyles()((theme) => ({
	bounding: {
		textAlign: 'center',
		'& .MuiPaper-root': {
			padding: '2rem',
			textAlign: 'left',
			display: 'inline-block',
			margin: '50px auto',
			minWidth: '250px',
			width: '20%',
		},
	},
}));

const validatePassword = (value) => {
	if (!value) return <FormattedMessage {...messages.required} />;
	else if (value.length < 8) return <FormattedMessage {...messages.passwordTooShort} values={{ number: 8 }} />;
	else if (value === toLower(value)) return <FormattedMessage {...messages.passwordNoCapital} />;
	else if (value.length - (value.match(/[a-zA-Z0-9]/g) || []).length < 1)
		return <FormattedMessage {...messages.passwordNoSpecialCharacter} />;

	return null;
};

const ChangePassword = () => {
	const dispatch = useDispatch();
	const { classes } = useStyles();
	const navigate = useNavigate();
	const isUserLoggedIn = useSelector(getIsUserLoggedIn);
	const intl = useIntl();

	const token = new URLSearchParams(window.location.search).get('token');

	const user = useSelector(getUser);

	const [password, setPassword] = useState('');
	const [confirmPassword, setConfirmPassword] = useState('');

	const [passwordHelperText, setPasswordHelperText] = useState(null);
	const [confirmPasswordHelperText, setConfirmPasswordHelperText] = useState(null);

	const isErrorDisplayed = passwordHelperText || confirmPasswordHelperText;

	useEffect(() => {
		if (isUserLoggedIn) navigate(ROUTES.DASHBOARD.PATH);
	}, [isUserLoggedIn, navigate]);

	useEffect(() => {
		if (!token) {
			navigate('/');
			return;
		}

		(async () => {
			const user = await dispatch(getUserByToken({ token }));
			if (!user) navigate(ROUTES.RESET_PASSWORD.PATH);
		})();

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch, token]);

	useEffect(() => {
		setPasswordHelperText(null);
		setConfirmPasswordHelperText(null);
	}, [password]);

	useEffect(() => {
		setConfirmPasswordHelperText(null);
	}, [confirmPassword]);

	useEffect(() => {
		dispatch(setPageTitle(intl.formatMessage(messages.sendNewPassword)));
	}, [dispatch, intl]);

	const handleChangePassword = async () => {
		const passwordError = validatePassword(password);
		const confirmPasswordError = validatePassword(confirmPassword);

		setPasswordHelperText(passwordError);
		setConfirmPasswordHelperText(confirmPasswordError);

		if (passwordError || confirmPasswordError) return;
		else if (password !== confirmPassword) {
			setConfirmPasswordHelperText(<FormattedMessage {...messages.confirmPasswordNotEqual} />);
			return;
		}

		const requestOk = await dispatch(
			changePassword({
				password,
				confirmPassword,
				userId: user.id,
				goToResetToken: () => navigate('/reset-change-password-token'),
			})
		);
		if (requestOk) navigate('/login');
	};

	return (
		<div className={classes.bounding}>
			<Paper elevation={3}>
				<h3>
					<FormattedMessage {...messages.createNewPassword} />
				</h3>
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<span>
							<FormattedMessage {...messages.email} />: {user.email}
						</span>
					</Grid>
					<Grid item xs={12}>
						<span>
							<FormattedMessage {...messages.userRole} />: <FormattedMessage {...messages[user.role ?? 'unknown']} />
						</span>
					</Grid>{' '}
					<Grid item xs={12}>
						<span>
							<FormattedMessage {...messages.passwordRules} />
						</span>
					</Grid>
					<Grid item xs={12}>
						<TextField
							label={<FormattedMessage {...messages.password} />}
							type="password"
							autoComplete="new-password"
							fullWidth
							value={password}
							error={passwordHelperText}
							helperText={passwordHelperText}
							onChange={(e) => setPassword(e.target.value)}
						/>
					</Grid>
					<Grid item xs={12}>
						<TextField
							label={<FormattedMessage {...messages.confirmPassword} />}
							type="password"
							autoComplete="new-password"
							fullWidth
							value={confirmPassword}
							error={confirmPasswordHelperText}
							helperText={confirmPasswordHelperText}
							onChange={(e) => setConfirmPassword(e.target.value)}
						/>
					</Grid>
					<Grid item xs={12}>
						<Button disabled={isErrorDisplayed} variant="contained" color="primary" onClick={handleChangePassword}>
							<FormattedMessage {...messages.createPassword} />
						</Button>
					</Grid>
				</Grid>
			</Paper>
		</div>
	);
};

export default ChangePassword;
