import { FileOpen, Refresh } from '@mui/icons-material';
import { Paper } from '@mui/material';
import { useEffect, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
	AUTHORIZED_ACTIONS,
	COMMISSIONER_REVIEW,
	ENTITIES,
	REVIEWER_REVIEW,
	ROUTES,
	YES_NO_VALUE_OPTIONS,
} from '../../consts';
import messages from '../../intl/messages';
import { fetchUsers } from '../../store/auth/actions';
import { getEmails, getUserRole } from '../../store/auth/selectors';
import { fetchDashboardExams, fetchDefinitions } from '../../store/model/actions';
import {
	getDashboardExams,
	getDefinitions,
	getDefNamesForExamFilter,
	getTermsForExamFilter,
} from '../../store/model/selectors';
import { setCurrentListTab } from '../../store/ui/actions';
import { toDateCzLocalized } from '../../utils/formatDate';
import {
	prepareExams,
	selectOperators,
	selectOperatorsWEmpty,
	stringOperatorsExtended,
} from '../../utils/prepareXGridData';
import checkUserAuthorization from '../../utils/userRights';
import DataGrid from '../DataGrid';
import { getCommissionerReviewIcon, getReviewerReviewIcon, useStyles } from '../Exams/ExamsList';
import UniButtton from '../UniButtton';

const PostponedExams = () => {
	const intl = useIntl();
	const { classes } = useStyles();
	const dispatch = useDispatch();
	const exams = useSelector(getDashboardExams());
	const definitions = useSelector(getDefinitions());
	const definitionNames = useSelector(getDefNamesForExamFilter());
	const terms = useSelector(getTermsForExamFilter());
	const userRole = useSelector(getUserRole);
	const canReviewAsCommissioner = checkUserAuthorization(userRole, AUTHORIZED_ACTIONS.REVIEW_AS_COMMISSIONER);
	const canReviewAsReviewer = checkUserAuthorization(userRole, AUTHORIZED_ACTIONS.REVIEW_AS_REVIEWER);
	const emails = useSelector(getEmails);
	const navigate = useNavigate();

	const handleOpenReview = async ({ definitionId, examId }) => {
		if (canReviewAsReviewer) {
			localStorage.setItem(ENTITIES.POSTPONE_REVIEW, examId);
		} else if (canReviewAsCommissioner) {
			dispatch(setCurrentListTab(ENTITIES.EXAM));
		}

		navigate(`${ROUTES.EXAMS.PATH}/${definitionId}/${examId}`);
	};

	useEffect(() => {
		dispatch(fetchDashboardExams());
		dispatch(fetchDefinitions());
		dispatch(fetchUsers());
	}, [dispatch]);

	const columns = useMemo(
		() => [
			{
				field: 'detail',
				headerName: '',
				width: 55,
				resizable: false,
				filterable: false,
				sortable: false,
				pinnable: false,
				disableExport: true,
				renderCell: (params) =>
					// Reviewers cannot use external links with definitionId and examId
					canReviewAsReviewer ? (
						<UniButtton
							type="icon"
							icon={<FileOpen />}
							tooltip={<FormattedMessage {...messages.detail} />}
							onClick={() => handleOpenReview({ definitionId: params.row.definitionId, examId: params.row.id })}
						/>
					) : (
						<UniButtton
							onClick={() => navigate(`${ROUTES.EXAMS.PATH}/${params?.row?.definitionId}/${params?.row?.id}`)}
							type="icon"
							icon={<FileOpen />}
							tooltip={<FormattedMessage {...messages.detail} />}
						/>
					),
			},
			{
				field: 'definitionName',
				headerName: intl.formatMessage(messages.definition),
				disableClickEventBubbling: true,
				width: 250,
				cellClassName: 'cursorAuto',
				// Filtering is done using definitionIds and not names themselves
				valueGetter: (params) => params.row.definitionId,
				// However, names are the thing displayed and exported
				renderCell: (params) => definitions[params.row.definitionId]?.name,
				valueFormatter: ({ value }) => definitions[value]?.name,
				type: 'singleSelect',
				filterOperators: selectOperators(definitionNames, intl),
				valueOptions: [],
			},
			{
				field: 'term',
				headerName: intl.formatMessage(messages.term),
				disableClickEventBubbling: true,
				width: 250,
				cellClassName: 'cursorAuto',
				// Filtering is done directly using names of terms, not definitionIds
				valueGetter: (params) => definitions[params.row.definitionId]?.term,
				// Ensures the value is displayed when exporting
				valueFormatter: ({ value }) => value,
				type: 'singleSelect',
				filterOperators: selectOperators(terms, intl),
				valueOptions: [],
			},
			{
				field: 'firstName',
				headerName: intl.formatMessage(messages.firstName),
				width: 180,
				type: 'string',
				filterOperators: stringOperatorsExtended(intl),
			},
			{
				field: 'lastName',
				headerName: intl.formatMessage(messages.lastName),
				width: 180,
				type: 'string',
				filterOperators: stringOperatorsExtended(intl),
			},
			{
				field: 'dateOfBirth',
				headerName: intl.formatMessage(messages.birthDate),
				width: 180,
				valueFormatter: ({ value }) => toDateCzLocalized(value),
				type: 'date',
			},
			{
				field: 'postponed',
				headerName: intl.formatMessage(messages.postponedReview),
				width: 200,
				type: 'singleSelect',
				filterOperators: selectOperators(YES_NO_VALUE_OPTIONS, intl, false),
			},
			{
				field: 'assignedTo',
				headerName: intl.formatMessage(messages.assignedTo),
				width: 240,
				type: 'singleSelect',
				filterOperators: selectOperatorsWEmpty(emails, intl),
			},
			{
				field: 'finalReview',
				headerName: intl.formatMessage(messages.finalReview),
				width: 230,
				renderCell: (params) => <div>{getCommissionerReviewIcon(params.row.finalReview)}</div>,
				type: 'singleSelect',
				filterOperators: selectOperators(COMMISSIONER_REVIEW, intl, false),
			},
			{
				field: 'reviewerReview',
				headerName: intl.formatMessage(messages.reviewerReview),
				width: 230,
				renderCell: (params) => <div>{getReviewerReviewIcon(params.row.reviewerReview)}</div>,
				type: 'singleSelect',
				filterOperators: selectOperators(REVIEWER_REVIEW, intl, false),
			},
			{
				field: 'commissionerReview',
				headerName: intl.formatMessage(messages.commissionerReview),
				width: 230,
				renderCell: (params) => <div>{getCommissionerReviewIcon(params.row.commissionerReview)}</div>,
				type: 'singleSelect',
				filterOperators: selectOperators(COMMISSIONER_REVIEW, intl, false),
			},
		],
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[definitionNames, definitions, dispatch, intl, canReviewAsReviewer, terms]
	);

	const dataGridConfig = {
		gridId: ENTITIES.POSTPONED_EXAMS,
		rows: prepareExams(intl, exams.postponedExams),
		columns,
	};

	return (
		<Paper className={classes.bounding} style={{ height: '345px' }}>
			<h2>
				{intl.formatMessage(messages.postponed)}
				<UniButtton
					onClick={() => dispatch(fetchDashboardExams())}
					icon={<Refresh size="small" />}
					tooltip={<FormattedMessage {...messages.refreshExams} />}
				/>
			</h2>

			<div style={{ height: '345px' }}>
				<DataGrid {...dataGridConfig} />
			</div>
		</Paper>
	);
};

export default PostponedExams;
