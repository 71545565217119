import { isEmpty } from 'ramda';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from 'tss-react/mui';
import { videoSeekFallback, ENTITIES, STREAM_TYPES, USER } from '../../consts';
import messages from '../../intl/messages';
import { getUserRole } from '../../store/auth/selectors';
import { getCurrentExam, getRoomVideoUrl, getScreenUrl, getWebcamUrl } from '../../store/model/selectors';
import { setVideoState } from '../../store/ui/actions';
import { getTypeFilter, getVideoSeek, getVideoState } from '../../store/ui/selectors';
import VideoControlBar from './VideoControlBar';

const useStyles = makeStyles()((theme) => ({
	root: {
		'& .examVideoWrapper': {
			minHeight: '350px',
			width: '100%',
		},
		'& .examVideo': {
			width: '100%',
			height: '350px',
			'&:focus': {
				outline: 'none',
			},
			'&:-webkit-media-controls-panel': {
				display: 'flex !important',
				opacity: '1 !important',
			},
		},
		'&.fullscreen': {
			background: 'black',
			'& .examVideo': {
				width: '100%',
				height: '88vh',
				top: 0,
				left: 0,
			},
			'& .control-bar': {
				marginTop: '-6px',
				paddingLeft: '1rem',
				paddingRight: '1rem',
				background: 'white',
				'& .MuiGrid-item': {
					paddingTop: '5px',
				},
			},
		},

		'& .videoNotFound': {
			position: 'absolute',
			top: '50%',
			textAlign: 'center',
			width: '100%',
		},
	},
}));

// play() / pause() - Fix for DOMException: The play() request was interrupted - https://goo.gl/LdLk22
const play = (videoEl) => {
	if (videoEl) {
		const lsItem = 'dontInterruptVideo' + videoEl.id;

		localStorage.setItem(lsItem, 'true');
		videoEl
			.play()
			.then(() => localStorage.removeItem(lsItem))
			.catch((e) => console.log(e));
	}
};

const pause = (videoEl) => {
	if (videoEl) {
		const lsItem = 'dontInterruptVideo' + videoEl.id;
		if (!localStorage.getItem(lsItem)) videoEl.pause();
	}
};

const VideoBox = ({ pipTypeFilter }) => {
	const dispatch = useDispatch();
	const videoOriginal = useRef();
	const { classes } = useStyles();
	const userRole = useSelector(getUserRole);
	const allowDownload = userRole !== USER.ROLES.REVIEWER && userRole !== USER.ROLES.COMMISSIONER;

	const _typeFilter = useSelector(getTypeFilter);
	const typeFilter = pipTypeFilter || _typeFilter;
	const exam = useSelector(getCurrentExam);
	const webcamUrl = useSelector(getWebcamUrl);
	const screenUrl = useSelector(getScreenUrl);
	const roomVideoUrl = useSelector(getRoomVideoUrl);
	const videoSeek = useSelector(getVideoSeek);
	const videoState = useSelector(getVideoState);

	const getSrc = useCallback(() => {
		switch (typeFilter) {
			case STREAM_TYPES.SCREEN:
				return screenUrl;
			case ENTITIES.VIDEO_ROOM:
				return roomVideoUrl;
			default:
				return webcamUrl;
		}
	}, [roomVideoUrl, screenUrl, typeFilter, webcamUrl]);

	const getVideoErrorMessage = useCallback(() => {
		switch (typeFilter) {
			case STREAM_TYPES.SCREEN:
				return <FormattedMessage {...messages.screenVideoNotFound} />;
			case ENTITIES.VIDEO_ROOM:
				return <FormattedMessage {...messages.roomVideoNotFound} />;
			default:
				return <FormattedMessage {...messages.webcamVideoNotFound} />;
		}
	}, [typeFilter]);

	const [videoUrl, setVideoUrl] = useState(getSrc());

	const [checkpointOffsets, setCheckpointOffsets] = useState([]);

	useEffect(() => {
		if (videoOriginal.current) {
			videoOriginal.current.onerror = (e) => console.error('Video Error: ', e);
			videoOriginal.current.onpause = () => dispatch(setVideoState('pause'));
			videoOriginal.current.onplay = () => dispatch(setVideoState('play'));
		}
	}, [dispatch]);

	useEffect(() => {
		if (videoOriginal.current) {
			const seekInSecs = Math.floor(videoSeek / 1000);
			const seekPoint = seekInSecs - videoSeekFallback < 0 ? 0 : seekInSecs - videoSeekFallback;

			videoOriginal.current.currentTime = seekPoint || 0;
		}
	}, [videoSeek]);

	useEffect(() => {
		if (videoOriginal.current) {
			if (videoState === 'play') play(videoOriginal.current);
			else pause(videoOriginal.current);
		}
	}, [videoState]);

	useEffect(() => {
		setVideoUrl(getSrc());
		dispatch(setVideoState('pause'));
	}, [dispatch, getSrc, typeFilter]);

	useEffect(() => {
		if (exam) {
			const offsets = {
				id: {
					offset: Date.parse(exam.idCheckpointStartedAt) - Date.parse(exam.streamStartedAt),
					tooltip: <FormattedMessage {...messages.idCheckpointStartedAt} />,
				},
				face: {
					offset: Date.parse(exam.faceCheckpointStartedAt) - Date.parse(exam.streamStartedAt),
					tooltip: <FormattedMessage {...messages.faceCheckpointStartedAt} />,
				},
				room: {
					offset: Date.parse(exam.roomCheckpointStartedAt) - Date.parse(exam.streamStartedAt),
					tooltip: <FormattedMessage {...messages.roomCheckpointStartedAt} />,
				},
				exam: {
					offset: Date.parse(exam.examCheckpointStartedAt) - Date.parse(exam.streamStartedAt),
					tooltip: <FormattedMessage {...messages.examCheckpointStartedAt} />,
				},
			};

			if (typeFilter === STREAM_TYPES.WEBCAM || typeFilter === STREAM_TYPES.SCREEN) {
				setCheckpointOffsets([offsets.id, offsets.face, offsets.room, offsets.exam]);
			} else if (typeFilter === ENTITIES.VIDEO_ROOM) setCheckpointOffsets([offsets.id, offsets.face, offsets.room]);
		}
	}, [exam, typeFilter]);

	useEffect(() => {
		const playPauseHandler = (event) => {
			if (event.srcElement === document.body && (event.type === 'click' || event.code === 'Space')) {
				event.preventDefault();
				dispatch(setVideoState(videoOriginal.current.paused ? 'play' : 'pause'));
			}
		};

		// Fullscreen
		const videoContainer = document.getElementById('videoContainer');
		if (videoContainer) {
			videoContainer.onfullscreenchange = (event) => {
				if (document.fullscreenElement) videoContainer.classList.add('fullscreen');
				else videoContainer.classList.remove('fullscreen');
			};
		}

		if (!pipTypeFilter) window.addEventListener('keydown', playPauseHandler);

		if (!pipTypeFilter && videoOriginal?.current) videoOriginal.current.addEventListener('click', playPauseHandler);
		return () => {
			window.removeEventListener('keydown', playPauseHandler);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// Prevent context menu on mouse right click
	useEffect(() => {
		if (videoOriginal.current) videoOriginal.current.addEventListener('contextmenu', (e) => e.preventDefault());
	}, [videoOriginal]);

	return (
		<div className={classes.root} id="videoContainer" style={{ position: 'relative' }}>
			<div className="examVideoWrapper">
				{videoUrl === "empty" && (
					<div className="videoNotFound">{getVideoErrorMessage()}</div>
				)}

				{videoUrl !== "empty" && <video className="examVideo" src={isEmpty(videoUrl) ? null : videoUrl} controls={false} ref={videoOriginal} />}
				<br />
				{videoOriginal.current && videoUrl !== "empty" && (
					<VideoControlBar
						video={videoOriginal.current}
						key={videoUrl}
						checkpointOffsets={typeFilter !== STREAM_TYPES.SCREEN ? checkpointOffsets : []}
						allowDownload={allowDownload}
						isPip={!!pipTypeFilter}
					/>
				)}
			</div>
		</div>
	);
};

export default VideoBox;
