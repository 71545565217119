import {
	CloudDownload,
	Fullscreen,
	Pause,
	PlayArrow,
	PictureInPictureAlt,
	VolumeUp,
	FastForward,
	FastRewind,
	VolumeDown,
	VolumeOff,
} from '@mui/icons-material';
import { Select, Grid, MenuItem, Slider } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState, useTransition } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { TimeSeekSlider } from 'react-time-seek-slider';
import { makeStyles } from 'tss-react/mui';
import messages from '../../intl/messages';
import { setVideoState, setIsPip, setVideoPlaybackRate } from '../../store/ui/actions';
import { getVideoState, getVideoPlaybackRate } from '../../store/ui/selectors';
import { msToTime } from '../../utils/formatDate';
import UniButtton from '../UniButtton';
import 'react-time-seek-slider/lib/ui-time-seek-slider.css';

const useStyles = makeStyles()((theme) => ({
	bounding: {
		'& .button': { '& .MuiButtonBase-root': { margin: 0, padding: '2px 0', minWidth: '40px' } },
		'& .select': { '& .MuiSelect-select': { paddingTop: '5px', paddingBottom: '5px' }, overflow: 'unset !important' },
		'& .time': { fontSize: '12px', fontWeight: 'bold', textAlign: 'center' },
		'& .MuiSelect-root': { padding: '5px 30px' },
		'& .left > div': { display: 'flex', float: 'left' },
		'& .right > div': { float: 'right', marginTop: '10px' },
		'& .center': {
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			flexDirection: 'column',
		},
		'& .buttonContainer': {
			width: '100%',
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
		},
		'& .sliderContainer': {
			position: 'relative',
		},
		'& .dot': {
			background: 'red',
			borderRadius: '50%',
			width: '6px',
			height: '6px',
			position: 'absolute',
			bottom: '25px',
			zIndex: 2,
			marginLeft: '0px',
			'& .tooltipText': {
				position: 'absolute',
				right: '-24px',
				width: 'auto',
				background: 'rgba(97, 97, 97, 0.92)',
				color: 'white',
				textAlign: 'center',
				borderRadius: '4px',
				fontSize: '0.6875rem',
				padding: '5px 8px',
				marginTop: '14px',
				transformOrigin: 'center top',
				transform: 'none',
				opacity: 0,
				transition: 'opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, transform 133ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
			},
		},
		'& .dot:hover': {
			'& .tooltipText': {
				opacity: '1',
			},
		},
		'& .ui-time-seek-slider .main': { backgroundColor: 'lightgrey' },
		'& .ui-time-seek-slider .connect': { backgroundColor: theme.palette.primary.main + ' !important' },
		'& .ui-time-seek-slider .seek-hover': { backgroundColor: theme.palette.primary.light + ' !important' },
		'& .ui-time-seek-slider .handler': { backgroundColor: theme.palette.primary.main + ' !important' },
	},
}));

const SPEED_CONFIG = [0.5, 1, 2, 3, 4, 5];

export const syncVideos = (prop, val) => {
	document.querySelectorAll('video.examVideo').forEach((video) => {
		video[prop] = val;
	});
};

const VideoControlBar = ({ video, checkpointOffsets, allowDownload, isPip }) => {
	const { classes } = useStyles();
	const videoState = useSelector(getVideoState);
	const playbackRate = useSelector(getVideoPlaybackRate);
	const dispatch = useDispatch();
	const [, startTransition] = useTransition();

	const selectRef = useRef();

	// https://stackoverflow.com/questions/53845595/wrong-react-hooks-behaviour-with-event-listener
	// Event listeners for rewind and forward can access freshState through a reference
	const [vidState, _setVidState] = useState({
		currentTime: 0,
		duration: video?.duration ?? null,
		playbackRate: 1,
		volume: video.volume,
	});
	const vidStateRef = useRef(vidState);
	const setVidState = (data) => {
		vidStateRef.current = data;
		_setVidState(data);
	};

	const [isFullscreen, setIsFullscreen] = useState(false);

	const isPaused = videoState === 'pause';
	// VIDEO EVENTS
	video.ondurationchange = (e) =>
		setVidState({ ...vidState, duration: !isNaN(e.target.duration) ? e.target.duration : video?.duration ?? null });
	video.ontimeupdate = (e) => setVidState({ ...vidState, currentTime: e.target.currentTime });
	video.onvolumechange = (e) => setVidState({ ...vidState, volume: e.target.volume });

	// FullScreen
	const handleFullscreen = () => {
		const videoElem = !isPip
			? document.getElementById('videoContainer')
			: document.querySelector('.react-draggable #videoContainer');

		if (videoElem.requestFullscreen) {
			if (document.fullscreenElement) {
				document.exitFullscreen();
				setTimeout(() => window.dispatchEvent(new Event('resize')), 100);
			} else {
				videoElem.requestFullscreen();

				// Force the SeekSlider component to recalculate its total track width
				// The event is fired alongside fullscreen by default but SeekSlider cannot get proper values at that time
				setTimeout(() => window.dispatchEvent(new Event('resize')), 100);

				// Remove focus from fullscreen button to enable SPACE video pause in fullscreen mode
				const button = document.getElementById('fullscreenButton');
				button.blur();
			}
		}
	};

	// Fullscreen cleanup
	useEffect(() => {
		const videoMoveHandler = (event) => {
			const nodeName = event?.target?.nodeName;

			if ((event.code === 'ArrowLeft' || event.code === 'ArrowRight') && nodeName === 'BODY') {
				event.preventDefault();
				(() => {
					handleVideoMove(event.code === 'ArrowLeft' ? -3 : 3, vidStateRef.current);
				})();
			} else if (event.code === 'Escape') setIsFullscreen(false);
		};

		// Necessary to set state like this since this also catches Esc key for instance not just the fullscreen button click
		const changeFullScreenOnKeyDown = () => setIsFullscreen(document.fullscreenElement);

		window.addEventListener('fullscreenchange', changeFullScreenOnKeyDown);
		window.addEventListener('keydown', videoMoveHandler);
		return () => {
			window.removeEventListener('keydown', videoMoveHandler);
			window.removeEventListener('fullscreenchange', changeFullScreenOnKeyDown);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// Download
	const handleDownload = (e) => {
		e.preventDefault();
		window.location.href = video.src;
	};

	// Sync forwarding/backwarding
	const handleVideoMove = (seconds, vidState = vidStateRef.current) =>
		syncVideos(
			'currentTime',
			seconds > 0
				? Math.min(vidState?.duration, vidState?.currentTime + seconds)
				: Math.max(0, vidState?.currentTime + seconds)
		);

	const handleVolumeChange = (e, newValue) => {
		video.volume = newValue;
	};

	// Sync playback rate
	useEffect(() => {
		if (playbackRate) syncVideos('playbackRate', playbackRate);
	}, [playbackRate]);

	return (
		<Grid
			container
			spacing={2}
			direction="row"
			justifyContent="space-between"
			className={`${classes.bounding} control-bar`}
		>
			<Grid item xs={4} display="flex" className="left">
				<Select
					ref={selectRef}
					className="select"
					value={playbackRate}
					variant="outlined"
					onChange={(e) => dispatch(setVideoPlaybackRate(e.target.value))}
					MenuProps={{
						container: selectRef.current,
					}}
				>
					{SPEED_CONFIG.map((speed, i) => (
						<MenuItem key={`VideoControlBar-${speed}-${i}`} value={speed}>
							x{speed}
						</MenuItem>
					))}
				</Select>
				<div className="buttonContainer">
					{vidState.volume > 0 ? <VolumeDown onClick={() => handleVolumeChange({}, 0)} /> : <VolumeOff />}
					<Slider
						min={0}
						max={1}
						step={0.01}
						value={vidState.volume}
						onChange={handleVolumeChange}
						sx={{
							color: 'rgba(0,0,0,0.87)',
							width: '50%',
							margin: '7px',
							'& .MuiSlider-track': {
								border: 'none',
							},
							'& .MuiSlider-thumb': {
								width: 15,
								height: 15,
								backgroundColor: '#fff',
								'&:before': {
									boxShadow: '0 4px 8px rgba(0,0,0,0.4)',
								},
								'&:hover, &.Mui-focusVisible, &.Mui-active': {
									boxShadow: 'none',
								},
							},
						}}
					/>
					<VolumeUp onClick={() => handleVolumeChange({}, 1)} />
				</div>
			</Grid>
			<Grid item xs={4} className="center">
				<div className="buttonContainer">
					<UniButtton
						className="button"
						icon={<FastRewind size="small" />}
						tooltip={<FormattedMessage {...messages.rewind} />}
						onClick={() => handleVideoMove(-3)}
					/>
					<UniButtton
						className="button"
						icon={isPaused ? <PlayArrow size="small" /> : <Pause size="small" />}
						tooltip={<FormattedMessage {...messages[isPaused ? 'play' : 'pause']} />}
						onClick={() => dispatch(setVideoState(isPaused ? 'play' : 'pause'))}
					/>
					<UniButtton
						className="button"
						icon={<FastForward size="small" />}
						tooltip={<FormattedMessage {...messages.forward} />}
						onClick={() => handleVideoMove(3)}
					/>
				</div>
			</Grid>
			<Grid item xs={4} className="right">
				<UniButtton
					id="fullscreenButton"
					className="button"
					icon={<Fullscreen size="small" />}
					tooltip={<FormattedMessage {...messages.fullscreen} />}
					onClick={handleFullscreen}
				/>
				<UniButtton
					className="button"
					icon={<PictureInPictureAlt size="small" />}
					tooltip={<FormattedMessage {...messages.pictureInPicture} />}
					disabled={isFullscreen}
					onClick={() => dispatch(setIsPip(!isPip))}
				/>
				{allowDownload && (
					<UniButtton
						className="button"
						icon={<CloudDownload size="small" />}
						tooltip={<FormattedMessage {...messages.download} />}
						onClick={handleDownload}
					/>
				)}
			</Grid>
			<Grid item xs={12} className="sliderContainer">
				{checkpointOffsets.map(({ tooltip, offset }, i) => (
					<div
						key={`VideoControlBar-checkpointOffsets-${i}`}
						className="dot tooltip"
						// Prevent the dot overflowing beyond 100% of videoBar
						style={{ left: Math.min((offset / 1000 / vidState.duration) * 100, 100) + '%' }}
						onClick={() => {
							// In case the dot has an offset longer than the duration
							// Subtract a fraction of a second from duration, otherwise the video goes back to start
							const time = Math.min(offset / 1000, vidState.duration - 0.05);
							startTransition(() => {
								setVidState({ ...vidState, currentTime: time });
							});
							syncVideos('currentTime', time);
						}}
					>
						<span className="tooltipText MuiTooltip-tooltip">{tooltip}</span>
					</div>
				))}
				<TimeSeekSlider
					key={isFullscreen ? 'fullscreen' : 'no-fullscreen'}
					max={vidState.duration}
					currentTime={vidState.currentTime}
					onSeeking={(time) => {
						startTransition(() => {
							setVidState({ ...vidState, currentTime: time });
						});
						syncVideos('currentTime', time);
					}}
					offset={0}
					secondsPrefix="00:00:"
					minutesPrefix="00:"
				/>
				<div className="time">
					{msToTime(vidState.currentTime * 1000)}
					{vidState.duration && '/' + msToTime(vidState.duration * 1000)}
				</div>
			</Grid>
		</Grid>
	);
};

VideoControlBar.propTypes = {
	video: PropTypes.object,
	checkpointOffsets: PropTypes.array,
};

export default VideoControlBar;
