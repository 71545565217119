import { Tooltip } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { messages } from '../intl';
import { logoutUser } from '../store/auth/actions';
import useInterval from '../utils/useInterval';

const msToHHMMSS = (ms) => {
	let seconds = ms / 1000;
	const hours = parseInt(seconds / 3600);
	seconds = seconds % 3600;
	const minutes = parseInt(seconds / 60);
	seconds = Math.floor(seconds % 60);

	return `${hours < 10 ? '0' + hours : hours}:${minutes < 10 ? '0' + minutes : minutes}:${
		seconds < 10 ? '0' + seconds : seconds
	}`;
};

const Countdown = () => {
	const intl = useIntl();
	const lastLoginTimestamp = JSON.parse(localStorage.getItem('lastLogin')) || 0;
	const [countdown, setCountdown] = useState(1000 * 60 * 60 * 6 - (+new Date() - lastLoginTimestamp));
	const hhmmss = msToHHMMSS(countdown);
	const timesUp = countdown < 0;
	const dispatch = useDispatch();

	useInterval(() => setCountdown(countdown - 1000), 1000, !timesUp);

	useEffect(() => {
		if (timesUp) dispatch(logoutUser());
	}, [dispatch, timesUp]);

	return lastLoginTimestamp ? (
		<Tooltip title={`${intl.formatMessage(messages.logoutIn)}: ${hhmmss}`}>
			<strong>{timesUp ? '00:00:00' : hhmmss}</strong>
		</Tooltip>
	) : null;
};

const LogoutCountdown = () => {
	const [reset, setReset] = useState();
	const forceUpdate = useCallback(() => setReset(+new Date()), []);

	// Reset timer when tab gets back active
	useEffect(() => {
		document.addEventListener('visibilitychange', () => {
			setTimeout(() => forceUpdate(), 1);
		});
	}, [forceUpdate]);

	return <Countdown key={reset} />;
};

export default LogoutCountdown;
