import RestartAltIcon from '@mui/icons-material/RestartAlt';
import { Button } from '@mui/material';
import {
	GridToolbarColumnsButton,
	GridToolbarDensitySelector,
	GridToolbarFilterButton,
	GridToolbarContainer,
	GridToolbarExport,
} from '@mui/x-data-grid-pro';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import messages from '../intl/messages';
import { resetXGridConfig } from '../store/ui/actions';

const XGridCustomToolbar = ({ children, gridId, disableExport, fileName }) => {
	const dispatch = useDispatch();
	const resetGrid = () => {
		dispatch(resetXGridConfig(gridId));
	};

	return (
		<GridToolbarContainer>
			<GridToolbarColumnsButton />
			<GridToolbarFilterButton />
			<GridToolbarDensitySelector />
			{!disableExport && (
				<GridToolbarExport
					csvOptions={{ fileName: fileName || gridId, delimiter: ';', utf8WithBom: true }}
					printOptions={{ disableToolbarButton: true }}
				/>
			)}
			<Button color="primary" variant="text" size="small" onClick={() => resetGrid()}>
				<RestartAltIcon fontSize="small" />
				<span style={{ marginLeft: 8 }}>
					<FormattedMessage {...messages.defaultSettings} />
				</span>
			</Button>
			{children}
		</GridToolbarContainer>
	);
};

export default XGridCustomToolbar;
