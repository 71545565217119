import { FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { NOTE_TYPES } from '../../consts';
import messages from '../../intl/messages';
import { hideModal } from '../../store/modal/actions';
import { editNote } from '../../store/model/actions';
import { getNote, getExamId } from '../../store/model/selectors';
import SaveCloseModalTemplate from './SaveCloseModalTemplate';

const EditNoteModal = ({ noteId }) => {
	const dispatch = useDispatch();

	const note = useSelector(getNote(noteId));
	const examId = useSelector(getExamId);

	const [text, setText] = useState(note?.text || '');
	const [type, setType] = useState(note?.type || NOTE_TYPES.VIOLATION);

	const handleEditNote = async () => {
		dispatch(editNote({ noteId, text, type, examId }));

		dispatch(hideModal());
	};

	return (
		<SaveCloseModalTemplate
			title={<FormattedMessage {...messages.editNote} />}
			saveBtnTitle={<FormattedMessage {...messages.editNote} />}
			onSave={handleEditNote}
			isSaveBtnDisabled={!text || !type}
		>
			<TextField
				onChange={(e) => setText(e.target.value)}
				fullWidth
				label={<FormattedMessage {...messages.writeYourNote} />}
				value={text}
				variant="outlined"
				multiline
				rows={3}
			/>
			<br />
			<br />
			<FormControl variant="outlined">
				<InputLabel id="note-type">
					<FormattedMessage {...messages.type} />
				</InputLabel>
				<Select
					labelId="note-type"
					value={type}
					onChange={(e) => setType(e.target.value)}
					label={<FormattedMessage {...messages.type} />}
					fullWidth
				>
					{Object.entries(NOTE_TYPES).map(([name, type]) => (
						<MenuItem key={'note-type-' + type} value={type}>
							<FormattedMessage {...messages['noteType' + name]} />
						</MenuItem>
					))}
				</Select>
			</FormControl>
		</SaveCloseModalTemplate>
	);
};

export default EditNoteModal;
