import { FileOpen } from '@mui/icons-material';
import { Paper } from '@mui/material';
import { useEffect, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { COMMISSIONER_REVIEW, ENTITIES, YES_NO_VALUE_OPTIONS } from '../../consts';
import { messages } from '../../intl';
import { fetchPreviousExams } from '../../store/model/actions';
import { getDefinitionsList, getExamId, getPreviousExams, getTerms } from '../../store/model/selectors';
import { numericOperators, selectOperators, stringOperatorsExtended } from '../../utils/prepareXGridData';
import DataGrid from '../DataGrid';
import { getCommissionerReviewIcon, useStyles } from '../Exams/ExamsList';
import UniButtton from '../UniButtton';

const PreviousExams = () => {
	const { classes } = useStyles();
	const intl = useIntl();
	const dispatch = useDispatch();
	const examId = useSelector(getExamId);
	const rows = useSelector(getPreviousExams);
	const definitionNames = useSelector(getDefinitionsList()).map(({ name }) => name);
	const terms = useSelector(getTerms());

	useEffect(() => {
		if (examId) {
			dispatch(fetchPreviousExams(examId));
		}
	}, [dispatch, examId]);

	const columns = useMemo(() => {
		return [
			{
				field: 'detail',
				headerName: '',
				width: 55,
				resizable: false,
				filterable: false,
				sortable: false,
				pinnable: false,
				disableExport: true,
				renderCell: (params) =>
					params.row.isArchived === intl.formatMessage(messages.no) ? (
						// Until proper routing is made, this allows exams to be opened in new tabs
						<Link to={`?definitionId=${params?.row?.definitionId}&examId=${params?.row?.examId}`} target="_blank">
							<UniButtton type="icon" icon={<FileOpen />} tooltip={<FormattedMessage {...messages.detail} />} />
						</Link>
					) : null,
			},
			{
				field: 'definitionName',
				headerName: intl.formatMessage(messages.definition),
				width: 220,
				type: 'singleSelect',
				filterOperators: selectOperators(definitionNames, intl),
			},
			{
				field: 'term',
				headerName: intl.formatMessage(messages.term),
				width: 180,
				type: 'singleSelect',
				filterOperators: selectOperators(terms, intl),
			},
			{
				field: 'finalReview',
				headerName: intl.formatMessage(messages.finalReview),
				width: 230,
				renderCell: (params) => <div>{getCommissionerReviewIcon(params.row.finalReview)}</div>,
				type: 'singleSelect',
				filterOperators: selectOperators(COMMISSIONER_REVIEW, intl, false),
			},
			{
				field: 'finalViolationMessage',
				headerName: intl.formatMessage(messages.finalViolationMessage),
				width: 170,
				type: 'string',
				valueOptions: [intl.formatMessage(messages.yes), intl.formatMessage(messages.no)],
				filterOperators: stringOperatorsExtended(intl),
			},
			{
				field: 'violationNote',
				headerName: intl.formatMessage(messages.violationNote),
				width: 230,
				type: 'string',
				valueOptions: [intl.formatMessage(messages.yes), intl.formatMessage(messages.no)],
				filterOperators: stringOperatorsExtended(intl),
			},
			{
				field: 'evaluationDetail',
				headerName: intl.formatMessage(messages.evaluationDetail),
				width: 230,
				type: 'string',
				valueOptions: [intl.formatMessage(messages.yes), intl.formatMessage(messages.no)],
				filterOperators: stringOperatorsExtended(intl),
			},
			{
				field: 'finalViolationCode',
				headerName: intl.formatMessage(messages.finalViolationCode),
				width: 150,
				align: 'right',
				headerAlign: 'right',
				type: 'number',
				filterOperators: numericOperators(intl),
			},
			{
				field: 'isViolationIntentional',
				headerName: intl.formatMessage(messages.intentionalViolation),
				width: 210,
				type: 'singleSelect',
				filterOperators: selectOperators(YES_NO_VALUE_OPTIONS, intl, false),
			},
		];
	}, [definitionNames, intl, terms]);

	const dataGridConfig = { columns, gridId: ENTITIES.EXAMS_PREVIOUS, rows, getRowId: (row) => row.examId };

	return (
		<Paper className={classes.bounding} style={{ height: 'calc(50vh + 55px)' }}>
			<h2>{intl.formatMessage(messages.previousUserExams)}</h2>
			<div style={{ height: '50vh' }}>
				<DataGrid {...dataGridConfig} />
			</div>
		</Paper>
	);
};

export default PreviousExams;
