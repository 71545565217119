import TextareaAutosize from '@mui/material/TextareaAutosize';
import { Editor } from '@tinymce/tinymce-react';
import React, { useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import { makeStyles } from 'tss-react/mui';
import { EDITOR_DEFAULT } from '../../consts';
import messages from '../../intl/messages';
import { hideModal } from '../../store/modal/actions';
import Switch from '../Switch';
import SaveCloseModalTemplate from './SaveCloseModalTemplate';

const insertStr = (str, index, stringToAdd) => str.substring(0, index) + stringToAdd + str.substring(index, str.length);

const useStyles = makeStyles()((theme) => ({
	bounding: {
		'& .row': {
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'flex-start',
		},
	},
}));

const EditTextModal = ({ title, text: initText, handleTextChange, textType, uploadFile }) => {
	const dispatch = useDispatch();
	const [text, setText] = useState(initText);
	const [isHtml, setIsHtml] = useState(false);
	const [cursorIndex, setCursorIndex] = useState(0);
	const [resetEditor, setResetEditor] = useState(Date.now());
	const [isUploading, setIsUploading] = useState(false);
	const editorRef = useRef();
	const { classes } = useStyles();

	const handleUpload = async (e) => {
		const file = e.target.files[0];
		const isImage = ['image/jpeg', 'image/png'].includes(file.type);
		const resp = await uploadFile(file);

		setIsUploading(false);
		setText(insertStr(text, cursorIndex, isImage ? `<img src='${resp.url}' />` : `<a href='${resp.url}'>New Link</a>`));
		setCursorIndex(0);
		setResetEditor(Date.now());
	};

	return (
		<SaveCloseModalTemplate
			title={title}
			saveBtnTitle={<FormattedMessage {...messages.changeText} />}
			onSave={() => {
				handleTextChange({ text: isHtml ? text : editorRef.current.getContent(), textType });
				dispatch(hideModal());
			}}
			maxWidth="xl"
		>
			<div className={classes.bounding}>
				<div className="row">
					<Switch
						label={<FormattedMessage {...messages.plainHtml} />}
						checked={isHtml}
						handleChange={() => setIsHtml(!isHtml)}
						labelPlacement="left"
					/>
					{uploadFile && (
						<div>
							{isUploading ? (
								<>
									<FormattedMessage {...messages.uploading} /> '...'
								</>
							) : (
								<input
									key={'fileInput' + resetEditor}
									type="file"
									onChange={(e) => {
										setIsUploading(true);
										handleUpload(e);
									}}
								/>
							)}
						</div>
					)}
				</div>
				<br />
				{isHtml ? (
					<TextareaAutosize
						value={text}
						maxRows={54}
						style={{ width: '90%', minWidth: '590px' }}
						onChange={(e) => setText(e.target.value)}
						defaultValue={initText}
					/>
				) : (
					<Editor
						apiKey={EDITOR_DEFAULT.API_KEY}
						initialValue={text}
						onInit={(evt, editor) => (editorRef.current = editor)}
						init={EDITOR_DEFAULT.INIT}
					/>
				)}
			</div>
		</SaveCloseModalTemplate>
	);
};

export default EditTextModal;
