import DeleteIcon from '@mui/icons-material/Delete';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import {
	Grid,
	IconButton,
	List,
	ListItem,
	ListItemText,
	ListSubheader,
	Menu,
	MenuItem,
	Select,
	Tooltip,
} from '@mui/material';
import PropTypes from 'prop-types';
import { isEmpty, mergeAll } from 'ramda';
import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from 'tss-react/mui';
import { CHECKPOINT_GROUP, ENTITIES, FILTER_VIOLATIONS, STREAM_TYPES, TABS, AUTHORIZED_ACTIONS } from '../consts';
import messages from '../intl/messages';
import { getUserId, getUserRole } from '../store/auth/selectors';
import { hideModal, showConfirmModal } from '../store/modal/actions';
import { deleteViolation, fetchViolations, setViolationReview, setViolationsReview } from '../store/model/actions';
import { getCurrentCheckpoints, getEvalsByTab, getViolations, getViolationsRoomScan } from '../store/model/selectors';
import { setVideoSeek } from '../store/ui/actions';
import { getTypeFilter } from '../store/ui/selectors';
import { msToTime } from '../utils/formatDate';
import negEvalsOnly from '../utils/negEvalsOnly';
import sortArrByObjProp from '../utils/sortArrByObjProp';
import checkUserAuthorization from '../utils/userRights';

const listStyles = makeStyles()((theme) => ({
	root: {
		width: '100%',
		overflow: 'auto',
		display: 'inline-block',
		padding: '0 !important',
		height: '498px',
		minHeight: '498px',
		'& .alignRight': {
			textAlign: 'right',
		},
	},
	listSection: {
		'& .commissionerTitle': { float: 'right', marginRight: '15px' },
		'& .reviewerTitle': { float: 'right', marginRight: '15px' },
	},
	ul: {
		backgroundColor: 'inherit',
		padding: 0,
		'& li': {
			margin: 0,
			padding: '0 20px',
			fontSize: '0.9rem',
			'& .MuiButtonBase-root': {
				margin: 0,
				padding: '10px',
			},
			'& .MuiButtonBase-root.delete-icon': {
				margin: 0,
				padding: 0,
			},
			'& .MuiTypography-root': {
				fontSize: '0.9rem',
			},
			'&.MuiListSubheader-sticky': {
				backgroundColor: theme.palette.primary.main,
				color: 'white',
			},
			'&.current': {
				backgroundColor: theme.palette.primary.light,
			},
			'&.fail': {
				color: theme.palette.error.main,
			},
			'& .fail': {
				color: theme.palette.error.main,
			},
			'&.susp': {
				color: theme.palette.warning.main,
			},
			'& .susp': {
				color: theme.palette.warning.main,
			},
			'&.pass': {
				color: theme.palette.success.main,
			},
			'& .pass': {
				color: theme.palette.success.main,
			},
			'& .MuiInputBase-root': {
				margin: '0 0 0 30px',
				width: '0%',
				'& .MuiSelect-root': { padding: '7px 14px' },
				'& svg': { display: 'none' },
			},
			'& .MuiListItemText-root': {
				flex: 'auto',
				cursor: 'pointer',
				margin: 0,
			},
			'& .commentContainer': {
				display: 'flex',
			},
			'& .comment': {
				textTransform: 'initial',
				textOverflow: 'ellipsis',
				overflow: 'hidden',
				whiteSpace: 'nowrap',
				maxWidth: '100%',
			},
			'& .MuiSelect-select.MuiSelect-select': {
				padding: 0,
				width: 0,
			},
		},
	},
}));

export const getCurrentTab = (type) => {
	switch (type) {
		case STREAM_TYPES.IMAGE:
			return TABS.IDENTIFICATION;
		case STREAM_TYPES.SCREEN:
			return TABS.SCREEN;
		case STREAM_TYPES.WEBCAM:
			return TABS.CAMERA;
		default:
			return TABS.ROOM;
	}
};

export const filterByType = (violations, typeFilter) => {
	const filtered = {};

	for (const [violation, details] of Object.entries(violations)) {
		filtered[violation] = Object.entries(details).filter(([, detail]) => {
			switch (typeFilter) {
				case STREAM_TYPES.SCREEN:
					return (
						detail.streamType === STREAM_TYPES.SCREEN ||
						detail.streamType === STREAM_TYPES.SCREEN_CHUNKS ||
						detail.checkpointGroup === CHECKPOINT_GROUP.PROCESS_ANALYSIS ||
						detail.streamType === 'displays'
					);
				case STREAM_TYPES.IMAGE:
					return detail.streamType === STREAM_TYPES.IMAGE;
				case ENTITIES.VIDEO_ROOM:
					return detail.checkpointGroup === CHECKPOINT_GROUP.ROOM_OBJECTS;
				default:
					return (
						(detail.streamType === STREAM_TYPES.WEBCAM ||
							detail.streamType === STREAM_TYPES.SOUND ||
							detail.streamType === STREAM_TYPES.HEADPOSE ||
							detail.streamType === STREAM_TYPES.WEBCAM_CHUNKS) &&
						detail.checkpointGroup !== CHECKPOINT_GROUP.ROOM_OBJECTS
					);
			}
		});
	}

	return filtered;
};

const filterByUsersChoice = (userFilter, violations, violationIdInReview) => {
	const allViolations = mergeAll(Object.values(violations));

	switch (userFilter) {
		case FILTER_VIOLATIONS.ALL:
			return { [FILTER_VIOLATIONS.ALL]: mergeAll(Object.values(violations)) };

		case FILTER_VIOLATIONS.SUSP:
			for (const violation of Object.values(allViolations)) {
				if (violation.reviewerReview !== 'NOK' && violation.commissionerReview !== 'NOK') {
					delete allViolations[violation.id];
				}
			}
			return { [FILTER_VIOLATIONS.ALL]: allViolations };

		case FILTER_VIOLATIONS.NO_REVIEW:
			for (const violation of Object.values(allViolations)) {
				if (
					(violation.reviewerReview !== null || violation.commissionerReview !== null) &&
					violationIdInReview !== violation.id.toString()
				) {
					delete allViolations[violation.id];
				}
			}
			return { [FILTER_VIOLATIONS.ALL]: allViolations };

		default:
			return violations;
	}
};

const sortByTime = (unsorted) => {
	const sorted = {};

	for (const [group, violations] of Object.entries(unsorted)) {
		sorted[group] = violations.sort((a, b) => {
			const keyA = new Date(a[1].createdAt);
			const keyB = new Date(b[1].createdAt);

			if (keyA < keyB) return -1;
			if (keyA > keyB) return 1;
			return 0;
		});
	}

	return sorted;
};

const ViolationsList = ({ isAssignedToMe, userFilter, examId }) => {
	const { classes: classesList } = listStyles();
	const [filteredViolations, setFilteredViolations] = useState({});
	const [currentViolation, setCurrentViolation] = useState(null);
	const [contextMenu, setContextMenu] = useState(null);
	const [violationIdInReview, setViolationIdInReview] = useState(null);
	const evalsByTab = useSelector(getEvalsByTab());
	const userRole = useSelector(getUserRole);
	const userId = useSelector(getUserId);
	const violations = useSelector(getViolations);
	const violationsRoomScan = useSelector(getViolationsRoomScan); // returns all violations but timestamps are counted in favor to room scan
	const dispatch = useDispatch();
	const checkpoints = useSelector(getCurrentCheckpoints());
	const canReviewAsReviewer = checkUserAuthorization(userRole, AUTHORIZED_ACTIONS.REVIEW_AS_REVIEWER);
	const typeFilter = useSelector(getTypeFilter);
	const isRoomTab = typeFilter === ENTITIES.VIDEO_ROOM;
	const ratingDetailOptions = sortArrByObjProp('code', negEvalsOnly(evalsByTab[getCurrentTab(typeFilter)]));

	// Fetch violations
	useEffect(() => {
		if (examId) dispatch(fetchViolations(examId));
	}, [examId, dispatch]);

	// Filtering
	useEffect(() => {
		setFilteredViolations(
			sortByTime(
				filterByType(
					filterByUsersChoice(userFilter, isRoomTab ? violationsRoomScan : violations, violationIdInReview),
					typeFilter
				)
			)
		);
	}, [isRoomTab, typeFilter, userFilter, violationIdInReview, violations, violationsRoomScan]);

	const seekToNext = (index, violationId) => {
		const nextTimePointId =
			filteredViolations[userFilter === FILTER_VIOLATIONS.ALL ? FILTER_VIOLATIONS.ALL : violationId]?.[index + 1]?.[1]
				.createdAt;
		if (nextTimePointId) {
			setCurrentViolation(index + 1);
			dispatch(setVideoSeek(nextTimePointId + index));
		}
	};

	// Rating
	const handleRating = ({ index, rating, checkpointId, violationId, byViolation }) => {
		const evaluationTab = checkpoints[checkpointId].tab;
		const evaluationId = evalsByTab[evaluationTab].length ? evalsByTab[evaluationTab][0].id : '';

		if (rating === 'NOK') {
			// NOK - set violationId in review to open select w ratingDetailOptions
			setViolationIdInReview(violationId);
		} else if (byViolation) {
			// OK - review same violations as OK
			const violationIds = Object.values(violations[byViolation.checkpointGroup])
				.filter((_violation) => _violation.comment === byViolation.comment)
				.map((_violation) => _violation.id);

			dispatch(setViolationsReview({ violationIds, review: 'OK', evaluationId }));
		} else {
			// OK - review single violation
			dispatch(setViolationReview({ violationId, review: 'OK', evaluationId }));
			const nextTimePointId =
				filteredViolations[userFilter === FILTER_VIOLATIONS.ALL ? FILTER_VIOLATIONS.ALL : violationId]?.[index + 1]?.[1]
					.createdAt;
			if (nextTimePointId) {
				setCurrentViolation(index + 1);
				dispatch(setVideoSeek(nextTimePointId + index));
			}
		}
	};

	// Rating NOK detail
	const handleRatingDetail = ({ reviewerNote, evaluationId, violationId, index }) => {
		dispatch(setViolationReview({ reviewerNote, violationId, review: 'NOK', evaluationId }));
		seekToNext(index, violationId);
	};

	const handleCloseContextMenu = (pass) => {
		if (pass) contextMenu.callback();

		setContextMenu(null);
	};

	const handleOnContextMenu = (event, violation, checkpointGroup, index, violationId) => {
		event.preventDefault();
		setContextMenu(
			contextMenu === null
				? {
						mouseX: event.clientX - 2,
						mouseY: event.clientY - 4,
						callback: () =>
							handleRating({
								byViolation: violation,
								checkpointGroup,
								checkpointId: violation.checkpointId,
								index,
								rating: 'OK',
								violationId,
							}),
				  }
				: null
		);
	};

	const handleDeleteViolation = (id) => {
		dispatch(
			showConfirmModal({
				handler: () => {
					dispatch(deleteViolation(id));
					dispatch(hideModal());
				},
				text: <FormattedMessage {...messages.deleteViolationPrompt} />,
				title: <FormattedMessage {...messages.deleteViolation} />,
				button: <FormattedMessage {...messages.deleteViolation} />,
			})
		);
	};

	return (
		<List className={classesList.root}>
			{/* Review multiple violations as OK */}
			<Menu
				open={contextMenu !== null}
				onClose={() => handleCloseContextMenu(false)}
				anchorReference="anchorPosition"
				anchorPosition={contextMenu !== null ? { top: contextMenu.mouseY, left: contextMenu.mouseX } : undefined}
			>
				<MenuItem onClick={() => handleCloseContextMenu(true)}>
					<FormattedMessage {...messages.bulkReviewOk} />
				</MenuItem>
			</Menu>

			{/* Violations list */}
			{Object.entries(filteredViolations)
				.filter(([, details]) => !isEmpty(details))
				.map(([checkpointGroup, details]) => (
					<li key={`checkpointGroup-${checkpointGroup}`} className={classesList.listSection}>
						<ul className={classesList.ul}>
							<ListSubheader>
								<Grid container spacing={0}>
									<Grid item xs={8}>
										<FormattedMessage {...messages.violation} />:{' '}
										<strong>
											{checkpointGroup === 'null' ? (
												<FormattedMessage {...messages.other} />
											) : checkpointGroup === FILTER_VIOLATIONS.ALL ? (
												<FormattedMessage {...messages.all} />
											) : (
												checkpointGroup
											)}
										</strong>
									</Grid>
									<Grid item xs={2}>
										<div className="reviewerTitle">
											<FormattedMessage {...messages.reviewer} />
										</div>
									</Grid>
									<Grid item xs={2}>
										<div className="commissionerTitle">
											<FormattedMessage {...messages.commissioner} />
										</div>
									</Grid>
								</Grid>
							</ListSubheader>
							{Object.values(details).map(([violationId, violation], index) => {
								const formattedTime = msToTime(violation.createdAt);
								const reviewerOkClass = violation.reviewerReview === 'OK' ? 'pass' : '';
								const reviewerNokClass = violation.reviewerReview === 'NOK' ? 'susp' : '';
								const commissionerOkClass = violation.commissionerReview === 'OK' ? 'pass' : '';
								const commissionerNokClass = violation.commissionerReview === 'NOK' ? 'fail' : '';
								const violationRated = canReviewAsReviewer ? violation.reviewerReview : violation.commissionerReview;
								const isRatingOK = violationRated === 'OK';
								const rowStyle = !violationRated ? '' : isRatingOK ? 'pass' : canReviewAsReviewer ? 'susp' : 'fail';
								const isCurrent = currentViolation === index ? 'current' : '';

								return (
									<ListItem key={`item-${checkpointGroup}-${violationId}`} className={`${rowStyle} ${isCurrent}`}>
										<Grid container spacing={0} direction="row" justifyContent="center" alignItems="center">
											<Grid item xs={8}>
												<Grid container spacing={0}>
													<Grid item xs={2}>
														<ListItemText
															primary={formattedTime}
															onClick={() => {
																setCurrentViolation(index);
																dispatch(setVideoSeek(violation.createdAt + index)); // TODO - fix this ugly hack
															}}
														/>
													</Grid>
													<Grid item xs={1}>
														{violation.isCustomViolation && (
															<Tooltip title={<FormattedMessage {...messages.deleteViolation} />}>
																<IconButton
																	disabled={violation.createdBy !== userId}
																	onClick={() => handleDeleteViolation(violation.id)}
																	className="delete-icon"
																>
																	<DeleteIcon />
																</IconButton>
															</Tooltip>
														)}
													</Grid>
													<Grid item xs={9} className="commentContainer">
														{!violation.reviewerNote ? (
															<div className="comment">{violation.comment}</div>
														) : (
															<div className="comment red">{violation.reviewerNote}</div>
														)}
														{violationId === violationIdInReview && (
															<Select
																onClose={() => setViolationIdInReview(null)}
																open
																variant="standard"
																onChange={(e) =>
																	handleRatingDetail({
																		reviewerNote: e.target.value.text,
																		evaluationId: e.target.value.id,
																		violationId,
																		index,
																	})
																}
															>
																{ratingDetailOptions.map(({ id, text }) => {
																	return (
																		<MenuItem key={`evalsByTab-${id}`} value={{ id, text }}>
																			{text}
																		</MenuItem>
																	);
																})}
															</Select>
														)}
													</Grid>
												</Grid>
											</Grid>
											<Grid item xs={2} className="alignRight">
												<Tooltip title="Pass">
													<IconButton
														className={reviewerOkClass}
														disabled={!checkUserAuthorization(userRole, AUTHORIZED_ACTIONS.REVIEW_AS_REVIEWER)}
														onContextMenu={(event) =>
															handleOnContextMenu(event, violation, checkpointGroup, index, violationId)
														}
														onClick={() =>
															handleRating({
																checkpointGroup,
																index,
																rating: 'OK',
																checkpointId: violation.checkpointId,
																violationId,
															})
														}
														size="large"
													>
														<ThumbUpIcon />
													</IconButton>
												</Tooltip>

												<Tooltip title="Suspicious">
													<IconButton
														className={reviewerNokClass}
														disabled={!checkUserAuthorization(userRole, AUTHORIZED_ACTIONS.REVIEW_AS_REVIEWER)}
														onClick={() =>
															handleRating({
																checkpointGroup,
																index,
																rating: 'NOK',
																checkpointId: violation.checkpointId,
																violationId,
															})
														}
														size="large"
													>
														<ThumbDownIcon />
													</IconButton>
												</Tooltip>
											</Grid>
											<Grid item xs={2} className="alignRight">
												<Tooltip title="Pass">
													<IconButton
														className={commissionerOkClass}
														disabled={
															!isAssignedToMe ||
															!checkUserAuthorization(userRole, AUTHORIZED_ACTIONS.REVIEW_AS_COMMISSIONER)
														}
														onContextMenu={(event) =>
															handleOnContextMenu(event, violation, checkpointGroup, index, violationId)
														}
														onClick={() =>
															handleRating({
																checkpointGroup,
																index,
																rating: 'OK',
																checkpointId: violation.checkpointId,
																violationId,
															})
														}
														size="large"
													>
														<ThumbUpIcon />
													</IconButton>
												</Tooltip>

												<Tooltip title="Fail">
													<IconButton
														className={commissionerNokClass}
														disabled={
															!isAssignedToMe ||
															!checkUserAuthorization(userRole, AUTHORIZED_ACTIONS.REVIEW_AS_COMMISSIONER)
														}
														onClick={() =>
															handleRating({
																checkpointGroup,
																index,
																rating: 'NOK',
																checkpointId: violation.checkpointId,
																violationId,
															})
														}
														size="large"
													>
														<ThumbDownIcon />
													</IconButton>
												</Tooltip>
											</Grid>
										</Grid>
									</ListItem>
								);
							})}
						</ul>
					</li>
				))}
		</List>
	);
};

ViolationsList.propTypes = {
	userFilter: PropTypes.string,
	isAssignedToMe: PropTypes.bool,
	examId: PropTypes.string,
};

export default ViolationsList;
