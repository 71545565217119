import { defineMessages } from 'react-intl';

export default defineMessages({
	remove: {
		id: 'global.remove',
		defaultMessage: 'Remove',
	},
	archive: {
		id: 'global.archive',
		defaultMessage: 'Archive',
	},
	active: {
		id: 'global.active',
		defaultMessage: 'Active',
	},
	expiredCode: {
		id: 'global.expiredCode',
		defaultMessage: 'Expired token',
	},
	resetChangePasswordTokenDesc: {
		id: 'global.resetChangePasswordTokenDesc',
		defaultMessage: 'The password reset token has expired. You can have a new one sent to you.',
	},
	back: {
		id: 'global.back',
		defaultMessage: 'Back',
	},
	notifOperationSuccess: {
		id: 'global.notifOperationSuccess',
		defaultMessage: 'Operation was successfully done',
	},
	deleteArchivedExams: {
		id: 'global.deleteArchivedExams',
		defaultMessage: 'Odstranit archivované zkoušky',
	},
	deleteArchivedExamsPrompt: {
		id: 'global.deleteArchivedExamsPrompt',
		defaultMessage: 'Are you sure you want to permanently delete all tests in this definition?',
	},
	bulkReviewOk: {
		id: 'global.bulkReviewOk',
		defaultMessage: 'Bulk review OK',
	},
	download: {
		id: 'global.download',
		defaultMessage: 'Download',
	},
	play: {
		id: 'global.play',
		defaultMessage: 'Play',
	},
	pause: {
		id: 'global.pause',
		defaultMessage: 'Pause',
	},
	rewind: {
		id: 'global.rewind',
		defaultMessage: 'Rewind',
	},
	forward: {
		id: 'global.forward',
		defaultMessage: 'Forward',
	},
	mute: {
		id: 'global.mute',
		defaultMessage: 'Mute',
	},
	unmute: {
		id: 'global.unmute',
		defaultMessage: 'Unmute',
	},
	fullscreen: {
		id: 'global.fullscreen',
		defaultMessage: 'Fullscreen',
	},
	pictureInPicture: {
		id: 'global.pictureInPicture',
		defaultMessage: 'Picture in picture',
	},
	idCheckpointStartedAt: {
		id: 'global.idCheckpointStartedAt',
		defaultMessage: 'ID',
	},
	faceCheckpointStartedAt: {
		id: 'global.faceCheckpointStartedAt',
		defaultMessage: 'Face',
	},
	roomCheckpointStartedAt: {
		id: 'global.roomCheckpointStartedAt',
		defaultMessage: 'Room',
	},
	examCheckpointStartedAt: {
		id: 'global.examCheckpointStartedAt',
		defaultMessage: 'Exam',
	},
	yes: {
		id: 'global.yes',
		defaultMessage: 'Yes',
	},
	no: {
		id: 'global.no',
		defaultMessage: 'No',
	},
	is: {
		id: 'global.is',
		defaultMessage: 'is',
	},
	isNot: {
		id: 'global.isNot',
		defaultMessage: 'is not',
	},
	empty: {
		id: 'global.empty',
		defaultMessage: 'empty',
	},
	notEmpty: {
		id: 'global.notEmpty',
		defaultMessage: 'not empty',
	},
	violationInfo: {
		id: 'global.violationInfo',
		defaultMessage: `
            <p><strong>{comment}</strong></p>
            <p>Checkpoint name: <strong>{checkpointName}</strong></p>
            <p>Checkpoint type: <strong>{checkpointType}</strong></p>`,
	},
	addViolation: {
		id: 'global.addViolation',
		defaultMessage: 'Add violation',
	},
	deleteViolation: {
		id: 'global.deleteViolation',
		defaultMessage: 'Delete violation',
	},
	confirmReview: {
		id: 'global.confirmReview',
		defaultMessage: 'Confirm review',
	},
	confirmClusterStop: {
		id: 'global.confirmClusterStop',
		defaultMessage: 'Confirm cluster stop',
	},
	confirmReviewDone: {
		id: 'global.confirmReviewDone',
		defaultMessage: 'Are you sure you want to send the review ?',
	},
	confirmReviewUndone: {
		id: 'global.confirmReviewUndone',
		defaultMessage: 'Some violations still need to get reviewed:',
	},
	copyDefinition: {
		id: 'global.copyDefinition',
		defaultMessage: 'Copy definition',
	},
	archiveDefinition: {
		id: 'global.archiveDefinition',
		defaultMessage: 'Archive definition',
	},
	unarchiveDefinition: {
		id: 'global.unarchiveDefinition',
		defaultMessage: 'Unarchive definition',
	},
	archiveDefinitionPrompt: {
		id: 'global.archiveDefinitionPrompt',
		defaultMessage:
			'All id card photos will be deleted upon archivation. Videos from the exam will be moved to the Cool tier. Definition can be unarchived later.',
	},
	unarchiveDefinitionPrompt: {
		id: 'global.unarchiveDefinitionPrompt',
		defaultMessage:
			'All exams of this definition will be made available upon unarchivation. Videos from the exam will stay in the Cool tier.',
	},
	removeDefinition: {
		id: 'global.removeDefinition',
		defaultMessage: 'Remove definition',
	},
	hours: {
		id: 'global.hours',
		defaultMessage: 'Hours',
	},
	minutes: {
		id: 'global.minutes',
		defaultMessage: 'Minutes',
	},
	seconds: {
		id: 'global.seconds',
		defaultMessage: 'Seconds',
	},
	violationTitle: {
		id: 'global.violationTitle',
		defaultMessage: 'Violation title',
	},
	violationNote: {
		id: 'global.violationNote',
		defaultMessage: 'Violation note',
	},
	violationComment: {
		id: 'global.violationComment',
		defaultMessage: 'Violation comment',
	},
	type: {
		id: 'global.type',
		defaultMessage: 'Type',
	},
	editUser: {
		id: 'global.editUser',
		defaultMessage: 'Edit user',
	},
	userRole: {
		id: 'global.userRole',
		defaultMessage: 'User role',
	},
	shouldSendEmail: {
		id: 'global.shouldSendEmail',
		defaultMessage: 'Send password link',
	},
	has2fa: {
		id: 'global.has2fa',
		defaultMessage: '2F authentication',
	},
	phoneNumber: {
		id: 'global.phoneNumber',
		defaultMessage: 'Phone number',
	},
	userRoleAssignedTo: {
		id: 'global.userRoleAssignedTo',
		defaultMessage: 'User role (assigned to)',
	},
	userSkill: {
		id: 'global.userSkill',
		defaultMessage: 'User skill',
	},
	instructionsText: {
		id: 'global.instructionsText',
		defaultMessage: 'Instructions text',
	},
	headline: {
		id: 'global.headline',
		defaultMessage: 'Headline',
	},
	position: {
		id: 'global.position',
		defaultMessage: 'Position',
	},
	durationInSecs: {
		id: 'global.durationInSecs',
		defaultMessage: 'Duration is seconds',
	},
	duration: {
		id: 'global.duration',
		defaultMessage: 'Duration',
	},
	runCheck: {
		id: 'global.runCheck',
		defaultMessage: 'Run check',
	},
	enabled: {
		id: 'global.enabled',
		defaultMessage: 'Enabled',
	},
	display: {
		id: 'global.display',
		defaultMessage: 'Display',
	},
	editInstructionsText: {
		id: 'global.editInstructionsText',
		defaultMessage: 'Edit instructions text',
	},
	dragToSort: {
		id: 'global.dragToSort',
		defaultMessage: 'Drag to sort',
	},
	saveChecklist: {
		id: 'global.saveChecklist',
		defaultMessage: 'Save whole checklist',
	},
	saveAllCheckpoints: {
		id: 'global.saveAllCheckpoints',
		defaultMessage: 'Save all checkpoints',
	},
	checklist: {
		id: 'global.checklist',
		defaultMessage: 'Checklist',
	},
	uploading: {
		id: 'global.uploading',
		defaultMessage: 'Uploading',
	},
	noChanges: {
		id: 'global.noChanges',
		defaultMessage: 'No changes',
	},
	saveDefinition: {
		id: 'global.saveDefinition',
		defaultMessage: 'Save definition',
	},
	dates: {
		id: 'global.dates',
		defaultMessage: 'Dates',
	},
	date: {
		id: 'global.date',
		defaultMessage: 'Date',
	},
	isArchived: {
		id: 'global.isArchived',
		defaultMessage: 'Archived',
	},
	logViolatedOnly: {
		id: 'global.logViolatedOnly',
		defaultMessage: 'Log violated only',
	},
	isOnboarding: {
		id: 'global.isOnboarding',
		defaultMessage: 'Onboarding',
	},
	GDPR: {
		id: 'global.GDPR',
		defaultMessage: 'GDPR',
	},
	realtimeNotification: {
		id: 'global.realtimeNotification',
		defaultMessage: 'Show realtime control notification',
	},
	proctoringNotification: {
		id: 'global.proctoringNotification',
		defaultMessage: 'Show proctoring desktop notification',
	},
	rules: {
		id: 'global.rules',
		defaultMessage: 'Rules',
	},
	breadcrumbDefinition: {
		id: 'global.breadcrumbDefinition',
		defaultMessage: `<p>definitionId: <strong>{id}</strong></p>`,
	},
	breadcrumbExam: {
		id: 'global.breadcrumbExam',
		defaultMessage: `
			<h3>Student</h3>
			<p>customerId: <strong>{customerId}</strong></p>
			<p>examId: <strong>{examId}</strong></p>
			<p>externalId: <strong>{externalId}</strong></p>
			<p>First name: <strong>{firstName}</strong></p>
			<p>Last name: <strong>{lastName}</strong></p>
		`,
	},
	breadcrumbDefinitions: {
		id: 'global.breadcrumbDefinitions',
		defaultMessage: `Definitions`,
	},
	login: {
		id: 'global.login',
		defaultMessage: `Login`,
	},
	createPassword: {
		id: 'global.createPassword',
		defaultMessage: `Create password`,
	},
	resend: {
		id: 'global.resend',
		defaultMessage: `Resend`,
	},
	codeExpiresIn: {
		id: 'global.codeExpiresIn',
		defaultMessage: 'Code expires in',
	},
	codeHasExpired: {
		id: 'global.codeHasExpired',
		defaultMessage: 'Code has expired, please generate a new one',
	},
	forgotPassword: {
		id: 'global.forgotPassword',
		defaultMessage: `Forgot password`,
	},
	twoFACode: {
		id: 'global.twoFACode',
		defaultMessage: `2FA Code`,
	},
	send: {
		id: 'global.send',
		defaultMessage: `Send`,
	},
	id: {
		id: 'global.id',
		defaultMessage: `ID`,
	},
	name: {
		id: 'global.name',
		defaultMessage: `Name`,
	},
	startsAt: {
		id: 'global.startsAt',
		defaultMessage: `Starts at`,
	},
	endsAt: {
		id: 'global.endsAt',
		defaultMessage: `Ends at`,
	},
	status: {
		id: 'global.status',
		defaultMessage: `Status`,
	},
	firstName: {
		id: 'global.firstName',
		defaultMessage: `First name`,
	},
	lastName: {
		id: 'global.lastName',
		defaultMessage: `Last name`,
	},
	startedAt: {
		id: 'global.startedAt',
		defaultMessage: `Started at`,
	},
	endedAt: {
		id: 'global.endedAt',
		defaultMessage: `endedAt`,
	},
	processingStatus: {
		id: 'global.processingStatus',
		defaultMessage: `Processing status`,
	},
	reviewStatus: {
		id: 'global.reviewStatus',
		defaultMessage: `Review status`,
	},
	definitions: {
		id: 'global.definitions',
		defaultMessage: `Definitions`,
	},
	detail: {
		id: 'global.detail',
		defaultMessage: `Detail`,
	},
	definitionId: {
		id: 'global.definitionId',
		defaultMessage: `DefinitionId`,
	},
	examId: {
		id: 'global.examId',
		defaultMessage: `ExamId`,
	},
	externalId: {
		id: 'global.externalId',
		defaultMessage: `ExternalId`,
	},
	exam: {
		id: 'global.exam',
		defaultMessage: `Exam`,
	},
	exams: {
		id: 'global.exams',
		defaultMessage: `Exams`,
	},
	done: {
		id: 'global.done',
		defaultMessage: `Done`,
	},
	camera: {
		id: 'global.camera',
		defaultMessage: `Camera`,
	},
	screen: {
		id: 'global.screen',
		defaultMessage: `Screen`,
	},
	identification: {
		id: 'global.identification',
		defaultMessage: `Identification`,
	},
	room: {
		id: 'global.room',
		defaultMessage: `Room`,
	},
	birthDate: {
		id: 'global.birthDate',
		defaultMessage: `Birth date`,
	},
	writeYourNote: {
		id: 'global.writeYourNote',
		defaultMessage: `Write your note`,
	},
	addNote: {
		id: 'global.addNote',
		defaultMessage: `Add note`,
	},
	editNote: {
		id: 'global.editNote',
		defaultMessage: `Edit note`,
	},
	deleteNote: {
		id: 'global.deleteNote',
		defaultMessage: `Delete note`,
	},
	deleteNotePrompt: {
		id: 'global.deleteNotePrompt',
		defaultMessage: `Confirm deleting note.`,
	},
	filterBy: {
		id: 'global.filterBy',
		defaultMessage: `filterBy`,
	},
	commissioner: {
		id: 'global.commissioner',
		defaultMessage: `Commissioner`,
	},
	reviewer: {
		id: 'global.reviewer',
		defaultMessage: `Reviewer`,
	},
	admin: {
		id: 'global.admin',
		defaultMessage: `Admin`,
	},
	superadmin: {
		id: 'global.superadmin',
		defaultMessage: `Superadmin`,
	},
	sysadmin: {
		id: 'global.sysadmin',
		defaultMessage: `Sysadmin`,
	},
	createNewUser: {
		id: 'global.createNewUser',
		defaultMessage: `Create new user`,
	},
	email: {
		id: 'global.email',
		defaultMessage: `Email`,
	},
	delete: {
		id: 'global.delete',
		defaultMessage: `Delete`,
	},
	resetPassword: {
		id: 'global.resetPassword',
		defaultMessage: `Reset password`,
	},
	users: {
		id: 'global.users',
		defaultMessage: `Users`,
	},
	password: {
		id: 'global.password',
		defaultMessage: `Password`,
	},
	createNewPassword: {
		id: 'global.createNewPassword',
		defaultMessage: `Create new password`,
	},
	passwordRules: {
		id: 'global.passwordRules',
		defaultMessage: `The password must be at least 8 characters long, include at least 1 capital letter and at least 1 special character.`,
	},
	confirmPassword: {
		id: 'global.confirmPassword',
		defaultMessage: `Repeat password`,
	},
	confirmPasswordNotEqual: {
		id: 'global.confirmPasswordNotEqual',
		defaultMessage: `Passwords do not match`,
	},
	passwordTooShort: {
		id: 'global.passwordTooShort',
		defaultMessage: `Password must contain at least {number} characters.`,
	},
	passwordNoCapital: {
		id: 'global.passwordNoCapital',
		defaultMessage: `Password must contain at least one uppercase character.`,
	},
	passwordNoSpecialCharacter: {
		id: 'global.passwordNoSpecialCharacter',
		defaultMessage: `Password must contain at least one special character.`,
	},
	twoFAHeading: {
		id: 'global.twoFAHeading',
		defaultMessage: `2 Factor Authentication`,
	},
	twoFAPrompt: {
		id: 'global.twoFAPrompt',
		defaultMessage: `Please type in a 6-digit code you received on your phone.`,
	},
	checkListAndCheckpoints: {
		id: 'global.checkListAndCheckpoints',
		defaultMessage: `Checklist & Checkpoints`,
	},
	actions: {
		id: 'global.actions',
		defaultMessage: `Actions`,
	},
	dashboard: {
		id: 'global.dashboard',
		defaultMessage: `Dashboard`,
	},
	statistics: {
		id: 'global.statistics',
		defaultMessage: `Statistics`,
	},
	listOfTemplates: {
		id: 'global.listOfTemplates',
		defaultMessage: `List of templates`,
	},
	currentExams: {
		id: 'global.currentExams',
		defaultMessage: `Current exams`,
	},
	definition: {
		id: 'global.definition',
		defaultMessage: `definition`,
	},
	editGDPR: {
		id: 'global.editGDPR',
		defaultMessage: `Edit GDPR`,
	},
	editRules: {
		id: 'global.editRules',
		defaultMessage: `Edit rules`,
	},
	rulesText: {
		id: 'global.rulesText',
		defaultMessage: `Rules text`,
	},
	GdprText: {
		id: 'global.GdprText',
		defaultMessage: `GDPR text`,
	},
	nameSubject: {
		id: 'global.nameSubject',
		defaultMessage: `Name / Subject`,
	},
	checkpoints: {
		id: 'global.checkpoints',
		defaultMessage: `Checkpoints`,
	},
	addProcess: {
		id: 'global.addProcess',
		defaultMessage: `Add process`,
	},
	editProcess: {
		id: 'global.editProcess',
		defaultMessage: `Edit process`,
	},
	deleteProcess: {
		id: 'global.deleteProcess',
		defaultMessage: `Delete process`,
	},
	processName: {
		id: 'global.processName',
		defaultMessage: `Process name`,
	},
	processes: {
		id: 'global.processes',
		defaultMessage: `Processes`,
	},
	streamType: {
		id: 'global.streamType',
		defaultMessage: `Stream type`,
	},
	checkpointGroup: {
		id: 'global.checkpointGroup',
		defaultMessage: `Checkpoint group`,
	},
	evaluationGroup: {
		id: 'global.evaluationGroup',
		defaultMessage: `Evaluation group`,
	},
	evaluationDetail: {
		id: 'global.evaluationDetail',
		defaultMessage: `Evaluation detail`,
	},
	confidanceTreshold: {
		id: 'global.confidanceTreshold',
		defaultMessage: `Confidance treshold`,
	},
	confidanceTresholdLow: {
		id: 'global.confidanceTresholdLow',
		defaultMessage: `Confidance treshold low`,
	},
	frameOccurance: {
		id: 'global.frameOccurance',
		defaultMessage: `Frame occurance`,
	},
	violationMessage: {
		id: 'global.violationMessage',
		defaultMessage: `Violation message`,
	},
	finalViolationMessage: {
		id: 'global.finalViolationMessage',
		defaultMessage: `Violation message`,
	},
	assetMissing: {
		id: 'global.assetMissing',
		defaultMessage: `Asset missing`,
	},
	refreshExams: {
		id: 'global.refreshExams',
		defaultMessage: `Refresh Exams`,
	},
	resetPostponedReviews: {
		id: 'global.resetPostponedReviews',
		defaultMessage: `Reset postponed reviews`,
	},
	resetAssignedReviews: {
		id: 'global.resetAssignedReviews',
		defaultMessage: `Reassign reviews`,
	},
	postponeReview: {
		id: 'global.postponeReview',
		defaultMessage: `Postpone review`,
	},
	postponedReview: {
		id: 'global.postponedReview',
		defaultMessage: `Review postponed`,
	},
	aiViolationsCount: {
		id: 'global.aiViolationsCount',
		defaultMessage: `AI violations`,
	},
	customViolationsCount: {
		id: 'global.customViolationsCount',
		defaultMessage: `Custom violations`,
	},
	intentionalViolation: {
		id: 'global.intentionalViolation',
		defaultMessage: `Intentional violation`,
	},
	assignedTo: {
		id: 'global.assignedTo',
		defaultMessage: `Assigned to`,
	},
	priority: {
		id: 'global.priority',
		defaultMessage: `Priority`,
	},
	reviewedExamsCount: {
		id: 'global.reviewedExamsCount',
		defaultMessage: `Reviewed exams count`,
	},
	changeText: {
		id: 'global.changeText',
		defaultMessage: `Change text`,
	},
	previous: {
		id: 'global.previous',
		defaultMessage: `Previous`,
	},
	next: {
		id: 'global.next',
		defaultMessage: `Next`,
	},
	plainHtml: {
		id: 'global.plainHtml',
		defaultMessage: `Plain HTML`,
	},
	postponed: {
		id: 'global.postponed',
		defaultMessage: `Postponed`,
	},
	filtering: {
		id: 'global.filtering',
		defaultMessage: `Filtering`,
	},
	reviewed: {
		id: 'global.reviewed',
		defaultMessage: `Reviewed`,
	},
	term: {
		id: 'global.term',
		defaultMessage: `Term`,
	},
	instructions: {
		id: 'global.instructions',
		defaultMessage: `Instructions`,
	},
	target: {
		id: 'global.target',
		defaultMessage: `Target`,
	},
	consistency: {
		id: 'global.consistency',
		defaultMessage: `Consistency`,
	},
	crossTermConsistency: {
		id: 'global.crossTermConsistency',
		defaultMessage: `Cross term consistency`,
	},
	customerId: {
		id: 'global.customerId',
		defaultMessage: `CustomerId`,
	},
	removeUser: {
		id: 'global.removeUser',
		defaultMessage: `Remove user`,
	},
	removeExams: {
		id: 'global.removeExams',
		defaultMessage: `Remove exams`,
	},
	sureToRemoveUser: {
		id: 'global.sureToRemoveUser',
		defaultMessage: `Are you sure about removing user`,
	},
	activateUser: {
		id: 'global.activateUser',
		defaultMessage: `Activate user`,
	},
	deactivateUser: {
		id: 'global.deactivateUser',
		defaultMessage: `Deactivate user`,
	},
	sureToActivateUser: {
		id: 'global.sureToActivateUser',
		defaultMessage: `Are you sure about activating user`,
	},
	sureToDeactivateUser: {
		id: 'global.sureToDeactivateUser',
		defaultMessage: `Are you sure about deactivating user`,
	},
	sureToRemoveExams: {
		id: 'global.sureToRemoveExams',
		defaultMessage: `Are you sure about removing selected exams ?`,
	},
	sureToFinishDefinitionExams: {
		id: 'global.sureToFinishDefinitionExams',
		defaultMessage: `Are you sure about finish all exams of this definition ?`,
	},
	reviewerReviewedBy: {
		id: 'global.reviewerReviewedBy',
		defaultMessage: `Reviewed by (reviewer)`,
	},
	commissionerReviewedBy: {
		id: 'global.commissionerReviewedBy',
		defaultMessage: `Reviewed by (commissioner)`,
	},
	proceedToExams: {
		id: 'global.proceedToExams',
		defaultMessage: `Proceed to exams`,
	},
	all: {
		id: 'global.all',
		defaultMessage: `All`,
	},
	groups: {
		id: 'global.groups',
		defaultMessage: `Groups`,
	},
	onlySuspicious: {
		id: 'global.onlySuspicious',
		defaultMessage: `Only suspicious`,
	},
	onlyUnreviewed: {
		id: 'global.onlyUnreviewed',
		defaultMessage: `Only unreviewed`,
	},
	close: {
		id: 'global.close',
		defaultMessage: `Close`,
	},
	examSettings: {
		id: 'global.examSettings',
		defaultMessage: `Exam settings`,
	},
	saveInstructions: {
		id: 'global.saveInstructions',
		defaultMessage: `Save instructions`,
	},
	logout: {
		id: 'global.logout',
		defaultMessage: `Logout`,
	},
	rootGridLabel: {
		id: 'global.rootGridLabel',
		defaultMessage: `grid`,
	},
	noRowsLabel: {
		id: 'global.noRowsLabel',
		defaultMessage: `No rows`,
	},
	errorOverlayDefaultLabel: {
		id: 'global.errorOverlayDefaultLabel',
		defaultMessage: `An error occurred.`,
	},
	toolbarDensity: {
		id: 'global.toolbarDensity',
		defaultMessage: `Density`,
	},
	toolbarDensityLabel: {
		id: 'global.toolbarDensityLabel',
		defaultMessage: `Density`,
	},
	toolbarDensityCompact: {
		id: 'global.toolbarDensityCompact',
		defaultMessage: `Compact`,
	},
	toolbarDensityStandard: {
		id: 'global.toolbarDensityStandard',
		defaultMessage: `Standard`,
	},
	toolbarDensityComfortable: {
		id: 'global.toolbarDensityComfortable',
		defaultMessage: `Comfortable`,
	},
	toolbarColumns: {
		id: 'global.toolbarColumns',
		defaultMessage: `Columns`,
	},
	toolbarColumnsLabel: {
		id: 'global.toolbarColumnsLabel',
		defaultMessage: `Select columns`,
	},
	toolbarFilters: {
		id: 'global.toolbarFilters',
		defaultMessage: `Filters`,
	},
	toolbarFiltersLabel: {
		id: 'global.toolbarFiltersLabel',
		defaultMessage: `Show filters`,
	},
	toolbarFiltersTooltipHide: {
		id: 'global.toolbarFiltersTooltipHide',
		defaultMessage: `Hide filters`,
	},
	toolbarFiltersTooltipShow: {
		id: 'global.toolbarFiltersTooltipShow',
		defaultMessage: `Show filters`,
	},
	toolbarExport: {
		id: 'global.toolbarExport',
		defaultMessage: `Export`,
	},
	toolbarExportLabel: {
		id: 'global.toolbarExportLabel',
		defaultMessage: `Export`,
	},
	toolbarExportCSV: {
		id: 'global.toolbarExportCSV',
		defaultMessage: `Download as CSV`,
	},
	columnsPanelTextFieldLabel: {
		id: 'global.columnsPanelTextFieldLabel',
		defaultMessage: `Find column`,
	},
	columnsPanelTextFieldPlaceholder: {
		id: 'global.columnsPanelTextFieldPlaceholder',
		defaultMessage: `Column title`,
	},
	columnsPanelDragIconLabel: {
		id: 'global.columnsPanelDragIconLabel',
		defaultMessage: `Reorder column`,
	},
	columnsPanelShowAllButton: {
		id: 'global.columnsPanelShowAllButton',
		defaultMessage: `Show all`,
	},
	columnsPanelHideAllButton: {
		id: 'global.columnsPanelHideAllButton',
		defaultMessage: `Hide all`,
	},
	filterPanelAddFilter: {
		id: 'global.filterPanelAddFilter',
		defaultMessage: `Add filter`,
	},
	filterPanelDeleteIconLabel: {
		id: 'global.filterPanelDeleteIconLabel',
		defaultMessage: `Delete`,
	},
	filterPanelOperators: {
		id: 'global.filterPanelOperators',
		defaultMessage: `Operators`,
	},
	filterPanelOperatorAnd: {
		id: 'global.filterPanelOperatorAnd',
		defaultMessage: `And`,
	},
	filterPanelOperatorOr: {
		id: 'global.filterPanelOperatorOr',
		defaultMessage: `Or`,
	},
	filterPanelColumns: {
		id: 'global.filterPanelColumns',
		defaultMessage: `Columns`,
	},
	filterPanelInputLabel: {
		id: 'global.filterPanelInputLabel',
		defaultMessage: `Value`,
	},
	filterPanelInputPlaceholder: {
		id: 'global.filterPanelInputPlaceholder',
		defaultMessage: `Filter value`,
	},
	filterOperatorIsEmpty: {
		id: 'global.filterOperatorIsEmpty',
		defaultMessage: `is empty`,
	},
	filterOperatorIsNotEmpty: {
		id: 'global.filterOperatorIsNotEmpty',
		defaultMessage: `is not empty`,
	},
	filterOperatorContains: {
		id: 'global.filterOperatorContains',
		defaultMessage: `contains`,
	},
	filterOperatorEquals: {
		id: 'global.filterOperatorEquals',
		defaultMessage: `equals`,
	},
	filterOperatorStartsWith: {
		id: 'global.filterOperatorStartsWith',
		defaultMessage: `starts with`,
	},
	filterOperatorEndsWith: {
		id: 'global.filterOperatorEndsWith',
		defaultMessage: `ends with`,
	},
	filterOperatorIs: {
		id: 'global.filterOperatorIs',
		defaultMessage: `is`,
	},
	filterOperatorNot: {
		id: 'global.filterOperatorNot',
		defaultMessage: `in not`,
	},
	filterOperatorAfter: {
		id: 'global.filterOperatorAfter',
		defaultMessage: `is after`,
	},
	filterOperatorOnOrAfter: {
		id: 'global.filterOperatorOnOrAfter',
		defaultMessage: `is on or after`,
	},
	filterOperatorBefore: {
		id: 'global.filterOperatorBefore',
		defaultMessage: `is before`,
	},
	filterOperatorOnOrBefore: {
		id: 'global.filterOperatorOnOrBefore',
		defaultMessage: `is on or before`,
	},
	columnMenuLabel: {
		id: 'global.columnMenuLabel',
		defaultMessage: `Menu`,
	},
	columnMenuShowColumns: {
		id: 'global.columnMenuShowColumns',
		defaultMessage: `Show columns`,
	},
	columnMenuFilter: {
		id: 'global.columnMenuFilter',
		defaultMessage: `Filter`,
	},
	columnMenuHideColumn: {
		id: 'global.columnMenuHideColumn',
		defaultMessage: `Hide`,
	},
	columnMenuUnsort: {
		id: 'global.columnMenuUnsort',
		defaultMessage: `Unsort`,
	},
	columnMenuSortAsc: {
		id: 'global.columnMenuSortAsc',
		defaultMessage: `Sort by ASC`,
	},
	columnMenuSortDesc: {
		id: 'global.columnMenuSortDesc',
		defaultMessage: `Sort by DESC`,
	},
	columnHeaderFiltersLabel: {
		id: 'global.columnHeaderFiltersLabel',
		defaultMessage: `Show filters`,
	},
	columnHeaderSortIconLabel: {
		id: 'global.columnHeaderSortIconLabel',
		defaultMessage: `Sort`,
	},
	pinToLeft: {
		id: 'global.pinToLeft',
		defaultMessage: `Pin to left`,
	},
	pinToRight: {
		id: 'global.pinToRight',
		defaultMessage: `Pin to right`,
	},
	unpin: {
		id: 'global.unpin',
		defaultMessage: `Unpin`,
	},
	footerTotalRows: {
		id: 'global.footerTotalRows',
		defaultMessage: `Total Rows:`,
	},
	checkboxSelectionHeaderName: {
		id: 'global.checkboxSelectionHeaderName',
		defaultMessage: `Checkbox selection`,
	},
	activeFilter: {
		id: 'global.activeFilter',
		defaultMessage: `active filter`,
	},
	activeFilters: {
		id: 'global.activeFilters',
		defaultMessage: `active filters`,
	},
	rowSelected: {
		id: 'global.rowSelected',
		defaultMessage: `row selected`,
	},
	rowsSelected: {
		id: 'global.rowsSelected',
		defaultMessage: `rows selected`,
	},
	commissionerReview: {
		id: 'global.commissionerReview',
		defaultMessage: `Commissioner review`,
	},
	reviewerReview: {
		id: 'global.reviewerReview',
		defaultMessage: `Reviewer review`,
	},
	violation: {
		id: 'global.violation',
		defaultMessage: `Violation`,
	},
	other: {
		id: 'global.other',
		defaultMessage: `Other`,
	},
	participantsCount: {
		id: 'global.participantsCount',
		defaultMessage: `Participants count`,
	},
	expectedExams: {
		id: 'global.expectedExams',
		defaultMessage: `Expected`,
	},
	parallelJobsCount: {
		id: 'global.parallelJobsCount',
		defaultMessage: `Parallel jobs count`,
	},
	scaling: {
		id: 'global.scaling',
		defaultMessage: `Scaling`,
	},
	scalingExam: {
		id: 'global.scalingExam',
		defaultMessage: `Scaling Exam`,
	},
	scalingPostprocessing: {
		id: 'global.scalingPostprocessing',
		defaultMessage: `Scaling Postprocessing`,
	},
	nodepool: {
		id: 'global.nodepool',
		defaultMessage: `Nodepool`,
	},
	minCount: {
		id: 'global.minCount',
		defaultMessage: `Minimum Count`,
	},
	maxCount: {
		id: 'global.maxCount',
		defaultMessage: `Maximum Count`,
	},
	currentCount: {
		id: 'global.currentCount',
		defaultMessage: `Current Count`,
	},
	autoScaling: {
		id: 'global.autoScaling',
		defaultMessage: `Automatic`,
	},
	manualScaling: {
		id: 'global.manualScaling',
		defaultMessage: `Manual`,
	},
	database: {
		id: 'global.database',
		defaultMessage: `Database`,
	},
	cpu: {
		id: 'global.cpu',
		defaultMessage: `CPU`,
	},
	storageMb: {
		id: 'global.storageMb',
		defaultMessage: `Storage MB`,
	},
	switchAINodepools: {
		id: 'global.switchAINodepools',
		defaultMessage: `Switch AI nodepools`,
	},
	switchBackendAutoscaling: {
		id: 'global.switchBackendAutoscaling',
		defaultMessage: `Scale backend`,
	},
	switchDbAutoscaling: {
		id: 'global.switchDbAutoscaling',
		defaultMessage: `Scale database`,
	},
	switchAutoscaler: {
		id: 'global.switchAutoscaler',
		defaultMessage: `Switch autoscaler`,
	},
	runningExams: {
		id: 'global.runningExams',
		defaultMessage: `Running exams`,
	},
	runningJobs: {
		id: 'global.runningJobs',
		defaultMessage: `Running jobs`,
	},
	running: {
		id: 'global.running',
		defaultMessage: `Running`,
	},
	finished: {
		id: 'global.finished',
		defaultMessage: `Finished`,
	},
	aksCluster: {
		id: 'global.aksCluster',
		defaultMessage: `AKS cluster`,
	},
	autoscaler: {
		id: 'global.autoscaler',
		defaultMessage: `Autoscaler`,
	},
	start: {
		id: 'global.start',
		defaultMessage: `Start`,
	},
	stop: {
		id: 'global.stop',
		defaultMessage: `Stop`,
	},
	clusterName: {
		id: 'global.clusterName',
		defaultMessage: `Name`,
	},
	clusterStatus: {
		id: 'global.clusterStatus',
		defaultMessage: `Name`,
	},
	cpuLoad: {
		id: 'global.cpuLoad',
		defaultMessage: `CPU load`,
	},
	application: {
		id: 'global.application',
		defaultMessage: `Application`,
	},
	required: {
		id: 'global.required',
		defaultMessage: `Required`,
	},
	wrongPhoneNumberFormat: {
		id: 'global.wrongPhoneNumberFormat',
		defaultMessage: `Incorrect number format (E.g. +420911222333)`,
	},
	currentStatus: {
		id: 'global.currentStatus',
		defaultMessage: `Current status`,
	},
	hardware: {
		id: 'global.hardware',
		defaultMessage: `Hardware`,
	},
	csvExport: {
		id: 'global.csvExport',
		defaultMessage: `CSV Export`,
	},
	filterExams: {
		id: 'global.filterExams',
		defaultMessage: `Filter exams`,
	},
	finishExams: {
		id: 'global.finishExams',
		defaultMessage: `Finish exams`,
	},
	reviewerReviewedAt: {
		id: 'global.reviewerReviewedAt',
		defaultMessage: `Reviewed at (Reviewer)`,
	},
	commissionerReviewedAt: {
		id: 'global.commissionerReviewedAt',
		defaultMessage: `Reviewed at (Commissioner)`,
	},
	finalViolationCode: {
		id: 'global.finalViolationCode',
		defaultMessage: `Final violation code`,
	},
	createNewFromDefault: {
		id: 'global.createNewFromDefault',
		defaultMessage: `Create new from default`,
	},
	default: {
		id: 'global.default',
		defaultMessage: `Default`,
	},
	setDefault: {
		id: 'global.setDefault',
		defaultMessage: `Set default`,
	},
	total: {
		id: 'global.total',
		defaultMessage: `Total`,
	},
	processed: {
		id: 'global.processed',
		defaultMessage: `Processed`,
	},
	assignReviews: {
		id: 'global.assignReviews',
		defaultMessage: `Assign reviews`,
	},
	finishExam: {
		id: 'global.finishExam',
		defaultMessage: `Finish exam`,
	},
	finishExamPrompt: {
		id: 'global.finishExamPrompt',
		defaultMessage: `Are you sure you want to finish the running exam manually?`,
	},
	selectUser: {
		id: 'global.selectUser',
		defaultMessage: `Select user`,
	},
	commissionerQueue: {
		id: 'global.commissionerQueue',
		defaultMessage: `Commissioner queue`,
	},
	reviewerQueue: {
		id: 'global.reviewerQueue',
		defaultMessage: `Reviewer queue`,
	},
	noQueue: {
		id: 'global.noQueue',
		defaultMessage: `There is no exam in the queue at the moment`,
	},
	finalReview: {
		id: 'global.finalReview',
		defaultMessage: `Final review`,
	},
	from: {
		id: 'global.from',
		defaultMessage: `from`,
	},
	noteTypeVIOLATION: {
		id: 'global.noteTypeVIOLATION',
		defaultMessage: 'Reviewer',
	},
	noteTypeNOTE: {
		id: 'global.noteTypeNOTE',
		defaultMessage: 'Commissioner',
	},
	noteTypeFEEDBACK: {
		id: 'global.noteTypeFEEDBACK',
		defaultMessage: 'Positive feedback',
	},
	noteTypeNEGATIVE_FEEDBACK: {
		id: 'global.noteTypeNEGATIVE_FEEDBACK',
		defaultMessage: 'Negative feedback',
	},
	noteTypeBOARD: {
		id: 'global.noteTypeBOARD',
		defaultMessage: 'Board of Appeal',
	},
	value: {
		id: 'global.value',
		defaultMessage: 'Value',
	},
	unassignReview: {
		id: 'global.unassignReview',
		defaultMessage: 'Unassign review',
	},
	sendAndClose: {
		id: 'global.sendAndClose',
		defaultMessage: 'Send and close',
	},
	loading: {
		id: 'global.loading',
		defaultMessage: 'Loading ...',
	},
	assignToReview: {
		id: 'global.assignToReview',
		defaultMessage: 'Assign to review',
	},
	unknown: {
		id: 'global.unknown',
		defaultMessage: 'N/A',
	},
	previousUserExams: {
		id: 'global.previousUserExams',
		defaultMessage: 'Previous user exams',
	},
	commissionerReviewDurationSec: {
		id: 'global.commissionerReviewDurationSec',
		defaultMessage: 'Review length (Commissioner)',
	},
	reviewerReviewDurationSec: {
		id: 'global.reviewerReviewDurationSec',
		defaultMessage: 'Review length (Reviewer)',
	},
	deleteViolationPrompt: {
		id: 'global.deleteViolationPrompt',
		defaultMessage: `Are you sure you want to delete this violation?`,
	},
	copyExamUrl: {
		id: 'global.copyExamUrl',
		defaultMessage: `Copy exam url`,
	},
	copiedIntoClipboard: {
		id: 'global.copiedIntoClipboard',
		defaultMessage: `copied into clipboard!`,
	},
	monitoring: {
		id: 'global.monitoring',
		defaultMessage: `Monitoring`,
	},
	addSubcheck: {
		id: 'global.addSubcheck',
		defaultMessage: `Add subcheck`,
	},
	scalingHistory: {
		id: 'global.scalingHistory',
		defaultMessage: `Scaling history`,
	},
	isManualScaling: {
		id: 'global.isManualScaling',
		defaultMessage: `Is manual scaling`,
	},
	wasScaledByLoad: {
		id: 'global.wasScaledByLoad',
		defaultMessage: `Was scaled by load`,
	},
	notes: {
		id: 'global.notes',
		defaultMessage: `Notes`,
	},
	note: {
		id: 'global.note',
		defaultMessage: `Note`,
	},
	authorEmail: {
		id: 'global.authorEmail',
		defaultMessage: `Author email`,
	},
	createdAt: {
		id: 'global.createdAt',
		defaultMessage: `Created at`,
	},
	idPhotoNotAvailable: {
		id: 'global.idPhotoNotAvailable',
		defaultMessage: `The photo of the ID is not available.`,
	},
	facePhotoNotAvailable: {
		id: 'global.facePhotoNotAvailable',
		defaultMessage: `The photo of the tested person is not available.`,
	},
	settings: {
		id: 'global.settings',
		defaultMessage: `Settings`,
	},
	noteType: {
		id: 'global.noteType',
		defaultMessage: 'Note type',
	},
	negative_feedback: {
		id: 'global.negative_feedback',
		defaultMessage: 'Negative feedback',
	},
	positive_feedback: {
		id: 'global.positive_feedback',
		defaultMessage: 'Positive feedback',
	},
	appeals_board: {
		id: 'global.appeals_board',
		defaultMessage: 'Appeals board',
	},
	panel: {
		id: 'global.panel',
		defaultMessage: 'Panel',
	},
	logoutIn: {
		id: 'global.logoutIn',
		defaultMessage: 'Logout in',
	},
	sendNewPassword: {
		id: 'global.sendNewPassword',
		defaultMessage: 'Send new password',
	},
	processExams: {
		id: 'global.processExams',
		defaultMessage: 'Process again',
	},
	webcam: {
		id: `global.webcam`,
		defaultMessage: 'Webcam',
	},
	'video/room': {
		id: `global.video/room`,
		defaultMessage: 'Room',
	},
	myExamCounter: {
		id: `global.myExamCounter`,
		defaultMessage: 'My exam counter',
	},
	introChecksTimer: {
		id: `global.introChecksTimer`,
		defaultMessage: 'Intro checks timer',
	},
	timerLimit: {
		id: `global.timerLimit`,
		defaultMessage: 'Timer limit (minutes)',
	},
	timerDisplay: {
		id: `global.timerDisplay`,
		defaultMessage: 'Timer display (minutes)',
	},
	timerWarning: {
		id: `global.timerWarning`,
		defaultMessage: 'Timer warning (minutes)',
	},
	archiveAfterDays: {
		id: `global.archiveAfterDays`,
		defaultMessage: 'Archive after (Days)',
	},
	deleteAfterDays: {
		id: `global.deleteAfterDays`,
		defaultMessage: 'Delete after (Days)',
	},
	reprocessExams: {
		id: `global.reprocessExams`,
		defaultMessage: 'Re-process exams',
	},
	reprocessExamsAgain: {
		id: `global.reprocessExamsAgain`,
		defaultMessage: 'Re-process exams again',
	},
	reprocessExamsAgainConfirm: {
		id: `global.reprocessExamsAgainConfirm`,
		defaultMessage: 'Are you sure you want to re-process the selected exams?',
	},
	operatingSystem: {
		id: `global.operatingSystem`,
		defaultMessage: 'Operating system',
	},
	webcamDevice: {
		id: `global.webcamDevice`,
		defaultMessage: 'Webcam device',
	},
	microphoneDevice: {
		id: `global.microphoneDevice`,
		defaultMessage: 'Microphone device',
	},
	deferredLimit: {
		id: `global.deferredLimit`,
		defaultMessage: 'Deferred limit',
	},
	webcamVideoNotFound: {
		id : 'global.webcamVideoNotFound',
		defaultMessage: 'Webcam video is not available',
	},
	roomVideoNotFound: {
		id : 'global.roomVideoNotFound',
		defaultMessage: 'Room scan video is not available',
	},
	screenVideoNotFound: {
		id : 'global.screenVideoNotFound',
		defaultMessage: 'Screen video is not available',
	},
	defaultSettings: {
		id : 'global.defaultSettings',
		defaultMessage: 'Default settings',
	},
});
