import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import { makeStyles } from 'tss-react/mui';
import messages from '../../intl/messages';
import { hideModal } from '../../store/modal/actions';

const useStyles = makeStyles()((theme) => ({
	bounding: {
		'& .MuiPaper-root': {
			padding: '2rem',
			minWidth: '400px',
			'& .title': {
				borderBottom: '1px solid' + theme.palette.grey[500],
				padding: '0px 0 10px 0',
			},
			'& .titleText': {
				color: theme.palette.primary.main,
				fontSize: 22,
			},
			'& .body': {
				padding: 0,
			},
			'& .bodyContent': {
				padding: '20px 0',
				margin: 0,
				color: '#5c5c5c',
				overflowX: 'hidden',
			},
			'& .footer': {
				borderTop: '1px solid' + theme.palette.grey[800],
				padding: '15px 0 0 0',
			},
			'& .hidden': {
				display: 'none',
			},
			'& .saveBtn': {
				backgroundColor: theme.palette.primary.main,
				color: theme.palette.secondary.main,
				'&.Mui-disabled': {
					backgroundColor: theme.palette.grey[300],
					color: 'black',
				},
			},
		},
	},
}));

const SaveCloseModalTemplate = (props) => {
	const { classes } = useStyles();
	const dispatch = useDispatch();
	const closeDialog = () => dispatch(hideModal());
	const {
		title,
		saveBtnTitle,
		closeBtnTitle,
		onSave,
		isSaveBtnDisabled,
		isSaveBtnVisible = true,
		maxWidth = 'sm',
		children,
		saveOnEnterKey,
		noControls,
		disableCloseDialog,
	} = props;

	const handleKeyPress = (e) => {
		if (saveOnEnterKey && e.key === 'Enter') {
			onSave(e);
		}
	};

	return (
		<Dialog
			open
			onClose={!disableCloseDialog ? closeDialog : () => {}}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
			onKeyPress={handleKeyPress}
			className={classes.bounding}
			disablePortal
			disableEnforceFocus
			disableAutoFocus
			maxWidth={maxWidth}
		>
			<DialogTitle id="alert-dialog-title" className="title">
				<span className="titleText">{title}</span>
			</DialogTitle>
			<DialogContent className="body">
				<div id="alert-dialog-description" className="bodyContent">
					{children}
				</div>
			</DialogContent>
			<DialogActions className={`footer, ${noControls && 'hidden'}`}>
				{isSaveBtnVisible && (
					<Button
						onClick={onSave}
						disabled={isSaveBtnDisabled}
						className="saveBtn"
						variant="contained"
						data-testid="modalConfirmButton"
					>
						{saveBtnTitle}
					</Button>
				)}
				<Button onClick={closeDialog} variant="contained" className="closeBtn">
					{closeBtnTitle || <FormattedMessage {...messages.close} />}
				</Button>
			</DialogActions>
		</Dialog>
	);
};

SaveCloseModalTemplate.propTypes = {
	isSaveBtnDisabled: PropTypes.bool,
	isSaveBtnVisible: PropTypes.bool,
	noControls: PropTypes.bool,
	disableCloseDialog: PropTypes.bool,
	onSave: PropTypes.func,
	saveBtnTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
	closeBtnTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
	saveOnEnterKey: PropTypes.bool,
	title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
	children: PropTypes.node.isRequired,
	saveBtnColor: PropTypes.string,
	closeBtnColor: PropTypes.string,
};

export default SaveCloseModalTemplate;
