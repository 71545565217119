import { FileOpen } from '@mui/icons-material';
import { Paper } from '@mui/material';
import { useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';
import { COMMISSIONER_REVIEW, ENTITIES, NOTE_TYPES } from '../consts';
import messages from '../intl/messages';
import { getUsers } from '../store/auth/selectors';
import { fetchAllNotes } from '../store/model/actions';
import { getAllNotes, getDefinitionsList, getTermsForExamFilter } from '../store/model/selectors';
import formatDate, { toDate } from '../utils/formatDate';
import { selectOperators, selectOperatorsWEmpty, stringOperatorsExtended } from '../utils/prepareXGridData';
import DataGrid from './DataGrid';
import { getCommissionerReviewIcon, useStyles } from './Exams/ExamsList';
import UniButtton from './UniButtton';

const notesStyles = makeStyles()((theme) => ({
	bounding: {
		'& .MuiDataGrid-virtualScrollerContent': {
			width: 'auto !important',
		},
	},
}));

const Notes = () => {
	const intl = useIntl();
	const { classes } = useStyles();
	const { classes: classesNotes } = notesStyles();
	const dispatch = useDispatch();
	const notes = useSelector(getAllNotes());
	const users = Object.values(useSelector(getUsers) || {});
	const emails = users.map((user) => user.email);
	const definitionNames = useSelector(getDefinitionsList()).map(({ name }) => name);
	const terms = useSelector(getTermsForExamFilter());

	const columns = [
		{
			field: 'detail',
			headerName: '',
			width: 55,
			resizable: false,
			filterable: false,
			sortable: false,
			pinnable: false,
			disableExport: true,
			renderCell: (params) => (
				<Link to={`/${ENTITIES.EXAMS}/${params?.row?.definitionId}/${params?.row?.examId}`}>
					<UniButtton type="icon" icon={<FileOpen />} tooltip={<FormattedMessage {...messages.detail} />} />
				</Link>
			),
		},
		{
			field: 'definitionId',
			headerName: intl.formatMessage(messages.definitionId),
			width: 0,
			cellClassName: 'hidden',
			headerClassName: 'hidden',
			type: 'string',
			filterable: false,
		},
		{
			field: 'examId',
			headerName: intl.formatMessage(messages.examId),
			width: 0,
			cellClassName: 'hidden',
			headerClassName: 'hidden',
			type: 'string',
			filterable: false,
		},
		{
			field: 'definitionName',
			headerName: intl.formatMessage(messages.definition),
			width: 220,
			type: 'singleSelect',
			filterOperators: selectOperators(definitionNames, intl),
		},
		{
			field: 'definitionTerm',
			headerName: intl.formatMessage(messages.date),
			width: 250,
			valueFormatter: ({ value }) => (value ? toDate(value) : undefined),
			type: 'singleSelect',
			filterOperators: selectOperators(terms, intl),
		},
		{
			field: 'authorEmail',
			headerName: intl.formatMessage(messages.authorEmail),
			width: 250,
			type: 'string',
			filterOperators: stringOperatorsExtended(intl),
		},
		{
			field: 'reviewerReviewedBy',
			headerName: intl.formatMessage(messages.reviewerReviewedBy),
			width: 250,
			type: 'singleSelect',
			filterOperators: selectOperatorsWEmpty(emails, intl),
			valueOptions: [],
		},
		{
			field: 'commissionerReviewedBy',
			headerName: intl.formatMessage(messages.commissionerReviewedBy),
			width: 250,
			type: 'singleSelect',
			filterOperators: selectOperatorsWEmpty(emails, intl),
			valueOptions: [],
		},
		{
			field: 'createdAt',
			headerName: intl.formatMessage(messages.createdAt),
			width: 150,
			valueFormatter: ({ value }) => formatDate(value).slice(0, -3),
			valueGetter: ({ value }) => new Date(value),
			type: 'dateTime',
		},
		{
			field: 'text',
			headerName: intl.formatMessage(messages.note),
			width: 450,
			renderCell: (params) => <div style={{ padding: '10px 0' }}>{params.row.text}</div>,
			type: 'string',
			filterOperators: stringOperatorsExtended(intl),
		},
		{
			field: 'finalReview',
			headerName: intl.formatMessage(messages.finalReview),
			width: 150,
			renderCell: (params) => <div>{getCommissionerReviewIcon(params.row.finalReview)}</div>,
			type: 'singleSelect',
			filterOperators: selectOperators(COMMISSIONER_REVIEW, intl, false),
		},
		{
			field: 'type',
			headerName: intl.formatMessage(messages.noteType),
			width: 180,
			renderCell: (params) => <div>{intl.formatMessage(messages[params.row.type])}</div>,
			type: 'singleSelect',
			filterOperators: selectOperators(Object.values(NOTE_TYPES), intl, false),
		},
	];

	const dataGridConfig = {
		columns,
		rows: notes,
		gridId: ENTITIES.NOTES,
		getRowHeight: () => 'auto',
	};

	useEffect(() => {
		dispatch(fetchAllNotes());
	}, [dispatch]);

	return (
		<Paper className={classes.bounding} style={{ margin: '1.5rem', height: 'calc(100vh - 100px)' }}>
			<h2>{intl.formatMessage(messages.notes)}</h2>
			<div className={classesNotes.bounding} style={{ height: 'calc(100vh - 155px)' }}>
				<DataGrid {...dataGridConfig} />
			</div>
		</Paper>
	);
};

export default Notes;
