import { Paper } from '@mui/material';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { ENTITIES } from '../../consts';
import messages from '../../intl/messages';
import { getDashboardExams, getDefNamesForExamFilter, getTermsForExamFilter } from '../../store/model/selectors';
import { toDate } from '../../utils/formatDate';
import { numericOperators, selectOperators } from '../../utils/prepareXGridData';
import DataGrid from '../DataGrid';
import { useStyles } from '../Exams/ExamsList';

const ReviewedExams = () => {
	const intl = useIntl();
	const { classes } = useStyles();
	const exams = useSelector(getDashboardExams());
	const definitionNames = useSelector(getDefNamesForExamFilter());
	const terms = useSelector(getTermsForExamFilter());

	const reviewedExamsColumns = [
		{
			field: 'name',
			headerName: intl.formatMessage(messages.definition),
			width: 500,
			type: 'singleSelect',
			filterOperators: selectOperators(definitionNames, intl),
		},
		{
			field: 'term',
			headerName: intl.formatMessage(messages.date),
			width: 250,
			valueFormatter: ({ value }) => toDate(value),
			type: 'singleSelect',
			filterOperators: selectOperators(terms, intl),
		},
		{
			field: 'reviewedExamsCount',
			headerName: intl.formatMessage(messages.reviewedExamsCount),
			width: 280,
			align: 'right',
			headerAlign: 'right',
			type: 'number',
			filterOperators: numericOperators(intl),
		},
	];

	const dataGridConfig = {
		gridId: ENTITIES.REVIEWED_EXAMS,
		rows: exams.reviewedExamsByDefinition,
		disableRowSelectionOnClick: true,
		columns: reviewedExamsColumns,
	};

	return (
		<Paper elevation={3} className={classes.bounding} style={{ height: '345px' }}>
			<h2>{intl.formatMessage(messages.myExamCounter)}</h2>
			<div style={{ height: '345px' }}>
				<DataGrid {...dataGridConfig} />
			</div>
		</Paper>
	);
};

export default ReviewedExams;
