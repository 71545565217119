import { Checkbox, FormControl, FormControlLabel, Grid, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from 'tss-react/mui';
import { USER, USER_SKILLS } from '../../consts';
import messages from '../../intl/messages';
import { editUser } from '../../store/auth/actions';
import { getUserById } from '../../store/auth/selectors';
import { hideModal } from '../../store/modal/actions';
import { isPhoneNumberInvalid } from '../../utils/validation';
import SaveCloseModalTemplate from './SaveCloseModalTemplate';

const useStyles = makeStyles()((theme) => ({
	fixSelectLabel: {
		top: '-7px',
		left: '14px',
	},
	selectTitle: {
		width: '406px',
	},
}));

const EditUserModal = ({ userId }) => {
	const { classes } = useStyles();
	const dispatch = useDispatch();

	const user = useSelector(getUserById(userId));
	const [state, setState] = useState(
		user
			? { ...user }
			: { name: '', surname: '', role: '', skill: '', has2fa: false, phoneNumber: '', maxPostponedExams: '' }
	);

	const [nameHelperText, setNameHelperText] = useState(null);
	const [surnameHelperText, setSurnameHelperText] = useState(null);
	const [phoneNumberHelperText, setPhoneNumberHelperText] = useState(null);

	useEffect(() => {
		if (!state.has2fa) {
			setPhoneNumberHelperText(null);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [state.has2fa]);

	useEffect(() => {
		if (state.phoneNumber) setPhoneNumberHelperText(null);
	}, [state.phoneNumber]);

	const handleEditUser = async () => {
		if (!state.name) {
			setNameHelperText(<FormattedMessage {...messages.required} />);
			return;
		}

		if (!state.surname) {
			setSurnameHelperText(<FormattedMessage {...messages.required} />);
			return;
		}

		if (state.has2fa) {
			const phoneNumberError = isPhoneNumberInvalid(state.phoneNumber);
			setPhoneNumberHelperText(phoneNumberError);
			if (phoneNumberError) return;
		}

		// BE does not accept empty string
		dispatch(editUser({ user: { ...state, phoneNumber: state.phoneNumber || undefined } }));

		dispatch(hideModal());
	};

	return (
		<SaveCloseModalTemplate
			title={<FormattedMessage {...messages.editUser} />}
			saveBtnTitle={<FormattedMessage {...messages.editUser} />}
			onSave={handleEditUser}
		>
			<Grid item xs={12}>
				<TextField
					variant="outlined"
					fullWidth
					label={<FormattedMessage {...messages.firstName} />}
					value={state.name}
					error={!!nameHelperText}
					helperText={nameHelperText}
					onChange={(e) => setState({ ...state, name: e.target.value })}
					id="name-edit-user-modal"
				/>
			</Grid>
			<br />
			<Grid item xs={12}>
				<TextField
					variant="outlined"
					fullWidth
					label={<FormattedMessage {...messages.lastName} />}
					value={state.surname}
					error={!!surnameHelperText}
					helperText={surnameHelperText}
					onChange={(e) => setState({ ...state, surname: e.target.value })}
					id="surname-edit-user-modal"
				/>
			</Grid>
			<br />
			<FormControl className={classes.selectTitle}>
				<InputLabel className={classes.fixSelectLabel} id="select-userRole">
					<FormattedMessage {...messages.userRole} />
				</InputLabel>
				<Select
					labelId="select-userRole"
					label={<FormattedMessage {...messages.userRole} />}
					value={state.role}
					variant="outlined"
					onChange={(e) => setState({ ...state, role: e.target.value })}
				>
					{Object.values(USER.ROLES).map((role, i) => (
						<MenuItem key={'role' + i} value={role}>
							{role || 'null'}
						</MenuItem>
					))}
				</Select>
			</FormControl>
			<br />
			<br />
			<FormControl className={classes.selectTitle}>
				<InputLabel className={classes.fixSelectLabel} id="select-userSkill">
					<FormattedMessage {...messages.userSkill} />
				</InputLabel>
				<Select
					labelId="select-userSkill"
					label={<FormattedMessage {...messages.userSkill} />}
					value={state.skill}
					variant="outlined"
					onChange={(e) => setState({ ...state, skill: e.target.value })}
				>
					{USER_SKILLS.map((skill, i) => (
						<MenuItem key={'skill' + i} value={skill}>
							{skill}
						</MenuItem>
					))}
				</Select>
			</FormControl>
			<br />
			<br />
			<FormControlLabel
				control={<Checkbox checked={state.has2fa} onChange={() => setState({ ...state, has2fa: !state.has2fa })} />}
				label={<FormattedMessage {...messages.has2fa} />}
			/>
			<br />
			<br />
			{state.has2fa && (
				<Grid item xs={12}>
					<TextField
						fullWidth
						variant="outlined"
						label={<FormattedMessage {...messages.phoneNumber} />}
						type="phoneNumber"
						value={state.phoneNumber}
						error={!!phoneNumberHelperText}
						helperText={phoneNumberHelperText}
						onChange={(e) => setState({ ...state, phoneNumber: e.target.value })}
					/>
				</Grid>
			)}
			<br />
			<br />
			<Grid item xs={12}>
				<TextField
					fullWidth
					value={state.maxPostponedExams || ' '}
					onChange={(e) => setState({ ...state, maxPostponedExams: Number(e.target.value) < 1 ? '' : e.target.value })}
					label={<FormattedMessage {...messages.deferredLimit} />}
					variant="outlined"
					type="number"
				/>
			</Grid>
		</SaveCloseModalTemplate>
	);
};

export default EditUserModal;
