export const getUrlDefinitionId = () => {
	const query = window.location.search;

	if (query === '') return null;
	else {
		const params = new URLSearchParams(query);
		return params.get('definitionId');
	}
};

export const getUrlExternalId = () => {
	const query = window.location.search;

	if (query === '/') return null;
	else {
		const params = new URLSearchParams(query);
		return params.get('externalId');
	}
};
