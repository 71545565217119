import { isEmpty, equals, pick, omit } from 'ramda';
import {
	addProcess as addProcessRequest,
	deleteArchivedExams as deleteArchivedExamsRequest,
	deleteDefinition,
	deleteExams as deleteExamsRequest,
	deleteNote as deleteNoteRequest,
	deleteProcess as deleteProcessRequest,
	editProcess as editProcessRequest,
	finishDefinition,
	getChecklists,
	getCheckpoints,
	getDashboardExams,
	getDefinitions,
	getEvaluationDetails,
	getEvaluations,
	getExam,
	getExamByExternalId,
	getExams,
	getFaceUrl,
	getFilteredExams,
	getIdUrl,
	getInstructions,
	getNotes,
	getPreviousExams,
	getQueueReview,
	getRoomVideoUrl,
	getRunningExamsCount,
	getRunningJobsCount,
	getScalingCluster,
	getScalingDb,
	getScalingDeployments,
	getScalingJobsCount,
	getScalingNodes,
	getScalingStatus,
	getScalingUsersCount,
	getScreenUrl,
	getSpecializedQueueReview,
	getStats,
	getSwitchValues,
	getViolations,
	getWebcamUrl,
	postDefaultDefinition,
	postDefinition,
	postNewViolation,
	postNote,
	postReview,
	putAssignReviewToCommisioner,
	putAssignReviews,
	putCheck,
	putCheckpoint,
	putDefinition,
	putFinishExam,
	putInstruction,
	putNote,
	putPostponeReview,
	putResetAssignedReviews,
	putResetPostponedReviews,
	putScaling,
	putScalingPostprocessing,
	putUnassignReview,
	putViolationReview,
	putViolationsReview,
	startAksCluster,
	stopAksCluster,
	getExamsForReviewCount,
	getExamsForCommissionerReviewCount,
	deleteViolationRequest,
	getAutoscalerStatus,
	putStartAutoscaler,
	putStopAutoscaler,
	getScalingHistory,
	getAllNotes,
	processExam,
	processExamsList,
} from '../../api';
import { AUTHORIZED_ACTIONS, ENTITIES, JOB_STATUS, REVIEW, USER } from '../../consts';
import { messages } from '../../intl';
import arrToObjIdAsKey from '../../utils/arrToObjIdAsKey';
import keyMirror from '../../utils/keyMirror';
import { makeActionCreator } from '../../utils/redux';
import { getUrlDefinitionId } from '../../utils/urlIds';
import checkUserAuthorization from '../../utils/userRights';
import { getUser, getUserById } from '../auth/selectors';
import { NOTIF_ACTION_TYPES } from '../notif/actions';
import { getCurrentLangCode, getCurrentListTab } from '../ui/selectors';
import { catchFail, prepareDefinitionForServer } from '../utils';
import {
	getChecklist as getStoredChecklist,
	getCheckpoints as getStoredCheckpoints,
	getDefinitionId,
	getInstructions as getStoredInstructions,
	getSelectedDefinitions,
} from './selectors';

export const MODEL_ACTION_TYPES = keyMirror({
	ADD_VIOLATION: null,
	ASSIGN_REVIEWS: null,
	ASSIGN_REVIEW_COMMISSIONER: null,
	DELETE_ARCHIVED_EXAMS: null,
	DELETE_NOTE: null,
	EDIT_CHECKLIST: null,
	EDIT_CHECKPOINTS: null,
	EDIT_DEFINITION: null,
	EDIT_INSTRUCTIONS: null,
	EDIT_NOTE: null,
	FETCH_CHECKLISTS: null,
	FETCH_CHECKPOINTS: null,
	FETCH_DASHBOARD_EXAMS: null,
	FETCH_DEFAULT_DEFINITION: null,
	FETCH_DEFINITION: null,
	FETCH_DEFINITIONS: null,
	FETCH_EVALUATIONS: null,
	FETCH_EVALUATION_DETAILS: null,
	FETCH_EXAM: null,
	FETCH_EXAMS: null,
	FETCH_EXAMS_FOR_REVIEW_COUNT: null,
	FETCH_EXAMS_FOR_COMMISSIONER_REVIEW_COUNT: null,
	FETCH_EXAMS_PENDING: null,
	FETCH_FACE_URL: null,
	FETCH_FILTERED_EXAMS: null,
	FETCH_ID_URL: null,
	FETCH_INSTRUCTIONS: null,
	FETCH_NOTES: null,
	FETCH_ALL_NOTES: null,
	FETCH_PREVIOUS_EXAMS: null,
	FETCH_QUEUE_REVIEW: null,
	FETCH_ROOM_VIDEO_URL: null,
	FETCH_RUNNING_EXAMS_COUNT: null,
	FETCH_RUNNING_JOBS_COUNT: null,
	FETCH_SCALING_CLUSTER: null,
	FETCH_SCALING_DB: null,
	FETCH_SCALING_DEPLOYMENTS: null,
	FETCH_SCALING_HISTORY: null,
	FETCH_SCALING_JOBS_COUNT: null,
	FETCH_SCALING_NODES: null,
	FETCH_SCALING_STATUS: null,
	FETCH_SCALING_SWITCH: null,
	FETCH_AUTOSCALER_STATUS: null,
	SWITCH_AUTOSCALER: null,
	FETCH_SCALING_USERS_COUNT: null,
	FETCH_SCREEN_URL: null,
	FETCH_SPECIALIZED_QUEUE_REVIEW: null,
	FETCH_STATS: null,
	FETCH_VIOLATIONS: null,
	DELETE_VIOLATION: null,
	FETCH_WEBCAM_URL: null,
	FINISH_DEFINITION_EXAMS: null,
	FINISH_EXAM: null,
	POSTPONE_REVIEW: null,
	REMOVE_DEFINITION: null,
	REMOVE_EXAMS: null,
	RESET_ASSIGNED_REVIEWS: null,
	RESET_EXAM_PREVIEW_URLS: null,
	RESET_POSTPONED_REVIEWS: null,
	SEND_NOTE: null,
	SEND_REVIEW: null,
	SET_CONFIG_DEFINITION_ID: null,
	SET_DEFINITION_ID: null,
	SET_EXAM_ID: null,
	SET_PROCESS_WAS_EDITED: null,
	SET_VIOLATIONS_REVIEW: null,
	SET_VIOLATION_REVIEW: null,
	UNASSIGN_REVIEW: null,
	UPDATE_SCALING_JOB_ID: null,
	UPDATE_SELECTED_DEFINITIONS: null,
	UPDATE_USER_COUNT: null,
	RESTART_EXAM_PROCESSING: null,
});

export const fetchChecklists = (definitionId) => async (dispatch, getState) => {
	if (!definitionId) return;

	const type = MODEL_ACTION_TYPES.FETCH_CHECKLISTS;

	try {
		const state = getState();
		const langCode = getCurrentLangCode(state);
		const checklists = arrToObjIdAsKey(await getChecklists(definitionId, langCode));

		dispatch({ type, payload: { definitionId, checklists } });
		return true;
	} catch (e) {
		catchFail({
			callback: () => fetchChecklists(definitionId),
			dispatch,
			e,
			type,
		});
	}
};

export const editChecklist = (checklist, definitionId) => async (dispatch, getState) => {
	const type = MODEL_ACTION_TYPES.EDIT_CHECKLIST;

	try {
		const state = getState();
		const storedChecklist = getStoredChecklist(definitionId)(state);
		const changedChecksOnly = checklist.filter((check, index) => !equals(check, storedChecklist[index]));
		const langCode = getCurrentLangCode(state);

		for (const check of changedChecksOnly) {
			if (!check.subChecklists) {
				await putCheck(
					pick(['headline', 'instructionsText', 'position', 'durationInSeconds', 'enabled'], check),
					langCode,
					check.id
				);
			} else {
				await putCheck(
					pick(['headline', 'instructionsText', 'position', 'enabled', 'subChecklists'], {
						...check,
						subChecklists: [...check.subChecklists].map((subChecklist) =>
							omit(['chosen', 'selected', 'runCheck'], subChecklist)
						),
					}),
					langCode,
					check.id
				);
			}
		}

		dispatch({ type, payload: checklist });
	} catch (e) {
		catchFail({
			callback: () => editChecklist(checklist, definitionId),
			dispatch,
			e,
			type,
		});
	}
};

export const fetchCheckpoints = (definitionId, processEdited) => async (dispatch, getState) => {
	if (!definitionId) return;
	const type = MODEL_ACTION_TYPES.FETCH_CHECKPOINTS;

	try {
		const state = getState();
		const langCode = getCurrentLangCode(state);
		const checkpoints = arrToObjIdAsKey(await getCheckpoints(definitionId, langCode));

		if (processEdited) {
			dispatch(setProcessWasEdited(true));
		}
		return dispatch({ type, payload: { definitionId, checkpoints } });
	} catch (e) {
		catchFail({
			callback: () => fetchCheckpoints(definitionId),
			dispatch,
			e,
			type,
		});
	}
};

export const editCheckpoints = (checkpoints, definitionId) => async (dispatch, getState) => {
	const type = MODEL_ACTION_TYPES.EDIT_CHECKPOINTS;

	try {
		const state = getState();
		const langCode = getCurrentLangCode(state);
		const storedCheckpoints = getStoredCheckpoints(definitionId)(state);
		const changedCheckpointsOnly = checkpoints.filter(
			(checkpoint, index) => !equals(checkpoint, storedCheckpoints[index])
		);

		for (const checkpoint of changedCheckpointsOnly) {
			await putCheckpoint(checkpoint, langCode);
		}

		dispatch({ type, payload: checkpoints });
	} catch (e) {
		catchFail({
			callback: () => editCheckpoints(checkpoints, definitionId),
			dispatch,
			e,
			type,
		});
	}
};

export const deleteProcess = (processId, definitionId) => async (dispatch) => {
	try {
		await deleteProcessRequest(processId);

		dispatch(fetchCheckpoints(definitionId));
	} catch (e) {
		catchFail({
			callback: () => deleteProcess(processId, definitionId),
			dispatch,
			e,
		});
	}
};

export const addProcess = (process, definitionId) => async (dispatch) => {
	try {
		await addProcessRequest(process, definitionId);

		dispatch(fetchCheckpoints(definitionId));
	} catch (e) {
		catchFail({
			callback: () => addProcess(process, definitionId),
			dispatch,
			e,
		});
	}
};

export const editProcess = (processId, process, definitionId) => async (dispatch) => {
	try {
		await editProcessRequest(processId, process);

		dispatch(fetchCheckpoints(definitionId, true));
	} catch (e) {
		catchFail({
			callback: () => editProcess(processId, process, definitionId),
			dispatch,
			e,
		});
	}
};

export const fetchDashboardExams = () => async (dispatch, getState) => {
	const type = MODEL_ACTION_TYPES.FETCH_DASHBOARD_EXAMS;

	const state = getState();
	const user = getUser(state);

	if (!user) return;

	try {
		const exams = await getDashboardExams({
			userId: user.id,
		});

		dispatch({ type, payload: exams });
	} catch (e) {
		catchFail({ callback: () => fetchDashboardExams({ userId: user.id }), dispatch, e, type });
	}
};

export const fetchDefinition = (definitionId) => async (dispatch) => {
	const type = MODEL_ACTION_TYPES.FETCH_DEFINITION;

	try {
		const newDefinition = await postDefinition(definitionId);

		dispatch({ type, payload: newDefinition });

		return newDefinition;
	} catch (e) {
		catchFail({
			callback: () => fetchDefinition(definitionId),
			dispatch,
			e,
			type,
		});
	}
};

export const fetchDefaultDefinition = () => async (dispatch) => {
	const type = MODEL_ACTION_TYPES.FETCH_DEFAULT_DEFINITION;

	try {
		const newDefinition = await postDefaultDefinition();

		dispatch({ type, payload: newDefinition });

		return newDefinition;
	} catch (e) {
		catchFail({ callback: () => fetchDefaultDefinition(), dispatch, e, type });
	}
};

export const editDefinition = (definition, intl) => async (dispatch, getState) => {
	const type = MODEL_ACTION_TYPES.EDIT_DEFINITION;

	try {
		const state = getState();
		const langCode = getCurrentLangCode(state);

		await putDefinition(prepareDefinitionForServer(definition), langCode);

		dispatch({
			type: NOTIF_ACTION_TYPES.SUCCESS,
			payload: { type, message: intl.formatMessage(messages.notifOperationSuccess) },
		});

		dispatch({ type, payload: definition });
	} catch (e) {
		catchFail({
			callback: () => editDefinition(definition, intl),
			dispatch,
			e,
			type,
		});
	}
};

export const removeDefinition = (definitionId, intl) => async (dispatch) => {
	const type = MODEL_ACTION_TYPES.REMOVE_DEFINITION;

	try {
		await deleteDefinition(definitionId);

		dispatch({
			type: NOTIF_ACTION_TYPES.SUCCESS,
			payload: { type, message: intl.formatMessage(messages.notifOperationSuccess) },
		});

		dispatch({ type, payload: { definitionId } });
	} catch (e) {
		catchFail({
			callback: () => removeDefinition(definitionId, intl),
			dispatch,
			e,
			type,
		});
	}
};

export const finishDefinitionExams = (definitionId, intl) => async (dispatch) => {
	const type = MODEL_ACTION_TYPES.FINISH_DEFINITION_EXAMS;

	try {
		await finishDefinition(definitionId);

		dispatch({
			type: NOTIF_ACTION_TYPES.SUCCESS,
			payload: { type, message: intl.formatMessage(messages.notifOperationSuccess) },
		});

		dispatch({ type, payload: { definitionId } });
	} catch (e) {
		catchFail({
			callback: () => finishDefinitionExams(definitionId, intl),
			dispatch,
			e,
			type,
		});
	}
};

export const deleteArchivedExams = (definitionId, intl) => async (dispatch) => {
	const type = MODEL_ACTION_TYPES.DELETE_ARCHIVED_EXAMS;

	try {
		await deleteArchivedExamsRequest(definitionId);

		dispatch({
			type: NOTIF_ACTION_TYPES.SUCCESS,
			payload: { type, message: intl.formatMessage(messages.notifOperationSuccess) },
		});

		dispatch({ type, payload: { definitionId } });
	} catch (e) {
		catchFail({
			callback: () => deleteArchivedExams(definitionId, intl),
			dispatch,
			e,
			type,
		});
	}
};

export const fetchDefinitions = () => async (dispatch, getState) => {
	const type = MODEL_ACTION_TYPES.FETCH_DEFINITIONS;

	try {
		const state = getState();
		const langCode = getCurrentLangCode(state);
		const definitions = arrToObjIdAsKey(await getDefinitions(langCode));

		dispatch({ type, payload: definitions });
	} catch (e) {
		catchFail({ callback: () => fetchDefinitions(), dispatch, e, type });
	}
};

export const fetchEvaluations = () => async (dispatch) => {
	const type = MODEL_ACTION_TYPES.FETCH_EVALUATIONS;

	try {
		const evaluations = arrToObjIdAsKey(await getEvaluations());

		dispatch({ type, payload: evaluations });
	} catch (e) {
		catchFail({ callback: () => fetchEvaluations(), dispatch, e, type });
	}
};

export const fetchExam = (callback, definitionId, examId) => async (dispatch) => {
	const type = MODEL_ACTION_TYPES.FETCH_EXAM;

	try {
		const exam = await getExam(callback, definitionId, examId);

		dispatch({ type, payload: exam });
	} catch (e) {
		catchFail({
			callback: () => fetchExam(callback, definitionId, examId),
			dispatch,
			e,
			type,
		});
	}
};

export const fetchExams = (definitionId) => async (dispatch, getState) => {
	dispatch({ type: MODEL_ACTION_TYPES.FETCH_EXAMS_PENDING, payload: true });

	const state = getState();
	const currentListTab = getCurrentListTab(state);

	if ((!definitionId || currentListTab === ENTITIES.EXAM) && !getUrlDefinitionId()) return;

	const type = MODEL_ACTION_TYPES.FETCH_EXAMS;
	const addDefinitionId = (exams, definitionId) => exams.map((exam) => ({ ...exam, definitionId }));
	const selectedDefinitions = getSelectedDefinitions(state);
	const _fetchExams = async (definitionId) => {
		const exams = arrToObjIdAsKey(addDefinitionId(await getExams(definitionId), definitionId));

		return dispatch({ type, payload: exams });
	};

	try {
		// happens when we display exams from multiple definitions
		if (!isEmpty(selectedDefinitions)) {
			for (const definition of selectedDefinitions) {
				await _fetchExams(definition.id);
			}
		} else {
			await _fetchExams(definitionId);
		}

		dispatch({ type: MODEL_ACTION_TYPES.FETCH_EXAMS_PENDING, payload: false });

		return true;
	} catch (e) {
		catchFail({ callback: () => fetchExams(definitionId), dispatch, e, type });
	}
};

export const fetchExamsForReviewCount = (userId) => async (dispatch) => {
	const type = MODEL_ACTION_TYPES.FETCH_EXAMS_FOR_REVIEW_COUNT;

	try {
		const res = await getExamsForReviewCount(userId);

		dispatch({ type, payload: res.examsCount });
	} catch (e) {
		catchFail({
			callback: () => fetchExamsForReviewCount(userId),
			dispatch,
			e,
			type,
		});
	}
};

export const fetchExamsForCommissionerReviewCount = (userId) => async (dispatch) => {
	const type = MODEL_ACTION_TYPES.FETCH_EXAMS_FOR_COMMISSIONER_REVIEW_COUNT;

	try {
		const res = await getExamsForCommissionerReviewCount(userId);

		dispatch({ type, payload: res.examsCount });
	} catch (e) {
		catchFail({
			callback: () => fetchExamsForCommissionerReviewCount(userId),
			dispatch,
			e,
			type,
		});
	}
};

export const fetchFilteredExams =
	({ filterModel, page, intl, limit, sortModel = {} }) =>
	async (dispatch) => {
		const type = MODEL_ACTION_TYPES.FETCH_FILTERED_EXAMS;

		try {
			const res = await getFilteredExams(filterModel, page, limit, sortModel.field, sortModel.sort);

			dispatch({ type, payload: { exams: res.exams, count: res.totalCount } });
		} catch (e) {
			catchFail({
				callback: () => fetchFilteredExams(filterModel, page, intl),
				dispatch,
				e,
				type,
			});
		}
	};

export const fetchAndSetExamByExternalId = (externalId) => async (dispatch) => {
	const type = MODEL_ACTION_TYPES.FETCH_EXAM;

	try {
		const exam = await getExamByExternalId(externalId);

		window.location.replace(`/exams/${exam.definitionId}/${exam.id}`);
	} catch (e) {
		catchFail({
			callback: () => fetchAndSetExamByExternalId(externalId),
			dispatch,
			e,
			type,
		});
	}
};

export const fetchWebcamUrl = (examId) => async (dispatch) => {
	if (!examId) return;

	const type = MODEL_ACTION_TYPES.FETCH_WEBCAM_URL;

	try {
		const payload = await getWebcamUrl(examId);

		dispatch({ type, payload });
	} catch (e) {
		catchFail({ callback: () => fetchWebcamUrl(examId), dispatch, e, type });
	}
};

export const fetchScreenUrl = (examId) => async (dispatch) => {
	if (!examId) return;

	const type = MODEL_ACTION_TYPES.FETCH_SCREEN_URL;

	try {
		const payload = await getScreenUrl(examId);

		dispatch({ type, payload });
	} catch (e) {
		catchFail({ callback: () => fetchScreenUrl(examId), dispatch, e, type });
	}
};

export const fetchRoomVideoUrl = (examId) => async (dispatch) => {
	if (!examId) return;

	const type = MODEL_ACTION_TYPES.FETCH_ROOM_VIDEO_URL;

	try {
		const payload = await getRoomVideoUrl(examId);

		dispatch({ type, payload });
	} catch (e) {
		catchFail({ callback: () => fetchRoomVideoUrl(examId), dispatch, e, type });
	}
};

export const fetchIdUrl = (examId) => async (dispatch) => {
	if (!examId) return;

	const type = MODEL_ACTION_TYPES.FETCH_ID_URL;

	try {
		const payload = await getIdUrl(examId);

		dispatch({ type, payload });
	} catch (e) {
		catchFail({ callback: () => fetchIdUrl(examId), dispatch, e, type });
	}
};

export const fetchFaceUrl = (examId) => async (dispatch) => {
	if (!examId) return;

	const type = MODEL_ACTION_TYPES.FETCH_FACE_URL;

	try {
		const payload = await getFaceUrl(examId);

		dispatch({ type, payload });
	} catch (e) {
		catchFail({ callback: () => fetchFaceUrl(examId), dispatch, e, type });
	}
};

export const fetchQueueReview = (userId) => async (dispatch) => {
	const type = MODEL_ACTION_TYPES.FETCH_QUEUE_REVIEW;

	try {
		const { definitionId, examId } = await getQueueReview(userId);

		dispatch({ type, payload: { definitionId, examId } });
		return definitionId;
	} catch (e) {
		catchFail({ callback: () => fetchQueueReview(userId), dispatch, e, type });
	}
};

export const fetchSpecializedQueueReview = (userId) => async (dispatch) => {
	const type = MODEL_ACTION_TYPES.FETCH_SPECIALIZED_QUEUE_REVIEW;

	try {
		const { definitionId, examId } = await getSpecializedQueueReview(userId);

		dispatch({ type, payload: { definitionId, examId } });
		return definitionId;
	} catch (e) {
		catchFail({
			callback: () => fetchSpecializedQueueReview(userId),
			dispatch,
			e,
			type,
		});
	}
};

export const fetchViolations = (examId) => async (dispatch) => {
	if (!examId) return;

	const type = MODEL_ACTION_TYPES.FETCH_VIOLATIONS;

	try {
		const violations = arrToObjIdAsKey(await getViolations(examId));

		dispatch({ type, payload: { violations, examId } });
	} catch (e) {
		catchFail({ callback: () => fetchViolations(examId), dispatch, e, type });
	}
};

export const deleteViolation = (id) => async (dispatch) => {
	const type = MODEL_ACTION_TYPES.DELETE_VIOLATION;

	try {
		await deleteViolationRequest(id);

		dispatch({ type, payload: [id] });
	} catch (e) {
		catchFail({ callback: () => deleteViolation(id), dispatch, e, type });
	}
};

export const setViolationReview =
	({ evaluationGroup, evaluationId, review, reviewerNote, violationId }) =>
	async (dispatch, getState) => {
		const type = MODEL_ACTION_TYPES.SET_VIOLATION_REVIEW;

		const user = getUser(getState());

		try {
			const payload = {
				evaluationGroup,
				evaluationId,
				reviewerNote: reviewerNote || null,
				violationId,
			};

			await putViolationReview({ ...payload, review, userId: user.id });

			dispatch({
				type,
				payload: {
					...payload,
					// We have to manually assign review as commissionerReview or reviewerReview in store (BE can do it automatically)
					[checkUserAuthorization(user.role, AUTHORIZED_ACTIONS.REVIEW_AS_COMMISSIONER)
						? REVIEW.TYPES.COMMISSIONER
						: REVIEW.TYPES.REVIEWER]: review,
				},
			});
		} catch (e) {
			catchFail({
				callback: () =>
					setViolationReview({
						evaluationGroup,
						evaluationId,
						review,
						reviewerNote,
						violationId,
					}),
				dispatch,
				e,
				type,
			});
		}
	};

export const setViolationsReview =
	({ evaluationId, violationIds }) =>
	async (dispatch, getState) => {
		const type = MODEL_ACTION_TYPES.SET_VIOLATIONS_REVIEW;

		const user = getUser(getState());

		try {
			await putViolationsReview({ evaluationId, violationIds, userId: user.id });

			// BE can identify userRole based on id but in store, we have to differentiate between commissionerReview and reviewerReview
			dispatch({ type, payload: { evaluationId, violationIds, userRole: user.role } });
		} catch (e) {
			catchFail({
				callback: () => setViolationsReview({ evaluationId, violationIds }),
				dispatch,
				e,
				type,
			});
		}
	};

export const addNewViolation =
	({ examId, checkpointId, createdAt }) =>
	async (dispatch) => {
		const type = MODEL_ACTION_TYPES.ADD_VIOLATION;

		try {
			const newViolation = await postNewViolation({
				examId,
				checkpointId,
				createdAt,
			});

			dispatch({ type, payload: newViolation });
			return newViolation.id;
		} catch (e) {
			catchFail({
				callback: () => addNewViolation({ examId, checkpointId, createdAt }),
				dispatch,
				e,
				type,
			});
		}
	};

export const sendReview =
	({
		evaluationDetail,
		examId,
		exit,
		finalViolationMessage,
		isViolationIntentional,
		review,
		reviewedBy,
		userId,
		violationNote,
	}) =>
	async (dispatch, getState) => {
		const type = MODEL_ACTION_TYPES.SEND_REVIEW;

		const user = getUserById(userId, getState());
		const userRole = checkUserAuthorization(user.role, AUTHORIZED_ACTIONS.REVIEW_AS_REVIEWER)
			? USER.ROLES.REVIEWER
			: USER.ROLES.COMMISSIONER;

		const payload =
			userRole === USER.ROLES.REVIEWER
				? { review, reviewedBy }
				: { evaluationDetail, review, reviewedBy, finalViolationMessage, isViolationIntentional, violationNote };

		try {
			await postReview({ examId, userRole, payload });
			await dispatch({
				type,
				payload: {
					evaluationDetail,
					examId,
					violationNote,
					finalViolationMessage,
					isViolationIntentional,
					review,
					userId,
					userRole,
				},
			});

			if (exit) exit();
		} catch (e) {
			catchFail({
				callback: () =>
					sendReview({
						evaluationDetail,
						examId,
						violationNote,
						finalViolationMessage,
						exit,
						isViolationIntentional,
						review,
						reviewedBy,
						userId,
					}),
				dispatch,
				e,
				type,
			});
		}
	};

export const fetchNotes = (examId) => async (dispatch) => {
	if (!examId) return;

	const type = MODEL_ACTION_TYPES.FETCH_NOTES;

	try {
		const notes = arrToObjIdAsKey(await getNotes(examId));

		dispatch({ type, payload: notes });

		return true;
	} catch (e) {
		catchFail({ callback: () => fetchNotes(examId), dispatch, e, type });
	}
};

export const fetchAllNotes = () => async (dispatch) => {
	const type = MODEL_ACTION_TYPES.FETCH_ALL_NOTES;

	try {
		const notes = await getAllNotes();

		dispatch({ type, payload: notes });

		return true;
	} catch (e) {
		catchFail({ callback: () => fetchNotes(), dispatch, e, type });
	}
};

export const sendNote =
	({ examId, userId, type, text }) =>
	async (dispatch) => {
		const actionType = MODEL_ACTION_TYPES.SEND_NOTE;

		try {
			await postNote({ examId, userId, type, text });

			dispatch(fetchNotes(examId));
		} catch (e) {
			catchFail({
				callback: () => sendNote({ examId, userId, type, text }),
				dispatch,
				e,
				type: actionType,
			});
		}
	};

export const editNote =
	({ noteId, type, text, examId }) =>
	async (dispatch) => {
		const actionType = MODEL_ACTION_TYPES.EDIT_NOTE;

		try {
			await putNote({ noteId, type, text });

			dispatch(fetchNotes(examId));
		} catch (e) {
			catchFail({
				callback: () => editNote({ noteId, type, text }),
				dispatch,
				e,
				type: actionType,
			});
		}
	};

export const deleteNote =
	({ noteId }) =>
	async (dispatch) => {
		const actionType = MODEL_ACTION_TYPES.DELETE_NOTE;

		try {
			await deleteNoteRequest({ noteId });

			dispatch({ type: actionType, payload: { noteId } });
		} catch (e) {
			catchFail({
				callback: () => deleteNote({ noteId }),
				dispatch,
				e,
				actionType,
			});
		}
	};

export const finishExam =
	({ examId }) =>
	async (dispatch) => {
		const type = MODEL_ACTION_TYPES.FINISH_EXAM;

		try {
			await putFinishExam({ examId });
		} catch (e) {
			catchFail({
				callback: () => finishExam({ examId }),
				dispatch,
				e,
				type,
			});
		}
	};

export const postponeReview =
	({ examId, userId }) =>
	async (dispatch) => {
		const type = MODEL_ACTION_TYPES.POSTPONE_REVIEW;

		try {
			await putPostponeReview({ examId, userId });
		} catch (e) {
			catchFail({
				callback: () => postponeReview({ examId, userId }),
				dispatch,
				e,
				type,
			});
		}
	};

export const resetPostponedReviews = (examIds, intl) => async (dispatch, getState) => {
	const type = MODEL_ACTION_TYPES.RESET_POSTPONED_REVIEWS;

	try {
		await putResetPostponedReviews(examIds);
		await dispatch(fetchExams(getDefinitionId(getState())));

		dispatch({
			type: NOTIF_ACTION_TYPES.SUCCESS,
			payload: { type, message: intl.formatMessage(messages.notifOperationSuccess) },
		});

		return true;
	} catch (e) {
		catchFail({
			callback: () => resetPostponedReviews(examIds, intl),
			dispatch,
			e,
			type,
		});
	}
};

export const resetAssignedReviews = (examIds, intl) => async (dispatch, getState) => {
	const type = MODEL_ACTION_TYPES.RESET_ASSIGNED_REVIEWS;

	try {
		await putResetAssignedReviews(examIds);
		await dispatch(fetchExams(getDefinitionId(getState())));

		dispatch({
			type: NOTIF_ACTION_TYPES.SUCCESS,
			payload: { type, message: intl.formatMessage(messages.notifOperationSuccess) },
		});

		return true;
	} catch (e) {
		catchFail({
			callback: () => resetAssignedReviews(examIds, intl),
			dispatch,
			e,
			type,
		});
	}
};

export const editInstructions = (instructions) => async (dispatch, getState) => {
	const type = MODEL_ACTION_TYPES.EDIT_INSTRUCTIONS;

	try {
		const state = getState();
		const storedInstructions = getStoredInstructions()(state);
		const changedInstructionsOnly = instructions.filter(
			(checkpoint, index) => !equals(checkpoint, storedInstructions[index])
		);

		for (const instruction of changedInstructionsOnly) {
			await putInstruction(instruction);
		}

		dispatch({ type, payload: instructions });
	} catch (e) {
		catchFail({
			callback: () => editInstructions(instructions),
			dispatch,
			e,
			type,
		});
	}
};

export const fetchInstructions = () => async (dispatch) => {
	const type = MODEL_ACTION_TYPES.FETCH_INSTRUCTIONS;

	try {
		const instructions = arrToObjIdAsKey(await getInstructions());

		dispatch({ type, payload: instructions });

		return true;
	} catch (e) {
		catchFail({ callback: () => fetchInstructions(), dispatch, e, type });
	}
};

export const fetchScalingDeployments = () => async (dispatch) => {
	const type = MODEL_ACTION_TYPES.FETCH_SCALING_DEPLOYMENTS;

	try {
		const scalingNodes = await getScalingDeployments();

		dispatch({ type, payload: scalingNodes });

		return true;
	} catch (e) {
		catchFail({ callback: () => fetchScalingDeployments(), dispatch, e, type });
	}
};

export const fetchScalingNodes = () => async (dispatch) => {
	const type = MODEL_ACTION_TYPES.FETCH_SCALING_NODES;

	try {
		const scalingNodes = await getScalingNodes();

		dispatch({ type, payload: scalingNodes });

		return true;
	} catch (e) {
		catchFail({ callback: () => fetchScalingNodes(), dispatch, e, type });
	}
};

export const fetchScalingDb = () => async (dispatch) => {
	const type = MODEL_ACTION_TYPES.FETCH_SCALING_DB;

	try {
		const scalingDb = await getScalingDb();

		dispatch({ type, payload: scalingDb });

		return true;
	} catch (e) {
		catchFail({ callback: () => fetchScalingDb(), dispatch, e, type });
	}
};

export const fetchScalingCluster = () => async (dispatch) => {
	const type = MODEL_ACTION_TYPES.FETCH_SCALING_CLUSTER;

	try {
		const scalingCluster = await getScalingCluster();

		dispatch({ type, payload: scalingCluster });

		return true;
	} catch (e) {
		catchFail({ callback: () => fetchScalingCluster(), dispatch, e, type });
	}
};

export const fetchScalingHistory = () => async (dispatch) => {
	const type = MODEL_ACTION_TYPES.FETCH_SCALING_HISTORY;

	try {
		dispatch({ type, payload: await getScalingHistory() });

		return true;
	} catch (e) {
		catchFail({ callback: () => fetchScalingHistory(), dispatch, e, type });
	}
};

export const fetchScalingUserCount = () => async (dispatch) => {
	const type = MODEL_ACTION_TYPES.FETCH_SCALING_USERS_COUNT;

	try {
		const scalingUsersCount = await getScalingUsersCount();

		dispatch({ type, payload: scalingUsersCount });

		return true;
	} catch (e) {
		catchFail({ callback: () => fetchScalingUserCount(), dispatch, e, type });
	}
};

export const fetchScalingSwitch = () => async (dispatch) => {
	const type = MODEL_ACTION_TYPES.FETCH_SCALING_SWITCH;

	try {
		const switchValues = await getSwitchValues();

		dispatch({ type, payload: switchValues });

		return true;
	} catch (e) {
		catchFail({ callback: () => fetchScalingSwitch(), dispatch, e, type });
	}
};

export const fetchAutoscalerStatus = () => async (dispatch) => {
	const type = MODEL_ACTION_TYPES.FETCH_AUTOSCALER_STATUS;

	try {
		const autoscalerStatus = await getAutoscalerStatus();

		dispatch({ type, payload: autoscalerStatus });

		return true;
	} catch (e) {
		catchFail({ callback: () => fetchAutoscalerStatus(), dispatch, e, type });
	}
};

export const fetchScalingJobsCount = () => async (dispatch) => {
	const type = MODEL_ACTION_TYPES.FETCH_SCALING_JOBS_COUNT;

	try {
		const scalingJobsCount = await getScalingJobsCount();

		dispatch({ type, payload: scalingJobsCount });

		return true;
	} catch (e) {
		catchFail({ callback: () => fetchScalingJobsCount(), dispatch, e, type });
	}
};

export const fetchRunningExamsCount = () => async (dispatch) => {
	const type = MODEL_ACTION_TYPES.FETCH_RUNNING_EXAMS_COUNT;

	try {
		const runningExamsCount = await getRunningExamsCount();

		dispatch({ type, payload: runningExamsCount });

		return true;
	} catch (e) {
		catchFail({ callback: () => fetchRunningExamsCount(), dispatch, e, type });
	}
};

export const fetchRunningJobsCount = () => async (dispatch) => {
	const type = MODEL_ACTION_TYPES.FETCH_RUNNING_JOBS_COUNT;

	try {
		const runningJobsCount = await getRunningJobsCount();

		dispatch({ type, payload: runningJobsCount });

		return true;
	} catch (e) {
		catchFail({ callback: () => fetchRunningJobsCount(), dispatch, e, type });
	}
};

export const fetchScalingStatus = (jobId) => async (dispatch) => {
	const type = MODEL_ACTION_TYPES.FETCH_SCALING_STATUS;

	try {
		const scalingStatus = await getScalingStatus(jobId);

		dispatch({ type, payload: scalingStatus });

		if (scalingStatus.jobStatus === 'FAILED' && scalingStatus.jobFailedReason) {
			throw new Error(scalingStatus.jobFailedReason);
		}

		return true;
	} catch (e) {
		catchFail({ callback: () => fetchScalingStatus(), dispatch, e, type });
	}
};

export const editScaling = (usersCount, shouldScaleModels, shouldScaleBackend, shouldScaleDb) => async (dispatch) => {
	try {
		const jobId = await putScaling(usersCount, shouldScaleModels, shouldScaleBackend, shouldScaleDb);

		dispatch({ type: MODEL_ACTION_TYPES.UPDATE_USER_COUNT, payload: usersCount });
		dispatch({ type: MODEL_ACTION_TYPES.UPDATE_SCALING_JOB_ID, payload: jobId });

		return true;
	} catch (e) {
		catchFail({ callback: () => editScaling(usersCount, shouldScaleModels), dispatch, e });
	}
};

export const enableAutoscaler = () => async (dispatch) => {
	try {
		await putStartAutoscaler();

		dispatch({ type: MODEL_ACTION_TYPES.FETCH_AUTOSCALER_STATUS, payload: { isEnabled: true } });

		return true;
	} catch (e) {
		catchFail({ callback: () => enableAutoscaler(), dispatch, e });
	}
};

export const disableAutoscaler = () => async (dispatch) => {
	try {
		await putStopAutoscaler();

		dispatch({ type: MODEL_ACTION_TYPES.FETCH_AUTOSCALER_STATUS, payload: { isEnabled: false } });

		return true;
	} catch (e) {
		catchFail({ callback: () => disableAutoscaler(), dispatch, e });
	}
};

export const editScalingPostprocessing = (jobsCount) => async (dispatch) => {
	try {
		await putScalingPostprocessing(jobsCount);

		return true;
	} catch (e) {
		catchFail({ callback: () => editScalingPostprocessing(jobsCount), dispatch, e });
	}
};

export const startCluster = () => async (dispatch) => {
	try {
		await startAksCluster();

		return true;
	} catch (e) {
		catchFail({ callback: () => startCluster(), dispatch, e });
	}
};

export const stopCluster = () => async (dispatch) => {
	try {
		await stopAksCluster();

		return true;
	} catch (e) {
		catchFail({ callback: () => stopCluster(), dispatch, e });
	}
};

export const fetchStats = () => async (dispatch) => {
	const type = MODEL_ACTION_TYPES.FETCH_STATS;

	try {
		const stats = await getStats();
		dispatch({ type, payload: stats });
		return true;
	} catch (e) {
		catchFail({ callback: () => fetchStats(), dispatch, e, type });
	}
};

export const assignReviews = (examIds, userId, intl) => async (dispatch, getState) => {
	const type = MODEL_ACTION_TYPES.ASSIGN_REVIEWS;

	try {
		await putAssignReviews(examIds, userId);
		await dispatch(fetchExams(getDefinitionId(getState())));

		dispatch({
			type: NOTIF_ACTION_TYPES.SUCCESS,
			payload: { type, message: intl.formatMessage(messages.notifOperationSuccess) },
		});

		return true;
	} catch (e) {
		catchFail({
			callback: () => assignReviews(examIds, userId, intl),
			dispatch,
			e,
			type,
		});
	}
};

export const assignReviewToCommissioner = (examId, userId) => async (dispatch, getState) => {
	const type = MODEL_ACTION_TYPES.ASSIGN_REVIEW_COMMISSIONER;

	try {
		await putAssignReviewToCommisioner(examId, userId);

		await dispatch({ type, payload: { examId, userId } });

		return true;
	} catch (e) {
		catchFail({
			callback: () => putAssignReviewToCommisioner(examId, userId),
			dispatch,
			e,
			type,
		});
	}
};

export const unassignReview =
	({ examId }) =>
	async (dispatch) => {
		const type = MODEL_ACTION_TYPES.UNASSIGN_REVIEW;

		try {
			await putUnassignReview(examId);
			dispatch({ type });

			return true;
		} catch (e) {
			catchFail({
				callback: () => unassignReview({ examId }),
				dispatch,
				e,
				type,
			});
		}
	};

export const restartProcessing = (examIds, intl) => async (dispatch, getState) => {
	const type = MODEL_ACTION_TYPES.RESTART_EXAM_PROCESSING;

	try {
		if (examIds.length > 1) {
			await processExamsList(examIds);
		} else await processExam(examIds?.[0]);

		const exams = getState().model.exams;
		for (let i = 0; i < examIds.length; i++) {
			exams[examIds[i]] = { ...exams[examIds[i]], jobStatus: JOB_STATUS.RUNNING };
		}

		await dispatch({ type, payload: { exams } });

		dispatch({
			type: NOTIF_ACTION_TYPES.SUCCESS,
			payload: { type, message: intl.formatMessage(messages.notifOperationSuccess) },
		});

		return true;
	} catch (e) {
		catchFail({
			callback: () => restartProcessing(examIds, intl),
			dispatch,
			e,
			type,
		});
	}
};

export const deleteExams = (examIds, intl) => async (dispatch) => {
	const type = MODEL_ACTION_TYPES.REMOVE_EXAMS;

	try {
		await deleteExamsRequest(examIds);

		dispatch({
			type: NOTIF_ACTION_TYPES.SUCCESS,
			payload: { type, message: intl.formatMessage(messages.notifOperationSuccess) },
		});

		dispatch({ type, payload: examIds });
		return true;
	} catch (e) {
		catchFail({ callback: () => deleteExams(examIds, intl), dispatch, e, type });
	}
};

export const fetchEvaluationDetails = () => async (dispatch) => {
	const type = MODEL_ACTION_TYPES.FETCH_EVALUATION_DETAILS;

	try {
		const evaluationDetails = await getEvaluationDetails();

		dispatch({ type, payload: evaluationDetails });
	} catch (e) {
		catchFail({ callback: () => getEvaluationDetails(), dispatch, e, type });
	}
};

export const fetchPreviousExams = (examId) => async (dispatch) => {
	const type = MODEL_ACTION_TYPES.FETCH_PREVIOUS_EXAMS;

	try {
		const payload = await getPreviousExams(examId);

		dispatch({ type, payload });
	} catch (e) {
		catchFail({ callback: () => fetchPreviousExams(examId), dispatch, e, type });
	}
};

export const updateSelectedDefinitionIds = makeActionCreator(MODEL_ACTION_TYPES.UPDATE_SELECTED_DEFINITIONS, 'ids');

export const setConfigDefinitionId = makeActionCreator(MODEL_ACTION_TYPES.SET_CONFIG_DEFINITION_ID, 'id');

export const setDefinitionId = makeActionCreator(MODEL_ACTION_TYPES.SET_DEFINITION_ID, 'id');

export const setExamId = makeActionCreator(MODEL_ACTION_TYPES.SET_EXAM_ID, 'id');

export const resetExamPreviewUrls = makeActionCreator(MODEL_ACTION_TYPES.RESET_EXAM_PREVIEW_URLS);

export const setProcessWasEdited = makeActionCreator(MODEL_ACTION_TYPES.SET_PROCESS_WAS_EDITED, 'value');
