import {
	CloudQueue,
	Dashboard as DashboardIcon,
	Equalizer,
	FindInPage,
	ListAlt,
	MeetingRoom,
	PeopleAlt,
	Settings,
	Visibility,
	Message,
} from '@mui/icons-material';
import { AppBar, Grid, Tab, Tabs, Tooltip } from '@mui/material';
import Badge from '@mui/material/Badge';
import { isEmpty } from 'ramda';
import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';
import { ENTITIES, ROUTES, TAB_VISIBILITY_RIGHTS, USER } from '../consts';
import messages from '../intl/messages';
import { logoutUser } from '../store/auth/actions';
import { getUserEmail, getUserId, getUserRole } from '../store/auth/selectors';
import {
	fetchCheckpoints,
	fetchEvaluations,
	fetchExams,
	fetchExamsForReviewCount,
	fetchExamsForCommissionerReviewCount,
	fetchInstructions,
	setDefinitionId,
	fetchDefinitions,
} from '../store/model/actions';
import {
	getExamsForReviewCount,
	getExamsForCommissionerReviewCount,
	getDefinitionsList,
} from '../store/model/selectors';
import redirectInsteadMenu from '../utils/redirectInsteadMenu';
import useInterval from '../utils/useInterval';
import LogoutCountdown from './LogoutCountdown';
import TabPanel from './TabPanel';
import UniButtton from './UniButtton';

const useStyles = makeStyles()((theme) => ({
	root: {
		flexGrow: 1,
		'& .MuiBox-root': {
			padding: 0,
		},
		'& .MuiTabs-scroller': {
			'& .MuiBadge-badge': {
				padding: 2,
				fontSize: 10,
				background: '#9C27B0',
				color: '#fff',
			},
		},
		'& .tabs': {
			'& .MuiTab-root': {
				minWidth: '50px',
			},
			'& .MuiTab-textColorSecondary.Mui-disabled': {
				color: 'rgba(0, 0, 0, 0.15)',
			},
		},

		'& .logout': {
			fontSize: '0.9rem',
			marginBottom: '-1rem',
			paddingTop: '1rem',
			textAlign: 'right',
			textTransform: 'capitalize',
			'& div': { float: 'right', padding: '0 1.5rem 0 0.4rem', position: 'relative', top: '-10px' },
			'& .MuiButton-root': { margin: '0 0 0 0.5rem' },
			'& strong': { textTransform: 'lowercase' },
		},
	},
}));

const renderTabs = (userRole, examsForReviewCount, examsForCommissionerReviewCount, isCommissionerQueueDisabled) => [
	<Tab
		key={'section-' + ROUTES.DASHBOARD.PATH}
		disabled={!TAB_VISIBILITY_RIGHTS[userRole].includes(ROUTES.DASHBOARD.PATH)}
		icon={
			<Tooltip title={<FormattedMessage {...messages.dashboard} />}>
				<DashboardIcon />
			</Tooltip>
		}
		value={ROUTES.DASHBOARD.PATH}
		onContextMenu={(e) => redirectInsteadMenu(e, ROUTES.DASHBOARD.PATH)}
	/>,
	<Tab
		key={'section-' + ROUTES.STAS.PATH}
		disabled={!TAB_VISIBILITY_RIGHTS[userRole].includes(ROUTES.STAS.PATH)}
		icon={
			<Tooltip title={<FormattedMessage {...messages.statistics} />}>
				<Equalizer />
			</Tooltip>
		}
		value={ROUTES.STAS.PATH}
		onContextMenu={(e) => redirectInsteadMenu(e, ROUTES.STAS.PATH)}
	/>,
	<Tab
		key={'section-' + ROUTES.EXAMS.PATH}
		disabled={!TAB_VISIBILITY_RIGHTS[userRole].includes(ROUTES.EXAMS.PATH)}
		icon={
			<Tooltip title={<FormattedMessage {...messages.listOfTemplates} />}>
				<ListAlt />
			</Tooltip>
		}
		value={ROUTES.EXAMS.PATH}
		onContextMenu={(e) => redirectInsteadMenu(e, ROUTES.EXAMS.PATH)}
	/>,
	<Tab
		key={'section-' + ROUTES.EXAMS_PREVIEW_REVIEWER.PATH}
		disabled={!TAB_VISIBILITY_RIGHTS[userRole].includes(ROUTES.EXAMS_PREVIEW_REVIEWER.PATH)}
		icon={
			<Badge badgeContent={examsForReviewCount} max={9999} showZero>
				<Tooltip title={<FormattedMessage {...messages.reviewerQueue} />}>
					<FindInPage />
				</Tooltip>
			</Badge>
		}
		value={ROUTES.EXAMS_PREVIEW_REVIEWER.PATH}
		onContextMenu={(e) => redirectInsteadMenu(e, ROUTES.EXAMS_PREVIEW_REVIEWER.PATH)}
	/>,
	<Tab
		key={'section-' + ROUTES.EXAMS_PREVIEW_COMMISSIONER.PATH}
		disabled={isCommissionerQueueDisabled}
		icon={
			<Badge badgeContent={examsForCommissionerReviewCount} max={9999} invisible={isCommissionerQueueDisabled} showZero>
				<Tooltip title={<FormattedMessage {...messages.commissionerQueue} />}>
					<Visibility />
				</Tooltip>
			</Badge>
		}
		value={ROUTES.EXAMS_PREVIEW_COMMISSIONER.PATH}
		onContextMenu={(e) => redirectInsteadMenu(e, ROUTES.EXAMS_PREVIEW_COMMISSIONER.PATH)}
	/>,
	<Tab
		key={'section-' + ROUTES.USERS.PATH}
		disabled={!TAB_VISIBILITY_RIGHTS[userRole].includes(ROUTES.USERS.PATH)}
		icon={
			<Tooltip title={<FormattedMessage {...messages.users} />}>
				<PeopleAlt />
			</Tooltip>
		}
		value={ROUTES.USERS.PATH}
		onContextMenu={(e) => redirectInsteadMenu(e, ROUTES.USERS.PATH)}
	/>,
	<Tab
		key={'section-' + ROUTES.NOTES.PATH}
		disabled={!TAB_VISIBILITY_RIGHTS[userRole].includes(ROUTES.NOTES.PATH)}
		icon={
			<Tooltip title={<FormattedMessage {...messages.notes} />}>
				<Message />
			</Tooltip>
		}
		value={ROUTES.NOTES.PATH}
		onContextMenu={(e) => redirectInsteadMenu(e, ROUTES.NOTES.PATH)}
	/>,
	<Tab
		key={'section-' + ROUTES.SETTINGS.PATH}
		disabled={!TAB_VISIBILITY_RIGHTS[userRole].includes(ROUTES.SETTINGS.PATH)}
		icon={
			<Tooltip title={<FormattedMessage {...messages.settings} />}>
				<Settings />
			</Tooltip>
		}
		value={ROUTES.SETTINGS.PATH}
		onContextMenu={(e) => redirectInsteadMenu(e, ROUTES.SETTINGS.PATH)}
	/>,
	<Tab
		key={'section-' + ROUTES.SCALING.PATH}
		disabled={!TAB_VISIBILITY_RIGHTS[userRole].includes(ROUTES.SCALING.PATH)}
		icon={
			<Tooltip title={<FormattedMessage {...messages.scaling} />}>
				<CloudQueue />
			</Tooltip>
		}
		value={ROUTES.SCALING.PATH}
		onContextMenu={(e) => redirectInsteadMenu(e, ROUTES.SCALING.PATH)}
	/>,
];

const Sections = ({ child }) => {
	const navigate = useNavigate();
	const { classes } = useStyles();
	const userRole = useSelector(getUserRole);
	const { pathname } = useLocation();
	const { definitionId } = useParams();

	const userEmail = useSelector(getUserEmail);
	const userId = useSelector(getUserId);
	const examsForReviewCount = useSelector(getExamsForReviewCount);
	const examsForCommissionerReviewCount = useSelector(getExamsForCommissionerReviewCount);
	const dispatch = useDispatch();
	const isCommissionerQueueDisabled = !TAB_VISIBILITY_RIGHTS?.[userRole]?.includes(ROUTES.EXAMS_PREVIEW_COMMISSIONER.PATH);
	const tabs = renderTabs(userRole, examsForReviewCount, examsForCommissionerReviewCount, isCommissionerQueueDisabled);
	const isAdmin = userRole === USER.ROLES.ADMIN;
	const isReviewer = userRole === USER.ROLES.REVIEWER;
	const definitions = useSelector(getDefinitionsList);

	useEffect(() => {
		if (!isAdmin) dispatch(fetchExamsForReviewCount(userId));
		if (!isCommissionerQueueDisabled) {
			dispatch(fetchExamsForCommissionerReviewCount(userId));
		}
	}, [dispatch, userId, isCommissionerQueueDisabled, isAdmin]);

	useEffect(() => {
		localStorage.removeItem(ENTITIES.POSTPONE_REVIEW);

		dispatch(fetchEvaluations());
		dispatch(fetchInstructions());

		if (definitionId) dispatch(setDefinitionId(definitionId));
	}, [dispatch, definitionId, userRole, navigate]);

	useEffect(() => {
		if (definitionId) {
			dispatch(fetchCheckpoints(definitionId));
			if (!isReviewer) dispatch(fetchExams(definitionId));
		}
	}, [definitionId, dispatch, isReviewer]);

	useEffect(() => {
		if (isEmpty(definitions)) dispatch(fetchDefinitions());
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useInterval(
		() => {
			if (!isAdmin) dispatch(fetchExamsForReviewCount(userId));
			if (!isCommissionerQueueDisabled) {
				dispatch(fetchExamsForCommissionerReviewCount(userId));
			}
		},
		20000,
		true
	);

	const onTabChange = (e, path) => {
		if (path === ROUTES.EXAMS_PREVIEW_REVIEWER.PATH || path === ROUTES.EXAMS_PREVIEW_COMMISSIONER.PATH) {
			// To track origin, we use this for originIsQueue and originIsExams in ExamPreview
			localStorage.setItem('navigatorHistory', path + ',' + localStorage.getItem('navigatorHistory'));
		}
		navigate(path);
	};

	return (
        <div className={classes.root}>
			<AppBar position="static">
				<Grid container spacing={0}>
					<Grid item xs={6} className="tabs">
						<Tabs
							variant="scrollable"
							indicatorColor="secondary"
							textColor="secondary"
							// match first level only
							value={pathname.match(/^\/([^/]+)/)[0] || ROUTES.DASHBOARD.PATH}
							onChange={onTabChange}
							scrollButtons
							allowScrollButtonsMobile
						>
							{tabs.map((tab) => tab)}
						</Tabs>
					</Grid>
					<Grid item xs={6} className="logout">
						<FormattedMessage {...messages[userRole ?? USER.ROLES.REVIEWER]} />: <strong>{userEmail}</strong>
						{' | '}
						<LogoutCountdown />
						<UniButtton
							color="secondary"
							tooltip={<FormattedMessage {...messages.logout} />}
							icon={<MeetingRoom />}
							onClick={() => dispatch(logoutUser())}
						/>
					</Grid>
				</Grid>
			</AppBar>
			<TabPanel>{child}</TabPanel>
		</div>
    );
};

export default Sections;
