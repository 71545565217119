import { ThemeProvider } from '@mui/material/styles';
import { LicenseInfo } from '@mui/x-data-grid-pro';
import { values } from 'ramda';
import { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { Routes, Route, Navigate, useLocation } from 'react-router-dom';
import ModalRoot from './components/Modals/ModalRoot';
import Notifications from './components/Notifications';
import Pip from './components/Pip';
import TwoFactorAuthentication from './components/TwoFactorAuthentication';
import { LS_USER, ROUTES } from './consts';
import { messages } from './intl';
import { logoutUser } from './store/auth/actions';
import { getIsUserLoggedIn, getIsUserPasswordCorrect } from './store/auth/selectors';
import { fetchAndSetExamByExternalId } from './store/model/actions';
import { setPageTitle } from './store/ui/actions';
import { getIsPip, getPageTitle } from './store/ui/selectors';
import theme from './theme';
import useInterval from './utils/useInterval';

LicenseInfo.setLicenseKey(
	'ce5fe28d7304dd3dc19b411eda1252c8Tz02NTcwMCxFPTE3MTQ3MzcxOTg4MjEsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI='
);

const urlParams = new URLSearchParams(window.location.search);
const definitionId = urlParams.get('definitionId');
const examId = urlParams.get('examId');
const externalId = urlParams.get('externalId');

const App = () => {
	const dispatch = useDispatch();
	const intl = useIntl();
	const { pathname } = useLocation();
	const pageTitle = useSelector(getPageTitle);
	const isPip = useSelector(getIsPip);

	// Keep comalibility with old routing
	if (examId) window.location.replace(`/exams/${definitionId}/${examId}`);
	else if (definitionId) window.location.replace(`/exams/${definitionId}`);
	else if (externalId) dispatch(fetchAndSetExamByExternalId(externalId));

	const isUserLoggedIn = useSelector(getIsUserLoggedIn);
	const isUserPasswordCorrect = useSelector(getIsUserPasswordCorrect);

	useInterval(() => {
		if (!localStorage.getItem(LS_USER) && isUserLoggedIn && isUserPasswordCorrect) dispatch(logoutUser());
	}, 1000);

	useEffect(() => {
		localStorage.setItem('navigatorHistory', (pathname + ',' + localStorage.getItem('navigatorHistory')).slice(0, 500));
	}, [pathname]);

	// clean ls navigatorHistory on every app init
	useEffect(() => {
		localStorage.removeItem('navigatorHistory');
	}, []);

	// Set page title for the first url level
	useEffect(() => {
		for (const { PATH, PAGE_TITLE } of Object.values(ROUTES)) {
			if (PATH === pathname && PAGE_TITLE) dispatch(setPageTitle(intl.formatMessage(messages[PAGE_TITLE])));
		}
	}, [dispatch, intl, pathname]);

	// Page title
	useEffect(() => {
		document.title = pageTitle;
	}, [pageTitle]);

	return (
		<ThemeProvider theme={theme}>
			<Routes>
				<Route
					path="/login/2fa"
					element={isUserPasswordCorrect ? <TwoFactorAuthentication /> : <Navigate to="/login" />}
				/>

				<Route path="*" element={<Navigate to={ROUTES.LOGIN.PATH} />} />

				{/* Routes */}
				{values(ROUTES)
					.filter(({ PUBLIC }) => isUserLoggedIn || PUBLIC)
					.map(({ ELEMENT, PATH }) => (
						<Route key={`routes-${PATH}`} element={ELEMENT} path={PATH} />
					))}
			</Routes>
			<ModalRoot />
			<Notifications />
			{isPip && <Pip />}
		</ThemeProvider>
	);
};

export default App;
