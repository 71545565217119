import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from 'tss-react/mui';
import messages from '../../intl/messages';
import { hideModal } from '../../store/modal/actions';
import { removeDefinition } from '../../store/model/actions';
import { getDefinition } from '../../store/model/selectors';
import SaveCloseModalTemplate from './SaveCloseModalTemplate';

const useStyles = makeStyles()((theme) => ({
	bounding: {
		lineHeight: '1.7rem',
	},
}));

const RemoveDefinitionModal = ({ definitionId }) => {
	const intl = useIntl();
	const { classes } = useStyles();
	const dispatch = useDispatch();
	const definition = useSelector(getDefinition(definitionId));

	return (
		<SaveCloseModalTemplate
			title={<FormattedMessage {...messages.removeDefinition} />}
			saveBtnTitle={<FormattedMessage {...messages.removeDefinition} />}
			onSave={() => {
				dispatch(removeDefinition(definition.id, intl));
				dispatch(hideModal());
			}}
		>
			<div className={classes.bounding}>
				Are you sure to remove definition: <strong>{definition.name}</strong>,<br />
				definitionId: <strong>{definition.id}</strong> ?
			</div>
		</SaveCloseModalTemplate>
	);
};

export default RemoveDefinitionModal;
