import { AppBar, Tab, Tabs } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';
import { ENTITIES, ROUTES, USER } from '../../consts';
import messages from '../../intl/messages';
import { getUserRole } from '../../store/auth/selectors';
import { setConfigDefinitionId } from '../../store/model/actions';
import TabPanel, { a11yProps } from '../TabPanel';
import Checklist from './Checklist';
import Checkpoints from './Checkpoints';
import Definition from './Definition';
import Definitions from './Definitions';
import Instructions from './Instructions';
import LangSelector from './LangSelector';

const useStyles = makeStyles()((theme) => ({
	root: {
		'& .LangSelector-bounding': {
			position: 'absolute',
			right: '25px',
			top: '52px',
		},

		'& .lists': {
			padding: '0 0 0 1.5rem',
		},

		'& .MuiBox-root': {
			padding: '25px !important',
		},
	},
}));

const Settings = () => {
	const { classes } = useStyles();
	const [tab, setTab] = useState(ENTITIES.DEFINITIONS);
	const dispatch = useDispatch();
	const isAdmin = useSelector(getUserRole) === USER.ROLES.ADMIN;
	const { settingsDefinitionId } = useParams();
	const navigate = useNavigate();

	const handleChangeTabs = (e, tabId) => {
		switch (tabId) {
			case ENTITIES.DEFINITION_SETTINGS:
				if (settingsDefinitionId) navigate(`${ROUTES.SETTINGS.PATH}/${settingsDefinitionId}`);
				break;
			default:
				navigate(ROUTES.SETTINGS.PATH);
				break;
		}
	};

	useEffect(() => {
		if (settingsDefinitionId) {
			dispatch(setConfigDefinitionId(settingsDefinitionId));
			setTab(ENTITIES.DEFINITION_SETTINGS);
		} else {
			dispatch(setConfigDefinitionId(null));
			setTab(ENTITIES.DEFINITIONS);
		}
	}, [settingsDefinitionId, dispatch]);

	return (
		<div className={classes.root}>
			<AppBar position="static" color="secondary">
				<Tabs
					variant="scrollable"
					scrollButtons={false}
					indicatorColor="primary"
					textColor="primary"
					value={tab}
					onChange={handleChangeTabs}
					className="lists"
				>
					<Tab
						label={<FormattedMessage {...messages.definitions} />}
						wrapped
						value={ENTITIES.DEFINITIONS}
						{...a11yProps(ENTITIES.DEFINITIONS)}
					/>
					<Tab
						label={<FormattedMessage {...messages.checkListAndCheckpoints} />}
						wrapped
						value={ENTITIES.DEFINITION_SETTINGS}
						{...a11yProps(ENTITIES.DEFINITION_SETTINGS)}
					/>
				</Tabs>
			</AppBar>
			{settingsDefinitionId && (
				<div className="LangSelector-bounding">
					<LangSelector />
				</div>
			)}
			<TabPanel value={tab} index={ENTITIES.DEFINITIONS}>
				<Definitions />
				<br />
				{!isAdmin && <Instructions />}
			</TabPanel>
			<TabPanel value={tab} index={ENTITIES.DEFINITION_SETTINGS}>
				<br />
				<Definition />
				<br />
				<Checklist />
				<br />
				<Checkpoints />
			</TabPanel>
		</div>
	);
};

export default Settings;
