import { Button, IconButton, Tooltip } from '@mui/material';

const UniButtton = ({ id, className, color = 'black', disabled, icon, onClick, text, size, tooltip, type }) => {
	const button = (
		<Button id={id} disabled={disabled} className={className} size={size} onClick={onClick} color={color}>
			{icon}
			{text && <span style={{ marginLeft: '0.4rem' }}> {text}</span>}
		</Button>
	);

	const iconButton = (
		<IconButton id={id} disabled={disabled} className={className} size={size} onClick={onClick} color={color}>
			{icon}
		</IconButton>
	);

	return tooltip ? (
		<Tooltip title={tooltip}>
			<div className={className}>{type === 'icon' ? iconButton : button}</div>
		</Tooltip>
	) : (
		button
	);
};

export default UniButtton;
