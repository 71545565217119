import { csCZ as coreBgBG } from '@mui/material/locale';
import { createTheme } from '@mui/material/styles';
import { csCZ } from '@mui/x-data-grid';
import { csCZ as pickersBgBG } from '@mui/x-date-pickers/locales';

const theme = createTheme({
	palette: {
		primary: {
			dark: '#026AA2',
			main: '#0086C9',
			light: '#EAECF0',
		},
		secondary: {
			main: '#fff',
			light: '#fff',
		},
		black: {
			main: '#000',
		},
		error: {
			main: '#F44236',
			light: '#ffd9d9',
		},
		warning: {
			main: '#ffc400',
			light: '#ffcf33',
		},
		success: {
			main: '#4CAF50',
			light: '#c7f5c9',
		},
	},
	csCZ, // x-data-grid translations
	pickersBgBG, // x-date-pickers translations
	coreBgBG, 
});

export default theme;
