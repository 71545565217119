import { Button, TextField, Paper } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';
import { ROUTES, TAB_VISIBILITY_RIGHTS } from '../consts';
import messages from '../intl/messages';
import { loginUser } from '../store/auth/actions';
import { getIsUserLoggedIn } from '../store/auth/selectors';
import { showForgotPasswordModal } from '../store/modal/actions';

const useStyles = makeStyles()((theme) => ({
	bounding: {
		textAlign: 'center',
		'& .MuiPaper-root': {
			padding: '2rem',
			textAlign: 'left',
			display: 'inline-block',
			margin: '50px auto',
			'& .MuiTextField-root': {},
		},
	},
}));

const Login = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const isUserLoggedIn = useSelector(getIsUserLoggedIn);

	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const { classes } = useStyles();

	useEffect(() => {
		if (isUserLoggedIn) navigate(ROUTES.DASHBOARD.PATH);
	}, [isUserLoggedIn, navigate]);

	const handleLogin = async () => {
		const user = await dispatch(loginUser({ email, password }));

		if (user?.has2fa) navigate('/login/2fa');
		else navigate(TAB_VISIBILITY_RIGHTS[user.role][0]);
	};

	return (
		<div className={classes.bounding}>
			<Paper elevation={3}>
				<TextField
					label={<FormattedMessage {...messages.email} />}
					type="email"
					value={email}
					onChange={(e) => setEmail(e.target.value)}
				/>{' '}
				<TextField
					label={<FormattedMessage {...messages.password} />}
					type="password"
					autoComplete="current-password"
					value={password}
					onChange={(e) => setPassword(e.target.value)}
				/>
				<br />
				<br />
				<Button variant="contained" color="primary" onClick={handleLogin}>
					<FormattedMessage {...messages.login} />
				</Button>
				<Button
					sx={{ float: 'right' }}
					variant="flat"
					color="primary"
					onClick={() => dispatch(showForgotPasswordModal({ email }))}
				>
					<FormattedMessage {...messages.forgotPassword} />
				</Button>
			</Paper>
		</div>
	);
};

export default Login;
