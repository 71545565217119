import { Button, TextField, Paper, Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { makeStyles } from 'tss-react/mui';
import { ROUTES } from '../consts';
import messages from '../intl/messages';
import { forgetPassword } from '../store/auth/actions';
import { getIsUserLoggedIn } from '../store/auth/selectors';
import { setPageTitle } from '../store/ui/actions';

const useStyles = makeStyles()((theme) => ({
	bounding: {
		textAlign: 'center',
		'& .MuiPaper-root': {
			padding: '2rem',
			textAlign: 'left',
			display: 'inline-block',
			margin: '10rem auto',
			minWidth: '26rem',
			width: '20%',
			lineHeight: '1.6rem',
		},
	},
}));

const ResetChangePasswordToken = () => {
	const dispatch = useDispatch();
	const { classes } = useStyles();
	const navigate = useNavigate();
	const intl = useIntl();
	const isUserLoggedIn = useSelector(getIsUserLoggedIn);

	const [email, setEmail] = useState('');
	const [emailHelperText, setEmailHelperText] = useState(null);

	useEffect(() => {
		setEmailHelperText(null);
	}, [email]);

	useEffect(() => {
		dispatch(setPageTitle(intl.formatMessage(messages.sendNewPassword)));
	}, [dispatch, intl]);

	useEffect(() => {
		if (isUserLoggedIn) navigate(ROUTES.DASHBOARD.PATH);
	}, [isUserLoggedIn, navigate]);

	return (
		<div className={classes.bounding}>
			<Paper elevation={3}>
				<h3>
					<FormattedMessage {...messages.expiredCode} />
				</h3>
				<FormattedMessage {...messages.resetChangePasswordTokenDesc} />
				<br />
				<br />
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<TextField
							onChange={(e) => setEmail(e.target.value)}
							fullWidth
							label={<FormattedMessage {...messages.email} />}
							value={email}
							variant="outlined"
						/>
					</Grid>
					<Grid item xs={12}>
						<Button
							disabled={emailHelperText}
							variant="contained"
							color="primary"
							onClick={() => dispatch(forgetPassword({ email, intl }))}
						>
							<FormattedMessage {...messages.send} />
						</Button>{' '}
						<Button onClick={() => navigate('/login')}>
							<FormattedMessage {...messages.back} />
						</Button>
					</Grid>
				</Grid>
			</Paper>
		</div>
	);
};

export default ResetChangePasswordToken;
