import SaveCloseModalTemplate from './SaveCloseModalTemplate';

const ConfirmModal = ({ handler, title, button, text }) => {
	return (
		<SaveCloseModalTemplate title={title || button} saveBtnTitle={button} onSave={handler}>
			<span>{text}</span>
		</SaveCloseModalTemplate>
	);
};

export default ConfirmModal;
