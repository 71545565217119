import { Save, CheckCircle, HourglassEmpty, Error, Help } from '@mui/icons-material';
import { Grid, Paper, TextField } from '@mui/material';
import { yellow } from '@mui/material/colors';
import { equals } from 'ramda';
import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from 'tss-react/mui';
import { JOB_STATUS, SCALING_EXAM_DEPLOYMENTS, SCALING_EXAM_NODEPOOLS } from '../../consts';
import messages from '../../intl/messages';
import {
	editScaling,
	fetchScalingDeployments,
	fetchScalingNodes,
	fetchScalingDb,
	fetchScalingUserCount,
	fetchScalingStatus,
	fetchRunningExamsCount,
	fetchScalingSwitch,
} from '../../store/model/actions';
import {
	getScalingDeployments,
	getScalingNodes,
	getScalingDb,
	getScalingUsersCount,
	getShouldScaleModels,
	getScalingJobId,
	getScalingJobStatus,
	getRunningExamsCount,
} from '../../store/model/selectors';
import useInterval from '../../utils/useInterval';
import Switch from '../Switch';
import UniButtton from '../UniButtton';

const useStyles = makeStyles()((theme) => ({
	bounding: {
		'& .headingContainer': {
			display: 'flex',
			alignItems: 'flex-start',
		},
		padding: '0.7rem 2rem 2rem 2rem',
		fontSize: '0.9rem',
		'& .saveIcon': { float: 'right', marginTop: '7px' },
		'& .statusIcon': { float: 'left', marginTop: '13px' },
		'& .successIcon': {
			'& .MuiSvgIcon-root': {
				fill: theme.palette.success.main,
			},
		},
		'& .warningIcon': {
			'& .MuiSvgIcon-root': {
				fill: theme.palette.warning.main,
			},
		},
		'& .failedIcon': {
			'& .MuiSvgIcon-root': {
				fill: theme.palette.error.main,
			},
		},
		'& .row': {
			backgroundColor: 'white',
		},
		'&.draft': {
			background: yellow['100'],
		},
		'& h2': {
			display: 'inline-block',
			fontSize: '1.2rem',
			marginBottom: '2rem',
		},
		'& h4': {
			margin: '5px',
			marginLeft: 0,
		},
		'& .pr30': { paddingRight: '30px' },
		'& .ml10': { marginLeft: '10px' },
		'& .ml30': { marginLeft: '30px' },
		'& .mt20': { marginTop: '20px' },
		'& .mt50': { marginTop: '50px' },
		'& .mt55': { marginTop: '55px' },
		'& .mb50': { marginBottom: '50px' },
		'& .switchGrid': {
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'flex-start',
			width: '100%',
			marginLeft: '12px',
			'& .switchLabel': {
				minWidth: '60%',
			},
		},
		'& .runningExams': {
			fontWeight: 'bold',
			fontSize: '1rem',
			margin: '50px 0 0 12px',
		},
	},
}));

const getJobStatusIcon = (jobStatus) => {
	switch (jobStatus) {
		case JOB_STATUS.COMPLETED:
			return <UniButtton icon={<CheckCircle />} className="successIcon" tooltip={jobStatus} disabled />;
		case JOB_STATUS.RUNNING:
			return <UniButtton icon={<HourglassEmpty />} className="warningIcon" tooltip={jobStatus} disabled />;
		case JOB_STATUS.QUEUED:
			return <UniButtton icon={<HourglassEmpty />} className="warningIcon" tooltip={jobStatus} disabled />;
		case JOB_STATUS.FAILED:
			return <UniButtton icon={<Error />} className="failedIcon" tooltip={jobStatus} disabled />;
		default:
			return <UniButtton icon={<Help />} tooltip={jobStatus} disabled />;
	}
};

const ScalingExam = () => {
	const intl = useIntl();
	const shouldScaleModels = useSelector(getShouldScaleModels);
	const [stateShouldScaleModels, setStateShouldScaleModels] = useState(shouldScaleModels);
	const [shouldScaleBackend, setShouldScaleBackend] = useState(true);
	const [shouldScaleDb, setShouldScaleDb] = useState(true);
	const [wasSwitchChanged, setWasSwitchChanged] = useState(false);

	const { classes } = useStyles();
	const scalingDeployments = useSelector(getScalingDeployments).filter((deployment) =>
		SCALING_EXAM_DEPLOYMENTS.includes(deployment.name)
	);
	const scalingNodes = useSelector(getScalingNodes).filter((nodepool) =>
		SCALING_EXAM_NODEPOOLS.includes(nodepool.name)
	);
	const scalingDb = useSelector(getScalingDb);
	const scalingUsersCount = useSelector(getScalingUsersCount);
	const [stateUsersCount, setStateUsersCount] = useState(scalingUsersCount);
	const scalingJobId = useSelector(getScalingJobId);
	const scalingJobStatus = useSelector(getScalingJobStatus);
	const runningExamsCount = useSelector(getRunningExamsCount);
	const dispatch = useDispatch();
	const [isDraft, setIsDraft] = useState(false);
	const [isSaving, setIsSaving] = useState(false);

	useEffect(() => {
		dispatch(fetchScalingUserCount());
		dispatch(fetchScalingSwitch());
		dispatch(fetchScalingDeployments());
		dispatch(fetchScalingNodes());
		dispatch(fetchScalingDb());
		dispatch(fetchRunningExamsCount());
	}, [dispatch]);

	useEffect(() => {
		setStateUsersCount(scalingUsersCount);
	}, [scalingUsersCount]);

	useEffect(() => {
		setStateShouldScaleModels(shouldScaleModels);
	}, [shouldScaleModels]);

	// saving a draft UX
	useEffect(() => {
		if (equals(scalingUsersCount, stateUsersCount) && !wasSwitchChanged) {
			window.onbeforeunload = () => {};
			setIsDraft(false);
		} else if (!isDraft) {
			window.onbeforeunload = () => false;
			setIsDraft(true);
		}
	}, [isDraft, stateUsersCount, scalingUsersCount, wasSwitchChanged]);

	const handleSave = async () => {
		setIsSaving(true);
		await dispatch(editScaling(stateUsersCount, stateShouldScaleModels, shouldScaleBackend, shouldScaleDb));
		setIsSaving(false);
		setIsDraft(false);
		setWasSwitchChanged(false);
		window.onbeforeunload = () => {};
	};

	// continuous data fetching
	useInterval(
		() => {
			dispatch(fetchScalingDeployments());
			dispatch(fetchScalingNodes());
			dispatch(fetchScalingDb());
		},
		20000,
		true
	);

	useInterval(
		() => {
			dispatch(fetchRunningExamsCount());

			if (scalingJobId) {
				dispatch(fetchScalingStatus(scalingJobId));
			}
		},
		5000,
		true
	);

	return (
		<Paper elevation={3} className={`${classes.bounding} ${isDraft && 'draft'}`}>
			<Grid container spacing={0} direction="row" justifyContent="space-evenly" alignItems="flex-start">
				<Grid item xs={3} className="headingContainer">
					<h2>
						<FormattedMessage {...messages.scalingExam} />
					</h2>
					<span className="statusIcon">{getJobStatusIcon(scalingJobStatus, intl)}</span>
				</Grid>
				<Grid item xs={8} />
				<Grid item xs={1}>
					{isSaving ? (
						<span className="saveIcon">
							<FormattedMessage {...messages.uploading} /> ...
						</span>
					) : (
						<UniButtton
							tooltip={<FormattedMessage {...messages[isDraft ? 'saveInstructions' : 'noChanges']} />}
							color="primary"
							onClick={handleSave}
							icon={<Save fontSize="large" />}
							className="saveIcon"
							disabled={!isDraft}
						/>
					)}
				</Grid>
			</Grid>
			<Grid container spacing={0} direction="row" justifyContent="space-evenly" alignItems="flex-start">
				<Grid item xs={2}>
					<Grid item xs={12}>
						<TextField
							fullWidth
							value={stateUsersCount}
							label={<FormattedMessage {...messages.participantsCount} />}
							variant="outlined"
							onChange={(e) => setStateUsersCount(isNaN(e.target.value) ? stateUsersCount : Number(e.target.value))}
						/>
					</Grid>
					<Grid item xs={12} className="switchGrid mt20">
						<div className="switchLabel">
							<FormattedMessage {...messages.switchAINodepools} />
						</div>
						<Switch
							checked={stateShouldScaleModels}
							handleChange={() => {
								setStateShouldScaleModels(!stateShouldScaleModels);
								setWasSwitchChanged(true);
							}}
						/>
					</Grid>
					<Grid item xs={12} className="switchGrid">
						<div className="switchLabel">
							<FormattedMessage {...messages.switchBackendAutoscaling} />
						</div>
						<Switch
							checked={shouldScaleBackend}
							handleChange={() => {
								setShouldScaleBackend(!shouldScaleBackend);
								setWasSwitchChanged(true);
							}}
						/>
					</Grid>
					<Grid item xs={12} className="switchGrid">
						<div className="switchLabel">
							<FormattedMessage {...messages.switchDbAutoscaling} />
						</div>
						<Switch
							checked={shouldScaleDb}
							handleChange={() => {
								setShouldScaleDb(!shouldScaleDb);
								setWasSwitchChanged(true);
							}}
						/>
					</Grid>
					<Grid item xs={12} className="runningExams">
						<div>
							<FormattedMessage {...messages.runningExams} />
							<span className="ml10">{runningExamsCount}</span>
						</div>
					</Grid>
				</Grid>
				<Grid item xs={1} />
				<Grid item xs={9}>
					<Grid container spacing={0} direction="row" justifyContent="space-evenly" alignItems="flex-start">
						<Grid item xs={4}>
							<h4>
								<FormattedMessage {...messages.application} />
							</h4>
							{scalingDeployments.map((deployment, i) => (
								<div key={'deploymentName' + i}>{deployment.name}</div>
							))}
						</Grid>
						<Grid item xs={4} className="tac">
							<h4>
								<FormattedMessage {...messages.required} />
							</h4>
							{scalingDeployments.map((deployment, i) => (
								<div key={'replicas' + i}>{`${deployment.replicas || 0}x`}</div>
							))}
						</Grid>
						<Grid item xs={4} className="tac">
							<h4>
								<FormattedMessage {...messages.currentStatus} />
							</h4>
							{scalingDeployments.map((deployment, i) => (
								<div key={'availableReplicas' + i}>{`${deployment.availableReplicas || 0}x`}</div>
							))}
						</Grid>
					</Grid>
					<div className="mt50">
						<Grid container direction="row" justifyContent="space-evenly" alignItems="flex-start">
							<Grid item xs={2}>
								<h4>
									<FormattedMessage {...messages.nodepool} />
								</h4>
							</Grid>
							<Grid item xs={2}>
								<h4>
									<FormattedMessage {...messages.hardware} />
								</h4>
							</Grid>
							<Grid item xs={2}>
								<h4>
									<FormattedMessage {...messages.cpuLoad} />
								</h4>
							</Grid>
							<Grid item xs={2}>
								<h4>
									<FormattedMessage {...messages.scaling} />
								</h4>
							</Grid>
							<Grid item xs={1}>
								<h4>
									<FormattedMessage {...messages.minCount} />
								</h4>
							</Grid>
							<Grid item xs={1}>
								<h4>
									<FormattedMessage {...messages.maxCount} />
								</h4>
							</Grid>
							<Grid item xs={2}>
								<h4>
									<FormattedMessage {...messages.currentCount} />
								</h4>
							</Grid>
							<Grid item xs={2}>
								{scalingNodes.map((node, i) => (
									<div key={'nodepool' + i}>{node.name}</div>
								))}
							</Grid>
							<Grid item xs={2} className="tac">
								{scalingNodes.map((node, i) => (
									<div key={'vmSize' + i}>{node.vmSize}</div>
								))}
							</Grid>
							<Grid item xs={2}>
								{scalingNodes.map((node, i) => (
									<div key={'cpuLoad' + i}>{node.cpuLoad ? `${node.cpuLoad}%` : '-'}</div>
								))}
							</Grid>
							<Grid item xs={2} className="tac">
								{scalingNodes.map((node, i) => (
									<div key={'enableAutoScaling' + i}>
										{node.enableAutoScaling ? (
											<FormattedMessage {...messages.autoScaling} />
										) : (
											<FormattedMessage {...messages.manualScaling} />
										)}
									</div>
								))}
							</Grid>
							<Grid item xs={1} className="tac">
								{scalingNodes.map((node, i) => (
									<div key={'minCount' + i}>{node.minCount ? `${node.minCount}x` : '-'}</div>
								))}
							</Grid>
							<Grid item xs={1} className="tac">
								{scalingNodes.map((node, i) => (
									<div key={'maxCount' + i}>{node.maxCount ? `${node.maxCount}x` : '-'}</div>
								))}
							</Grid>
							<Grid item xs={2} className="tac">
								{scalingNodes.map((node, i) => (
									<div key={'currentCount' + i}>{`${node.count}x`}</div>
								))}
							</Grid>
						</Grid>
					</div>
					<div className="mt55 mb50">
						<Grid container direction="row" justifyContent="space-evenly" alignItems="flex-start">
							<Grid item xs={4}>
								<h4>
									<FormattedMessage {...messages.database} />
								</h4>
							</Grid>
							<Grid item xs={4}>
								<h4>
									<FormattedMessage {...messages.cpu} />
								</h4>
							</Grid>
							<Grid item xs={4}>
								<h4>
									<FormattedMessage {...messages.storageMb} />
								</h4>
							</Grid>
							<Grid item xs={4}>
								<div key={'dbName'}>{scalingDb?.name}</div>
							</Grid>
							<Grid item xs={4} className="tac">
								<div key={'dbCpu'}>{scalingDb?.resources?.cpu}</div>
							</Grid>
							<Grid item xs={4} className="tac">
								<div key={'dbStorageMb'}>{scalingDb?.resources?.storageMb}</div>
							</Grid>
						</Grid>
					</div>
				</Grid>
			</Grid>
		</Paper>
	);
};

export default ScalingExam;
