import { produce } from 'immer';
import { omit } from 'ramda';
import { LS_USER } from '../../consts';
import { AUTH_ACTION_TYPES as actionTypes } from '../auth/actions.js';
import { NOTIF_ACTION_TYPES as notifActionTypes } from '../notif/actions.js';

const initialState = {
	isUserLoggedIn: !!localStorage.getItem(LS_USER),
	isUserPasswordCorrect: !!localStorage.getItem(LS_USER),
	user: localStorage.getItem(LS_USER) ? JSON.parse(localStorage.getItem(LS_USER) || '{}') : {},
	users: {},
};

const auth = produce((state, action) => {
	const { type } = action;

	switch (type) {
		case notifActionTypes.ERROR:
			if (action.payload.message === 'Unauthorized')
				return { isUserLoggedIn: false, isUserPasswordCorrect: false, user: null, users: {} };
			break;
		case actionTypes.DELETE_USER:
			state.users = omit([action.payload.id], state.users);
			break;
		case actionTypes.LOGIN_USER:
			if (!action.payload.has2fa) state.isUserLoggedIn = true;
			state.isUserPasswordCorrect = true;
			state.user = action.payload;
			break;
		case actionTypes.LOGIN_USER_2FA:
			state.isUserLoggedIn = true;
			state.user = action.payload;
			break;
		case actionTypes.LOGOUT_USER:
			return { isUserLoggedIn: false, isUserPasswordCorrect: false, user: null, users: {} };
		case actionTypes.FETCH_USERS:
			state.users = action.payload;
			break;
		case actionTypes.SIGNUP_USER:
			state.users = { ...state.users, [action.payload.id]: { ...action.payload, skill: null } };
			break;
		case actionTypes.GET_USER_BY_TOKEN:
			state.user = action.payload;
			break;
		case actionTypes.ACTIVATE_USER:
			state.users = { ...state.users, [action.payload.id]: { ...state.users[action.payload.id], isActive: true } };
			break;
		case actionTypes.DEACTIVATE_USER:
			state.users = { ...state.users, [action.payload.id]: { ...state.users[action.payload.id], isActive: false } };
			break;
		default:
			return state;
	}
}, initialState);

export default auth;
