import { isEmpty } from 'ramda';
import { saveDatagridConfig, getDatagridConfig } from '../../api';
import keyMirror from '../../utils/keyMirror';
import { makeActionCreator } from '../../utils/redux';
import { getUser } from '../auth/selectors';

export const UI_ACTION_TYPES = keyMirror({
	SET_CURRENT_LANG_CODE: null,
	SET_CURRENT_LIST_TAB: null,
	SET_CURRENT_SECTION_TAB: null,
	SET_EXAMS_LIST_CONFIG: null,
	SET_PAGE_TITLE: null,
	SET_VIDEO_SEEK: null,
	SET_VIDEO_STATE: null,
	SET_XGRID_CONFIG: null,
	SET_IS_PIP: null,
	SET_TYPE_FILTER: null,
	SET_VIDEO_PLAYBACK_RATE: null,
});

const gridCallsTimeouts = {};

export const defaultConfig = {
	sortModel: [],
	filterModel: { items: [], logicOperator: 'and' },
	columnsOrder: [],
	pinnedColumns: { left: [], right: [] },
	invisibleComulmns: [],
};

export const setXGridConfig = (config, configEntity) => async (dispatch, getState) => {
	const isFilterValEmpty =
		!isEmpty(config.filterModel.items) &&
		!config.filterModel.items?.[0]?.value &&
		config.filterModel.items?.length === 1;

	const user = getUser(getState());

	const configPayload = isFilterValEmpty
		? {
				...config,
				filterModel: { ...config.filterModel, items: [...config.filterModel.items] },
		  }
		: config;

	if (gridCallsTimeouts[configEntity]) clearTimeout(gridCallsTimeouts[configEntity]);
	gridCallsTimeouts[configEntity] = setTimeout(() => {
		saveDatagridConfig(user.id, configEntity, JSON.stringify(configPayload));
	}, 2000);

	dispatch({
		type: UI_ACTION_TYPES.SET_XGRID_CONFIG,
		payload: {
			config: configPayload,
			configEntity,
		},
	});
};

export const resetXGridConfig = (configEntity) => async (dispatch, getState) => {
	const user = getUser(getState());
	await saveDatagridConfig(user.id, configEntity, JSON.stringify(defaultConfig));
	dispatch({
		type: UI_ACTION_TYPES.SET_XGRID_CONFIG,
		payload: {
			config: defaultConfig,
			configEntity,
		},
	});
};

export const loadXGridConfig = (configEntity) => async (dispatch, getState) => {
	return new Promise((resolve) => {
		const user = getUser(getState());

		if (!user) resolve(false);
		getDatagridConfig(user.id, configEntity).then((config) => {
			if (Object.keys(config).length > 0) {
				if (gridCallsTimeouts[configEntity]) clearTimeout(gridCallsTimeouts[configEntity]);
				dispatch({
					type: UI_ACTION_TYPES.SET_XGRID_CONFIG,
					payload: {
						config: config,
						configEntity,
					},
				});
				resolve(true);
			}
		});
	});
};

export const setCurrentListTab = makeActionCreator(UI_ACTION_TYPES.SET_CURRENT_LIST_TAB, 'tabId');
export const setCurrentLangCode = makeActionCreator(UI_ACTION_TYPES.SET_CURRENT_LANG_CODE, 'langCode');
export const setVideoSeek = makeActionCreator(UI_ACTION_TYPES.SET_VIDEO_SEEK, 'seek');
export const setVideoState = makeActionCreator(UI_ACTION_TYPES.SET_VIDEO_STATE, 'state');
export const setPageTitle = makeActionCreator(UI_ACTION_TYPES.SET_PAGE_TITLE, 'pageTitle');
export const setIsPip = makeActionCreator(UI_ACTION_TYPES.SET_IS_PIP, 'isPip');
export const setTypeFilter = makeActionCreator(UI_ACTION_TYPES.SET_TYPE_FILTER, 'typeFilter');
export const setVideoPlaybackRate = makeActionCreator(UI_ACTION_TYPES.SET_VIDEO_PLAYBACK_RATE, 'playbackRate');
