import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import thunk from 'redux-thunk';
import auth from './auth/reducers';
import modal from './modal/reducers';
import model from './model/reducers';
import notif from './notif/reducers';
import ui from './ui/reducers';

const USE_EXT = window.__REDUX_DEVTOOLS_EXTENSION__;
const composeEnhancers = USE_EXT
	? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({ trace: true, traceLimit: 25 })
	: compose;

const initialState = {};

const middleware = [thunk];

const reducers = combineReducers({
	auth,
	modal,
	model,
	notif,
	ui,
});

const store = createStore(reducers, initialState, composeEnhancers(applyMiddleware(...middleware)));

export default store;
