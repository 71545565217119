import { PlaylistAddCheck, Save } from '@mui/icons-material';
import { Grid, Paper, TextField } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import { yellow } from '@mui/material/colors';
import { equals, isEmpty, omit, uniq } from 'ramda';
import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from 'tss-react/mui';
import { postFile } from '../../api';
import messages from '../../intl/messages';
import { showEditTextModal } from '../../store/modal/actions';
import { editDefinition, fetchDefinitions } from '../../store/model/actions';
import { getCurrentConfigDefinition, getDefinitionsList } from '../../store/model/selectors';
import { setPageTitle } from '../../store/ui/actions';
import { getCurrentLangCode } from '../../store/ui/selectors';
import { prepareDefinitionForServer } from '../../store/utils';
import DateAndTimePicker from '../DateAndTimePicker';
import Switch from '../Switch';
import UniButtton from '../UniButtton';

const useStyles = makeStyles()((theme) => ({
	bounding: {
		padding: '0.2rem 2rem 2rem 2rem',
		fontSize: '0.9rem',
		'&.draft': {
			background: yellow['100'],
		},
		'& h2': {
			display: 'inline-block',
		},
		'& .saveIcon': { float: 'right', marginTop: '7px' },
		'& span.saveIcon': { margin: '19px' },
		'& .header': {
			fontWeight: 'bold',
			fontSize: '0.8rem',
			textAlign: 'center',
			padding: '10px 0',
		},
		'& .w100': {
			width: '100%',
		},
		'& .rules': {
			textAlign: 'center',
			display: 'flex',
			justifyContent: 'center',
			'& .MuiButtonBase-root': {
				minWidth: 'auto',
			},
		},
	},
}));

const Definition = () => {
	const intl = useIntl();
	const { classes } = useStyles();
	const definition = omit(['runningExamsCount', 'finishedExamsCount'], useSelector(getCurrentConfigDefinition) || {});
	const definitions = useSelector(getDefinitionsList);
	const [state, setState] = useState(definition);
	const dispatch = useDispatch();
	const [isDraft, setIsDraft] = useState(false);
	const [isSaving, setIsSaving] = useState(false);
	const terms = uniq(definitions.map((d) => d.term));
	const currentLangCode = useSelector(getCurrentLangCode);

	useEffect(() => {
		(async () => {
			await dispatch(fetchDefinitions());
			setState([]);
		})();
	}, [currentLangCode, dispatch]);

	useEffect(() => {
		if (isEmpty(state)) setState(definition);

		if (equals(prepareDefinitionForServer(definition), prepareDefinitionForServer(state))) {
			window.onbeforeunload = () => {};
			setIsDraft(false);
		} else if (!isDraft && !isEmpty(state)) {
			window.onbeforeunload = () => false;
			setIsDraft(true);
		}
	}, [definition, isDraft, state]);

	// Set page title for settings 2nd level
	useEffect(() => {
		if (definition?.name) {
			dispatch(setPageTitle(`${definition.name} - ${intl.formatMessage(messages.settings)}`));
		}
	}, [definition?.name, dispatch, intl]);

	const handleTextChange = ({ text, textType }) => setState({ ...state, [textType]: text });

	const handleSave = async () => {
		setIsSaving(true);
		await dispatch(editDefinition(state, intl));
		setIsSaving(false);
		window.onbeforeunload = () => {};
	};

	const handleSetNumber = (e, key) =>
		e.target.value > -1 ? setState({ ...state, [key]: Number(e.target.value) }) : undefined;

	return (
		!isEmpty(state) && (
			<Paper elevation={3} className={`${classes.bounding} ${isDraft && 'draft'}`}>
				<h2>
					<FormattedMessage {...messages.definition} />
				</h2>{' '}
				( definitionId: {state.id} )
				{isSaving ? (
					<span className="saveIcon">
						<FormattedMessage {...messages.uploading} /> ...
					</span>
				) : (
					<UniButtton
						tooltip={<FormattedMessage {...messages[isDraft ? 'saveDefinition' : 'noChanges']} />}
						color="primary"
						onClick={handleSave}
						icon={<Save fontSize="large" />}
						className="saveIcon"
						disabled={!isDraft}
					/>
				)}
				<br />
				<br />
				<Grid container spacing={2} className="row" direction="row" alignItems="flex-end">
					<Grid item xs={3}>
						<TextField
							fullWidth
							value={state.name}
							onChange={(e) => setState({ ...state, name: e.target.value })}
							label={<FormattedMessage {...messages.nameSubject} />}
							variant="outlined"
						/>
					</Grid>
					<Grid item xs={3}>
						<Autocomplete
							options={terms}
							freeSolo
							getOptionLabel={(term) => term}
							style={{ width: '100%' }}
							onChange={(e, term) => setState({ ...state, term })}
							value={state.term}
							renderInput={(params) => (
								<TextField
									{...params}
									onChange={(e) => setState({ ...state, term: e.target.value })}
									label={<FormattedMessage {...messages.term} />}
									variant="outlined"
								/>
							)}
						/>
					</Grid>
					<Grid item xs={3}>
						<DateAndTimePicker
							onChange={(v) => setState({ ...state, startsAt: v || state.startsAt })}
							classname="w100"
							label={<FormattedMessage {...messages.startsAt} />}
							defaultValue={state.startsAt}
						/>
					</Grid>
					<Grid item xs={3}>
						<DateAndTimePicker
							onChange={(v) => setState({ ...state, endsAt: v || state.endsAt })}
							classname="w100"
							label={<FormattedMessage {...messages.endsAt} />}
							defaultValue={state.endsAt}
						/>
					</Grid>
				</Grid>
				{/* ---- */}
				<Grid container spacing={2} className="header" direction="row" justifyContent="center" alignItems="center">
					<Grid item xs={3} />
					<Grid item xs={3} />
					<Grid item xs={1}>
						<FormattedMessage {...messages.logViolatedOnly} />
					</Grid>
					<Grid item xs={1}>
						<FormattedMessage {...messages.reviewerQueue} />
					</Grid>
					<Grid item xs={1}>
						<FormattedMessage {...messages.commissionerQueue} />
					</Grid>
					<Grid item xs={1}>
						<FormattedMessage {...messages.isOnboarding} />
					</Grid>
					<Grid item xs={1}>
						<FormattedMessage {...messages.proctoringNotification} />
					</Grid>
					<Grid item xs={1}>
						<FormattedMessage {...messages.GDPR} /> & <FormattedMessage {...messages.rules} />
					</Grid>
				</Grid>
				<Grid container spacing={2} className="row" direction="row" alignItems="flex-end">
					<Grid item xs={3} className="rules">
						<TextField
							className="w100"
							value={state.priority || ' '}
							onChange={(e) => handleSetNumber(e, 'priority')}
							label={<FormattedMessage {...messages.priority} />}
							variant="outlined"
							type="number"
						/>
					</Grid>
					<Grid item xs={3}>
						{!state.isOnboarding && (
							<TextField
								className="w100"
								value={state.participantsCount || ' '}
								onChange={(e) => handleSetNumber(e, 'participantsCount')}
								label={<FormattedMessage {...messages.expectedExams} />}
								variant="outlined"
								type="number"
							/>
						)}
					</Grid>
					<Grid item xs={1} className="rules">
						<Switch
							checked={state.logViolatedOnly}
							handleChange={() => setState({ ...state, logViolatedOnly: !state.logViolatedOnly })}
							labelPlacement="top"
						/>
					</Grid>
					<Grid item xs={1} className="rules">
						<Switch
							disabled={state.isOnboarding}
							checked={state.displayedInRevQueue}
							handleChange={() => setState({ ...state, displayedInRevQueue: !state.displayedInRevQueue })}
							labelPlacement="top"
						/>
					</Grid>
					<Grid item xs={1} className="rules">
						<Switch
							disabled={state.isOnboarding}
							checked={state.displayedInComQueue}
							handleChange={() => setState({ ...state, displayedInComQueue: !state.displayedInComQueue })}
							labelPlacement="top"
						/>
					</Grid>
					<Grid item xs={1} className="rules">
						<Switch
							disabled={state.displayedInComQueue || state.displayedInRevQueue}
							checked={state.isOnboarding}
							handleChange={() => setState({ ...state, isOnboarding: !state.isOnboarding })}
							labelPlacement="top"
						/>
					</Grid>
					<Grid item xs={1} className="rules">
						<Switch
							checked={state.showDesktopNotification}
							handleChange={() => setState({ ...state, showDesktopNotification: !state.showDesktopNotification })}
							labelPlacement="top"
						/>
					</Grid>
					<Grid item xs={1} className="rules">
						<UniButtton
							tooltip={<FormattedMessage {...messages.editGDPR} />}
							onClick={() =>
								dispatch(
									showEditTextModal({
										title: <FormattedMessage {...messages.GdprText} />,
										text: state.gdprText,
										handleTextChange,
										textType: 'gdprText',
										uploadFile: (file) => postFile(definition.id, file),
									})
								)
							}
							icon={<PlaylistAddCheck fontSize="large" />}
						/>
						<UniButtton
							tooltip={<FormattedMessage {...messages.editRules} />}
							onClick={() =>
								dispatch(
									showEditTextModal({
										title: <FormattedMessage {...messages.rulesText} />,
										text: state.rulesText,
										handleTextChange,
										textType: 'rulesText',
										uploadFile: (file) => postFile(definition.id, file),
									})
								)
							}
							icon={<PlaylistAddCheck fontSize="large" />}
						/>
					</Grid>
				</Grid>
				<br />
				<br />
				<Grid container spacing={2} className="row" direction="row" alignItems="flex-end">
					<Grid item xs={2} className="rules">
						<FormattedMessage {...messages.introChecksTimer} />
						<Switch
							checked={state.isTimerEnabled}
							handleChange={() => setState({ ...state, isTimerEnabled: !state.isTimerEnabled })}
							labelPlacement="top"
						/>
					</Grid>
					<Grid item xs={2}>
						<TextField
							disabled={!state.isTimerEnabled}
							className="w100"
							value={state.timerLimit || ' '}
							onChange={(e) => handleSetNumber(e, 'timerLimit')}
							label={<FormattedMessage {...messages.timerLimit} />}
							variant="outlined"
							type="number"
						/>
					</Grid>
					<Grid item xs={2}>
						<TextField
							disabled={!state.isTimerEnabled}
							className="w100"
							value={state.timerDisplay || ' '}
							onChange={(e) => handleSetNumber(e, 'timerDisplay')}
							label={<FormattedMessage {...messages.timerDisplay} />}
							variant="outlined"
							type="number"
						/>
					</Grid>
					<Grid item xs={2}>
						<TextField
							disabled={!state.isTimerEnabled}
							className="w100"
							value={state.timerWarning || ' '}
							onChange={(e) => handleSetNumber(e, 'timerWarning')}
							label={<FormattedMessage {...messages.timerWarning} />}
							variant="outlined"
							type="number"
						/>
					</Grid>
					<Grid item xs={2}>
						<div style={{ display: 'flex' }}>
							<Switch
								checked={state.autoArchive}
								handleChange={() => setState({ ...state, autoArchive: !state.autoArchive })}
								labelPlacement="top"
							/>
							<TextField
								disabled={!state.autoArchive}
								className="w100"
								value={state.archiveAfterDays || ' '}
								onChange={(e) => handleSetNumber(e, 'archiveAfterDays')}
								label={<FormattedMessage {...messages.archiveAfterDays} />}
								variant="outlined"
								type="number"
							/>
						</div>
					</Grid>
					<Grid item xs={2}>
						<div style={{ display: 'flex' }}>
							<Switch
								checked={state.autoDelete}
								handleChange={() => setState({ ...state, autoDelete: !state.autoDelete })}
								labelPlacement="top"
							/>
							<TextField
								disabled={!state.autoDelete}
								className="w100"
								value={state.deleteAfterDays || ' '}
								onChange={(e) => handleSetNumber(e, 'deleteAfterDays')}
								label={<FormattedMessage {...messages.deleteAfterDays} />}
								variant="outlined"
								type="number"
							/>
						</div>
					</Grid>
				</Grid>
			</Paper>
		)
	);
};

export default Definition;
