import { DragIndicator, PlaylistAddCheck, Save, ExpandLess, ExpandMore } from '@mui/icons-material';
import { Grid, Paper, TextField } from '@mui/material';
import { yellow } from '@mui/material/colors';
import { setAutoFreeze } from 'immer';
import cloneDeep from 'lodash/cloneDeep';
import { equals, find, isEmpty, omit, propEq, update } from 'ramda';
import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { ReactSortable } from 'react-sortablejs';
import { makeStyles } from 'tss-react/mui';
import { postFile } from '../../api';
import { CHECK } from '../../consts';
import messages from '../../intl/messages';
import { showEditTextModal } from '../../store/modal/actions';
import { editChecklist, fetchChecklists } from '../../store/model/actions';
import { getChecklist } from '../../store/model/selectors';
import { getCurrentLangCode } from '../../store/ui/selectors';
import Switch from '../Switch';
import UniButtton from '../UniButtton';
import SubChecklists from './SubChecklists';

const omitStateOfReactSortable = (state) =>
	state.map((check, i) => omit(['chosen', 'selected'], { ...check, position: i + 1 }));

const useStyles = makeStyles()((theme) => ({
	bounding: {
		padding: '0.2rem 2rem 2rem 2rem',
		fontSize: '0.9rem',
		'&.draft': {
			background: yellow['100'],
		},
		'& h2': {
			display: 'inline-block',
		},
		'& .saveIcon': { float: 'right', marginTop: '7px' },
		'& span.saveIcon': { margin: '19px' },
		'& .header': {
			fontWeight: 'bold',
			fontSize: '0.8rem',
			textAlign: 'center',
			padding: '10px 0',
		},
		'& .row': {
			textAlign: 'center',
			'& .durationInSeconds': {
				'& .MuiInputBase-root': {
					maxWidth: '80px',
					'& input': {
						textAlign: 'center',
					},
				},
			},
		},
	},
}));

// https://github.com/SortableJS/react-sortablejs/issues/237
// Even though it was deepCloned, the checklists object was not extensible and stopped working with react-sortablejs
setAutoFreeze(false);

const Checklist = () => {
	const { classes } = useStyles();
	const { settingsDefinitionId: definitionId } = useParams();
	// The during_exam checklist does not have to be displayed since it cannot be disabled anyway (at least the exam has to be there)
	const checklists = useSelector(getChecklist(definitionId)).filter((checklist) => checklist.runCheck !== CHECK.EXAM);
	const [state, setState] = useState(cloneDeep(checklists));
	const [isSaving, setIsSaving] = useState(false);
	const [toggleRoom, setToggleRoom] = useState(true);
	const dispatch = useDispatch();
	const [isDraft, setIsDraft] = useState(false);
	const currentLangCode = useSelector(getCurrentLangCode);
	const isRoomCheckEnabled = !!find(propEq('runCheck', 'room'), state)?.enabled;

	useEffect(() => {
		setToggleRoom(isRoomCheckEnabled);
	}, [isRoomCheckEnabled]);

	useEffect(() => {
		(async () => {
			await dispatch(fetchChecklists(definitionId));
			setState([]);
		})();
	}, [currentLangCode, definitionId, dispatch]);

	useEffect(() => {
		if (!isEmpty(checklists)) {
			if (isEmpty(state)) setState(checklists);

			if (equals(checklists, omitStateOfReactSortable(state))) {
				window.onbeforeunload = () => {};
				setIsDraft(false);
			} else if (!isDraft && !isEmpty(state)) {
				window.onbeforeunload = () => false;
				setIsDraft(true);
			}
		}
	}, [checklists, isDraft, state]);

	const handleSave = async () => {
		setIsSaving(true);
		await dispatch(editChecklist(omitStateOfReactSortable(state), definitionId));
		setIsSaving(false);
		window.onbeforeunload = () => {};
	};

	return (
		<Paper elevation={3} className={`${classes.bounding} ${isDraft && 'draft'}`}>
			<h2>
				<FormattedMessage {...messages.checklist} />
			</h2>
			{isSaving ? (
				<span className="saveIcon">
					<FormattedMessage {...messages.uploading} /> ...
				</span>
			) : (
				<UniButtton
					tooltip={<FormattedMessage {...messages[isDraft ? 'saveChecklist' : 'noChanges']} />}
					color="primary"
					onClick={handleSave}
					icon={<Save fontSize="large" />}
					className="saveIcon"
					disabled={!isDraft}
				/>
			)}
			<Grid container spacing={2} className="header" direction="row" justifyContent="center" alignItems="center">
				<Grid item xs={1}>
					<FormattedMessage {...messages.position} />
				</Grid>
				<Grid item xs={3}>
					<FormattedMessage {...messages.headline} />
				</Grid>
				<Grid item xs={2}>
					<FormattedMessage {...messages.instructionsText} />
				</Grid>
				<Grid item xs={2}>
					<FormattedMessage {...messages.durationInSecs} />
				</Grid>
				<Grid item xs={2}>
					<FormattedMessage {...messages.runCheck} />
				</Grid>
				<Grid item xs={1}>
					<FormattedMessage {...messages.enabled} />
				</Grid>
				<Grid item xs={1} />
			</Grid>
			<ReactSortable list={state} setList={setState} animation={150} handle=".handle">
				{state.map(
					(
						{ id, durationInSeconds, enabled, headline, instructionsText, position, runCheck, subChecklists },
						index
					) => {
						return (
							<Grid
								container
								spacing={2}
								key={`${position}-checklists`}
								className="row"
								justifyContent="center"
								alignItems="center"
							>
								<Grid item xs={1}>
									<span className="handle">
										<UniButtton tooltip={<FormattedMessage {...messages.dragToSort} />} icon={<DragIndicator />} />
									</span>
								</Grid>
								<Grid item xs={3}>
									<TextField
										fullWidth
										value={headline}
										onChange={(e) => setState(update(index, { ...state[index], headline: e.target.value }, state))}
										variant="outlined"
										size="small"
									/>
								</Grid>
								<Grid item xs={2}>
									<UniButtton
										tooltip={<FormattedMessage {...messages.editInstructionsText} />}
										onClick={() =>
											dispatch(
												showEditTextModal({
													title: `Instructions text (${runCheck})`,
													text: instructionsText,
													handleTextChange: ({ text, textType }) =>
														setState(update(index, { ...state[index], [textType]: text }, state)),
													textType: 'instructionsText',
													uploadFile: (file) => postFile(id, file),
												})
											)
										}
										icon={<PlaylistAddCheck fontSize="large" />}
									/>
								</Grid>
								<Grid item xs={2} className="durationInSeconds">
									{!subChecklists && (
										<TextField
											value={isNaN(durationInSeconds) || durationInSeconds === 0 ? '' : durationInSeconds}
											onChange={(e) =>
												setState(
													update(
														index,
														{
															...state[index],
															durationInSeconds: isNaN(e.target.value) === '' ? null : Number(e.target.value),
														},
														state
													)
												)
											}
											variant="outlined"
											size="small"
										/>
									)}
								</Grid>
								<Grid item xs={2}>
									<div>{runCheck}</div>
								</Grid>
								<Grid item xs={1}>
									<Switch
										checked={enabled}
										handleChange={(e) => setState(update(index, { ...state[index], enabled: !enabled }, state))}
										labelPlacement="top"
									/>
								</Grid>
								<Grid item xs={1}>
									{subChecklists && (
										<UniButtton
											color="primary"
											onClick={() => setToggleRoom(!toggleRoom)}
											icon={toggleRoom ? <ExpandLess /> : <ExpandMore />}
											className="saveIcon"
											disabled={!enabled}
										/>
									)}
								</Grid>
								{subChecklists && toggleRoom && (
									<SubChecklists
										subChecklists={subChecklists}
										state={state}
										setState={setState}
										runCheck={runCheck}
										parentChecklist={id}
									/>
								)}
							</Grid>
						);
					}
				)}
			</ReactSortable>
		</Paper>
	);
};

export default Checklist;
