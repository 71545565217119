import { produce } from 'immer';
import { isEmpty, mergeDeepRight, mergeLeft, omit } from 'ramda';
import { AUTHORIZED_ACTIONS, REVIEW, USER } from '../../consts';
import checkUserAuthorization from '../../utils/userRights';
import { AUTH_ACTION_TYPES as actionTypesAuth } from '../auth/actions.js';
import { MODEL_ACTION_TYPES as actionTypes } from '../model/actions.js';
import { mergeWithExistingExams } from '../utils.js';

const initialState = {
	checklists: {},
	checkpointProcessWasEdited: false,
	checkpoints: {},
	configDefinitionId: null,
	dashboardExams: { reviewedExamsByDefinition: [], postponedExams: [] },
	dashboardPreview: {},
	definitionId: null,
	definitions: {},
	evaluationDetails: [],
	evaluations: {},
	examId: null,
	exams: {},
	examsForReviewCount: 0,
	examsForCommissionerReviewCount: 0,
	filteredExams: { exams: [], count: 0 },
	instructions: {},
	allNotes: [],
	notes: {},
	pending: {},
	previousExams: [],
	scaling: {
		nodes: [],
		deployments: [],
		db: {},
		cluster: {},
		history: [],
		usersCount: 0,
		jobsCount: 0,
		jobId: null,
		jobStatus: null,
		runningExamsCount: null,
		runningJobsCount: null,
		shouldScaleModels: true,
		isAutoscalerEnabled: true,
	},
	selectedDefinitionIds: [],
	stats: [],
	violations: {},
};

const model = produce((state, action) => {
	const { payload, type } = action;
	switch (type) {
		case actionTypes.FETCH_CHECKLISTS:
			state.checklists[payload.definitionId] = payload.checklists;
			break;
		case actionTypes.EDIT_CHECKLIST:
			state.checklists[state.configDefinitionId] = payload;
			break;
		case actionTypes.FETCH_CHECKPOINTS:
			state.checkpoints[payload.definitionId] = payload.checkpoints;
			break;
		case actionTypes.SET_PROCESS_WAS_EDITED:
			state.checkpointProcessWasEdited = payload.value;
			break;
		case actionTypes.EDIT_CHECKPOINTS:
			state.checkpoints[state.configDefinitionId] = payload;
			break;
		case actionTypes.FETCH_DASHBOARD_EXAMS:
			state.dashboardExams = payload;
			break;
		case actionTypes.FETCH_DEFAULT_DEFINITION:
			state.definitions[payload.id] = payload;
			state.configDefinitionId = payload.id;
			break;
		case actionTypes.FETCH_DEFINITION:
			state.definitions[payload.id] = payload;
			state.configDefinitionId = payload.id;
			break;
		case actionTypes.EDIT_DEFINITION:
			for (const definition of Object.values(state.definitions)) {
				state.definitions[definition.id] = { ...definition, isDefault: false };
			}

			state.definitions[payload.id] = payload;
			break;
		case actionTypes.REMOVE_DEFINITION:
			delete state.definitions[payload.definitionId];
			break;
		case actionTypes.FETCH_DEFINITIONS:
			state.definitions = payload;
			break;
		case actionTypes.FETCH_VIOLATIONS:
			state.violations = payload.violations;
			break;
		case actionTypes.DELETE_VIOLATION:
			state.violations = omit(payload, state.violations);
			break;
		case actionTypes.FETCH_EVALUATIONS:
			state.evaluations = payload;
			break;
		case actionTypes.FETCH_EXAM:
			state.exams[payload.id] = { ...state.exams[payload.id], ...payload };
			break;
		case actionTypes.FETCH_EXAMS:
			const isSelectedDefinitions = !isEmpty(state.selectedDefinitionIds);
			state.exams = isSelectedDefinitions
				? mergeDeepRight(state.exams, payload)
				: mergeWithExistingExams(state.exams, payload);
			break;
		case actionTypes.FETCH_EXAMS_FOR_REVIEW_COUNT:
			state.examsForReviewCount = payload;
			break;
		case actionTypes.FETCH_EXAMS_FOR_COMMISSIONER_REVIEW_COUNT:
			state.examsForCommissionerReviewCount = payload;
			break;
		case actionTypes.FETCH_EXAMS_PENDING:
			state.pending[actionTypes.FETCH_EXAMS_PENDING] = payload;
			break;
		case actionTypes.FETCH_FILTERED_EXAMS:
			state.filteredExams = payload;
			break;
		case actionTypes.REMOVE_EXAMS:
			state.exams = omit(payload, state.exams);
			break;
		case actionTypes.FETCH_WEBCAM_URL:
			state.exams[state.examId] = { ...state.exams[state.examId], webcamUrl: payload };
			break;
		case actionTypes.FETCH_SCREEN_URL:
			state.exams[state.examId] = { ...state.exams[state.examId], screenUrl: payload };
			break;
		case actionTypes.FETCH_ID_URL:
			state.exams[state.examId] = { ...state.exams[state.examId], idUrl: payload.idUrl };
			break;
		case actionTypes.FETCH_FACE_URL:
			state.exams[state.examId] = { ...state.exams[state.examId], faceUrl: payload.faceUrl };
			break;
		case actionTypes.FETCH_ROOM_VIDEO_URL:
			state.exams[state.examId] = { ...state.exams[state.examId], roomVideoUrl: payload };
			break;
		case actionTypes.FETCH_QUEUE_REVIEW:
			state.examId = payload.examId;
			state.definitionId = payload.definitionId;
			break;
		case actionTypes.FETCH_SPECIALIZED_QUEUE_REVIEW:
			state.examId = payload.examId;
			state.definitionId = payload.definitionId;
			break;
		case actionTypes.FETCH_NOTES:
			state.notes = payload;
			break;
		case actionTypes.FETCH_ALL_NOTES:
			state.allNotes = payload;
			break;
		case actionTypes.DELETE_NOTE:
			delete state.notes[payload.noteId];
			break;
		case actionTypes.EDIT_INSTRUCTIONS:
			state.instructions = payload;
			break;
		case actionTypes.FETCH_INSTRUCTIONS:
			state.instructions = payload;
			break;
		case actionTypes.SET_DEFINITION_ID:
			state.definitionId = payload.id;
			break;
		case actionTypes.SET_CONFIG_DEFINITION_ID:
			state.configDefinitionId = payload.id;
			break;
		case actionTypes.SET_EXAM_ID:
			state.examId = payload.id;
			break;
		case actionTypes.ADD_VIOLATION:
			state.violations[payload.id] = payload;
			break;
		case actionTypes.SET_VIOLATION_REVIEW:
			state.violations[payload.violationId] = mergeLeft(
				omit(['violationId'], payload),
				omit(['checkpointGroup'], state.violations[payload.violationId])
			);
			break;
		case actionTypes.SET_VIOLATIONS_REVIEW:
			for (const violationId of payload.violationIds) {
				state.violations[violationId] = {
					...state.violations[violationId],
					[checkUserAuthorization(payload.userRole, AUTHORIZED_ACTIONS.REVIEW_AS_COMMISSIONER)
						? REVIEW.TYPES.COMMISSIONER
						: REVIEW.TYPES.REVIEWER]: 'OK',
				};
			}
			break;
		case actionTypesAuth.LOGOUT_USER:
			return initialState;
		case actionTypes.RESET_EXAM_PREVIEW_URLS:
			state.exams[state.examId] = {
				...state.exams[state.examId],
				webcamUrl: null,
				screenUrl: null,
				idUrl: null,
				faceUrl: null,
				roomVideoUrl: null,
			};
			break;
		case actionTypes.UPDATE_SELECTED_DEFINITIONS:
			state.selectedDefinitionIds = payload.ids;
			break;
		case actionTypes.FETCH_SCALING_NODES:
			state.scaling.nodes = payload;
			break;
		case actionTypes.FETCH_SCALING_DB:
			state.scaling.db = payload;
			break;
		case actionTypes.FETCH_SCALING_CLUSTER:
			state.scaling.cluster = payload;
			break;
		case actionTypes.FETCH_SCALING_HISTORY:
			state.scaling.history = payload;
			break;
		case actionTypes.FETCH_SCALING_DEPLOYMENTS:
			state.scaling.deployments = payload;
			break;
		case actionTypes.UPDATE_USER_COUNT:
			state.scaling.usersCount = payload;
			break;
		case actionTypes.UPDATE_SCALING_JOB_ID:
			state.scaling.jobId = payload.id;
			break;
		case actionTypes.FETCH_SCALING_USERS_COUNT:
			state.scaling.usersCount = payload.numberOfUsers;
			break;
		case actionTypes.FETCH_SCALING_SWITCH:
			state.scaling.shouldScaleModels = payload.shouldScaleModels;
			break;
		case actionTypes.FETCH_AUTOSCALER_STATUS:
			state.scaling.isAutoscalerEnabled = payload.isEnabled;
			break;
		case actionTypes.FETCH_SCALING_JOBS_COUNT:
			state.scaling.jobsCount = payload.numberOfJobs;
			break;
		case actionTypes.FETCH_SCALING_STATUS:
			state.scaling.jobStatus = payload.jobStatus;
			break;
		case actionTypes.FETCH_RUNNING_EXAMS_COUNT:
			state.scaling.runningExamsCount = payload.runningExamsCount;
			break;
		case actionTypes.FETCH_RUNNING_JOBS_COUNT:
			state.scaling.runningJobsCount = payload.runningJobsCount;
			break;
		case actionTypes.FETCH_STATS:
			state.stats = payload;
			break;
		case actionTypes.SEND_REVIEW:
			const { examId, violationNote, finalViolationMessage, isViolationIntentional, review, userId, userRole } =
				payload;
			const isCommissioner = userRole === USER.ROLES.COMMISSIONER;

			state.exams[examId] = {
				...state.exams[examId],
				[isCommissioner ? 'commissionerReview' : 'reviewerReview']: review,
				[isCommissioner ? 'commissionerReviewedAt' : 'reviewerReviewedAt']: new Date(),
				[isCommissioner ? 'commissionerReviewedBy' : 'reviewerReviewedBy']: userId,
				finalReview: isCommissioner ? review : state.exams[examId].finalReview,
				violationNote: violationNote || state.exams[examId].violationNote,
				finalViolationMessage: finalViolationMessage || state.exams[examId].finalViolationMessage,
				isViolationIntentional: isViolationIntentional || state.exams[examId].isViolationIntentional,
			};
			break;
		case actionTypes.UNASSIGN_REVIEW:
			state.exams[state.examId] = {
				...state.exams[state.examId],
				assignedTo: null,
			};
			break;
		case actionTypes.ASSIGN_REVIEW_COMMISSIONER:
			state.exams[payload.examId] = { ...state.exams[payload.examId], assignedTo: payload.userId };
			break;
		case actionTypes.FETCH_EVALUATION_DETAILS:
			state.evaluationDetails = payload;
			break;
		case actionTypes.FETCH_PREVIOUS_EXAMS:
			state.previousExams = payload;
			break;
		case actionTypes.RESTART_EXAM_PROCESSING:
			state.exams = payload.exams;
			break;
		default:
			return state;
	}
}, initialState);

export default model;
