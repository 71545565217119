import { Delete, Edit, Lock, LockOpen, RotateLeft } from '@mui/icons-material';
import { Grid, Paper } from '@mui/material';
import { useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from 'tss-react/mui';
import { ENTITIES, USER_ROLES, USER_SKILLS, YES_NO_VALUE_OPTIONS } from '../consts';
import messages from '../intl/messages';
import { renewPassword, fetchUsers } from '../store/auth/actions';
import { getUsers } from '../store/auth/selectors';
import { showEditUserModal, showActiveDeactiveRemoveUserModal } from '../store/modal/actions';
import {
	prepareUsers,
	selectOperators,
	selectOperatorsWEmpty,
	stringOperatorsExtended,
} from '../utils/prepareXGridData';
import DataGrid from './DataGrid';
import Signup from './Signup';
import UniButtton from './UniButtton';

const useStyles = makeStyles()((theme) => ({
	bounding: {
		padding: '25px',
		'& h2': {
			margin: '10px 0',
			padding: '0 10px',
		},
		'& .MuiPaper-root': {
			fontSize: '0.9rem',
			padding: '10px',
			margin: 10,
			position: 'relative',
			'& .exportCsv': {
				top: '77px',
			},
			'& .dataGridBounding': {
				height: 'calc(100vh - 180px)',
				minHeight: '400px',
			},
		},
		'& .MuiDataGrid-root': {
			'& .MuiDataGrid-toolbar': {
				padding: '7px 12px',
			},
			'& .MuiDataGrid-columnHeaders': {
				background: theme.palette.primary.main,
				color: theme.palette.secondary.main,
				'& .MuiSvgIcon-root': {
					fill: theme.palette.primary.dark,
				},
			},
		},
	},
}));

const Users = () => {
	const intl = useIntl();
	const { classes } = useStyles();
	const dispatch = useDispatch();
	const users = useSelector(getUsers);

	useEffect(() => {
		dispatch(fetchUsers());
	}, [dispatch]);

	const columns = [
		{
			field: 'name',
			headerName: intl.formatMessage(messages.firstName),
			width: 180,
			type: 'singleSelect',
			filterOperators: stringOperatorsExtended(intl),
		},
		{
			field: 'surname',
			headerName: intl.formatMessage(messages.lastName),
			width: 200,
			type: 'singleSelect',
			filterOperators: stringOperatorsExtended(intl),
		},
		{
			field: 'role',
			headerName: intl.formatMessage(messages.userRole),
			width: 220,
			type: 'singleSelect',
			filterOperators: selectOperators(USER_ROLES, intl, false),
		},
		{
			field: 'email',
			headerName: intl.formatMessage(messages.email),
			width: 380,
			type: 'string',
			filterOperators: stringOperatorsExtended(intl),
		},
		{
			field: 'has2fa',
			headerName: intl.formatMessage(messages.has2fa),
			width: 100,
			type: 'singleSelect',
			filterOperators: selectOperators(YES_NO_VALUE_OPTIONS, intl, false),
		},
		{
			field: 'phoneNumber',
			headerName: intl.formatMessage(messages.phoneNumber),
			width: 200,
			type: 'string',
			filterOperators: stringOperatorsExtended(intl),
		},
		{
			field: 'skill',
			headerName: intl.formatMessage(messages.userSkill),
			width: 220,
			type: 'singleSelect',
			filterOperators: selectOperatorsWEmpty(USER_SKILLS, intl),
		},
		{
			field: 'isActive',
			headerName: intl.formatMessage(messages.active),
			width: 120,
			type: 'singleSelect',
			filterOperators: selectOperators(YES_NO_VALUE_OPTIONS, intl, false),
		},
		{
			field: 'maxPostponedExams',
			headerName: intl.formatMessage(messages.deferredLimit),
			width: 220,
			type: 'number',
			renderCell: (params) => params.row.maxPostponedExams || '',
		},
		{
			field: 'action',
			headerName: intl.formatMessage(messages.actions),
			disableColumnMenu: true,
			width: 260,
			type: 'string',
			sortable: false,
			filterable: false,
			renderCell: (params) => {
				const isActive = params.row.isActive === intl.formatMessage(messages.yes);
				return (
					<>
						<UniButtton
							tooltip={<FormattedMessage {...messages.delete} />}
							onClick={() => dispatch(showActiveDeactiveRemoveUserModal({ user: params.row }))}
							icon={<Delete />}
							className="controls"
						/>
						<UniButtton
							tooltip={<FormattedMessage {...messages[isActive ? 'deactivateUser' : 'activateUser']} />}
							onClick={() =>
								dispatch(
									showActiveDeactiveRemoveUserModal({ activate: !isActive, deactivate: isActive, user: params.row })
								)
							}
							icon={!isActive ? <LockOpen /> : <Lock />}
							className="controls"
						/>
						<UniButtton
							tooltip={<FormattedMessage {...messages.resetPassword} />}
							onClick={() => dispatch(renewPassword(params.row.id, intl))}
							icon={<RotateLeft />}
							className="controls"
						/>
						<UniButtton
							tooltip={<FormattedMessage {...messages.editUser} />}
							onClick={() => dispatch(showEditUserModal({ userId: params.row.id }))}
							icon={<Edit />}
							className="controls"
						/>
					</>
				);
			},
		},
	];

	const dataGridConfig = {
		columns,
		gridId: ENTITIES.USERS,
		rows: prepareUsers(Object.values(users)),
	};

	return (
		<Grid container spacing={0} className={classes.bounding}>
			<Grid item xs={3}>
				<Paper elevation={3}>
					<h3>
						<FormattedMessage {...messages.createNewUser} />
					</h3>
					<Signup />
				</Paper>
			</Grid>
			<Grid item xs={9}>
				<Paper elevation={3}>
					<h2>
						<FormattedMessage {...messages.users} />
					</h2>
					<div className="dataGridBounding">
						<DataGrid {...dataGridConfig} />
					</div>
				</Paper>
			</Grid>
		</Grid>
	);
};

export default Users;
