import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';
import { setDefinitionId, setExamId } from '../../store/model/actions';
import { setCurrentListTab } from '../../store/ui/actions';
import { getUrlExternalId } from '../../utils/urlIds';

const useStyles = makeStyles()((theme) => ({
	root: {
		flexGrow: 1,
		padding: '20px 25px',

		'& .lists': {
			padding: '0 0 0 1.5rem',
		},

		'& .MuiBox-root': {
			padding: '25px !important',
		},
	},
}));

const Exams = ({ child }) => {
	const { classes } = useStyles();
	const dispatch = useDispatch();
	const { definitionId, examId } = useParams();

	useEffect(() => {
		if (examId && definitionId) handleSetExamId(examId, definitionId);
	});

	useEffect(() => {
		const externalId = getUrlExternalId();
		if (externalId && examId && definitionId) handleSetExamId(examId);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [examId]);

	const handleSetExamId = (id, defId) => {
		if (defId) dispatch(setDefinitionId(defId));
		dispatch(setExamId(id));
		dispatch(setCurrentListTab(3));
	};

	return <div className={classes.root}>{child}</div>;
};

export default Exams;
