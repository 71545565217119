import { PlayArrow, Stop } from '@mui/icons-material';
import { Paper } from '@mui/material';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import { makeStyles } from 'tss-react/mui';
import messages from '../../intl/messages';
import { showConfirmModal, hideModal } from '../../store/modal/actions';
import { startCluster, stopCluster } from '../../store/model/actions';
import UniButtton from '../UniButtton';
import aksIcon from './aks_icon.png';

const useStyles = makeStyles()((theme) => ({
	bounding: {
		padding: '0 2rem 2rem 2rem',
		fontSize: '0.9rem',
		'& .row': {
			display: 'flex',
			justifyContent: 'space-between',
			alignItems: 'center',
			'& .heading': {
				display: 'flex',
				justifyContent: 'flex-start',
				alignItems: 'center',
				paddingTop: '5px',
				'& img': {
					width: '47px',
					height: '40px',
					paddingBottom: '0.6rem',
				},
				'& h2': {
					display: 'inline-block',
					fontSize: '1.2rem',
					margin: '1.5rem 0 2rem 1rem',
				},
			},
			'& .buttons': {
				display: 'flex',
				justifyContent: 'flex-end',
				alignItems: 'center',
				'& .mr20': {
					marginRight: '20px',
				},
				'& .pr10': {
					paddingRight: '10px',
				},
			},
		},
		'& .clusterInfo': {
			display: 'flex',
			justifyContent: 'flex-start',
			alignItems: 'center',
			marginLeft: '5px',
			'& b': {
				minWidth: '60px',
			},
			'& p': {
				margin: '5px 0',
			},
		},
		'& .mb50': {
			marginBottom: '30px',
		},
	},
}));

const ScalingCluster = ({ aksCluster, isClusterRunning }) => {
	const dispatch = useDispatch();
	const { classes } = useStyles();
	const [isSaving, setIsSaving] = useState(false);

	const handleClusterStart = async () => {
		setIsSaving(true);
		await dispatch(startCluster());
		setIsSaving(false);
		window.onbeforeunload = () => {};
	};

	const handleClusterStop = async () => {
		await dispatch(hideModal());
		setIsSaving(true);
		await dispatch(stopCluster());
		setIsSaving(false);
		window.onbeforeunload = () => {};
	};

	const handleOpenConfirmModal = () => {
		dispatch(
			showConfirmModal({
				handler: async () => await handleClusterStop(),
				title: <FormattedMessage {...messages.confirmClusterStop} />,
				button: <FormattedMessage {...messages.confirmClusterStop} />,
			})
		);
	};

	return (
		<Paper elevation={3} className={classes.bounding}>
			<div className="row">
				<div className="heading">
					<img src={aksIcon} alt="aks-logo" />
					<h2>
						<FormattedMessage {...messages.aksCluster} />
					</h2>
				</div>
				{isSaving ? (
					<span>
						<FormattedMessage {...messages.uploading} /> ...
					</span>
				) : (
					<div className="buttons">
						<UniButtton
							color="primary"
							onClick={handleClusterStart}
							icon={<PlayArrow fontSize="large" />}
							disabled={
								isClusterRunning ||
								!aksCluster?.provisioningState ||
								aksCluster?.provisioningState === 'Starting' ||
								aksCluster?.provisioningState === 'Stopping'
							}
							text={<FormattedMessage {...messages.start} />}
							className="mr20 pr10"
						/>
						<UniButtton
							color="primary"
							onClick={handleOpenConfirmModal}
							icon={<Stop fontSize="large" />}
							disabled={
								!isClusterRunning ||
								!aksCluster?.provisioningState ||
								aksCluster?.provisioningState === 'Starting' ||
								aksCluster?.provisioningState === 'Stopping'
							}
							text={<FormattedMessage {...messages.stop} />}
							className="mr20 pr10"
						/>
					</div>
				)}
			</div>
			{aksCluster.name && (
				<span>
					<div className="clusterInfo">
						<b>
							<FormattedMessage {...messages.clusterName} />:
						</b>
						<p>{aksCluster.name}</p>
					</div>
					<div className="clusterInfo mb50">
						<b>
							<FormattedMessage {...messages.clusterStatus} />:
						</b>
						<p>
							{aksCluster.provisioningState} ({aksCluster.powerState})
						</p>
					</div>
				</span>
			)}
		</Paper>
	);
};

export default ScalingCluster;
