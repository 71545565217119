import { SnackbarProvider } from 'notistack';
import React from 'react';
import * as ReactDOM from 'react-dom/client';
import './index.css';
import { IntlProvider } from 'react-intl';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import { translation } from './intl';
import reportWebVitals from './reportWebVitals';
import store from './store';

const LOCALE = 'cs';

ReactDOM.createRoot(document.querySelector('#root')).render(
	<React.StrictMode>
		<Provider store={store}>
			<IntlProvider locale={LOCALE} messages={translation[LOCALE]}>
				<SnackbarProvider maxSnack={3} preventDuplicate>
					<BrowserRouter>
						<App />
					</BrowserRouter>
				</SnackbarProvider>
			</IntlProvider>
		</Provider>
	</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
