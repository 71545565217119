import { Add, Delete, DragIndicator, PlaylistAddCheck } from '@mui/icons-material';
import { Grid, TextField } from '@mui/material';
import { equals, remove } from 'ramda';
import React, { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import { ReactSortable } from 'react-sortablejs';
import { postFile } from '../../api';
import messages from '../../intl/messages';
import { showEditTextModal } from '../../store/modal/actions';
import keyMirror from '../../utils/keyMirror';
import Switch from '../Switch';
import UniButtton from '../UniButtton';

const CHANGE = keyMirror({
	ADD: null,
	DURATION: null,
	HEADLINE: null,
	INSTRUCTIONS: null,
	REMOVE: null,
	SORT: null,
	STATUS: null,
});

const subChecklistDefault = {
	headline: '',
	instructionsText: '',
	durationInSeconds: 120,
	enabled: true,
	chosen: false,
	selected: false,
};

const setPositions = (arr) => arr.map((item, i) => ({ ...item, position: i + 1 }));

const SubChecklists = ({
	subChecklists,
	state: parentState,
	setState: setParentState,
	runCheck: parentRunCheck,
	parentChecklist,
}) => {
	const dispatch = useDispatch();
	const allowAddSubcheck = subChecklists.length < 8;
	const allowRemoveSubcheck = subChecklists.length > 1;

	const handleChange = useCallback(
		(value, index, change) => {
			setParentState(
				parentState.map((checklist) =>
					checklist.runCheck !== parentRunCheck
						? checklist
						: {
								...checklist,
								subChecklists:
									CHANGE.ADD === change
										? setPositions([...checklist.subChecklists, { ...subChecklistDefault, parentChecklist }])
										: CHANGE.DURATION === change
										? checklist.subChecklists.map((subChecklist, i) =>
												index === i
													? { ...subChecklist, durationInSeconds: isNaN(value) === '' ? null : Number(value) }
													: subChecklist
										  )
										: CHANGE.HEADLINE === change
										? checklist.subChecklists.map((subChecklist, i) =>
												index === i ? { ...subChecklist, headline: value } : subChecklist
										  )
										: CHANGE.INSTRUCTIONS === change
										? checklist.subChecklists.map((subChecklist, i) =>
												index === i ? { ...subChecklist, [value.textType]: value.text } : subChecklist
										  )
										: CHANGE.REMOVE === change
										? setPositions(remove(index, 1, checklist.subChecklists))
										: CHANGE.SORT === change
										? value.map((subChecklist, i) => ({ ...subChecklist, position: i + 1 }))
										: CHANGE.STATUS === change
										? checklist.subChecklists.map((subChecklist, i) =>
												index === i ? { ...subChecklist, enabled: value } : subChecklist
										  )
										: null,
						  }
				)
			);
		},
		[parentChecklist, parentRunCheck, parentState, setParentState]
	);

	return (
		<Grid item xs={12} style={{ padding: '20px 60px 40px 60px' }}>
			<Grid container spacing={2} className="row" justifyContent="center" alignItems="center">
				<Grid item xs={1}>
					<FormattedMessage {...messages.position} />
				</Grid>
				<Grid item xs={5}>
					<FormattedMessage {...messages.headline} />
				</Grid>
				<Grid item xs={2}>
					<FormattedMessage {...messages.instructionsText} />
				</Grid>
				<Grid item xs={2}>
					<FormattedMessage {...messages.durationInSecs} />
				</Grid>
				<Grid item xs={1}>
					<FormattedMessage {...messages.enabled} />
				</Grid>
				<Grid item xs={1}>
					<UniButtton
						disabled={!allowAddSubcheck}
						icon={<Add size="small" />}
						onClick={() => handleChange(subChecklists, 0, CHANGE.ADD)}
					/>
				</Grid>
			</Grid>
			<ReactSortable
				list={subChecklists}
				setList={(subChecklistsSorted) =>
					!equals(subChecklists, subChecklistsSorted) ? handleChange(subChecklistsSorted, 0, CHANGE.SORT) : null
				}
				animation={150}
				handle=".handleSub"
			>
				{subChecklists.map(
					({ id, durationInSeconds, enabled, headline, instructionsText, position, runCheck }, index) => (
						<Grid
							container
							spacing={2}
							key={`${position}-subChecklists`}
							className="row"
							justifyContent="center"
							alignItems="center"
						>
							<Grid item xs={1}>
								<span className="handleSub">
									<UniButtton tooltip={<FormattedMessage {...messages.dragToSort} />} icon={<DragIndicator />} />
								</span>
							</Grid>
							<Grid item xs={5}>
								<TextField
									fullWidth
									value={headline}
									onChange={(e) => handleChange(e.target.value, index, CHANGE.HEADLINE)}
									variant="outlined"
									size="small"
								/>
							</Grid>
							<Grid item xs={2}>
								<UniButtton
									tooltip={<FormattedMessage {...messages.editInstructionsText} />}
									onClick={() =>
										dispatch(
											showEditTextModal({
												title: `Instructions text (${runCheck} - ${headline})`,
												text: instructionsText,
												handleTextChange: (props) => handleChange(props, index, CHANGE.INSTRUCTIONS),
												textType: 'instructionsText',
												uploadFile: (file) => postFile(id, file),
											})
										)
									}
									icon={<PlaylistAddCheck fontSize="large" />}
								/>
							</Grid>
							<Grid item xs={2} className="durationInSeconds">
								<TextField
									value={isNaN(durationInSeconds) || durationInSeconds === 0 ? '' : durationInSeconds}
									onChange={(e) => handleChange(e.target.value, index, CHANGE.DURATION)}
									variant="outlined"
									size="small"
								/>
							</Grid>
							<Grid item xs={1}>
								<Switch
									checked={enabled}
									handleChange={(e) => handleChange(!enabled, index, CHANGE.STATUS)}
									labelPlacement="top"
								/>
							</Grid>
							<Grid item xs={1}>
								<UniButtton
									icon={<Delete size="small" />}
									onClick={() => handleChange(subChecklists, index, CHANGE.REMOVE)}
									disabled={!allowRemoveSubcheck}
								/>
							</Grid>
						</Grid>
					)
				)}
			</ReactSortable>
		</Grid>
	);
};

export default SubChecklists;
