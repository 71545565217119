import { Grid, TextField } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import messages from '../intl/messages';

const Timespan = ({ className, handleTimeChange, time }) => {
	return (
		<Grid className={className} container spacing={1} direction="row" justifyContent="center" alignItems="center">
			<Grid item xs={4}>
				<TextField
					className="timeInput"
					value={time[0]}
					label={<FormattedMessage {...messages.hours} />}
					fullWidth
					variant="outlined"
					number
					onChange={(e) => handleTimeChange(e, 0)}
				/>
			</Grid>
			<Grid item xs={4}>
				<TextField
					className="timeInput"
					value={time[1]}
					label={<FormattedMessage {...messages.minutes} />}
					fullWidth
					variant="outlined"
					number
					onChange={(e) => handleTimeChange(e, 1)}
				/>
			</Grid>
			<Grid item xs={4}>
				<TextField
					className="timeInput"
					value={time[2]}
					label={<FormattedMessage {...messages.seconds} />}
					fullWidth
					variant="outlined"
					number
					onChange={(e) => handleTimeChange(e, 2)}
				/>
			</Grid>
		</Grid>
	);
};

export default Timespan;
